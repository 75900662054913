import React, { memo, useEffect, useState } from 'react'

import { Line } from 'react-chartjs-2'

function SaleChart({ data }) {
  const [date, setDate] = useState()
  const [value, setValue] = useState()

  useEffect(() => {
    if (data?.length > 0) {
      if (data[0].typeShowData === 'weeks') {
        const listDate = data?.map((element) => {
          const getDate = element.date.slice(0, 5)
          return `${getDate}`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else if (data[0].typeShowData === 'months') {
        const listDate = data?.map((element) => {
          const getMonth = element.monthInhYear.slice(0, 2)
          return `${getMonth} M`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else {
        const listDate = data?.map((element) => {
          const getYear = element.year
          return `${getYear} Y`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      }
    }
  }, [JSON.stringify(data)])

  return (
    <div>
      <Line
        data={{
          labels: date,
          datasets: [
            {
              label: 'TOTAL SALES: $',
              data: value,
              fill: true,
              backgroundColor: '#9AD0F5',
              borderColor: '#3EA5EB',
              tension: 0.4,
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </div>
  )
}

export default memo(SaleChart)
