/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import { ProductItemLazadaContext } from '_contexts/ProductItemLazadaContext'
import React, { useContext } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import RenderListVariants from '../RenderListVariants'

function Variations() {
  const {
    register,
    controlVariations,
    onAddOption,
    onRemoveOption,
    onCopyVariant,
    onRemoveVariant,
    onAddVariant,
  } = useContext(ProductItemLazadaContext)

  return (
    <>
      {controlVariations.map((variant, variantIndex) => (
        <div className='sub-item c-form is-active' key={`${variant?.id}-${variantIndex}`}>
          <div className='sub-item-div'>
            <div className='row mb-3'>
              <div className='col col-2 align-self-center col-index fw-semibold px-0'>
                Variant {variantIndex + 1}:
              </div>
              <div className='col col-8'>
                <input
                  type='text'
                  defaultValue={variant?.type}
                  placeholder='Enter Variant Type'
                  className='form-control form-control-line bg-light p-1 rounded-top-3 f-14 mx-auto'
                  {...register(`variations.${variantIndex}.type`)}
                />
              </div>
            </div>
            {variant?.list?.map((option, optionIndex) => (
              <div
                className='row sub-item-option align-items-center justify-content-between'
                key={`${option?.id}-${optionIndex}`}
              >
                <div className='col col-2'>
                  <span className='stt f-14'>Option Name:</span>
                </div>
                <div className='col-8'>
                  <input
                    type='text'
                    className='form-control form-control-line bg-light p-1 rounded-top-3 f-14 w-100'
                    placeholder='Enter Option Name'
                    defaultValue={option?.name}
                    {...register(`variations.${variantIndex}.list.${optionIndex}.name`)}
                  />
                </div>
                <div className='col-2'>
                  <div className='d-flex align-items-center justify-content-end'>
                    <AiOutlinePlus
                      className='btn-add-option cursor-pointer d-flex align-items-center justify-content-center me-2 border-0 rounded-circle bg-transparent shadow-none'
                      size={20}
                      onClick={() => onAddOption(variantIndex)}
                    />
                    <a
                      className='btn-remove cursor-pointer'
                      onClick={() => onRemoveOption(variantIndex, optionIndex)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='sub-item-div sub-item-div-last border-top-0 mt-3'>
            <div className='row'>
              <div className='col-6 ps-0'></div>
              <div className='col-6'>
                <div className='btn-action-01 d-flex w-100 align-items-center justify-content-end'>
                  <a className='btn-add-01 cursor-pointer' onClick={onAddVariant} />
                  <a
                    className='btn-copy me-0 cursor-pointer'
                    onClick={() => onCopyVariant(variantIndex)}
                  />
                  <a
                    className='btn-remove me-0 cursor-pointer'
                    onClick={() => onRemoveVariant(variantIndex)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <RenderListVariants />
    </>
  )
}

export default Variations
