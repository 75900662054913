import {
  LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID,
  LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
  PAYMENT_HITPAY,
  TRANSFER_BANKING,
} from '_utils/constant'
import { isNull } from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { MdPayment } from 'react-icons/md'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import RenderStatus from './RenderStatus'

function getPaymentTypeInfo(item) {
  let color
  let text
  switch (item.paymentType) {
    case PAYMENT_HITPAY:
      color = '#ff6903'
      text = 'Hitpay'
      break
    case TRANSFER_BANKING:
      color = '#15cdca'
      text = 'Banking'
      break
    default:
      color = '#6c757d'
      text = 'Free'
  }
  return { color, text }
}

const ListInvoiceLazada = ({
  loading,
  currentInvoices,
  togglePopover,
  popoverRef,
  popoverOpen,
  onPaymentHistory,
  onRemove,
  onCancel,
  tableHeaders,
}) => (
  <>
    {!loading && currentInvoices.length
      ? currentInvoices.map((item, index) => (
          <tr key={item.id}>
            <th scope='row'>{index + 1}</th>
            <td>
              {item.name} {item.price ? `($${item.price})` : null}
            </td>
            <td>
              <RenderStatus item={item} type='lazada' />
            </td>
            <td>
              <p className='mb-0'>
                <strong style={{ color: getPaymentTypeInfo(item).color }}>
                  {getPaymentTypeInfo(item).text}
                </strong>
              </p>
            </td>
            <td>
              <button
                className='bg-transparent border-0 shadow-none rounded-circle'
                style={{ fontSize: 16, height: 30, width: 30, lineHeight: '18px' }}
                id={`popover-${item.id}`}
                onClick={() => togglePopover(item.id)}
              >
                <BsInfoCircle />
              </button>
              <Popover
                innerRef={popoverRef}
                placement={'top'}
                isOpen={popoverOpen[item.id]}
                target={`popover-${item.id}`}
                toggle={() => togglePopover(item)}
              >
                <PopoverHeader>Info Subscription</PopoverHeader>
                <PopoverBody>
                  <p className='mb-0'>
                    Id: <strong>{item?.id}</strong>
                  </p>
                  <p className='mb-0'>
                    Created Date:
                    <strong>
                      {!isNull(item?.createdTimestamp || item?.createdDateTimestamp)
                        ? moment
                            .unix(item.createdTimestamp || item.createdDateTimestamp)
                            .format('DD/MM/YYYY')
                        : null}
                    </strong>
                  </p>
                  {item?.packageId && !isNull(item?.expireTimestamp) && (
                    <p className='mb-0'>
                      Expired Date:{' '}
                      <strong>{moment.unix(item?.expireTimestamp).format('DD/MM/YYYY')}</strong>
                    </p>
                  )}
                  {!isNull(item?.activeDateTimestamp) && (
                    <p className='mb-0'>
                      Approved Date:{' '}
                      <strong>{moment.unix(item?.activeDateTimestamp).format('DD/MM/YYYY')}</strong>
                    </p>
                  )}
                </PopoverBody>
              </Popover>
            </td>
            <td>
              <div className='d-flex align-items-center justify-content-between w-100'>
                {(() => {
                  const showConfirm =
                    item.paymentType === PAYMENT_HITPAY &&
                    (item.status === LAZADA_FEATURE_PAYMENT_STATUS_PENDING ||
                      item.paymentStatus === LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID) &&
                    !item.name.toLowerCase().includes('basic') &&
                    item.paymentStatus === LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID

                  const showRemove = showConfirm // Giả sử logic hiển thị Remove giống Confirm

                  const showCancel =
                    item.paymentType === TRANSFER_BANKING &&
                    item.status === LAZADA_FEATURE_PAYMENT_STATUS_PENDING

                  const showNoActions = !showConfirm && !showRemove && !showCancel

                  return (
                    <>
                      {showConfirm && (
                        <button
                          className='btn-payment mx-2 rounded d-flex align-items-center justify-content-center'
                          onClick={() => onPaymentHistory(item)}
                          style={{ fontSize: 12 }}
                        >
                          <MdPayment size={16} />
                          Confirm
                        </button>
                      )}

                      {showRemove && (
                        <button
                          className='btn btn-secondary rounded shadow-none h-auto mt-0'
                          style={{ padding: 5, fontSize: 12 }}
                          onClick={() => onRemove(item)}
                        >
                          Remove
                        </button>
                      )}

                      {showCancel && (
                        <button
                          className='btn btn-secondary rounded shadow-none h-auto mt-0'
                          style={{ padding: 5, fontSize: 12, width: 94 }}
                          onClick={() => onCancel(item)}
                        >
                          {loading ? 'canceling..' : 'Cancel'}
                        </button>
                      )}

                      {showNoActions && (
                        <span className='fw-bold text-dark-emphasis'>No Actions</span>
                      )}
                    </>
                  )
                })()}
              </div>
            </td>
          </tr>
        ))
      : null}

    {!loading && !currentInvoices.length ? (
      <tr>
        <td colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>
          No data
        </td>
      </tr>
    ) : null}
  </>
)

export default ListInvoiceLazada
