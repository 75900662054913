import { NO_FULFILLMENT, SELF_PICK_UP } from '_utils/constant'
import * as yup from 'yup'

// const allowedAccounts = getDataShopDetail()?.allowedAccount

export const createBuyerSchema = (activeTab) =>
  yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Please enter a valid email').required('Please enter your email'),
    // .test('is-allowed-email', 'Your email is not in the white list', (value) => {
    //   if (allowedAccounts?.length === 0) {
    //     return true // allowed accounts is empty, so all emails are allowed
    //   }
    //   return allowedAccounts?.includes(value)
    // }),
    phone: yup.string().typeError('Phone must be a number').required('Please enter your phone'),
    // .test('phone', 'Your phone is not in the white list', (value) => {
    //   if (!value) {
    //     return true // If value is empty, the 'required' validation will handle it
    //   }
    //
    //   if (allowedAccounts?.length === 0) {
    //     return true // allowed accounts is empty, so all phones are allowed
    //   }
    //
    //   // Remove non-numeric characters and check if the resulting value is in the allowed list
    //   const numericValue = value.replace(/\D/g, '') // Remove non-numeric characters
    //
    //   const isValidNumber = allowedAccounts?.includes(numericValue)
    //
    //   if (!isValidNumber) {
    //     throw new yup.ValidationError(
    //       'You entered an invalid phone number.',
    //       value,
    //       'is-allowed-phone'
    //     )
    //   }
    //
    //   return true
    //   // return allowedAccounts.includes(numericValue)
    // }),
    address: yup.string().when(['isGift', 'noLogin', 'fulfillmentType'], {
      is: (isGift, noLogin, fulfillmentType) =>
        isGift || activeTab === SELF_PICK_UP || noLogin || fulfillmentType === NO_FULFILLMENT,
      then: yup.string().notRequired(),
      otherwise: yup.string().required('Please enter your address'),
    }),
    isGift: yup.boolean(),
    fulfillmentType: yup.number(),
    giftInfo: yup.object().when('isGift', {
      is: true,
      then: yup.object().shape({
        name: yup.string().required('Gift name is required'),
        phone: yup.string().required('Gift phone is required'),
        address: yup.string().required('Gift address is required'),
        email: yup.string().required('Gift email is required'),
      }),
      otherwise: yup.object().notRequired(),
    }),
  })
