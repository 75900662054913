import { convertToCurrentGMT } from '_utils/functions/converter'
import { isEmpty } from 'lodash'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'

const ContactDetails = () => {
  // const shopDetail = getDataShopDetail()
  const { orderDetailsByRef } = useSelector((state) => state.order)
  const convertRangeTimeOrderToArray =
    orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.time?.split('-') ||
    orderDetailsByRef?.orderPickup?.pickupBookingDate?.time?.split('-')

  const dateOrder =
    orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.date ||
    orderDetailsByRef?.orderPickup?.pickupBookingDate?.date

  const convertRangeTimeOrderToCurrentGMT = convertRangeTimeOrderToArray
    ?.map((time) => {
      const currentTime = convertToCurrentGMT(`${dateOrder} ${time.trim()}`)

      return currentTime?.slice(11)
    })
    ?.join(' - ')

  return (
    <div
      className='bg-white rounded contact-details-info p-3 mb-3'
      style={{
        color: '#919192',
      }}
    >
      <div className='row'>
        <div className='col-4'>
          <strong>Name:</strong>
        </div>
        <div className='col-8 text-break'>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.name ||
            orderDetailsByRef?.orderDelivery?.uName ||
            orderDetailsByRef?.orderPickup?.uName ||
            orderDetailsByRef?.uName}
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <strong>Email:</strong>
        </div>
        <div className='col-8 text-break'>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.email ||
            orderDetailsByRef?.orderDelivery?.uEmail ||
            orderDetailsByRef?.orderPickup?.uEmail ||
            orderDetailsByRef?.uEmail}
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <strong>Phone:</strong>
        </div>
        <div className='col-8 text-break'>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.phone ||
            orderDetailsByRef?.orderDelivery?.uPhone ||
            orderDetailsByRef?.orderPickup?.uPhone ||
            orderDetailsByRef?.uPhone}
        </div>
      </div>
      {(!isEmpty(orderDetailsByRef?.orderPickup) || !isEmpty(orderDetailsByRef?.orderDelivery)) && (
        <>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.address ||
          orderDetailsByRef?.orderDelivery?.uAddress ||
          orderDetailsByRef?.uAddress ? (
            <div className='row'>
              <div className='col-4'>
                <strong>Address:</strong>
              </div>
              {/* orderDetailsByRef?.orderPickup?.pickupAddress || */}
              <div className='col-8 text-break'>
                {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.address ||
                  orderDetailsByRef?.orderDelivery?.uAddress ||
                  orderDetailsByRef?.uAddress}
              </div>
            </div>
          ) : null}
        </>
      )}

      {!isEmpty(orderDetailsByRef?.orderDelivery) ? (
        <>
          <div className='row'>
            <div className='col-4'>
              <strong>Delivery zone:</strong>
            </div>
            <div className='col-8 text-break'>
              {orderDetailsByRef?.orderDelivery?.deliveryOptionName}

              <span className={'text-primary ms-1'}>
                (
                {orderDetailsByRef?.orderDelivery?.deliveryFee ? (
                  <CurrencyFormat
                    value={orderDetailsByRef?.orderDelivery?.deliveryFee || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ) : (
                  'Free'
                )}
                )
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <strong>Order datetime:</strong>
            </div>
            <div className='col-8 text-break'>
              {orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.date}, (
              {convertRangeTimeOrderToCurrentGMT})
            </div>
          </div>
        </>
      ) : null}

      {!isEmpty(orderDetailsByRef?.orderPickup) ? (
        <>
          <div className='row'>
            <div className='col-4'>
              <strong>Pickup zone:</strong>
            </div>
            <div className='col-8 text-break'>
              {orderDetailsByRef?.orderPickup?.pickupOptionName}
              {orderDetailsByRef?.orderPickup?.pickupAddress && (
                <span>, {orderDetailsByRef?.orderPickup?.pickupAddress}</span>
              )}
              <span className={'text-primary ms-1'}>
                (
                {orderDetailsByRef?.orderPickup?.pickupFee &&
                orderDetailsByRef?.orderPickup?.pickupFee > 0 ? (
                  <CurrencyFormat
                    value={orderDetailsByRef?.orderPickup?.pickupFee || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ) : (
                  'Free'
                )}
                )
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <strong>Date-time:</strong>
            </div>
            <div className='col-8 text-break'>
              {orderDetailsByRef?.orderPickup?.pickupBookingDate?.date} - (
              {convertRangeTimeOrderToCurrentGMT})
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ContactDetails
