import React, { useEffect, useRef, useState } from 'react'
import { BsTrashFill } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { setCategoryName, getCreateShopData, setCreateShopData } from '_utils/localData'

function CategoryItem({ handleRemoveCategory, index, categoryName, setListProduct }) {
  const history = useHistory()
  const categoryRef = useRef('')
  const typingTimeoutRef = useRef(null)
  const createShopData = getCreateShopData()
  const productByIndex = createShopData?.products ? createShopData?.products[index] : undefined

  const [isError, setIsError] = useState(false)
  const [text, setText] = useState(categoryName || categoryRef.current.value)
  const handleAddItemForCategory = () => {
    if (categoryRef.current.value) {
      setCategoryName(categoryRef.current.value)
      history.push(`/virtual-shop/add-items?indexItem=${index}`)
    } else {
      setIsError(true)
    }
  }

  useEffect(() => {
    setText(categoryName)
  }, [categoryName])

  const handleOnChange = (e) => {
    setText(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (productByIndex) {
        const newArrayProduct = []
        const changeCategoryName = {
          ...productByIndex,
          category: e.target.value,
        }
        for (let i = 0; i < createShopData?.products.length; i++) {
          if (i === index) {
            newArrayProduct.push(changeCategoryName)
          } else {
            newArrayProduct.push(createShopData?.products[i])
          }
        }
        const dataShop = {
          ...createShopData,
          products: newArrayProduct,
        }
        setCreateShopData(dataShop)
        setListProduct(newArrayProduct)
      }
    }, 500)
  }

  return (
    <div
      className='box-add-category p-3 rounded shadow-sm border mb-3'
      style={{ backgroundColor: '#ECFBFB' }}
    >
      <div className='d-flex align-items-center mb-3 w-100'>
        <span className='me-3'>Category:</span>
        <input
          ref={categoryRef}
          type='text'
          className='form-control-sm w-100 border'
          placeholder='Set Meal'
          value={text}
          onChange={(e) => handleOnChange(e)}
        />
        {isError ? <small className='text-danger'>This field is required</small> : ''}
      </div>
      <div className='d-flex align-items-end'>
        <button
          type='submit'
          className='btn btn-primary rounded text-white'
          onClick={handleAddItemForCategory}
        >
          Add / Edit Items
        </button>
        <button
          className='bg-transparent border-0 ms-2'
          onClick={() => handleRemoveCategory(index)}
        >
          <BsTrashFill size={30} color='#A6DEDD' />
        </button>
      </div>
    </div>
  )
}

export default CategoryItem
