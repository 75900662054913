import { LIST_TIME_2 } from '_utils/constant'
import React, { useEffect, useState } from 'react'

const Timer = (props) => {
  const { setHours, Hours } = props
  const [hourStart, setHourStart] = useState(Hours.hourStart)
  const [hourEnd, setHourEnd] = useState(Hours.hourEnd)

  useEffect(() => {
    setHours({
      ...Hours,
      hourStart,
    })
  }, [hourStart])

  useEffect(() => {
    setHours({
      ...Hours,
      hourEnd,
    })
  }, [hourEnd])

  return (
    <div
      className='group-hour-delivery d-flex align-items-center justify-content-between flex-wrap mb-3'
      style={{
        gap: '0 1rem',
      }}
    >
      <p className='w-100 mb-1'>Opening Time</p>
      <div className='input-delivery-hour flex-fill'>
        <select
          name='timer-start-delivery'
          id='timer-start-delivery'
          value={hourStart}
          className='shadow-sm border-0 py-1 px-2 rounded w-100'
          onChange={(e) => setHourStart(e.target.value)}
        >
          {LIST_TIME_2.map((item, index) => (
            <option key={index}>{item.text}</option>
          ))}
        </select>
      </div>

      <div className='input-delivery-hour text-center flex-fill'>
        <select
          name='timer-end-delivery'
          id='timer-end-delivery'
          value={hourEnd}
          className='py-1 px-2 rounded shadow-sm border-0 w-100'
          onChange={(e) => setHourEnd(e.target.value)}
        >
          {LIST_TIME_2.map((item, index) => (
            <option key={index}>{item.text}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Timer
