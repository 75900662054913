import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function ProductName({ productName, watchName, register }) {
  const [isEditName, setIsEditName] = useState(false)

  const { isSavedProduct } = useSelector((state) => state.product)

  useEffect(() => {
    if (isSavedProduct) setIsEditName(false)
  }, [isSavedProduct])

  const toggle = () => setIsEditName(!isEditName)

  return (
    <div className='cursor-pointer' style={{ overflowWrap: 'anywhere' }}>
      {(!isEditName && !productName) || !watchName ? (
        <div className='text-content' onClick={toggle}>
          Add name
        </div>
      ) : (
        <div className='text-content' onClick={toggle}>
          {watchName}
        </div>
      )}

      <Modal isOpen={isEditName} toggle={toggle} className='modal-edit-product'>
        <ModalHeader toggle={toggle}>{productName}</ModalHeader>
        <ModalBody>
          <label htmlFor='product-price'>Enter name:</label>
          <input
            type='text'
            defaultValue={watchName}
            className={`form-control-sm border w-100 ${isEditName ? 'mb-2' : 'mb-0'}`}
            {...register('name', { required: true })}
          />
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-submit' onClick={toggle}>
            Ok
          </button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ProductName
