import { clearCart, clearOrderDetailsByRef } from '_redux/modules/order'
import { getCartCheckout, removeCartCheckout, removeCartOrderDetailsByRef } from '_utils/localData'
import { isNull, isUndefined } from 'lodash'
import React from 'react'
import { FaShoppingCart } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

function ButtonCart() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderDetailsByRef } = useSelector((state) => state.order)
  const { cart: cartRedux } = useSelector((state) => state.order)
  const cart = cartRedux.length ? cartRedux : getCartCheckout()
  const shopSlugBefore = getCartCheckout()?.shopSlug

  const currentUrl = window.location.href
  const urlParts = currentUrl.split('/')
  const orderIdParams = urlParts[urlParts.length - 1]
  const isOrderRef = orderIdParams === orderDetailsByRef?.ref

  const onClearCart = () => {
    dispatch(clearCart())
    dispatch(clearOrderDetailsByRef())
    removeCartOrderDetailsByRef()
    removeCartCheckout()
  }

  const handleRedirect = () => {
    if (isOrderRef) return

    if (cart?.orderList?.length === 0 || isNull(cart)) {
      history.push('/virtual-shop/list')
    } else if (cart?.orderList?.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          onClearCart()
          if (isUndefined(shopSlugBefore)) {
            const shopSlug =
              cart?.shopLink?.split('/')[cart?.shopLink?.split('/')?.length - 1] || cart?.shopLink

            history.push(`/${shopSlug}`)
          }
          history.push(`/${shopSlugBefore}`)
        }
      })
    }
  }

  return (
    <button className='btn-cart float f-16 translate-middle-x' onClick={handleRedirect}>
      <FaShoppingCart color='white' />
      {cart?.orderList?.length > 0 ? (
        <span
          className='badge bg-danger top-0 position-absolute rounded-pill'
          style={{ padding: '6px 10px', transform: 'translate(18px, -10px)' }}
        >
          {cart?.orderList?.reduce((acc, curr) => acc + curr?.quantityInput, 0)}
        </span>
      ) : null}
    </button>
  )
}

export default ButtonCart
