import { NUMBER_ENLARGE_DEFAULT, SIZE_IMAGE } from '_utils/constant'
import 'photoswipe/dist/default-skin/default-skin.css'
import 'photoswipe/dist/photoswipe.css'
import { useEffect, useState } from 'react'

import { Gallery, Item } from 'react-photoswipe-gallery'

export default function MyGallery({ image }) {
  const [dimensions, setDimensions] = useState({
    dimensions: {
      height: SIZE_IMAGE,
      width: SIZE_IMAGE,
    },
  })
  // get Size screen
  const screenSize = {
    height: window.innerHeight,
    width: window.innerWidth,
  }
  const [numberEnlarge, setNumberEnlarge] = useState(NUMBER_ENLARGE_DEFAULT)
  const [imageSrc, setImageSrc] = useState(image)

  // get Size image
  function onImgLoad({ target: img }) {
    setDimensions({
      dimensions: {
        height: img.offsetHeight,
        width: img.offsetWidth,
      },
    })
  }
  useEffect(() => {
    if (image && typeof image === 'object') {
      setImageSrc(image[0])
    } else {
      setImageSrc(image)
    }
  }, [image])

  useEffect(() => {
    if (screenSize.width && dimensions && dimensions.dimensions && dimensions.dimensions.width) {
      // enlargement ratio
      const newNumberEnlarge = Math.floor(screenSize.width / dimensions.dimensions.width)
      if (newNumberEnlarge < numberEnlarge) {
        setNumberEnlarge(newNumberEnlarge)
      } else {
        setNumberEnlarge(NUMBER_ENLARGE_DEFAULT)
      }
    }
  }, [
    dimensions?.dimensions?.height,
    dimensions?.dimensions?.width,
    screenSize?.width,
    screenSize?.height,
  ])
  return (
    <Gallery>
      <Item
        original={imageSrc}
        thumbnail={imageSrc}
        width={dimensions.dimensions.width * numberEnlarge}
        height={dimensions.dimensions.height * numberEnlarge}
      >
        {({ ref, open }) => <img ref={ref} onClick={open} src={imageSrc} onLoad={onImgLoad} />}
      </Item>
    </Gallery>
  )
}
