import { PLATFORM_LAZADA } from '_utils/constant'
import React from 'react'
import { useSelector } from 'react-redux'
import ListProduct from '../CoKii/ListProduct'
import ListProductLazada from '../Lazada/ListProductLazada'

function RenderListProduct({
  listProductFilter,
  isPushingLaz,
  setIsPushingLaz,
  isRemovingLaz,
  setIsRemovingLaz,
  isUpdatingVariationLaz,
  setIsUpdatingVariationLaz,
  setPreventFetchVariationProduct,
  preventFetchVariationProduct,
  searchString
}) {
  const { tabSelected } = useSelector((state) => state.product)

  if (tabSelected === PLATFORM_LAZADA) {
    return (
      <ListProductLazada
        isPushingLaz={isPushingLaz}
        setIsPushingLaz={setIsPushingLaz}
        isRemovingLaz={isRemovingLaz}
        setIsRemovingLaz={setIsRemovingLaz}
        isUpdatingVariationLaz={isUpdatingVariationLaz}
        setIsUpdatingVariationLaz={setIsUpdatingVariationLaz}
        setPreventFetchVariationProduct={setPreventFetchVariationProduct}
        preventFetchVariationProduct={preventFetchVariationProduct}
        searchString={searchString}
      />
    )
  }

  return <ListProduct listProductFilter={listProductFilter} />
}

export default RenderListProduct
