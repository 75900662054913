import LoadingOverlay from '_components/LoadingOverlay'
import Pagination from '_components/Pagination'
import AlertIcon from '_images/alert-cropped.png'
import { GMT_0, SHORT_DATE } from '_utils/constant'
import { convertTimestampToCurrentGMT } from '_utils/functions/converter'
import parse from 'html-react-parser'
import '../../style.scss'

import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const PageSize = 10

const Item = ({ notification }) => {
  const { createdAt, senderName, title, message } = notification

  return (
    <div className='noti-list-item row align-items-center'>
      <div className='col-2'>
        <div className='d-flex flex-column align-items-center'>
          <span className='fw-bold text-center' style={{ color: '#0fbdba' }}>
            {title}
          </span>
          <span className='fw-bold'>
            {moment
              .unix(convertTimestampToCurrentGMT(createdAt))
              .utcOffset(GMT_0)
              .format(SHORT_DATE)}
          </span>
        </div>
      </div>
      <div className='col-7 text-message'>{parse(message)}</div>
      <div className='col-2 text-right text-center font-semibold' style={{ color: '#f06a0f' }}>
        {senderName}
      </div>
    </div>
  )
}

const NotificationList = ({ notifications }) => {
  const { loadingNotification } = useSelector((state) => state.user)
  const [currentPage, setCurrentPage] = useState(1)

  const currentNotifications = useMemo(() => {
    if (notifications.length === 0) {
      return []
    }

    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return notifications.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, notifications])

  if (!loadingNotification && !currentNotifications.length === 0) {
    return (
      <div className='noti-list'>
        <div className='noti-list-item row'>
          <img
            src={AlertIcon}
            alt='icon-bell'
            className='mx-auto mb-3'
            style={{
              width: 200,
              mixBlendMode: 'multiply',
            }}
          />
          <h3 className='col-12 text-secondary text-center'>No notification</h3>
        </div>
      </div>
    )
  }

  if (loadingNotification) <LoadingOverlay />

  return (
    <React.Fragment>
      {/* <div className='c-txt-14 text-center text-underline'>Notification</div> */}
      <div id='itemList' className='noti-list'>
        {currentNotifications.map((notification) => (
          <Item key={Math.random().toString(36).substring(2, 9)} notification={notification} />
        ))}
      </div>
      <Pagination
        className='pagination-bar mt-3'
        currentPage={currentPage}
        totalCount={notifications.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </React.Fragment>
  )
}

export default NotificationList
