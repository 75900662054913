/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import { TourProvider } from '@reactour/tour'
import userApi from '_api/user'
import Footer from '_components/Footer'
import LoadingOverlay from '_components/LoadingOverlay'
import NotFound from '_components/NotFound'
import { addToCart, clearCart, clearOrderDetailsByRef, getCart } from '_redux/modules/order'
import {
  clearShopAndShopDetail,
  getShopByShopSlug,
  setViewDefaultForShop,
} from '_redux/modules/shop'
import { SUBITEM_REQUIRED, VIEW_GRID, VIEW_LIST } from '_utils/constant'
import { scrollToTop } from '_utils/function'
import {
  getCartOrderDetailsByRef,
  getCreateShopData,
  getUserInfo,
  getViewDefault,
  removeCartOrderDetailsByRef,
  removeCreateShopData,
  setCartCheckout,
  setUserInfo,
} from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { BsFillCartFill } from 'react-icons/bs'
import { GoArrowLeft } from 'react-icons/go'
import { MdOutlineDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import steps from './TourGuide/steps'
import { ModalCongratulationCreateShop } from './components/ModalCongratulationCreateShop'
import ProductList from './components/ProductList'
import SelectViewProduct from './components/SelectViewProduct'
import ShopBanner from './components/ShopBanner'
import './style.scss'

function ShopDetail() {
  const user = getUserInfo()
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { shopSlug } = useParams()
  const location = useLocation()

  const arrShopSlugParams = window.location.pathname.split('/')
  const shopSlugParams = arrShopSlugParams.filter(
    (slug) => slug && !['admin-console', 'shop-front'].includes(slug)
  )[0]

  const [noShopFound, setNoShopFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [orderProducts, setOrderProducts] = useState([])
  const [listProduct, setListProduct] = useState([])
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const { shopSlugBefore, cart } = useSelector((state) => state.order)

  const dataCreateShop = getCreateShopData()
  const cartOrderDetailsByRef = getCartOrderDetailsByRef()

  const { listProduct: listProductRedux } = useSelector((state) => state.product)
  const { shopDetail, viewDefault } = useSelector((state) => state.shop)
  const isOwnerShop = user?.shopLinks?.some((item) => item.includes(shopSlugParams))
  const isShopCreated =
    dataCreateShop?.shopSlug?.includes(window.location.pathname.slice(1)) &&
    !!dataCreateShop?.isShowGuide
  const [isOpenModal, setIsOpenModal] = useState(isShopCreated)
  const viewDefaultLocal = getViewDefault()
  const shopColorTheme = shopDetail?.shopColorTheme
  const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme

  const toggle = () => setIsOpenModal(!isOpenModal)

  const convertProducts = (_listProduct) =>
    _listProduct?.map((product, index) => ({
      ...product,
      subItems: product.subItems
        .filter((subItem) => subItem.required === SUBITEM_REQUIRED)
        .map((subItem) => ({
          type: subItem.type,
          name: subItem.list[0].name,
          price: subItem.list[0].price,
          required: subItem.required,
          list: subItem.list,
        })),
      quantityInput: product.defaultQuantity,
      index,
    }))

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }

    window.addEventListener('resize', handleResize)

    const intervalId = setInterval(() => {
      const facebookShareButton = document.querySelector('button[aria-label="facebook"]')
      const whatsAppShareButton = document.querySelector('button[aria-label="whatsapp"]')

      if (facebookShareButton) {
        facebookShareButton.innerHTML = `
          <button type='button' class='facebook'>Share</button>
        `
        clearInterval(intervalId)
      }

      if (whatsAppShareButton) {
        whatsAppShareButton.innerHTML = `
          <button type='button' class='whatsapp'>Share</button>
        `
        clearInterval(intervalId)
      }
    }, 1000)

    return () => {
      const userShopSlugs = user?.shopLinks?.map((item) => {
        const arr = item.split('/')
        return arr[arr.length - 1]
      })
      const userMatchShop = userShopSlugs?.find((item) => item === shopSlug)

      if (!userMatchShop && user?.id) {
        dispatch(clearShopAndShopDetail())
      }

      clearInterval(intervalId)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setIsLoading(true)
        await dispatch(getShopByShopSlug(shopSlug))
      } catch (e) {
        setNoShopFound(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (isEmpty(shopDetail) && shopSlug) {
      fetchShop().then(() => {})
    }

    dispatch(getCart())
    scrollToTop()

    return () => {
      // setListProduct([])
      setOrderProducts([])
      // setIsLoading(true)
    }
  }, [shopSlug])

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setIsLoading(true)
        await dispatch(getShopByShopSlug(shopSlugParams))
      } catch (e) {
        setNoShopFound(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (shopSlugParams) {
      fetchShop().then(() => {})
    }
  }, [shopSlugParams])

  useEffect(() => {
    if (isShopCreated) {
      const fetchUser = async () => {
        const { msgResp } = await userApi.getUser()
        setUserInfo(msgResp)
      }

      fetchUser()

      setIsOpenModal(true)
    }
  }, [isShopCreated])

  useEffect(() => {
    if (listProductRedux) {
      setListProduct(listProductRedux)
      setListProduct(convertProducts(listProductRedux?.filter((item) => item.active)))
    }
  }, [JSON.stringify(listProductRedux)])

  useEffect(() => {
    if (!isEmpty(shopDetail)) {
      const filterListProduct = shopDetail?.products?.filter((item) => item.active)

      if (!viewDefaultLocal) {
        dispatch(
          setViewDefaultForShop(shopDetail?.productView === VIEW_GRID ? VIEW_GRID : VIEW_LIST)
        )
      } else {
        dispatch(setViewDefaultForShop(viewDefaultLocal))
      }

      // if (!shopDetailLocalStorage) {
      //   setListProduct(convertProducts(filterListProduct))
      // }

      setListProduct(convertProducts(filterListProduct))

      if (cart?.orderList?.length && shopSlug.includes(shopSlugBefore)) {
        const newCart = [...cart?.orderList]

        setOrderProducts(newCart)
        setListProduct(newCart)
      }
    }
  }, [JSON.stringify(shopDetail), JSON.stringify(cart?.orderList)])

  const findProduct = (pid, index) => listProduct.find((n) => n.id === pid && n.index === index)

  const changeProductQuantityInput = ({ pid, number, index, type }) => {
    const newOrderProducts = [...orderProducts]
    const newListProduct = [...listProduct]
    const selectedProduct = findProduct(pid, index)
    const inOrderProducts = Boolean(
      newOrderProducts.find((item) => item.id === pid && item.index === index)
    )

    const totalQuantityInOrder = orderProducts
      .filter((item) => item.id === pid)
      .reduce((total, item) => total + item.quantityInput, 0)

    let numeric = number

    if (number < 0) return
    if (isNaN(number)) numeric = 0

    const totalQuantityOrderOfSelectedProduct =
      totalQuantityInOrder + numeric - selectedProduct.quantityInput

    if (type === 'plus' || type === 'typing') {
      if (totalQuantityOrderOfSelectedProduct > selectedProduct.availableOfStock) {
        addToast('Cannot order more than available of stock', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }

      if (selectedProduct && !inOrderProducts) {
        const updatedOrderProducts = newListProduct.map((item) => {
          if (item.id === pid && item.index === index) {
            if (number < item.minOrderQty) {
              numeric = item.minOrderQty
            }

            if (number >= item.limitPerOrder) {
              numeric = item.limitPerOrder
            }

            return {
              ...item,
              quantityInput: numeric,
            }
          }

          return item
        })
        setOrderProducts(updatedOrderProducts)
        setListProduct(updatedOrderProducts)
      }

      if (selectedProduct && inOrderProducts) {
        const updateQuantity = (item) => {
          const { minOrderQty } = item
          const { limitPerOrder } = item

          if (numeric !== 0 && numeric < minOrderQty) {
            item.quantityInput = minOrderQty
          } else if (numeric >= limitPerOrder) {
            item.quantityInput = limitPerOrder
          } else {
            item.quantityInput = numeric
          }
        }

        const selectedOrderProduct = newOrderProducts.find(
          (item) => item.id === pid && item.index === index
        )

        selectedOrderProduct && updateQuantity(selectedOrderProduct)
        selectedOrderProduct && updateQuantity(selectedOrderProduct)

        setOrderProducts(newOrderProducts)
        setListProduct(newOrderProducts)
      }
    } else if (type === 'minus') {
      if (selectedProduct && inOrderProducts) {
        const updateQuantity = (item) => {
          const { minOrderQty } = item

          if (numeric < minOrderQty) {
            item.quantityInput = 0
          } else {
            item.quantityInput = numeric
          }
        }

        const selectedOrderProduct = newOrderProducts.find(
          (item) => item.id === pid && item.index === index
        )

        selectedOrderProduct && updateQuantity(selectedOrderProduct)
        selectedOrderProduct && updateQuantity(selectedOrderProduct)

        setOrderProducts(newOrderProducts)
        setListProduct(newOrderProducts)
      }
    }
  }

  const getValueSelect = ({ pid, pIndex, subItemType }) => {
    const indexProduct = listProduct.findIndex((n) => n.id === pid && n.index === pIndex)
    const listSubItem = listProduct[indexProduct].subItems
    const indexSubItem = listSubItem?.findIndex((subItem) => subItem?.type === subItemType)
    if (indexSubItem !== undefined && indexSubItem !== -1) {
      return JSON.stringify({
        name: listSubItem[indexSubItem].name,
        price: listSubItem[indexSubItem].price,
      })
    }
    return ''
  }

  const handleChangeSubItem = ({ pid, subItem, value, pIndex }) => {
    const newListProduct = [...listProduct]
    const indexProduct = newListProduct.findIndex((n) => n.id === pid && n.index === pIndex)
    const listSubItem = newListProduct[indexProduct].subItems
    const indexSubItem = listSubItem.findIndex(
      (s, index) => s.type === subItem.type && index === subItem.index
    )

    const updatedSubItem = {
      ...listSubItem[indexSubItem],
      name: value.name,
      price: value.price,
    }

    const updatedProduct = {
      ...newListProduct[indexProduct],
      subItems: [...listSubItem],
    }

    updatedProduct.subItems[indexSubItem] = updatedSubItem
    newListProduct[indexProduct] = updatedProduct

    setListProduct(newListProduct)
    setOrderProducts(newListProduct)
  }

  const addAnotherOrder = (pid, pIndex) => {
    const newListProduct = [...listProduct]
    const selectedProduct = findProduct(pid, pIndex)

    const indexProduct = newListProduct.findIndex((n) => n.id === pid && n.index === pIndex)

    const totalQuantity = newListProduct.reduce(
      (acc, product) => (product.id === pid ? acc + product.quantityInput : acc),
      0
    )

    if (totalQuantity >= selectedProduct.availableOfStock) {
      addToast('Cannot order more than available of stock', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    const newQuantityInput =
      selectedProduct.availableOfStock - totalQuantity === 1 ? 1 : selectedProduct.quantityInput

    newListProduct.splice(indexProduct + 1, 0, {
      ...selectedProduct,
      isCopy: true,
      quantityInput: newQuantityInput,
    })

    setOrderProducts(newListProduct.map((item, index) => ({ ...item, index })))
    setListProduct(newListProduct.map((item, index) => ({ ...item, index })))
  }

  const deleteItemOrder = (pid, pIndex) => {
    const newListProduct = [...listProduct]
    const newOrderProducts = [...orderProducts]
    const selectedProduct = findProduct(pid, pIndex)
    const inCart = Boolean(
      newOrderProducts.find((item) => item.id === pid && item.index === pIndex - 1)
    )

    if (selectedProduct && inCart) {
      const indexOrderProducts = newOrderProducts.findIndex(
        (item) => item.id === pid && item.index === pIndex
      )

      newOrderProducts.splice(indexOrderProducts, 1)
      setOrderProducts(newOrderProducts)
    }

    newListProduct.splice(pIndex, 1)

    setListProduct(newListProduct.map((item, index) => ({ ...item, index })))
  }

  const getProductWithMinOrderQty = useCallback(async () => {
    const productWithMinOrderQty = listProduct.filter((item) => item.minOrderQty)
    if (!orderProducts.length && productWithMinOrderQty) {
      return productWithMinOrderQty
    }

    return orderProducts
  }, [JSON.stringify(listProduct)])

  const handleCheckout = async () => {
    const newOrderProducts = await getProductWithMinOrderQty()

    const totalQuantity = newOrderProducts.reduce((pre, cur) => pre + cur.quantityInput, 0)

    if (cartOrderDetailsByRef?.ref) {
      removeCartOrderDetailsByRef()
    }

    if (!isEmpty(cart?.orderList) && shopSlug !== shopSlugBefore) {
      Swal.fire({
        title: 'Payment',
        text: 'Please confirm payment before checkout',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setListProduct([])
          setOrderProducts([])
          dispatch(clearShopAndShopDetail())
          dispatch(clearOrderDetailsByRef())
          history.push(`/${shopSlugBefore}`)
        }
      })
      return
    }

    if (totalQuantity > 0) {
      setCartCheckout({
        orderList: newOrderProducts,
        listProductBefore: listProduct,
        email: user?.email,
        shopSlug,
      })
      dispatch(addToCart(newOrderProducts, shopDetail?.id, listProduct, shopSlug))
      history.push('/virtual-shop/checkout')
      return
    }

    return addToast('Please select quantity to checkout', {
      appearance: 'error',
      autoDismiss: true,
    })
  }

  const handleClearCart = async () => {
    Swal.fire({
      title: 'Are you sure to clear cart?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(clearCart())
        setOrderProducts([])
        setListProduct(
          listProduct.map((product) => ({
            ...product,
            quantityInput: 0,
          }))
        )
        Swal.fire('Cleared!', 'Your cart has been cleared.', 'success')
      }
    })
  }

  const handleOnClick = (link) => {
    if (link !== '') {
      window.open(link)
    } else {
      addToast('This Link is not exist', { appearance: 'error', autoDismiss: true })
    }
  }

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!isLoading && !shopDetail?.active) {
    return <NotFound />
  }

  return (
    <TourProvider
      showCloseButton={false}
      showPrevNextButtons={false}
      showDots={false}
      showBadge={false}
      steps={steps}
      styles={{
        controls: (base) => ({ ...base, marginTop: 100, display: 'none' }),
        popover: (base) => ({
          ...base,
          borderRadius: '0.25rem',
          padding: '35px',
        }),
      }}
      onClickMask={(e) => e.setIsOpen(true)}
      disableInteraction
      onClickHighlighted={() => {
        if (!isEmpty(dataCreateShop)) {
          removeCreateShopData()
        }
        history.push('/admin-console')
      }}
      highlightedMaskClassName='highlighted-area'
    >
      <div
        className={'shop-detail'}
        style={{
          backgroundColor:
            shopDetail?.shopColorTheme?.customsColorTheme?.backgroundColor || '#f5f5f5',
        }}
      >
        <div className='container'>
          <div
            className='bg-white shop-detail-body position-relative pt-3'
            style={{ paddingBottom: '5rem' }}
          >
            <div className='row'>
              <div className='col-12'>
                <div className='shop-detail bg-white'>
                  {noShopFound && <div className='text-center mb-3'>Something went wrong</div>}

                  {!isLoading && !noShopFound ? (
                    <>
                      {!user?.id && (
                        <button
                          type='button'
                          className='d-flex align-items-center ms-auto bg-transparent border-0 f-14 text-primary'
                          onClick={() => history.push('/virtual-shop/list')}
                        >
                          <GoArrowLeft className='me-1' />
                          <span>Back</span>
                        </button>
                      )}
                      <ShopBanner shopDetail={shopDetail} handleOnClick={handleOnClick} />

                      <SelectViewProduct viewDefault={viewDefault} />

                      {cart?.orderList?.length && shopSlug === shopSlugBefore ? (
                        <button
                          onClick={handleClearCart}
                          className='btn btn-outline-danger my-3 ms-auto btn-clear'
                        >
                          <MdOutlineDelete size={20} />
                          <span className='ms-1'>Clear</span>
                        </button>
                      ) : null}

                      <div className='row'>
                        <ProductList
                          listProduct={listProduct}
                          findProduct={findProduct}
                          orderProducts={orderProducts}
                          handleChangeSubItem={handleChangeSubItem}
                          getValueSelect={getValueSelect}
                          changeProductQuantityInput={changeProductQuantityInput}
                          addAnotherOrder={addAnotherOrder}
                          deleteItemOrder={deleteItemOrder}
                          viewDefault={viewDefault}
                          isDesktop={isDesktop}
                        />
                      </div>
                    </>
                  ) : null}

                  <button
                    className='text-uppercase d-flex align-items-center btn-publish justify-content-center w-100'
                    style={{
                      color: colorTheme?.generalTextColor,
                      border: `2px solid ${colorTheme?.generalTextColor}`,
                      backgroundColor: colorTheme?.backgroundColor,
                    }}
                    onClick={handleCheckout}
                  >
                    <BsFillCartFill className='me-2' />
                    Checkout
                  </button>

                  {isOwnerShop && !window.location.pathname.includes('my-shop') ? (
                    <Footer isOpenModal={isOpenModal} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalCongratulationCreateShop
        isOpenModal={isOpenModal}
        toggle={toggle}
        shopDetail={shopDetail}
        urlPath={location.pathname}
      />
    </TourProvider>
  )
}
export default ShopDetail
