import noImage from '_images/noimage02.svg'
import { onUserImageUploaded, swapDownProduct, swapUpProduct } from '_redux/modules/product'
import React from 'react'
import { FaCameraRotate } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function ProductImage({ product, setValue, loadingProduct, setLoadingProduct }) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { tabSelected } = useSelector((state) => state.product)
  const { shopDetail } = useSelector((state) => state.shop)

  const onUploadImage = async (e, prodId) => {
    try {
      setLoadingProduct(prodId)
      await dispatch(onUserImageUploaded(e, prodId, setValue, tabSelected, shopDetail?.id))
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoadingProduct('')
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='wrap-arrow me-2 cursor-pointer'>
        <div className='arrow-up' onClick={() => dispatch(swapUpProduct(product))}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='currentColor'
          >
            <path d='M12 3l12 18h-24z' />
          </svg>
        </div>
        <div className='arrow-down' onClick={() => dispatch(swapDownProduct(product))}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='currentColor'
          >
            <path d='M12 21l-12-18h24z' />
          </svg>
        </div>
      </div>
      <div className='upload-image flex-column cursor-pointer'>
        {/* photoUrls for Co-Hoot, photoUrl for Lazada */}
        {product?.id === loadingProduct ? (
          <div
            className='loading d-flex align-items-center justify-content-center bg-gray-300 rounded'
            style={{ width: 90, height: 80 }}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <img
            src={product?.photoUrls || product?.photoUrl || noImage}
            className={`${product?.photoUrl ? 'img-upload' : ''} image img-fluid object-fit-cover`}
            alt='img'
          />
        )}

        <div className='u-file-upload__item' style={{ margin: '10px auto 0 auto', width: 79 }}>
          <div className='u-file-upload__wrap'>
            <label
              htmlFor={`formFile${product?.id}`}
              className='c-upload preview-images-zone w-100 text-center'
              style={{ cursor: 'pointer' }}
            >
              <FaCameraRotate size={30} color='#929292' />
              <input
                type='file'
                accept='image/*'
                multiple
                id={`formFile${product?.id}`}
                className='inputFile form-control'
                onChange={(e) => onUploadImage(e, product?.id)}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductImage
