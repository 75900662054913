import { removeAccents } from '_utils/function'
import { domainGenerator } from '_utils/functions/generator'
import React, { useState } from 'react'

const ShopName = (props) => {
  const { register, error, value } = props
  const textAfterHandle = value?.toLowerCase().replace(/\s+/g, ' ').trim()
  const [shopName, setShopName] = useState(textAfterHandle?.split(' ').join('-'))
  const [errorLength, setErrorLength] = useState('')

  const handleOnChange = (e) => {
    const inputValue = e.target.value
    // Kiểm tra nếu độ dài của giá trị nhập vào đã đạt 255 kí tự
    if (inputValue.length <= 255) {
      setErrorLength('')
      const convertShopName = inputValue.toLowerCase().replace(/\s+/g, ' ').trim()
      const newStr = convertShopName.split(' ').join('-')
      setShopName(removeAccents(newStr))
    } else {
      setErrorLength('Shop name must be less than 255 characters')
    }
  }

  const content = domainGenerator() + (!shopName ? 'example' : shopName)
  const isContentLong = content.length > 255

  return (
    <div className='mb-3'>
      <label htmlFor='shop-name' className='form-label'>
        Shop Name <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type='text'
        className='form-control'
        id='shop-name'
        defaultValue={value}
        {...register('shopName')}
        onChange={(e) => handleOnChange(e)}
      />
      {error && <small className='errorMessage'>{error}</small>}
      {errorLength && <small className='errorMessage'>{errorLength}</small>}
      <div
        id='shop-domain'
        className='form-text text-break overflow-hidden'
        style={{ height: isContentLong ? '147px' : 'auto' }}
      >
        Domain: {content}
      </div>
    </div>
  )
}

export default ShopName
