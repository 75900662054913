import { updateStatusOrderLazada } from '_redux/modules/order'
import { ORDER_CANCELED_STATUS } from '_utils/constant'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function ModalGetReasonCancel({
  isOpen,
  toggle,
  listReasonCancel,
  selectedOrderProduct,
  itemSelected,
}) {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const [selectedReason, setSelectedReason] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { addToast } = useToasts()

  const onSelectReason = (reasonId) => {
    setSelectedReason(reasonId)
  }

  const submit = async () => {
    try {
      setIsSubmitting(true)
      await dispatch(
        updateStatusOrderLazada(
          itemSelected,
          selectedOrderProduct,
          ORDER_CANCELED_STATUS,
          selectedShop?.id,
          selectedReason
        )
      )
      addToast('Cancel success', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsSubmitting(false)
      toggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-reason-cancel'>
      <ModalHeader toggle={toggle}>Reason Cancel</ModalHeader>
      <ModalBody>
        <div className='list-reason-cancel'>
          {listReasonCancel.map((reason) => (
            <div className='form-check' key={reason.reason_id}>
              <input
                className='form-check-input cursor-pointer'
                type='radio'
                name={reason.reason_name}
                id={reason.reason_id}
                checked={reason.reason_id === selectedReason?.reason_id}
                onChange={() => onSelectReason(reason)}
              />
              <label className='form-check-label cursor-pointer' htmlFor={reason.reason_id}>
                {reason.reason_name}
              </label>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn btn-submit w-auto shadow-none rounded-pill'
          onClick={submit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Processing...' : 'Proceed to cancel'}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalGetReasonCancel
