import { generateRandomColor } from '_utils/function'
import moment from 'moment/moment'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'

const listColor = ['#4472C4', '#ED7D31', '#A5A5A5']
const defaultColor = '#FFC000'

const excludeColors = [...listColor, defaultColor]
while (listColor.length < 103) {
  const newColor = generateRandomColor(excludeColors)
  listColor.push(newColor)
  excludeColors.push(newColor)
}

const getProductColor = (listProductSelected, key) => {
  if (listProductSelected.length === 1) {
    return listColor[0]
  }
  if (listProductSelected.length > 1 && listProductSelected.length < 3) {
    const index = listProductSelected.findIndex((product) => product.name === key)
    if (index !== -1) {
      return listColor[index % listColor.length]
    }
  } else if (listProductSelected.length === 3) {
    if (listProductSelected.some((product) => product.name === key)) {
      return listColor[
        listProductSelected.findIndex((product) => product.name === key) % listColor.length
      ]
    }
  } else if (listProductSelected.length > 3) {
    const index = listProductSelected.findIndex((product) => product.name === key)
    if (index >= 3) {
      return listColor[index % listColor.length]
    }
    if (index !== -1) {
      return listColor[index % listColor.length]
    }
  }

  return defaultColor // Trả về màu mặc định nếu không thỏa mãn điều kiện nào
}

function FulfillChart({
  listFulfillmentByShopId,
  listProductSelected,
  toggleModalProductCheckbox,
  loadingFulfill,
}) {
  const history = useHistory()
  const [filteredChartData, setFilteredChartData] = useState([])
  const { selectedShop } = useSelector((state) => state.shop)
  const { listProduct } = useSelector((state) => state.product)

  const sortedListProduct = [...listProduct].sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt))
  )
  const sortedListProductSelected = [...listProductSelected].sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt))
  )

  const productMap = sortedListProductSelected.reduce((acc, product) => {
    acc[product.id] = product.name
    return acc
  }, {})

  const allProductMap = sortedListProduct.reduce((acc, product) => {
    acc[product.id] = product.name
    return acc
  }, {})

  const shouldIncludeOthers = sortedListProductSelected.length !== sortedListProduct.length

  const chartData = listFulfillmentByShopId.reduce((acc, fulfillment) => {
    fulfillment.listOrder.forEach((order) => {
      const orderDate = moment
        .unix(fulfillment?.deliveryTimestamp || fulfillment?.pickupBookingTimestamp)
        .format('DD-MM-YYYY')
      let productName = productMap[order.pid] // Kiểm tra xem sản phẩm có trong listProductSelected không

      let dateObj = acc.find((obj) => obj.orderDate === orderDate)
      if (!dateObj) {
        dateObj = { orderDate }
        acc.push(dateObj)
      }

      if (!productName && shouldIncludeOthers) {
        // Cập nhật điều kiện này
        // Nếu sản phẩm không nằm trong listProductSelected nhưng có trong listProduct, gán là "Others"
        productName = allProductMap[order.pid] ? 'Others' : undefined
      }

      if (productName) {
        dateObj[productName] = (dateObj[productName] || 0) + order.pQuantity
      }
    })

    return acc
  }, [])

  const productKeys = [...new Set(chartData.flatMap(Object.keys))].filter(
    (key) => key !== 'orderDate'
  )

  const allDates = Array.from({ length: 7 }, (_, i) => {
    const date = moment().add(i, 'days')
    return { orderDate: date.format('DD-MM-YYYY') }
  })

  const preparedChartData = allDates.map((date) => {
    const existingData = chartData.find((data) => data.orderDate === date.orderDate)
    return existingData || { orderDate: date.orderDate }
  })

  useEffect(() => {
    const newChartData = preparedChartData.map((entry) => {
      const filteredEntry = { orderDate: entry.orderDate }
      sortedListProduct.forEach((productObj) => {
        const productName = productObj.name
        if (entry[productName] !== undefined) {
          filteredEntry[productName] = entry[productName]
        } else if (shouldIncludeOthers) {
          filteredEntry.Others = entry.Others || 0
        }
      })
      return filteredEntry
    })

    setFilteredChartData(newChartData)
  }, [JSON.stringify(sortedListProductSelected), JSON.stringify(preparedChartData)])

  const productKeysWithoutOthers = productKeys.filter((key) => key !== 'Others')

  const handleBarClick = (index) => {
    const data = filteredChartData[index]
    history.push(`/admin-console/${selectedShop?.shopSlug}/delivery?date=${data.orderDate}`)
  }

  const handleBarClickForOthers = (index) => {
    const data = filteredChartData[index]
    history.push(`/admin-console/${selectedShop?.shopSlug}/delivery?date=${data.orderDate}`)
  }

  return (
    <div className='f-16'>
      {loadingFulfill ? (
        <div className='text-center'>Loading...</div>
      ) : (
        <BarChart
          width={500}
          height={300}
          data={filteredChartData}
          margin={{ top: 20, right: 30, left: -25, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='orderDate'
            tickFormatter={(value) => moment(value, 'DD-MM-YYYY').format('DD-MM')}
            angle={-25}
            dy={10}
          />
          <YAxis />
          <Tooltip />
          {productKeysWithoutOthers.map((key) => (
            <Fragment key={key}>
              <Bar
                dataKey={key}
                stackId='a'
                fill={getProductColor(listProductSelected, key)}
                onClick={(_, i) => handleBarClick(i)}
              />
            </Fragment>
          ))}

          {productKeys.includes('Others') && (
            <Bar
              dataKey='Others'
              stackId='a'
              fill={getProductColor(listProductSelected, 'Others')}
              onClick={(_, index) => {
                handleBarClickForOthers(index)
              }}
            />
          )}
        </BarChart>
      )}

      <div className='d-flex justify-content-start w-100 flex-wrap mt-3 gap-3'>
        {listProductSelected.map((product) => (
          <div key={product.id} className='d-flex align-items-center product-selected-label'>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: getProductColor(listProductSelected, product.name),
                marginRight: 5,
              }}
            ></div>
            <div>{product.name}</div>
          </div>
        ))}

        <div
          className='d-flex align-items-center cursor-pointer'
          onClick={toggleModalProductCheckbox}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: '#FFC000',
              marginRight: 5,
            }}
          ></div>
          <div>Other</div>
        </div>
      </div>
    </div>
  )
}

export default FulfillChart
