import { yupResolver } from '@hookform/resolvers/yup'
import virtualShopApi from '_api/virtualShop'
import { deletePickup, getListPickup, updatePickup } from '_redux/modules/fulfillment'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { DATES_OF_WEEK, LIST_TIME_2, TIME_SLOT } from '_utils/constant'
import { getDatesOfCurrentWeek } from '_utils/function'
import { calculateDuration } from '_utils/functions/calculator'
import { convertToGMT0 } from '_utils/functions/converter'
import { getDataShopDetail } from '_utils/localData'
import Holidays from 'date-holidays'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsTrashFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import OpeningHours from './OpeningHours'
import PickupAddress from './PickupAddress'
import PickupFee from './PickupFee'
import PickupInstruction from './PickupInstruction'
import PickupName from './PickupName'
import PickupTimeSlot from './PickupTimeSlot'
import Timer from './Timer'

const validationSchema = Yup.object().shape({
  pickupAddress: Yup.string().required('Invalid address').min(10, 'Min 10 characters'),
  optionPickupName: Yup.string('Invalid pickup name').required('Invalid pickup name'),
  instruction: Yup.string('Invalid instruction').nullable().default(''),
  pickupFee: Yup.string().nullable(),
})

const listDatesOfCurrentWeek = getDatesOfCurrentWeek()
const formatDatesOfWeek = DATES_OF_WEEK.map((item, i) => ({
  ...item,
  date: listDatesOfCurrentWeek[i].date,
}))

function PickUpOption({ pickup, index, shopId }) {
  const dispatch = useDispatch()
  const shop = getDataShopDetail()
  const { addToast } = useToasts()
  const { pathname } = useLocation()
  const {
    optionName,
    pickupFee,
    instructions,
    id,
    pickupAddress,
    pickupEndTime: pickupEndTimeResponse,
    datesOfWeek: datesOfWeekResponse,
    timeSlot: timeSlotResponse,
    pickupStartTime: pickupStartTimeResponse,
    isCheckPublicHoliday: isCheckPublicHolidayResponse,
  } = pickup || {}

  const [datesOfWeek, setDatesOfWeek] = useState(datesOfWeekResponse || formatDatesOfWeek)
  const [checkedHoliday, setCheckedHoliday] = useState(false)
  const [checkedPickupFee, setCheckedPickupFee] = useState(false)
  const [checkedPickupInstruction, setCheckedPickupInstruction] = useState(false)
  const [checkedTimeSlot, setCheckedTimeSlot] = useState(false)
  const [isRealId] = useState(id?.indexOf('FAKE_ID') === -1)

  const [pickupHour, setPickupHour] = useState(
    calculateDuration(
      datesOfWeek[0].date,
      datesOfWeek[6].date,
      pickupStartTimeResponse || '2:00',
      pickupEndTimeResponse || '11:00'
    ) || {
      hourStart: '9AM',
      hourEnd: '6PM',
    }
  )

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    getValues,
    reset,
    // watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      timeSlots: TIME_SLOT,
    },
  })

  const { timeSlots } = getValues()
  const holidays = new Holidays('SG')
  const currentYear = new Date().getFullYear()

  const showPublicHoliday = (isCheck) => {
    const currentHolidayYear = holidays.getHolidays(currentYear)
    const publicHolidays = currentHolidayYear.filter((date) => {
      const dateOfHoliday = moment(date.date).format('YYYY-MM-DD')
      return listDatesOfCurrentWeek.some(
        (dateCurrentWeek) => dateCurrentWeek.date === dateOfHoliday
      )
    })

    return datesOfWeek.map((date) => {
      const isHoliday = publicHolidays.some(
        (holiday) =>
          moment(holiday.date).format('DD-MM-YYYY') === moment(date.date).format('DD-MM-YYYY')
      )
      const nameHoliday = publicHolidays.find(
        (holiday) =>
          moment(holiday.date).format('DD-MM-YYYY') === moment(date.date).format('DD-MM-YYYY')
      )

      if (isCheck) {
        return {
          ...date,
          isClose: isHoliday || date.isClose,
          name: isHoliday ? nameHoliday : '',
        }
      }

      return {
        ...date,
        isClose: isHoliday ? false : date.isClose,
        name: '',
      }
    })
  }

  useEffect(() => {
    if (timeSlotResponse) {
      setCheckedTimeSlot(true)

      reset({
        timeSlots: timeSlots.map((time) => ({
          ...time,
          isCheck: timeSlotResponse === time.value,
        })),
      })
    } else {
      setCheckedTimeSlot(false)
    }

    if (pickupFee) {
      setCheckedPickupFee(true)
    } else {
      setCheckedPickupFee(false)
    }

    if (instructions) {
      setCheckedPickupInstruction(true)
    } else {
      setCheckedPickupInstruction(false)
    }

    if (isCheckPublicHolidayResponse) {
      showPublicHoliday(true)
      setCheckedHoliday(true)
    } else {
      showPublicHoliday(false)
      setCheckedHoliday(false)
    }

    if (datesOfWeekResponse) {
      setDatesOfWeek(datesOfWeekResponse)
    }
  }, [pathname, datesOfWeekResponse, isCheckPublicHolidayResponse])

  const handleCheckHoliday = (e) => {
    const isCheck = e.target.checked
    setCheckedHoliday(isCheck)
    const newDatesOfWeek = showPublicHoliday(isCheck)
    setDatesOfWeek(newDatesOfWeek)
  }

  const handleClickDate = (e, i) => {
    const isCheck = e.target.checked
    const newDatesPublicHoliday = datesOfWeek.map((item, _i) => {
      if (_i === i) {
        return { ...item, isClose: isCheck }
      }
      return item
    })

    if (newDatesPublicHoliday.every((item) => item.isClose)) {
      return addToast('You cannot turn off all dates of week', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    setDatesOfWeek(newDatesPublicHoliday)
  }

  const toggleInstruction = (e) => {
    const isCheck = e.target.checked
    setCheckedPickupInstruction(isCheck)
  }

  const handleCheckTimeSlot = (e, i, onChange) => {
    const isCheck = e.target.checked

    const newTimeSlot = timeSlots.map((item, _i) => {
      if (_i === i) {
        return { ...item, isCheck }
      }
      return { ...item, isCheck: false }
    })

    onChange(newTimeSlot)

    // reset({ timeSlots: newTimeSlot })
  }

  const handleAddPickupFee = (e) => {
    const isCheck = e.target.checked
    setCheckedPickupFee(isCheck)
  }

  const handleAddTimeSlot = (e) => {
    const isCheck = e.target.checked
    setCheckedTimeSlot(isCheck)
  }

  const convertTimeToGMT0 = (date, time) => {
    const dateAndTime = date + time
    return convertToGMT0(dateAndTime).slice(11)
  }

  const onSubmit = async (data) => {
    try {
      const pickupStartIndex = LIST_TIME_2.findIndex((item) => item.text === pickupHour?.hourStart)
      const pickupEndIndex = LIST_TIME_2.findIndex((item) => item.text === pickupHour?.hourEnd)
      const pickupStartTime = LIST_TIME_2[pickupStartIndex].valueString
      const pickupEndTime = LIST_TIME_2[pickupEndIndex].valueString

      const timeSlotSelected = data?.timeSlots?.find((item) => item.isCheck)?.value

      const postData = {
        optionName: data?.optionPickupName,
        pickupFee: checkedPickupFee ? Number(data?.pickupFee) : 0,
        instructions: checkedPickupInstruction ? data?.instruction : '',
        pickupTime: {
          startTime: convertTimeToGMT0(
            moment(datesOfWeek[0].date).format('DD-MM-YYYY'),
            pickupStartTime
          ),
          endTime: convertTimeToGMT0(
            moment(datesOfWeek[6].date).format('DD-MM-YYYY'),
            pickupEndTime
          ),
        },
        datesOfWeek,
        isCheckPublicHoliday: checkedHoliday,
        timeSlot: checkedTimeSlot ? timeSlotSelected : null,
        pickupAddress: data.pickupAddress,
      }

      if (id.includes('FAKE_ID')) {
        const [{ msgResp }] = await Promise.all([
          virtualShopApi.createPickUpOption(shopId, postData),
          virtualShopApi.updateShopNoPickupAndDelivery(shopId, {
            fulfillmentStatus: Number(shop.fulfillmentStatus),
          }),
        ])

        dispatch(updatePickup(index, msgResp))
        addToast('Create success !', { appearance: 'success', autoDismiss: true })
      } else {
        await Promise.all([virtualShopApi.updatePickUpOption(id, postData)])

        if (data?.optionPickupName) {
          await virtualShopApi.updateShopNoPickupAndDelivery(shop?.id, {
            fulfillmentStatus: Number(shop.fulfillmentStatus),
          })
        }

        dispatch(getListPickup(shopId))
        addToast('Update successfully', { appearance: 'success', autoDismiss: true })
      }

      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          fulfillmentStatus: Number(shop.fulfillmentStatus),
        })
      )
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleRemovePickupOption = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (id.includes('FAKE_ID')) {
            dispatch(deletePickup(index))
          } else {
            await virtualShopApi.deletePickUpOption(id)
            dispatch(deletePickup(index))
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
          }
        } catch (e) {
          addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
        }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PickupAddress pickupAddress={pickupAddress} index={index} {...{ register, errors }} />
      <div className='delivery-options border rounded-3 p-3 mb-3'>
        <PickupName register={register} errors={errors} optionName={optionName} index={index} />

        <OpeningHours
          datesOfWeek={datesOfWeek}
          handleCheckHoliday={handleCheckHoliday}
          index={index}
          checkedHoliday={checkedHoliday}
          handleClickDate={handleClickDate}
          type='pickup'
        />

        <Timer setHours={setPickupHour} Hours={pickupHour} />

        <PickupFee
          handleAddPickupFee={handleAddPickupFee}
          checkedPickupFee={checkedPickupFee}
          pickupFee={pickupFee}
          errors={errors}
          control={control}
        />

        <PickupInstruction
          register={register}
          errors={errors}
          checkedPickupInstruction={checkedPickupInstruction}
          toggleInstruction={toggleInstruction}
          instructions={instructions}
        />

        <PickupTimeSlot
          checkedTimeSlot={checkedTimeSlot}
          handleAddTimeSlot={handleAddTimeSlot}
          handleCheckTimeSlot={handleCheckTimeSlot}
          index={index}
          control={control}
        />

        <div className='d-flex mt-3 align-items-center justify-content-center'>
          {isRealId ? (
            <button
              className='btn btn-primary-outline btn-cancel text-uppercase shadow-none'
              disabled={isSubmitting}
            >
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'Update'}
            </button>
          ) : (
            <button
              className='btn btn-primary rounded-pill text-uppercase ms-3 shadow-none'
              disabled={isSubmitting}
            >
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save'}
            </button>
          )}
          <div
            className='bg-transparent border-0 ms-2 cursor-pointer'
            onClick={handleRemovePickupOption}
          >
            <BsTrashFill size={30} color='#0BADAD' />
          </div>
        </div>
      </div>
    </form>
  )
}

export default PickUpOption
