import React from 'react'
import { BiSolidPurchaseTag } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { clearCart, clearOrderDetailsByRef } from '../redux/modules/order'
import { removeCartCheckout, removeCartOrderDetailsByRef } from '../utils/localData'

export function ButtonToMyPurchase({ style }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { cart } = useSelector((state) => state.order)

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    removeCartOrderDetailsByRef()
    removeCartCheckout()
    dispatch(clearCart())
  }

  const onRedirect = () => {
    if (cart?.orderList?.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          onClearCart()
          setTimeout(() => {
            history.push('/virtual-shop/my-purchase')
          }, 500)
        }
      })
    } else {
      history.push('/virtual-shop/my-purchase')
    }
  }

  return (
    <button
      className='btn float-button w-auto rounded shadow-sm'
      style={style}
      onClick={onRedirect}
    >
      <BiSolidPurchaseTag size={18} className='me-1' />
      <span>My Purchase</span>
    </button>
  )
}
