export const headers = [
  {
    name: 'Order #',
    field: 'shopOrderNo',
    className: 'col',
    type: 'number',
    sortable: true,
  },
  {
    name: 'Name',
    field: '',
    className: 'col',
    type: '',
    sortable: false,
  },
  {
    name: 'Mobile',
    field: '',
    className: 'col',
    type: '',
    sortable: false,
  },
  {
    name: 'Orders/Qty',
    field: '',
    className: 'col-2',
    type: 'string',
    sortable: false,
  },
  {
    name: 'Fulfillment',
    field: '',
    className: 'col-2',
    type: 'string',
    sortable: false,
  },
  {
    name: 'Order Date',
    field: 'createdAt',
    className: 'col',
    type: 'number',
    sortable: true,
  },
  {
    name: 'Fulfillment Date',
    field: '',
    className: 'col',
    type: 'string',
    sortable: false,
  },
  {
    name: 'Payment',
    field: 'buyerStatus',
    className: 'col text-center',
    type: 'number',
    sortable: false,
  },
  {
    name: 'Status',
    field: 'status',
    className: 'col',
    type: 'number',
    sortable: false,
  },
]
