/* eslint-disable no-unused-vars */
import { TYPE_SHOP_WITH_SINGLE_LIST } from '_utils/constant'
import { getCreateShopData, setCreateShopData } from '_utils/localData'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import CategoryList from './components/CategoryList'

import './style.scss'

function AddCategory() {
  const history = useHistory()
  const createShopData = getCreateShopData()
  const { addToast } = useToasts()
  const [quantityCategory, setQuantityCategory] = useState()
  const [listProduct, setListProduct] = useState(
    createShopData?.products ? createShopData?.products : [{ category: '' }]
  )

  if (!createShopData?.products) {
    setCreateShopData({
      ...createShopData,
      products: [{ category: '' }],
    })
  }

  const handleAddCategory = () => {
    listProduct.push({ category: '' })
    setListProduct(listProduct)
    setCreateShopData({
      ...createShopData,
      products: listProduct,
    })
    setQuantityCategory(listProduct?.length)
  }

  const handleRemoveCategory = (index) => {
    listProduct.splice(index, 1)
    setCreateShopData({
      ...createShopData,
      products: listProduct,
    })
    setQuantityCategory(listProduct?.length)
    setListProduct(listProduct)
  }

  const handleSubmit = () => {
    const listProductHaveNot = listProduct?.filter((item) => Object.keys(item).length === 1)
    if (listProductHaveNot?.length) {
      listProduct.forEach((category, i) => {
        if (Object.keys(category).length === 1) {
          addToast(
            `${
              category?.category === '' ? `category ${i + 1}` : category?.category
            } without items !`,
            { appearance: 'error', autoDismiss: true }
          )
        }
      })
    } else {
      const postData = {
        ...createShopData,
        products: listProduct,
      }
      setCreateShopData(postData)
      history.push('/virtual-shop/confirm-shop')
    }
  }

  if (!createShopData?.shopFrontType) {
    history.push('/virtual-shop/type-shop')
  } else if (createShopData.shopFrontType === TYPE_SHOP_WITH_SINGLE_LIST) {
    history.push('/virtual-shop/add-items')
  }
  return (
    <div className='add-category d-flex flex-column mt-5'>
      <p className='text-center fw-bold title mb-5'>Add Category</p>
      <CategoryList
        listProduct={listProduct}
        handleAddCategory={handleAddCategory}
        handleRemoveCategory={handleRemoveCategory}
        setListProduct={setListProduct}
      />
      <div className='row'>
        <div className='col-6'>
          <Link to='/virtual-shop/type-shop' className='btn btn-primary-outline text-uppercase'>
            Cancel
          </Link>
        </div>
        <div className='col-6'>
          <button
            className='btn btn-primary rounded-pill text-uppercase text-white'
            onClick={handleSubmit}
          >
            Save & Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddCategory
