import { getUserInfo } from '_utils/localData'
import { capitalize } from 'lodash'
import React from 'react'
import { AiFillShop } from 'react-icons/ai'
import ReactImageGallery from 'react-image-gallery'
import { Link } from 'react-router-dom'

function Banner({ dataCreateShop, listImages }) {
  const userInfo = getUserInfo()

  return (
    <div className='confirm__shop-banner'>
      <div className='confirm__shop-banner-title my-3 fw-bold d-flex justify-content-between'>
        <p className='shop-detail-banner-title text-start fw-bold mb-0 f-24'>
          {capitalize(dataCreateShop?.shopName)}
        </p>
        {!userInfo?.id ? (
          <Link
            to='/virtual-shop/sign-in'
            className='sign-in text-secondary f-14 fw-semibold align-self-end'
          >
            Sign-in / Sign-up
          </Link>
        ) : null}
      </div>
      <div className='confirm__shop-banner-img'>
        {listImages?.length > 0 && (
          <ReactImageGallery
            items={listImages}
            lazyLoad
            autoPlay
            showPlayButton={false}
            showNav={false}
            showBullets={true}
            showThumbnails={false}
            className='rounded shadow-sm overflow-hidden'
          />
        )}
      </div>
      <div className='confirm__shop-banner-desc my-3 shadow-sm'>
        <p className='title-description mb-2'>
          <AiFillShop size={20} className='me-2' />
          Description
        </p>
        <span>{dataCreateShop?.headline}</span>
      </div>
    </div>
  )
}

export default Banner
