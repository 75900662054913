import axiosClient from './axiosClient'

const uploadApi = {
  uploadPhoto: (files) => {
    const url = '/upload-photo'
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      formData.append('type', 1)
      formData.append('file', files[i])
    }
    return axiosClient.post(url, formData)
  },
  uploadPhotoLazada: (shopId, files) => {
    const url = `/lazada-upload-photo?shopId=${shopId}`
    const formData = new FormData()
    // For upload from computer
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('type', 1)
        formData.append('file', files[i])
      }
      return axiosClient.post(url, formData)
    }

    // For convert image and pass into this

    formData.append('type', 2)
    formData.append('file', files)

    return axiosClient.post(url, formData)
  },
}

export default uploadApi
