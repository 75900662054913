import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ProductItemContext } from '.'

function ProductPrice(props) {
  const { productName, productPrice } = props

  const { isSavedProduct } = useSelector((state) => state.product)
  const { handleSubmit, register, setValue, errors, isSubmitting, handleSave } =
    useContext(ProductItemContext)

  const [isEditPrice, setIsEditPrice] = useState(false)

  useEffect(() => {
    if (isSavedProduct) setIsEditPrice(false)
  }, [isSavedProduct])

  const toggle = () => {
    setIsEditPrice(!isEditPrice)
  }

  const onSubmit = (data) => {
    handleSave(data)
    toggle()
  }

  return (
    <>
      <Modal isOpen={isEditPrice} toggle={toggle} className='modal-edit-product'>
        <ModalHeader toggle={toggle}>{productName}</ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <label htmlFor='product-price' className='mb-0'>
              Enter price ($):
            </label>

            <CurrencyFormat
              id='product-price'
              value={productPrice || 0}
              displayType={'input'}
              thousandSeparator={true}
              className='form-control-sm border w-100 mb-2'
              onValueChange={(values) => {
                const { value } = values
                setValue('price', value)
              }}
              {...register('price')}
            />

            {errors.price && <small className='text-danger'>{errors.price.message}</small>}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-submit' type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </ModalFooter>
        </form>
      </Modal>

      <div className='text-content text-end' onClick={toggle}>
        <CurrencyFormat
          value={productPrice || 0}
          displayType='text'
          thousandSeparator={true}
          // prefix='$'
        />
      </div>
    </>
  )
}

export default ProductPrice
