import { Fragment } from 'react'
// import { useSelector } from 'react-redux'
// import ListWithCategory from './ListWithCategory'
import { TURN_ON, VIEW_LIST } from '_utils/constant'
import moment from 'moment/moment'
import ProductItem from './ProductItem'

const ProductList = ({
  listProduct,
  findProduct,
  handleChangeSubItem,
  getValueSelect,
  changeProductQuantityInput,
  addAnotherOrder,
  deleteItemOrder,
  viewDefault,
  isDesktop,
}) => {
  if (!listProduct?.length) {
    return <div>No products</div>
  }

  listProduct?.sort((a, b) => {
    const dateA = moment.unix(a.createdAt)
    const dateB = moment.unix(b.createdAt)
    return dateB - dateA
  })

  const className = viewDefault === VIEW_LIST ? 'col-12' : 'col-6'

  // const isHasSubItem = listProduct.some((product) => product?.subItems?.length > 0)

  return listProduct.map((product, index) => (
    <Fragment key={`${product.id}-${index}`}>
      {product?.active === TURN_ON ? (
        <ProductItem
          className={className}
          styleFooterCard={{ height: 'auto' }}
          product={product}
          findProduct={findProduct}
          changeProductQuantityInput={changeProductQuantityInput}
          handleChangeSubItem={handleChangeSubItem}
          getValueSelect={getValueSelect}
          addAnotherOrder={addAnotherOrder}
          deleteItemOrder={deleteItemOrder}
          viewDefault={viewDefault}
          isDesktop={isDesktop}
        />
      ) : null}
    </Fragment>
  ))
}
export default ProductList
