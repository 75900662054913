import PropTypes from 'prop-types'
import React from 'react'
import { FormGroup } from 'reactstrap'

const InputField = (props) => {
  const { register, type, label, placeholder, disabled, className, id, ...rest } = props
  // const { name } = register
  return (
    <FormGroup>
      {/* {label && <Label for={id}>{label}</Label>} */}

      <input
        className={className}
        id={id}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        {...register}
        {...rest}
      />
    </FormGroup>
  )
}

export default InputField

InputField.propTypes = {
  register: PropTypes.object.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
