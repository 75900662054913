import { setViewDefaultForShop } from '_redux/modules/shop'
import { VIEW_GRID, VIEW_LIST } from '_utils/constant'
import classNames from 'classnames'
import React from 'react'
import { BsGrid, BsList } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

function SelectViewProduct({ viewDefault }) {
  const dispatch = useDispatch()
  return (
    <div className='w-100 d-flex align-items-center mb-3 select-view'>
      <button
        className={classNames(
          'btn me-2',
          viewDefault === VIEW_LIST ? 'btn-secondary' : 'btn-outline-secondary'
        )}
        onClick={() => dispatch(setViewDefaultForShop(VIEW_LIST))}
      >
        <BsList className='me-2' size={16} />
        List
      </button>
      <button
        className={classNames(
          'btn mt-0',
          viewDefault === VIEW_GRID ? 'btn-secondary' : 'btn-outline-secondary'
        )}
        onClick={() => dispatch(setViewDefaultForShop(VIEW_GRID))}
      >
        <BsGrid className='me-2' size={16} />
        Tile
      </button>
    </div>
  )
}

export default SelectViewProduct
