/* eslint-disable import/no-cycle */

import moment from 'moment'
import { DELIVERY_BUYER_CANCELLED, DELIVERY_HOST_CANCELLED } from '../constant'
import { isDisableEvent } from '../function'
import { convertToTimeEnd } from './converter'

export function sortedJsonStringify(obj) {
  const sortedObj = Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      result[key] = obj[key]
      return result
    }, {})

  return JSON.stringify(sortedObj)
}

// sort list order by disable order and time created
export const sortListOrder = (listOrder) => {
  if (!listOrder || !listOrder.length) return []
  return listOrder.sort((first, second) => {
    const fDisable = isDisableEvent(
      first.status,
      first.buyerStatus,
      first.ePickupTime,
      first.ePickupDuration
    )
    const sDisable = isDisableEvent(
      second.status,
      second.buyerStatus,
      second.ePickupTime,
      second.ePickupDuration
    )
    if ((fDisable && !sDisable) || first.createdAt - second.createdAt < 0) return 1
    return -1
  })
}
export const sortListMyOrder1 = (listOrder) => {
  if (!listOrder || !listOrder.length) return []
  const listOrder1 = listOrder.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    return -1
  })
  const listOrder2 = listOrder1.filter(
    (item) => !isDisableEvent(item.status, item.buyerStatus, item.ePickupTime, item.ePickupDuration)
  )
  const listOrder3 = listOrder1.filter((item) =>
    isDisableEvent(item.status, item.buyerStatus, item.ePickupTime, item.ePickupDuration)
  )
  const listOrder4 = listOrder3.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    return -1
  })
  return [...listOrder2, ...listOrder4]
}

// sort list event by time created
export const sortListEvents = (listEvent) => {
  if (!listEvent || !listEvent.length) return []
  return listEvent.sort((first, second) => {
    if (first.createdAt - second.createdAt < 0) return 1
    return -1
  })
}

// sort list event by pickupTimestamp
export const sortListEventsByPickupTimestamp = (listEvent, decrease = true) => {
  if (!listEvent || !listEvent.length) return []
  return listEvent.sort((first, second) => {
    if (first.pickupTimestamp - second.pickupTimestamp < 0) return decrease ? 1 : -1
    return decrease ? -1 : 1
  })
}

export const sortArrayBaseOnAnotherArray = (itemsArray, sortingArr) =>
  itemsArray
    .map((item) => {
      const n = sortingArr.indexOf(item.pid)
      return [n, item]
    })
    .sort()
    .map((j) => j[1])

export const sortListMyOrder = (listOrder) => {
  if (!listOrder || !listOrder.length) return []
  const listOrderActive = listOrder.filter(
    (item) => !isDisableEvent(item.status, item.buyerStatus, item.ePickupTime, item.ePickupDuration)
  )
  const listOrderDisable = listOrder.filter((i) => listOrderActive.indexOf(i) === -1)

  // eslint-disable-next-line array-callback-return
  const listOrderDisableSort = listOrderDisable.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (first.createdAt > second.createdAt) {
      return 1
    }
    if (first.createdAt < second.createdAt) {
      return -1
    }
  })
  // eslint-disable-next-line array-callback-return
  const listOrderActiveSort = listOrderActive.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (first.createdAt > second.createdAt) {
      return -1
    }
    if (first.createdAt < second.createdAt) {
      return 1
    }
  })
  return { listOrderActiveSort, listOrderDisableSort }
}

export const sortListMyDelivery = (listDelivery) => {
  if (!listDelivery || !listDelivery.length) return []
  const listDeliveryActive = []
  listDelivery.forEach((item) => {
    const { bookerStatus, status, dDeliveryDuration, dDeliveryTime } = item
    if (
      !moment().isAfter(
        moment(convertToTimeEnd(dDeliveryTime, dDeliveryDuration), 'DD-MM-YYYY HH:mm')
      ) &&
      bookerStatus !== DELIVERY_BUYER_CANCELLED &&
      status !== DELIVERY_HOST_CANCELLED
    ) {
      listDeliveryActive.push(item)
    }
  })
  const listDeliveryDisable = listDelivery.filter((i) => listDeliveryActive.indexOf(i) === -1)

  // eslint-disable-next-line array-callback-return
  const listDeliveryDisableSort = listDeliveryDisable.sort((first, second) => {
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (first.createdAt > second.createdAt) {
      return 1
    }
    if (first.createdAt < second.createdAt) {
      return -1
    }
  })
  // eslint-disable-next-line array-callback-return
  const listDeliveryActiveSort = listDeliveryActive.sort((first, second) => {
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (first.createdAt > second.createdAt) {
      return -1
    }
    if (first.createdAt < second.createdAt) {
      return 1
    }
  })
  return { listDeliveryActiveSort, listDeliveryDisableSort }
}
