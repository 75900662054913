/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup'
import virtualShopApi from '_api/virtualShop'
import { deleteDelivery, updateDelivery } from '_redux/modules/fulfillment'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { DATES_OF_WEEK, LIST_TIME_2, TIME_SLOT } from '_utils/constant'
import { getDatesOfCurrentWeek } from '_utils/function'
import { calculateDuration } from '_utils/functions/calculator'
import { convertToGMT0 } from '_utils/functions/converter'
import { getDataShopDetail } from '_utils/localData'
import Holidays from 'date-holidays'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsTrashFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import DeliveryFee from './DeliveryFee'
import DeliveryInstruction from './DeliveryInstruction'
import DeliveryName from './DeliveryName'
import DeliveryTimeSlot from './DeliveryTimeSlot'
import OpeningHours from './OpeningHours'
import Timer from './Timer'

const validationSchema = Yup.object().shape({
  optionDeliveryName: Yup.string('Invalid delivery name').required('Invalid delivery name'),
  instruction: Yup.string('Invalid headline').nullable().default(''),
  deliveryFee: Yup.string().nullable(),
})

const listDatesOfCurrentWeek = getDatesOfCurrentWeek()
const formatDatesOfWeek = DATES_OF_WEEK.map((item, i) => ({
  ...item,
  date: listDatesOfCurrentWeek[i].date,
  isClose: false,
}))

function DeliveryOption({ delivery, index, shopId }) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const {
    optionName,
    deliveryFee,
    instructions,
    id,
    deliveryEndTime: deliveryEndTimeResponse,
    datesOfWeek: datesOfWeekResponse,
    timeSlot: timeSlotResponse,
    deliveryStartTime: deliveryStartTimeResponse,
    isCheckPublicHoliday: isCheckPublicHolidayResponse,
  } = delivery

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    getValues,
    // watch,
    // setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      timeSlots: TIME_SLOT,
    },
  })

  const { timeSlots } = getValues()
  const shop = getDataShopDetail()
  const [checkedInstruction, setCheckedInstruction] = useState(!!instructions)
  const [checkedDeliveryFee, setCheckedDeliveryFee] = useState(false)
  const [checkedTimeSlot, setCheckedTimeSlot] = useState(false)
  const [checkedHoliday, setCheckedHoliday] = useState(false)
  const [datesOfWeek, setDatesOfWeek] = useState(datesOfWeekResponse || formatDatesOfWeek)
  const { pathname } = useLocation()
  const [deliveryHour, setDeliveryHour] = useState(
    calculateDuration(
      datesOfWeek[0].date,
      datesOfWeek[6].date,
      deliveryStartTimeResponse || '2:00',
      deliveryEndTimeResponse || '11:00'
    ) || {
      hourStart: '9AM',
      hourEnd: '6PM',
    }
  )
  const [isRealId] = useState(id?.indexOf('FAKE_ID') === -1)

  const holidays = new Holidays('SG')
  const currentYear = new Date().getFullYear()

  const showPublicHoliday = (isCheck) => {
    const currentHolidayYear = holidays.getHolidays(currentYear)
    const publicHolidays = currentHolidayYear.filter((date) => {
      const dateOfHoliday = moment(date.date).format('YYYY-MM-DD')
      return listDatesOfCurrentWeek.some(
        (dateCurrentWeek) => dateCurrentWeek.date === dateOfHoliday
      )
    })

    return datesOfWeek.map((date) => {
      const isHoliday = publicHolidays.some(
        (holiday) =>
          moment(holiday.date).format('DD-MM-YYYY') === moment(date.date).format('DD-MM-YYYY')
      )
      const nameHoliday = publicHolidays.find(
        (holiday) =>
          moment(holiday.date).format('DD-MM-YYYY') === moment(date.date).format('DD-MM-YYYY')
      )

      if (isCheck) {
        return {
          ...date,
          isClose: isHoliday || date.isClose,
          name: isHoliday ? nameHoliday : '',
        }
      }

      return {
        ...date,
        isClose: isHoliday ? false : date.isClose,
        name: '',
      }
    })
  }

  useEffect(() => {
    if (timeSlotResponse) {
      setCheckedTimeSlot(true)
      reset({
        timeSlots: timeSlots.map((time) => ({
          ...time,
          isCheck: timeSlotResponse === time.value,
        })),
      })
    } else {
      setCheckedTimeSlot(false)
    }

    if (deliveryFee) {
      setCheckedDeliveryFee(true)
    } else {
      setCheckedDeliveryFee(false)
    }

    if (instructions) {
      setCheckedInstruction(true)
    } else {
      setCheckedInstruction(false)
    }

    if (isCheckPublicHolidayResponse) {
      showPublicHoliday(true)
      setCheckedHoliday(true)
    } else {
      showPublicHoliday(false)
      setCheckedHoliday(false)
    }

    if (datesOfWeekResponse) {
      setDatesOfWeek(datesOfWeekResponse)
    }
  }, [pathname, datesOfWeekResponse, isCheckPublicHolidayResponse])

  const handleChangeInstruction = (e) => {
    const isCheck = e.target.checked
    setCheckedInstruction(isCheck)
  }

  const handleAddDeliveryFee = (e) => {
    const isCheck = e.target.checked
    setCheckedDeliveryFee(isCheck)
  }

  const handleAddTimeSlot = (e) => {
    const isCheck = e.target.checked
    setCheckedTimeSlot(isCheck)
  }

  const handleCheckHoliday = (e) => {
    const isCheck = e.target.checked
    setCheckedHoliday(isCheck)

    const newDatesOfWeek = showPublicHoliday(isCheck)
    setDatesOfWeek(newDatesOfWeek)
  }

  const handleCheckTimeSlot = (e, i, onChange) => {
    const isCheck = e.target.checked

    const newTimeSlots = timeSlots.map((item, _i) => {
      if (_i === i) {
        return { ...item, isCheck }
      }
      return { ...item, isCheck: false }
    })

    onChange(newTimeSlots)

    // reset({ ...watch, timeSlots: newTimeSlots })
  }

  const handleClickDate = (e, i) => {
    const isCheck = e.target.checked
    const newDatesPublicHoliday = datesOfWeek.map((item, _i) => {
      if (_i === i) {
        return { ...item, isClose: isCheck }
      }
      return { ...item }
    })

    if (newDatesPublicHoliday.every((item) => item.isClose)) {
      return addToast('You cannot turn off all dates of week', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    setDatesOfWeek(newDatesPublicHoliday)
  }

  const convertTimeToGMT0 = (date, time) => {
    const dateAndTime = date + time
    return convertToGMT0(dateAndTime).slice(11)
  }

  const onSubmit = async (data) => {
    try {
      const deliveryStartIndex = LIST_TIME_2.findIndex(
        (item) => item.text === deliveryHour?.hourStart
      )
      const deliveryEndIndex = LIST_TIME_2.findIndex((item) => item.text === deliveryHour?.hourEnd)

      const deliveryStartTime = LIST_TIME_2[deliveryStartIndex].valueString
      const deliveryEndTime = LIST_TIME_2[deliveryEndIndex].valueString

      const timeSlotSelected = data?.timeSlots?.find((item) => item.isCheck)?.value

      const postData = {
        optionName: data?.optionDeliveryName,
        deliveryFee: checkedDeliveryFee ? Number(data?.deliveryFee) : 0,
        instructions: checkedInstruction ? data?.instruction : '',
        deliveryTime: {
          startTime: convertTimeToGMT0(
            moment(datesOfWeek[0].date).format('DD-MM-YYYY'),
            deliveryStartTime
          ),
          endTime: convertTimeToGMT0(
            moment(datesOfWeek[6].date).format('DD-MM-YYYY'),
            deliveryEndTime === '00:00' ? moment().endOf('day').format('HH:mm') : deliveryEndTime
          ),
        },
        datesOfWeek,
        isCheckPublicHoliday: checkedHoliday,
        timeSlot: checkedTimeSlot ? timeSlotSelected : null,
      }

      if (id.indexOf('FAKE_ID') === -1) {
        const postDataUpdate = {
          ...delivery,
          ...postData,
        }

        await Promise.all([
          virtualShopApi.updateDeliveryOption(id, postData),
          virtualShopApi.updateShopNoPickupAndDelivery(shopId, {
            fulfillmentStatus: Number(shop.fulfillmentStatus),
          }),
        ])

        dispatch(updateDelivery(index, postDataUpdate))
        addToast('Update successfully', { appearance: 'success', autoDismiss: true })
      } else {
        const [{ msgResp }] = await Promise.all([
          virtualShopApi.createDeliveryOption(shopId, postData),
        ])

        if (data?.optionDeliveryName) {
          await virtualShopApi.updateShopNoPickupAndDelivery(shopId, {
            fulfillmentStatus: Number(shop.fulfillmentStatus),
          })
        }

        dispatch(updateDelivery(index, msgResp))
        addToast('Create success !', { appearance: 'success', autoDismiss: true })
      }

      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          fulfillmentStatus: Number(shop.fulfillmentStatus),
        })
      )
    } catch (e) {
      addToast(e.msgResp || e.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleRemoveDeliveryOption = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (id.indexOf('FAKE_ID') === -1) {
            await virtualShopApi.deleteDeliveryOption(id)
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
            dispatch(deleteDelivery(index))
          } else {
            dispatch(deleteDelivery(index))
          }
        } catch (e) {
          addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
        }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='delivery-options border rounded-3 p-3 mb-3'>
        <DeliveryName optionName={optionName} register={register} errors={errors} />

        <OpeningHours
          datesOfWeek={datesOfWeek}
          handleCheckHoliday={handleCheckHoliday}
          index={index}
          checkedHoliday={checkedHoliday}
          handleClickDate={handleClickDate}
          register={register}
          type='delivery'
        />

        <Timer setHours={setDeliveryHour} Hours={deliveryHour} />

        <DeliveryFee
          deliveryFee={deliveryFee}
          checkedDeliveryFee={checkedDeliveryFee}
          handleAddDeliveryFee={handleAddDeliveryFee}
          errors={errors}
          control={control}
        />

        <DeliveryInstruction
          checkedInstruction={checkedInstruction}
          handleChangeInstruction={handleChangeInstruction}
          instructions={instructions}
          register={register}
          errors={errors}
        />

        <DeliveryTimeSlot
          checkedTimeSlot={checkedTimeSlot}
          handleAddTimeSlot={handleAddTimeSlot}
          control={control}
          index={index}
          handleCheckTimeSlot={handleCheckTimeSlot}
        />

        <div className='row'>
          <div className='col-12 d-flex align-items-center justify-content-center mt-3'>
            {isRealId ? (
              <button
                className='btn btn-primary-outline btn-cancel text-uppercase shadow-none'
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'Update'}
              </button>
            ) : (
              <button
                className='btn btn-primary-outline btn-cancel text-uppercase shadow-none'
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save'}
              </button>
            )}
            <div
              className='bg-transparent border-0 ms-2 cursor-pointer'
              onClick={handleRemoveDeliveryOption}
            >
              <BsTrashFill size={30} color='#0BADAD' />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default DeliveryOption
