import InputField from '_components/InputField'
import React from 'react'

const EventPickupLocation = (props) => {
  const { register, error } = props
  return (
    <div className='form-group' data-testid="event-pickup-location">
      <label>Pick-up Location</label>
      <div>
        <InputField
          className='form-control text-left'
          name={register.name}
          type='text'
          register={register}
        />
        <small className='errorMessage'>{error}</small>
      </div>
    </div>
  )
}

export default EventPickupLocation
