import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_EVENT_COLLECTED,
  HOST_ORDER_EVENT_VERIFIED,
  HOST_ORDER_UNPAID,
} from '_utils/constant'
import moment from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { FaCheck } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Tooltip } from 'reactstrap'

const statusStyles = {
  [HOST_ORDER_UNPAID]: { color: '#ff6903', backgroundColor: '#f06a0f', fontSize: '1em' },
  [HOST_ORDER_EVENT_VERIFIED]: { color: '#ffffff', backgroundColor: '#198754', fontSize: '0.6em' },
  [HOST_ORDER_EVENT_COLLECTED]: { color: '#7c797a', backgroundColor: '#0dcaf0', fontSize: '1em' },
  default: { color: '#7c797a', backgroundColor: '#7c797a', fontSize: '1em' },
}

export default function OrderEventRecordItem({
  item,
  index,
  tooltipOpen,
  toggleTooltip,
  onChangeStatusSelect,
  openPaymentScreen,
  onOpenComment,
}) {
  const { loadingEventAndOrderById, loadingListCombinedPayment } = useSelector(
    (state) => state.orderEvent
  )

  const currentStyle = statusStyles[item.status] || statusStyles.default

  const [fontSize, setFontSize] = useState('1em')
  const selectRef = useRef(null)

  useEffect(() => {
    const updateFontSize = () => {
      if (selectRef.current) {
        const width = selectRef.current.offsetWidth
        if (width < 100) {
          setFontSize('0.6em')
        } else if (width < 200) {
          setFontSize('0.8em')
        } else {
          setFontSize('1em')
        }
      }
    }

    updateFontSize()
    window.addEventListener('resize', updateFontSize)
    return () => window.removeEventListener('resize', updateFontSize)
  }, [])

  const styleLoading = {
    opacity: !loadingEventAndOrderById && !loadingListCombinedPayment ? 1 : 0.5,
    pointerEvents: !loadingEventAndOrderById && !loadingListCombinedPayment ? 'auto' : 'none',
  }

  return (
    <>
      <div
        id={`tooltip-${index}`}
        className={`${index % 2 !== 0 ? 'bg-light' : ''} row align-items-center p-3 row-item`}
        style={{ fontSize: '0.8vw', ...styleLoading }}
      >
        <div className='col'>
          <span className='me-2'>{index + 1}</span>
        </div>
        <div className='col d-flex flex-column text-break'>
          <span>{item?.uName}</span>
          {item?.comment ? (
            <button className='p-0 bg-transparent border-0' onClick={() => onOpenComment(item)}>
              <i className='icon-new' />
            </button>
          ) : null}
        </div>
        <div
          className='col text-break'
          style={{
            width: '110px',
          }}
        >
          {item?.uPhone}
        </div>
        <div className='col-2 pe-3 text-break'>
          <ul className='list-unstyled mb-0'>
            {item?.allProductPerOrder?.map((product, _index) => (
              <Fragment key={`${product.pid}-${_index}`}>
                <li
                  className={`d-flex align-items-center flex-wrap ${
                    item?.allProductPerOrder?.length > 1 ? 'mb-1' : 'mb-0'
                  }`}
                >
                  <p className='mb-0'>
                    <span>{product.pName}: </span>
                    <strong>
                      <CurrencyFormat
                        value={product.pPriceNoSubItems || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </strong>{' '}
                    <span>({product.pQuantity})</span>
                  </p>
                  {product?.pSubItems?.length > 0 ? (
                    <>
                      {product.pSubItems.map((subItem, subIndex) => (
                        <div key={`${subItem.name}-${subIndex}`}>
                          <span>{subItem.name}:</span>
                          {subItem.price > 0 ? (
                            <strong className='ms-1'>
                              <CurrencyFormat
                                value={subItem.price || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </strong>
                          ) : (
                            <strong className='ms-1'>$0</strong>
                          )}
                          {subIndex !== product.pSubItems.length - 1 ? (
                            <span style={{ marginRight: 3 }}>,</span>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </>
                  ) : null}
                </li>
              </Fragment>
            ))}
          </ul>
        </div>
        <div className='col-2 fw-bold text-break'>
          <div className='d-flex flex-column fw-normal'>
            <p className='fw-bold mb-0'>Event pickup</p>
            <span>{item?.ePickupAddress}</span>
          </div>
        </div>
        <div className='col text-break'>
          <p className='mb-0'>{moment.unix(item.createdAt).format('DD-MM-YYYY')}</p>
          <span className='fw-bold' style={{ color: '#f06a0f' }}>
            {moment.unix(item.createdAt).format('HH:mm:ss')}
          </span>
        </div>
        <div className='col text-break'>
          <p className='mb-0'>{moment.unix(item.ePickupTimestamp).format('DD-MM-YYYY')}</p>
        </div>
        <div className='col text-break text-center'>
          {item?.buyerStatus === BUYER_ORDER_UNPAID && (
            <p className='n-paid text-center mb-0' style={{ paddingRight: 0 }}>
              Not Paid
            </p>
          )}
          {item?.buyerStatus === BUYER_ORDER_PAID && (
            <div
              className='d-flex align-items-center fw-bold justify-content-center cursor-pointer'
              onClick={(e) => {
                if (!loadingEventAndOrderById && !loadingListCombinedPayment) {
                  openPaymentScreen(e, item)
                }
              }}
            >
              <CurrencyFormat
                value={item?.totalFee || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />

              <a
                href='#'
                className={`ms-1 ${item?.combinedPaymentRef ? 'paid-combined' : 'paid'}`}
                data-toggle='modal'
                data-target='#modal-paid'
              />
            </div>
          )}
          {item?.buyerStatus === BUYER_ORDER_CANCELED && (
            <p className='mb-0 text-center'>Cancelled</p>
          )}
          {item?.buyerStatus === BUYER_ORDER_COLLECTED && (
            <p className='mb-0 text-center'>
              <span
                className='d-flex align-items-center fw-bold justify-content-center cursor-pointer'
                onClick={(e) => openPaymentScreen(e, item)}
              >
                <CurrencyFormat
                  value={item?.totalFee || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                <FaCheck color='#14b711' className='ms-1' />
              </span>
            </p>
          )}
          {item?.buyerStatus === BUYER_ORDER_REFUNDED && (
            <p className='mb-0 text-center'>Refunded</p>
          )}
        </div>
        <div className='col text-break'>
          <select
            ref={selectRef}
            className='form-select-sm border text-white w-100 rounded-3'
            value={item?.status}
            onChange={(e) =>
              onChangeStatusSelect(e, item?.ref, item?.combinedPaymentRef, item?.eid)
            }
            style={{
              ...currentStyle,
              fontSize,
              height: 30,
            }}
          >
            <option className='bg-white text-default' value={HOST_ORDER_CANCELED}>
              Cancelled
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_UNPAID}>
              Unpaid
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_EVENT_VERIFIED}>
              Payment Verified
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_EVENT_COLLECTED}>
              Collected
            </option>
          </select>
        </div>
      </div>
      <Tooltip
        isOpen={tooltipOpen === item.ref}
        target={`tooltip-${index}`}
        toggle={() => toggleTooltip(item.ref)}
        className='tooltip-custom'
      >
        Order Ref: {item.combinedPaymentRef || item.ref}
      </Tooltip>
    </>
  )
}
