/* eslint-disable no-prototype-builtins */
/* eslint-disable function-paren-newline */
import Pagination from '_components/Pagination'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import NotFoundData from '_images/not-found-data.jpg'
import { getListOrderFulfillment, setListDateIsPicked } from '_redux/modules/order'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'

import FulfillmentItem from './FulfillmentItem'
import Map from './Map'

function ListFulfillment({
  currentOrdersFulfillment,
  swapUpFulfillment,
  swapDownFulfillment,
  currentPage,
  setCurrentPage,
  loading,
  updateStatusFulfillment,
  search,
  allListFulfillment,
}) {
  const headers = [
    {
      name: 'Seq',
      field: '',
      className: 'col text-center',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Orders',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Orders/Qty',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Fulfillment',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Fulfill Date',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Status',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
  ]
  const dispatch = useDispatch()
  const { listDateIsPicked } = useSelector((state) => state.order)
  const [lstAddress, setLstAddress] = useState([])
  const [orderFulfillment, setOrderFulfillment] = useState(null)

  useEffect(() => {
    const address = []

    currentOrdersFulfillment?.forEach((d, index) => {
      if (d.status === 1 || d.status === 0) {
        if (d?.giftRecipientInfo) {
          address.push({ index, value: d.giftRecipientInfo?.address })
        } else {
          address.push({ index, value: d?.pickupAddress || d?.uAddress })
        }
      }
    })

    setLstAddress(address)
    dispatch(setListDateIsPicked([]))
    dispatch(getListOrderFulfillment(currentOrdersFulfillment))
  }, [JSON.stringify(currentOrdersFulfillment)])

  useEffect(() => {
    if (orderFulfillment?.length > 0) {
      const newOrderFulfillment = []
      for (let i = 0; i < orderFulfillment?.length; i++) {
        const index = lstAddress.findIndex((item) => item.value === orderFulfillment[i]?.uAddress)
        newOrderFulfillment.push({ ...orderFulfillment[i], index: index + 1 })
      }
      setOrderFulfillment(newOrderFulfillment)
    }
  }, [orderFulfillment?.length, lstAddress?.length])

  useEffect(() => {
    if (listDateIsPicked?.length > 0) {
      const newDeliveryOrderList = []
      for (let k = 0; k < listDateIsPicked?.length; k++) {
        const orderByListDateIsPicked = currentOrdersFulfillment?.filter(
          (item) =>
            moment.unix(item?.deliveryTimestamp).format('DD-MM-YYYY') ===
            moment.unix(listDateIsPicked[k]).format('DD-MM-YYYY')
        )
        newDeliveryOrderList?.push(orderByListDateIsPicked)
      }
      return newDeliveryOrderList?.flat()
    }
    return null
  }, [listDateIsPicked?.length, currentOrdersFulfillment?.length])

  return (
    <div className='row'>
      <div className='col-3'>
        {lstAddress?.length > 0 ? (
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&callback=initMap`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px', width: '100%' }} />}
            mapElement={<div style={{ height: '600px' }} />}
            lstAddress={lstAddress}
            listDateIsPicked={listDateIsPicked}
          />
        ) : (
          <div className='w-100 h-100 bg-light d-flex flex-column align-items-center justify-content-center'>
            <span className='fw-semibold'>
              Google Map.
              <FaLocationDot
                className='text-danger f-20'
                style={{ transform: 'translateY(-5px)' }}
              />
            </span>
            <small className='text-info px-3 text-center'>
              No orders are currently pending to be displayed on the map.
            </small>
          </div>
        )}
      </div>
      <div className='col-9'>
        <div className='list-delivery'>
          <TableHeaderWithSort headers={headers} className='row p-3 bg-light table-header f-14' />
          {orderFulfillment?.length
            ? orderFulfillment?.map((item, index) => (
                <Fragment key={item.id}>
                  <FulfillmentItem
                    key={item.id}
                    delivery={item}
                    deliveryIndex={index}
                    swapUpFulfillment={swapUpFulfillment}
                    swapDownFulfillment={swapDownFulfillment}
                  />
                </Fragment>
              ))
            : null}

          {!loading &&
            !orderFulfillment?.length ?
            currentOrdersFulfillment?.map((item, index) => (
              <Fragment key={item.id}>
                <FulfillmentItem
                  key={item.id}
                  fulfillment={item}
                  fulfillmentIndex={index}
                  swapUpFulfillment={swapUpFulfillment}
                  swapDownFulfillment={swapDownFulfillment}
                  maxLengthFulfillment={currentOrdersFulfillment?.length - 1}
                  updateStatusFulfillment={updateStatusFulfillment}
                />
              </Fragment>
            )) : null}

          {!loading && !currentOrdersFulfillment?.length ? (
            <div className='text-center my-5'>
              <img src={NotFoundData} style={{ width: 200, height: 200 }} alt='' />
              <h6 className='text-secondary'>No Deliveries Found</h6>
            </div>
          ) : null}
        </div>

        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={!search ? allListFulfillment.length : currentOrdersFulfillment.length}
          pageSize={5}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  )
}

export default ListFulfillment
