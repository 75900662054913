import { setListDateIsPicked } from '_redux/modules/order'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ORDERS_PAGE } from '_utils/constant'
import moment from 'moment'

import './style.scss'

function formatDate(item, typePage) {
  const dateToFormat =
    item?.pickupBookingDate?.createdAtOrder ||
    item?.deliveryBookingDate?.createdAtOrder ||
    item?.createdAt
  const formatString = typePage === ORDERS_PAGE ? 'DD MMMM YYYY' : 'DD MMM YYYY'
  const formattedDate = moment.unix(dateToFormat).format(formatString)
  return moment.unix(dateToFormat).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
    ? 'Today'
    : formattedDate
}

function sortData(data, typePage) {
  return data.sort((a, b) => {
    if (typePage === ORDERS_PAGE) {
      return a.createdAt < b.createdAt ? -1 : 1
    }
    return a.deliveryTimestamp < b.deliveryTimestamp ? -1 : 1
  })
}

function ListItem({ item, isActive, handleClick }) {
  return (
    <div
      className={`list_date-fulfillment-item cursor-pointer ${isActive ? 'active-s' : ''}`}
      onClick={handleClick}
    >
      <span>{item.displayDate}</span>
      <span>{'>'}</span>
    </div>
  )
}

function ListDateFulfillment({ data, handleAddItemIntoListDateIsPicked, listDate, typePage }) {
  const sortedData = sortData(data, typePage).map((item) => ({
    ...item,
    displayDate: formatDate(item, typePage),
    isActive: listDate.some(
      (date) =>
        (date.deliveryTimestamp || date.createdAt) ===
        (item?.createdAt ?? item?.pickupTimestamp ?? item?.deliveryTimestamp)
    ),
    handleClick: () =>
      handleAddItemIntoListDateIsPicked(
        item?.createdAt
          ? { createdAt: item?.createdAt, address: item?.pickupAddress || item.uAddress }
          : {
              deliveryTimestamp: item?.pickupTimestamp || item?.deliveryTimestamp,
              address: item?.pickupAddress || item.uAddress,
            }
      ),
  }))

  return (
    <>
      {sortedData.map((item, i) => (
        <ListItem key={i} item={item} isActive={item.isActive} handleClick={item.handleClick} />
      ))}
    </>
  )
}

export default function FulfillmentCalendar({ typePage }) {
  const dispatch = useDispatch()
  const { listOrdersByHost, listOrderFulfillment, listDateIsPicked } = useSelector(
    (state) => state.order
  )


  const handleAddItemIntoListDateIsPicked = (ref) => {
    const { deliveryTimestamp, createdAt } = ref
    const newDate = deliveryTimestamp || createdAt

    const isDateAlreadySelected = listDateIsPicked.some(
      (item) => (item.deliveryTimestamp || item.createdAt) === newDate
    )

    let newListDateIsPicked

    if (isDateAlreadySelected) {
      newListDateIsPicked = listDateIsPicked.filter(
        (item) => (item.deliveryTimestamp || item.createdAt) !== newDate
      )
    } else {
      newListDateIsPicked = [ref]
    }

    dispatch(setListDateIsPicked(newListDateIsPicked))
  }

  const handleClearDuplicateInArray = (array) => {
    const convertData = () => {
      const newArray = []
      for (let i = 0; i < array?.length; i++) {
        const data = array[i]
        const item = {
          ...data,
          createdAtString: moment
            .unix(
              data?.pickupBookingDate?.createdAtOrder ||
                data?.deliveryBookingDate?.createdAtOrder ||
                data?.createdAt
            )
            .format('DD-MM-YYYY'),
        }
        newArray.push(item)
      }
      return newArray
    }

    const arrayAfterConvert = convertData()

    const uniqueIDate = []
    const unique = arrayAfterConvert.filter((element) => {
      const isDuplicate = uniqueIDate.includes(element.createdAtString)

      if (!isDuplicate) {
        uniqueIDate.push(element?.createdAtString)
        return true
      }

      return false
    })

    return unique
  }

  return (
    <>
      <div className='list_date-fulfillment'>
        <ListDateFulfillment
          data={
            typePage === ORDERS_PAGE
              ? handleClearDuplicateInArray(listOrdersByHost)
              : handleClearDuplicateInArray(listOrderFulfillment)
          }
          listDate={listDateIsPicked}
          typePage={typePage}
          handleAddItemIntoListDateIsPicked={handleAddItemIntoListDateIsPicked}
        />
      </div>
    </>
  )
}
