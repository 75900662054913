import axiosClient from './axiosClient'

const packagesApi = {
  getListPackagesByType: (type) => {
    const url = `get-list-package-by-type?type=${type}`
    return axiosClient.get(url)
  },
  getListPackageConfig: () => {
    const url = '/get-list-config'
    return axiosClient.get(url)
  },
}

export default packagesApi
