import { CheckoutContext } from '_contexts/CheckoutContext'
import { SELF_PICK_UP } from '_utils/constant'
import { getCartCheckout } from '_utils/localData'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

function FormContactDetails() {
  const { buyer, register, errors, shop, setValue } = useContext(CheckoutContext)
  const { orderDetailsByRef } = useSelector((state) => state.order)
  const { fulfillmentType, listDelivery } = useSelector((state) => state.fulfillment)
  const cartCheckout = getCartCheckout()

  useEffect(() => {
    if (!isEmpty(cartCheckout?.buyer)) {
      setValue('name', cartCheckout?.buyer?.name)
      setValue('email', cartCheckout?.buyer?.email)
      setValue('phone', cartCheckout?.buyer?.phone)
      setValue('address', cartCheckout?.buyer?.address)
    }
  }, [JSON.stringify(cartCheckout?.buyer)])

  useEffect(() => {
    if (
      !isEmpty(orderDetailsByRef) &&
      isEmpty(orderDetailsByRef?.orderPickup) &&
      isEmpty(orderDetailsByRef?.orderDelivery)
    ) {
      setValue('name', orderDetailsByRef.uName)
      setValue('email', orderDetailsByRef.uEmail)
      setValue('phone', orderDetailsByRef.uPhone)
    }
  }, [JSON.stringify(orderDetailsByRef)])

  useEffect(() => {
    if (!isEmpty(cartCheckout?.orderDelivery)) {
      setValue('name', cartCheckout?.orderDelivery?.uName)
      setValue('email', cartCheckout?.orderDelivery?.uEmail)
      setValue('phone', cartCheckout?.orderDelivery?.uPhone)
      setValue('address', cartCheckout?.orderDelivery?.uAddress || '')
    }
  }, [JSON.stringify(cartCheckout?.orderDelivery)])

  useEffect(() => {
    if (!isEmpty(cartCheckout?.orderPickup)) {
      setValue('name', cartCheckout?.orderPickup?.uName)
      setValue('email', cartCheckout?.orderPickup?.uEmail)
      setValue('phone', cartCheckout?.orderPickup?.uPhone)
      setValue('address', cartCheckout?.orderPickup?.uAddress || '')
    }
  }, [JSON.stringify(cartCheckout?.orderPickup)])

  useEffect(() => {
    if (!isEmpty(orderDetailsByRef)) {
      if (!isEmpty(orderDetailsByRef?.orderDelivery)) {
        setValue('name', orderDetailsByRef?.orderDelivery?.uName)
        setValue('email', orderDetailsByRef?.orderDelivery?.uEmail)
        setValue('phone', orderDetailsByRef?.orderDelivery?.uPhone)
        setValue('address', orderDetailsByRef?.orderDelivery?.uAddress || '')
      } else if (!isEmpty(orderDetailsByRef?.orderPickup)) {
        setValue('name', orderDetailsByRef?.orderPickup?.uName)
        setValue('email', orderDetailsByRef?.orderPickup?.uEmail)
        setValue('phone', orderDetailsByRef?.orderPickup?.uPhone)
        setValue('address', orderDetailsByRef?.orderPickup?.uAddress || '')
      }
    }
  }, [JSON.stringify(orderDetailsByRef)])

  const getDisplayStyle = () => {
    if (
      buyer.isGift ||
      fulfillmentType === SELF_PICK_UP ||
      !shop?.fulfillmentStatus ||
      !listDelivery?.length
    ) {
      return {
        display: 'none',
      }
    }

    return {
      display: 'block',
    }
  }

  return (
    <div
      className={classNames(
        'form-delivery-details bg-white p-3 rounded',
        shop?.giftRecipient || !shop?.fulfillmentStatus ? '' : 'mt-3'
      )}
      style={{
        border: '1px solid lightseagreen',
      }}
    >
      <p className='text-uppercase title'>Contact Details</p>
      <div className='mb-3 name'>
        <input
          type='text'
          name='name'
          className='form-control-sm border-0 rounded-pill'
          defaultValue={buyer?.name}
          placeholder='Full Name'
          {...register('name')}
        />
        {errors.name && <small className='text-danger text-left'>{errors.name.message}</small>}
      </div>
      <div className='mb-3 e-mail'>
        <input
          type='email'
          name='email'
          className=' form-control-sm border-0 rounded-pill'
          placeholder='E-mail'
          defaultValue={buyer?.email}
          {...register('email')}
        />
        {errors.email && <small className='text-danger text-left'>{errors.email.message}</small>}
      </div>
      <div className='mb-3 phone'>
        <input
          type='text'
          name='phone'
          className='form-control-sm border-0 rounded-pill'
          placeholder='Mobile Number'
          defaultValue={buyer?.phone}
          {...register('phone')}
        />
        {errors.phone && <small className='text-danger text-left'>{errors.phone.message}</small>}
      </div>
      <div className='address'>
        <input
          type='text'
          name='address'
          className='form-control-sm border-0 rounded-pill'
          placeholder='Address'
          defaultValue={buyer?.address}
          style={getDisplayStyle()}
          {...register('address')}
        />
        {errors.address && (
          <small className='text-danger text-left'>{errors.address.message}</small>
        )}
      </div>
    </div>
  )
}

export default FormContactDetails
