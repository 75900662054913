import invoiceApi from '_api/invoice'
import { SUBSCRIPTION_STATUS_REFUND } from '_utils/constant'
import { getCurrentLazadaPackageLocalStorage, setLazadaPackageLocalStorage } from '_utils/localData'

const SET_LAZADA_PACKAGE = 'SET_LAZADA_PACKAGE'
const GET_LAZADA_PACKAGE = 'GET_LAZADA_PACKAGE'
const CANCEL_LAZADA = 'CANCEL_LAZADA'
const REMOVE_LAZADA = 'REMOVE_LAZADA'
const FETCH_LIST_INVOICE_LAZADA = 'FETCH_LIST_INVOICE_LAZADA'

const initialState = {
  listInvoiceLazada: [],
  lazadaPackage: {},
}

export const getCurrentLazadaPackage = () => (dispatch) => {
  const lazadaPackage = getCurrentLazadaPackageLocalStorage()
  if (lazadaPackage) {
    dispatch({
      type: GET_LAZADA_PACKAGE,
      payload: { lazadaPackage },
    })
  }
}

export const setLazadaPackage = (data) => (dispatch) => {
  dispatch({
    type: SET_LAZADA_PACKAGE,
    payload: { lazadaPackage: data },
  })
  setLazadaPackageLocalStorage(data)
}

export const handleRemoveLazada = (lazadaId) => async (dispatch, getState) => {
  const { lazada } = getState()
  const { listInvoiceLazada } = lazada

  const newCurrentInvoices = listInvoiceLazada.filter((item) => item?.id !== lazadaId)

  try {
    await invoiceApi.removeLazadaFeature(lazadaId)
    dispatch({
      type: REMOVE_LAZADA,
      payload: {
        listInvoiceLazada: newCurrentInvoices,
      },
    })
  } catch (error) {
    dispatch({
      type: REMOVE_LAZADA,
      payload: {
        listInvoiceLazada,
      },
    })
    throw new Error(error.message || error.msgResp)
  }
}

export const handleCancelLazada = (lazadaId) => async (dispatch, getState) => {
  const { lazada } = getState()
  const { listInvoiceLazada } = lazada
  const newCurrentInvoices = [...listInvoiceLazada]
  const findIndex = newCurrentInvoices.findIndex((item) => item?.id === lazadaId)

  newCurrentInvoices[findIndex].status = SUBSCRIPTION_STATUS_REFUND

  try {
    dispatch({
      type: CANCEL_LAZADA,
      payload: {
        listInvoiceLazada: newCurrentInvoices,
      },
    })
    await invoiceApi.cancelLazadaFeature(lazadaId)
  } catch (error) {
    dispatch({
      type: CANCEL_LAZADA,
      payload: {
        listInvoiceLazada,
      },
    })
    throw new Error(error.message || error.msgResp)
  }
}

export const fetchListInvoiceLazada = () => async (dispatch) => {
  try {
    const { msgResp } = await invoiceApi.getListInvoice()

    dispatch({
      type: FETCH_LIST_INVOICE_LAZADA,
      payload: {
        listInvoiceLazada: msgResp.invoicesLazadaSort || [],
      },
    })

    if (msgResp?.invoicesLazadaSort?.length > 0) {
      setLazadaPackageLocalStorage(msgResp?.invoicesLazadaSort[0])
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

const lazadaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_INVOICE_LAZADA:
    case CANCEL_LAZADA:
    case REMOVE_LAZADA:
    case GET_LAZADA_PACKAGE:
    case SET_LAZADA_PACKAGE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default lazadaReducer
