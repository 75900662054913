import { CheckoutContext } from '_contexts/CheckoutContext'
import { DELIVERY_OPTION, SELF_PICK_UP } from '_utils/constant'
import { useContext } from 'react'
import { FaTruckMoving } from 'react-icons/fa'
import { GiPerson } from 'react-icons/gi'
import { useSelector } from 'react-redux'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import TabDelivery from './TabDelivery'
import TabPickup from './TabPickup'

function Tabs() {
  const { activeTab, setActiveTab, shop } = useContext(CheckoutContext)
  const { listPickUp, listDelivery } = useSelector((state) => state.fulfillment)

  return (
    <div className='tabs'>
      {shop?.fulfillmentStatus && (listPickUp.length || listDelivery.length) ? (
        <Nav tabs>
          {listPickUp.length ? (
            <NavItem>
              <NavLink
                className={`text-decoration-none ${
                  activeTab === SELF_PICK_UP ? 'active' : 'bg-white border'
                }`}
                onClick={() => setActiveTab(SELF_PICK_UP)}
                style={{ height: '100%' }}
              >
                <div className='d-flex align-items-center justify-content-center h-100 option-self-pickup'>
                  <GiPerson size={24} /> <span className='ms-2'>Self - Pickup</span>
                </div>
              </NavLink>
            </NavItem>
          ) : null}

          {shop?.fulfillmentStatus && listDelivery.length ? (
            <NavItem>
              <NavLink
                className={`text-decoration-none ${
                  activeTab === DELIVERY_OPTION ? 'active' : 'bg-white border'
                }`}
                style={{ height: '100%' }}
                onClick={() => setActiveTab(DELIVERY_OPTION)}
              >
                <div className='d-flex align-items-center justify-content-center option-delivery'>
                  <FaTruckMoving size={24} /> <span className='ms-2'>Delivery to my address</span>
                </div>
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
      ) : null}

      <TabContent activeTab={activeTab}>
        {shop?.fulfillmentStatus && listDelivery.length ? (
          <TabPane tabId={DELIVERY_OPTION}>
            <Row>
              <Col sm='12'>
                <TabDelivery />
              </Col>
            </Row>
          </TabPane>
        ) : null}

        {shop?.fulfillmentStatus && listPickUp.length ? (
          <TabPane tabId={SELF_PICK_UP}>
            <Row>
              <Col sm='12'>
                <TabPickup />
              </Col>
            </Row>
          </TabPane>
        ) : null}
      </TabContent>
    </div>
  )
}

export default Tabs
