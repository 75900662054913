import { PACKAGE_LAZADA, PACKAGE_SUBSCRIPTION } from '_utils/constant'
import { Button, ButtonGroup } from 'reactstrap'

const SelectTabInvoice = ({ tabInvoice, setTabInvoice }) => (
  <>
    {/* <hr style={{ borderTop: 'solid 1px #dee2e6' }} /> */}
    <ButtonGroup className={'w-100 justify-content-center wrap-button-invoice'}>
      <Button
        color='primary'
        outline
        onClick={() => setTabInvoice(PACKAGE_SUBSCRIPTION)}
        active={tabInvoice === PACKAGE_SUBSCRIPTION}
        className={'shadow-none'}
      >
        Subscription
      </Button>
      <Button
        color='primary'
        outline
        onClick={() => setTabInvoice(PACKAGE_LAZADA)}
        active={tabInvoice === PACKAGE_LAZADA}
        className={'mt-0 shadow-none'}
      >
        Lazada
      </Button>
    </ButtonGroup>
    {/* <hr style={{ borderTop: 'solid 1px #dee2e6' }} /> */}
  </>
)

export default SelectTabInvoice
