import deliveryApi from '_api/delivery'

import serviceApi from '_api/service'
import { DOMAIN, STATUS_PAID } from '_utils/constant'
import { getDomain, setDomain } from '_utils/localData'

const LIST_DELIVERY_CREATED_BY_HOST = 'LIST_DELIVERY_CREATED_BY_HOST'

const initialState = {
  listDeliveryCreatedByHost: [],
  callNewAPIDeliveryCreatedByHost: false,
}


export const getListDeliveryCreatedByHost = (hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    let { listDeliveryCreatedByHost = [] } = delivery
    const { callNewAPIDeliveryCreatedByHost } = delivery

    const index = listDeliveryCreatedByHost.findIndex((item) => item.hostId === hostId)

    if (index !== -1 && !callNewAPIDeliveryCreatedByHost) {
      return
    }

    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }
    const { msgResp: listDeliveryCreated } = await deliveryApi.listDeliveryCreatedByHost(
      hostId,
      domainLocal
    )

    if (index !== -1) {
      listDeliveryCreatedByHost[index] = { hostId, listDeliveryCreated }
    } else {
      listDeliveryCreatedByHost = [...listDeliveryCreatedByHost, { hostId, listDeliveryCreated }]
    }
    dispatch({
      type: LIST_DELIVERY_CREATED_BY_HOST,
      payload: {
        listDeliveryCreatedByHost,
        callNewAPIDeliveryCreatedByHost: false,
      },
    })
  } catch (e) {}
}

export const clearDeliveryCreatedByHost = () => async (dispatch) => {
  dispatch({
    type: LIST_DELIVERY_CREATED_BY_HOST,
    payload: { listDeliveryCreatedByHost: [] },
  })
}

const eventDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DELIVERY_CREATED_BY_HOST:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default eventDeliveryReducer
