import { combineReducers } from 'redux'
import dashBoardShopReducer from './modules/dashboard'
import deliveryReducer from './modules/delivery'
import eventReducer from './modules/event'
import eventDeliveryReducer from './modules/eventDelivery'
import fulfillmentReducer from './modules/fulfillment'
import lazadaReducer from './modules/lazada'
import notificationReducer from './modules/notification'
import orderShopReducer from './modules/order'
import packageReducer from './modules/package'
import productReducer from './modules/product'
import shopReducer from './modules/shop'
import subscriptionReducer from './modules/subscription'
import userReducer from './modules/user'
import orderEventReducer from './modules/orderEvent'

const reducers = combineReducers({
  shop: shopReducer,
  dashBoard: dashBoardShopReducer,
  order: orderShopReducer,
  fulfillment: fulfillmentReducer,
  user: userReducer,
  package: packageReducer,
  notification: notificationReducer,
  product: productReducer,
  subscription: subscriptionReducer,
  lazada: lazadaReducer,
  delivery: deliveryReducer,
  event: eventReducer,
  eventDelivery: eventDeliveryReducer,
  orderEvent: orderEventReducer,
})

export default (state, action) => reducers(state, action)
