import LoadingOverlay from '_components/LoadingOverlay'
import {
  fetchListProductLazada,
  fetchListVariationProductLazada,
  selectTab,
  updateListProductLazada,
} from '_redux/modules/product'
import { PLATFORM_CO_KII, SUBSCRIPTION_STATUS_APPROVED } from '_utils/constant'
import { flexibleSearch } from '_utils/function'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductLazadaItem from '../Lazada/ProductItem'

function ListProductLazada({
  isPushingLaz,
  setIsPushingLaz,
  isRemovingLaz,
  setIsRemovingLaz,
  isUpdatingVariationLaz,
  setIsUpdatingVariationLaz,
  searchString,
}) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const { listProductLazada } = useSelector((state) => state.product)
  const { selectedShop } = useSelector((state) => state.shop)
  const { listInvoiceLazada } = useSelector((state) => state.lazada)

  const isHasLazada =
    listInvoiceLazada?.find((item) => item.shopId === selectedShop?.id)?.status ===
      SUBSCRIPTION_STATUS_APPROVED || false

  useEffect(() => {
    if (selectedShop?.id && selectedShop?.id !== '') {
      const fetchData = async () => {
        await dispatch(fetchListProductLazada(selectedShop.id))
        setLoading(false)
      }
      if (isHasLazada) {
        fetchData()
      } else {
        localStorage.removeItem('selectedTabProduct')
        dispatch(selectTab(PLATFORM_CO_KII))
        setLoading(false)
      }
    }

    return () => {
      dispatch(updateListProductLazada([]))
      localStorage.removeItem('listProductLazada')
    }
  }, [selectedShop?.id, isHasLazada])

  useEffect(() => {
    if (listProductLazada?.length) {
      const hasVariations = listProductLazada.some((item) => item.variations.length)
      if (hasVariations) {
        dispatch(fetchListVariationProductLazada())
      }

      localStorage.setItem('listProductLazada', JSON.stringify(listProductLazada))
    } else {
      localStorage.removeItem('listProductLazada')
    }
  }, [listProductLazada?.length, isHasLazada])

  const tableHeaders = [
    { label: '', name: '' },
    { label: 'Name', name: 'name' },
    { label: 'Price', name: 'price' },
    { label: 'Inventory', name: 'inventory' },
    { label: 'Purchase', name: 'purchase' },
    { label: 'Description', name: 'description' },
    { label: 'Active', name: 'active' },
    { label: 'Actions', name: 'actions' },
  ]

  const listProductLazadaFiltered = useMemo(() => {
    if (searchString) {
      return listProductLazada.filter((product) => flexibleSearch(product.name, searchString))
    }

    return listProductLazada
  }, [searchString, JSON.stringify(listProductLazada), JSON.stringify(selectedShop)])

  return (
    <div className='list-product position-relative border-start border-end border-bottom mb-3'>
      <table className='table'>
        <thead className='product-header'>
          <tr>
            {tableHeaders.map((item) => (
              <th key={item.name}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className='product-content f-14'>
          {loading ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <LoadingOverlay style={{ margin: '50px auto' }} />
              </td>
            </tr>
          ) : (
            <>
              {listProductLazadaFiltered?.length ? (
                listProductLazadaFiltered
                  .sort((a, b) => b.createdDate - a.createdDate)
                  .map((product) => (
                    <Fragment key={product.id}>
                      <ProductLazadaItem
                        product={product}
                        tableHeaders={tableHeaders}
                        isPushingLaz={isPushingLaz}
                        setIsPushingLaz={setIsPushingLaz}
                        isRemovingLaz={isRemovingLaz}
                        setIsRemovingLaz={setIsRemovingLaz}
                        isUpdatingVariationLaz={isUpdatingVariationLaz}
                        setIsUpdatingVariationLaz={setIsUpdatingVariationLaz}
                      />
                    </Fragment>
                  ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length} width={'100%'}>
                    <div className='text-center my-5'>
                      <h6 className='text-secondary'>No Products Found</h6>
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ListProductLazada
