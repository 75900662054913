import * as Sentry from '@sentry/react'
// import FallBack from '_components/FallBack'
import React from 'react'
import FallBack from './Fallback'

const WithErrorBoundary = (WrappedComponent, fallback = () => <FallBack />) =>
  Sentry.withErrorBoundary(WrappedComponent, {
    fallback,
  })

export default WithErrorBoundary
