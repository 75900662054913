/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import virtualShopApi from '_api/virtualShop'
import cancelImageIcon from '_images/cancelImageIcon.png'
import TelegramSVG from '_images/telegram.svg'
import {
  selectShopAndSelectShopDetail,
  setListShopActive,
  updateShopInMyShops,
} from '_redux/modules/shop'
import { fetchUserInfo } from '_redux/modules/user'
import { domainGenerator, telegramLinkGenerator } from '_utils/functions/generator'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineQuestionCircle, AiOutlineWhatsApp } from 'react-icons/ai'
import { FaInstagram } from 'react-icons/fa'
import { IoTrashOutline } from 'react-icons/io5'
import { SiFacebook } from 'react-icons/si'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import { Tooltip } from 'reactstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import ExternalLinkModal from './components/ExternalLinkModal'
import ShopName from './components/InputShopName'
import LogoModal from './components/LogoModal'
import ModalTelegram from './components/ModalTelegram'
import './style.scss'

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
const shopNameRegex = /^[a-zA-Z0-9 ]*$/

const validationSchema = Yup.object().shape({
  shopName: Yup.string('Invalid shop name')
    .required('Invalid shop name')
    .min(3, 'Shop name must be at least 3 characters')
    .max(255, 'Shop name must be at most 255 characters')
    .matches(shopNameRegex, 'Shop name must not contain special characters'),
  // shopLink: Yup.string('Invalid shop link').required('Invalid shop link'),
  headline: Yup.string('Invalid headline')
    .nullable()
    .default('')
    .max(1024, 'Headline must be at most 1024 characters'),
  address: Yup.string('Invalid address').nullable().default(''),
  linkInstagram: Yup.string().matches(URL, {
    message: 'invalid link Instagram',
    excludeEmptyString: true,
  }),
  linkFacebook: Yup.string().matches(URL, {
    message: 'invalid link Facebook',
    excludeEmptyString: true,
  }),
  whatsApp: Yup.string('Invalid whatsApp')
    .typeError('whatsApp must be a number')
    .max(16, 'whatsApp number with at most 16 digits')
    .nullable()
    .required('Phone number is required'),
})

function General() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const timeZoneRef = useRef('')
  const currencyRef = useRef('')
  const history = useHistory()

  const { selectedShop, listShopActive } = useSelector((state) => state.shop)

  const [listLinkExternal, setListLinkExternal] = useState([])
  const [isOpenModalAddLink, setIsOpenModalAddLink] = useState(false)
  // const [profileImage, setProfileImage] = useState({
  //   url: selectedShop?.profileImageUrl,
  //   file: null,
  // })
  const [isOpenModalAddLogo, setIsOpenModalAddLogo] = useState(false)
  const [listLogoShop, setListLogoShop] = useState([])
  const [isIntegratedTelegram, setIsIntegratedTelegram] = useState(false)
  const [isLoadingIntegrateTelegram, setIsLoadingIntegrateTelegram] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isOpenModalTelegram, setIsOpenModalTelegram] = useState(false)
  const [isSubmittingTelegram, setIsSubmittingTelegram] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (selectedShop?.shopSlug) {
      setValue('shopName', selectedShop?.shopName)
      setValue('shopLink', selectedShop?.shopSlug)
      setValue('headline', selectedShop?.headline)
      setValue('shopAddress', selectedShop?.shopAddress)
      setValue('linkInstagram', selectedShop?.linkInstagram)
      setValue('linkFacebook', selectedShop?.linkFacebook)
      setValue('whatsApp', selectedShop?.whatsApp)
      setValue('timeZone', selectedShop?.timeZone)
      setValue('currency', selectedShop?.currency)
      setValue('telegramChatId', selectedShop?.telegramChatId)

      setListLinkExternal(selectedShop?.externalLink || [])
      setListLogoShop(selectedShop?.logo?.map((item) => ({ url: item, file: '' })))

      if (selectedShop?.telegramChatId) {
        setIsIntegratedTelegram(true)
      } else {
        setIsIntegratedTelegram(false)
      }
    }
  }, [selectedShop?.shopSlug])

  const toggleModalAddLink = () => {
    setIsOpenModalAddLink(!isOpenModalAddLink)
  }

  const toggleModalAddLogo = () => {
    setIsOpenModalAddLogo(!isOpenModalAddLogo)
  }

  const removeExternalLink = (index) => {
    const newList = [...listLinkExternal]

    newList.splice(index, 1)

    setListLinkExternal(newList)

    dispatch(selectShopAndSelectShopDetail({ ...selectedShop, externalLink: newList }))
  }

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const toggleModalTelegram = () => setIsOpenModalTelegram(!isOpenModalTelegram)

  const onSubmit = async (data) => {
    const list = listLogoShop
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.file) {
        const { msgResp } = await uploadApi.uploadPhoto([element.file])
        element.url = msgResp.url
      }
    }

    try {
      const postData = {
        ...data,
        logo: list.map((item) => item.url),
        timeZone: Number(timeZoneRef.current.value),
        currency: currencyRef.current.value,
        externalLink: listLinkExternal,
        shopSlug: data.shopName.trim().replace(/ /g, '-').toLowerCase(),
        shopLink: domainGenerator() + data.shopName.trim().replace(/ /g, '-').toLowerCase(),
      }
      await virtualShopApi.updateShop(postData, selectedShop.id)

      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          ...postData,
        })
      )

      /* trick pass switch between shops when changing shopName, improve later */
      const updatedListShopActive = listShopActive.map((shop) => {
        if (shop.id === selectedShop.id) {
          return {
            ...shop,
            ...postData,
          }
        }
        return shop
      })

      dispatch(setListShopActive(updatedListShopActive))
      dispatch(
        updateShopInMyShops({
          ...selectedShop,
          ...postData,
        })
      )
      dispatch(fetchUserInfo())
      /* end trick */

      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
      history.push(`/admin-console/${postData.shopSlug}/setting/general`)
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleIntegrateTelegram = async () => {
    try {
      setIsLoadingIntegrateTelegram(true)
      window.open(`${telegramLinkGenerator()}`, '_blank')

      setIsIntegratedTelegram(true)
      setIsLoadingIntegrateTelegram(false)
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUninstallTelegram = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to uninstall telegram',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsSubmittingTelegram(true)
          const postData = {
            ...selectedShop,
            telegramChatId: null,
          }

          await virtualShopApi.updateShop(postData, selectedShop.id)

          setIsIntegratedTelegram(false)
          dispatch(selectShopAndSelectShopDetail({ ...selectedShop, telegramChatId: null }))
          dispatch(updateShopInMyShops({ ...selectedShop, telegramChatId: null }))
          addToast('Uninstall successfully', { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        } finally {
          setIsSubmittingTelegram(false)
        }
      }
    })
  }

  return (
    <div className='general bg-white pt-3 px-3 pb-5'>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        <div className='col-5'>
          <div>
            <ShopName
              error={errors?.shopName?.message}
              register={register}
              value={selectedShop?.shopName}
            />
            <div className='mb-3'>
              <label htmlFor='headline' className='form-label'>
                Headline
              </label>
              <textarea
                className='form-control'
                id='headline'
                rows='3'
                {...register('headline')}
                defaultValue={selectedShop?.headline}
              ></textarea>
              <small className='errorMessage'>{errors?.headline?.message}</small>
            </div>
            <div className='mb-3'>
              <label htmlFor='TimeZone' className='form-label'>
                TimeZone
              </label>
              <select
                ref={timeZoneRef}
                defaultValue={8}
                className='form-select form-select-sm mb-3 cursor-not-allow'
                aria-label='.form-select-lg example'
                disabled
              >
                <option value={8}>ASIA /SINGAPORE</option>
              </select>
            </div>
            <div className='mb-3'>
              <label htmlFor='TimeZone' className='form-label'>
                Currency
              </label>
              <select
                ref={currencyRef}
                defaultValue='SGD'
                className='form-select form-select-sm mb-3 cursor-not-allow'
                aria-label='.form-select-lg example'
                disabled
              >
                <option value='SGD'>SGD</option>
              </select>
            </div>
          </div>
        </div>
        <div className='col-2'></div>
        <div className='col-5'>
          <div className='mb-3 '>
            <label htmlFor='contact' className='ps-0 w-100'>
              Contact
            </label>
            <div className='row'>
              <div className='col-auto me-2'>
                <AiOutlineWhatsApp fontSize={30} color='#A6DFDE' className='mt-1' />
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className='form-control'
                  id='whats-app'
                  {...register('whatsApp')}
                  defaultValue={selectedShop?.whatsApp}
                  placeholder='Enter whatsapp number'
                />
                <small className='errorMessage'>{errors?.whatsApp?.message}</small>
              </div>
            </div>
          </div>
          <div className='mb-3 row'>
            <div className='col-auto me-2'>
              <FaInstagram fontSize={30} color='#A6DFDE' className='mt-1' />
            </div>
            <div className='col-10'>
              <input
                type='text'
                className='form-control'
                id='instagram'
                {...register('linkInstagram')}
                defaultValue={selectedShop?.linkInstagram}
                placeholder='Enter instagram link'
              />
              <small className='errorMessage'>{errors?.linkInstagram?.message}</small>
            </div>
          </div>
          <div className='mb-3 row'>
            <div className='col-auto me-2'>
              <SiFacebook
                fontSize={29.5}
                color='#A6DFDE'
                className='mt-1'
                style={{ transform: 'translateX(0.5px)' }}
              />
            </div>
            <div className='col-10'>
              <input
                type='text'
                className='form-control'
                id='facebook'
                {...register('linkFacebook')}
                defaultValue={selectedShop?.linkFacebook}
                placeholder='Enter facebook link'
              />
              <small className='errorMessage'>{errors?.linkFacebook?.message}</small>
            </div>
          </div>

          <div className='mb-3 row d-none'>
            <div className='col-12 mb-2'>
              <span>Social Media</span>
            </div>
            <div className='col-12'>
              {isIntegratedTelegram ? (
                <div className='row align-items-center position-relative'>
                  {!selectedShop?.telegramChatId ? (
                    <div className='col-auto me-2'>
                      <>
                        <AiOutlineQuestionCircle
                          size={30}
                          className='cursor-pointer'
                          id='telegram-tooltip'
                          onClick={toggleModalTelegram}
                          color='#A6DFDE'
                        />
                        <Tooltip
                          isOpen={tooltipOpen}
                          target='telegram-tooltip'
                          toggle={toggleTooltip}
                        >
                          Click me!
                        </Tooltip>
                      </>
                    </div>
                  ) : null}
                  <div className='col-10'>
                    {!selectedShop?.telegramChatId ? (
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter ChatID telegram'
                        {...register('telegramChatId')}
                      />
                    ) : (
                      <div className='d-flex align-items-start'>
                        <div className='border p-2 d-flex rounded gap-2 align-items-center'>
                          <span className='w-100 fw-bold f-14'>
                            <img src={TelegramSVG} alt='telegram' width={24} className='me-1' />
                            Chat ID: {selectedShop?.telegramChatId}
                          </span>
                          <button
                            type='button'
                            className='btn btn-telegram rounded shadow-none uninstall text-secondary p-0 ms-auto'
                            onClick={handleUninstallTelegram}
                            disabled={isSubmittingTelegram}
                          >
                            <IoTrashOutline size={15} />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <button
                  type='button'
                  className='btn btn-telegram shadow-none d-flex align-items-center justify-content-center'
                  style={{ width: 100 }}
                  onClick={handleIntegrateTelegram}
                  disabled={isLoadingIntegrateTelegram}
                >
                  <img src={TelegramSVG} alt='telegram' width={24} className='me-2' />
                  {isLoadingIntegrateTelegram ? (
                    <div className='btn-telegram loading'></div>
                  ) : (
                    <span>Install</span>
                  )}
                </button>
              )}
            </div>
          </div>

          <div className='mb-4'>
            <label htmlFor='address' className='form-label' style={{ marginBottom: 13 }}>
              Address
            </label>
            <input
              type='text'
              className='form-control'
              id='shopAddress'
              {...register('shopAddress')}
              defaultValue={selectedShop?.shopAddress}
            />
            <small className='errorMessage'>{errors?.shopAddress?.message}</small>
          </div>

          <div className='mb-3 row'>
            <div className='col-4'>
              <span>Banner:</span>
            </div>
            <div className='col-8'>
              <span
                style={{ color: '#ff6903' }}
                className='cursor-pointer fw-bold'
                onClick={toggleModalAddLogo}
              >
                Add Banner
              </span>
            </div>
          </div>
          {listLogoShop?.length > 0 && (
            <div className='text-center mb-3'>
              {listLogoShop?.slice(0, 6).map((logo, index) => (
                <img
                  src={logo.url}
                  className={`rounded shadow-sm ${listLogoShop?.length > 1 ? 'me-2 mb-2' : ''}`}
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover' }}
                  alt=''
                  key={index}
                />
              ))}
            </div>
          )}

          <div className='row mb-3'>
            <div className='col-4'>
              <span>External Link:</span>
            </div>
            <div className='col-8'>
              <span
                style={{ color: '#ff6903' }}
                className='cursor-pointer fw-bold'
                onClick={toggleModalAddLink}
              >
                Add External Link
              </span>
            </div>
          </div>
          {listLinkExternal?.length > 0 && (
            <div
              className='row external__link-list my-3 p-3 rounded'
              style={{ backgroundColor: 'rgb(248 250 252)' }}
            >
              <p style={{ color: '#00000078', fontWeight: 600 }}>External link list</p>
              {listLinkExternal?.map((item, i) => (
                <div key={i} className='external__link-list-item align-items-center'>
                  <div className='external__link-list-item-text'>
                    <span>{item.name}: </span>
                    <a href={item.value}>{item.value}</a>
                  </div>
                  <div onClick={() => removeExternalLink(i)}>
                    <img src={cancelImageIcon} alt='' />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='w-100'></div>
        <div className='col-12 mt-4'>
          <div className='d-flex align-items-center justify-content-end'>
            <button className='btn btn-primary-outline btn-cancel text-uppercase'>Cancel</button>
            <button
              className='btn btn-primary rounded-pill text-uppercase ms-3'
              type='submit'
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </form>
      <ExternalLinkModal
        modal={isOpenModalAddLink}
        toggle={toggleModalAddLink}
        value={listLinkExternal}
        setValue={setListLinkExternal}
      />

      <LogoModal
        modal={isOpenModalAddLogo}
        toggle={toggleModalAddLogo}
        listImage={listLogoShop}
        setListImage={setListLogoShop}
      />

      <ModalTelegram
        modal={isOpenModalTelegram}
        toggle={toggleModalTelegram}
        className='modal-telegram custom-scrollbar'
      />
    </div>
  )
}

export default General
