import subscriptionApi from '_api/subscription'
import hitpayIcon from '_images/hitpay-icon.jpg'
import { PACKAGE_SUBSCRIPTION, PAYMENT_HITPAY, TRANSFER_BANKING } from '_utils/constant'
import { getUserInfo } from '_utils/localData'
import React, { useRef, useState } from 'react'
import { AiFillCreditCard } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import PaymentScreen from './PaymentScreen'
import './style.scss'

function Payment() {
  const { addToast } = useToasts()
  const userInfo = getUserInfo()
  const [selectedOption, setSelectedOption] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const photoRef = useRef()
  const [paymentFileUrl, setPaymentFileUrl] = useState()
  const history = useHistory()
  // const [paymentFileUpload, setPaymentFileUpload] = useState()
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)

  const subscriptionSelected = JSON.parse(localStorage.getItem('SUBSCRIPTION_TYPE'))

  const onUserImageUploaded = (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPaymentFileUrl(URL.createObjectURL(img))
    }
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option)
  }

  const createSubscription = async () => {
    try {
      const url = paymentFileUrl || photoRef.current
      // const url = await uploadPaymentScreenshot()
      if (!url && showForm) {
        setIsSubmittedPayment(false)
        alert('Please upload payment screenshot')
        return
      }

      setIsSubmittedPayment(true)

      const postData = {
        paymentType: selectedOption === TRANSFER_BANKING ? TRANSFER_BANKING : PAYMENT_HITPAY,
        subscriptionPaymentScreenshot: selectedOption === TRANSFER_BANKING ? url : '',
        subscriptionPrice: parseFloat(subscriptionSelected?.price),
        subscriptionPackageId: subscriptionSelected?.id,
        packageType: PACKAGE_SUBSCRIPTION,
      }

      const { msgResp, msgCode } = await subscriptionApi.createSubscription(userInfo?.id, postData)

      if (msgResp?.subscriptionPaymentLink) {
        window.open(msgResp.subscriptionPaymentLink, '_blank')
      } else if (msgCode === 52400) {
        addToast('Create successfully!', {
          appearance: 'success',
          autoDismiss: true,
          transitionDuration: 500,
        })
        localStorage.removeItem('SUBSCRIPTION_TYPE')
        setTimeout(() => {
          history.push('/user/invoice')
        }, 1000)
      }
    } catch (error) {
      addToast(error.msgResp || error.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsSubmittedPayment(false)
    }
  }

  const handleNext = async () => {
    try {
      if (selectedOption === TRANSFER_BANKING) {
        setShowForm(true)
      } else if (selectedOption === PAYMENT_HITPAY) {
        // Xử lý thanh toán qua hitpay
        await createSubscription()
      } else {
        addToast('Please select a payment option', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } catch (error) {
      addToast('An error occurred during payment. Please try again later.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleBack = () => {
    if (showForm) {
      setShowForm(false)
    } else {
      window.history.back()
    }
  }

  return (
    <div className='subscription-payment'>
      <h2 className='text-uppercase'>Payment Methods</h2>
      {!showForm ? (
        <div className='payment-options'>
          <label
            className={`payment-option ${selectedOption === TRANSFER_BANKING ? 'selected' : ''}`}
          >
            <input
              type='radio'
              value='upload'
              checked={selectedOption === TRANSFER_BANKING}
              onChange={() => handleOptionChange(TRANSFER_BANKING)}
            />
            <AiFillCreditCard className='payment-option-image ms-2' color='#edaf00f7' />
            {/* <img className='payment-option-image ms-2' src={paynowIcon} alt='Paynow' /> */}
            <span className='payment-option-label'>Pay via Banking</span>
          </label>
          <label
            className={`payment-option flex-wrap ${selectedOption === 'hitpay' ? 'selected' : ''}`}
          >
            <input
              type='radio'
              value='hitpay'
              checked={selectedOption === PAYMENT_HITPAY}
              onChange={() => handleOptionChange(PAYMENT_HITPAY)}
            />
            <img className='payment-option-image ms-2' src={hitpayIcon} alt='Hitpay' />
            <span className='payment-option-label'>Pay via Hitpay</span>
            <small className='f-12 text-info w-100 mt-1 text-end pe-4'>
              After successful HitPay transactions, subscription cancellations aren&apos;t possible.
            </small>
          </label>
        </div>
      ) : (
        <PaymentScreen
          paymentFileUrl={paymentFileUrl}
          onUserImageUploaded={onUserImageUploaded}
          confirmPayment={createSubscription}
          isSubmittedPayment={isSubmittedPayment}
          ref={photoRef}
          stringRefNumber={Math.random().toString(36).substring(7)}
          price={subscriptionSelected?.price}
          phone={userInfo?.phone}
        />
      )}
      <div className='d-flex align-items-center gap-3'>
        <button onClick={handleBack} className='btn btn-outline-secondary shadow-none rounded'>
          Back
        </button>
        <button
          onClick={!showForm ? handleNext : createSubscription}
          disabled={isSubmittedPayment}
          className='btn btn-primary mt-0 shadow-none'
        >
          {selectedOption === 'paynow' && !showForm
            ? 'Next'
            : isSubmittedPayment
            ? 'Loading...'
            : 'Payment'}
        </button>
      </div>
    </div>
  )
}

export default Payment
