import React from 'react'

const STATUS_CANCELLED = -1
const STATUS_PENDING = 1
const STATUS_PAID = 2
const STATUS_VERIFIED = 3

function FilterMyPurchase({ onFilter }) {
  return (
    <div className='text-end mb-3'>
      <select className='form-select-sm border f-16' onChange={onFilter}>
        <option value='all'>All</option>
        <option value={STATUS_PENDING}>Pending</option>
        <option value={STATUS_PAID}>Paid</option>
        <option value={STATUS_VERIFIED}>Verified</option>
        <option value={STATUS_CANCELLED}>Cancelled</option>
      </select>
    </div>
  )
}

export default FilterMyPurchase
