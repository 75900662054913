import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_VERIFIED,
} from '../constant'
import { convertTimeStringWithDuration } from './converter'
import { sortArrayBaseOnAnotherArray } from './sorter'

export const handleCombinedPayment = (combinedPayment, currRef = '') => {
  let { orderCombinedPayment = [], deliveryCombinedPayment = [] } = combinedPayment
  let stringReturn = ''
  deliveryCombinedPayment = deliveryCombinedPayment
    .filter((item) => item.ref !== currRef)
    .map(
      ({ dDeliveryTime, dDeliveryDuration }) =>
        `delivery for (${convertTimeStringWithDuration(dDeliveryTime, dDeliveryDuration)})`
    )

  orderCombinedPayment = orderCombinedPayment
    .filter((item) => item.ref !== currRef)
    .map(({ eTitle }) => `${eTitle}`)

  if (deliveryCombinedPayment.length && orderCombinedPayment.length) {
    stringReturn = `${orderCombinedPayment.join(', ')}, ${deliveryCombinedPayment.join(', ')}`
  } else if (deliveryCombinedPayment.length === 0 && orderCombinedPayment.length) {
    stringReturn = `${orderCombinedPayment.join(', ')}`
  } else if (deliveryCombinedPayment.length && orderCombinedPayment.length === 0) {
    stringReturn = `${deliveryCombinedPayment.join(', ')}`
  }
  return stringReturn
}

export const handleDataOrderDigistall = (
  _listOrders,
  _adminCost = 0,
  _discount = 0,
  _deliveryCost = 0,
  _productIdList = []
) => {
  let dataReturn = []
  let userOrder = []
  let adminCostAllOrder = 0
  let totalAllOrder = 0
  let placeBy = []
  let paymentCollected = 0
  let numBuy = 0
  if (_listOrders && _listOrders.length > 0) {
    let orderRef = []

    _listOrders.forEach((e) => {
      if (orderRef.includes(e.ref) !== true) {
        if (e.buyerStatus !== BUYER_ORDER_CANCELED && e.status !== HOST_ORDER_CANCELED) {
          totalAllOrder += e.pPrice * e.pQuantity
          numBuy += e.pQuantity
          userOrder = [...userOrder, e.uid]
        }
        dataReturn = [
          ...dataReturn,
          {
            ref: e.ref,
            buyerStatus: e.buyerStatus,
            status: e.status,
            comment: e.comment,
            combinedPaymentRef:
              e.combinedPaymentRef && !!e.combinedPaymentRef.length ? e.combinedPaymentRef : '',
            uName: e.uName,
            uPhone: e.uPhone,
            uPhotoUrl: e.uPhotoUrl,
            uid: e.uid,
            sTitle: e.eTitle,
            createdAt: e.createdAt,
            shopOrderNo: e.shopOrderNo,
            fulfillmentType: e.fulfilmentType,
            deliveryDetail: e.deliveryDetail,
            shopProfileImageUrl: e.shopProfileImageUrl,
            pickupDetail: e.pickupDetail,
            shopName: e.shopName,
            shopId: e.shopId,
            visible: e.visible,
            paymentScreenshot: e.paymentScreenshot,
            transactionFees: e.transactionFees || 0,
            cashOnDeliveryPickup: e.cashOnDeliveryPickup,
            giftRecipientInfo: e.giftRecipientInfo,
            uEmail: e.uEmail,
            lazadaInfo: e?.lazadaInfo,
            allProductPerOrder: [
              {
                pid: e.pid,
                name: e.pName,
                price: e.pPrice,
                photoUrl: e.pPhotoUrl,
                quantity: e.pQuantity,
                subItems: e.pSubItems,
                pPriceNoSubItems: e.pPriceNoSubItems,
              },
            ],
          },
        ]
        orderRef = [...orderRef, e.ref]
      } else {
        if (e.buyerStatus !== BUYER_ORDER_CANCELED && e.status !== HOST_ORDER_CANCELED) {
          totalAllOrder += e.pPrice * e.pQuantity
          numBuy += e.pQuantity
        }
        for (let t = 0; t < dataReturn.length; t++) {
          if (dataReturn && dataReturn[t]?.ref === e.ref) {
            dataReturn[t].allProductPerOrder.push({
              pid: e.pid,
              name: e.pName,
              price: e.pPrice,
              photoUrl: e.pPhotoUrl,
              quantity: e.pQuantity,
              subItems: e.pSubItems,
              pPriceNoSubItems: e.pPriceNoSubItems,
            })
          }
        }
      }
    })
    const listUid = []
    dataReturn.forEach((d) => {
      if (!listUid.includes(d.uid)) {
        placeBy = [...placeBy, d.uName]
        listUid.push(d.uid)
      }
    })
    const newUserOrder = userOrder.reduce((prev, cur) => {
      prev[cur] = (prev[cur] || 0) + 1
      return prev
    }, {})
    for (let index = 0; index < dataReturn.length; index++) {
      let totalPricePerOrder = 0
      dataReturn[index].allProductPerOrder.forEach((p) => {
        totalPricePerOrder += p.price * p.quantity
      })
      const adminCostPerOrder = _adminCost
      let discountPerOrder = 0
      if (totalAllOrder !== 0 && !Number.isNaN(totalAllOrder)) {
        discountPerOrder = (_discount * totalPricePerOrder) / totalAllOrder
      }
      let deliveryPerOrder = 0
      if (
        Object.getOwnPropertyNames(newUserOrder).length !== 0 &&
        typeof newUserOrder[dataReturn[index].uid] !== 'undefined' &&
        newUserOrder[dataReturn[index].uid] !== 0
      ) {
        deliveryPerOrder =
          _deliveryCost /
          Object.getOwnPropertyNames(newUserOrder).length /
          newUserOrder[dataReturn[index].uid]
      }
      dataReturn[index].totalPricePerOrder = totalPricePerOrder
      dataReturn[index].adminCostPerOrder = adminCostPerOrder
      dataReturn[index].discountPerOrder = discountPerOrder
      dataReturn[index].deliveryPerOrder = deliveryPerOrder
      if (
        dataReturn[index].status !== HOST_ORDER_CANCELED &&
        dataReturn[index].buyerStatus !== BUYER_ORDER_CANCELED
      ) {
        dataReturn[index].totalFee =
          totalPricePerOrder + adminCostPerOrder - discountPerOrder + deliveryPerOrder
      } else {
        dataReturn[index].totalFee = totalPricePerOrder
      }
    }
    for (let index = 0; index < dataReturn.length; index++) {
      if (
        dataReturn[index].buyerStatus >= BUYER_ORDER_PAID &&
        dataReturn[index].status >= HOST_ORDER_VERIFIED
      ) {
        paymentCollected += dataReturn[index].totalFee
      }
    }
    adminCostAllOrder =
      dataReturn.filter(
        (item) => item.buyerStatus !== BUYER_ORDER_CANCELED && item.status !== HOST_ORDER_CANCELED
      ).length * _adminCost
  }
  if (_productIdList && _productIdList.length > 0) {
    for (let index = 0; index < dataReturn.length; index++) {
      dataReturn[index].allProductPerOrder = sortArrayBaseOnAnotherArray(
        dataReturn[index].allProductPerOrder,
        [..._productIdList]
      )
    }
  }
  return {
    listOrder: dataReturn?.sort((a, b) => a.eOrderNo - b.eOrderNo),
    placeBy,
    numBuy,
    totalAllOrder,
    adminCostAllOrder,
    paymentCollected,
  }
}

export const handleDataOrderEvent = (
  _listOrders,
  _adminCost,
  _discount,
  _deliveryCost,
  _productIdList = []
) => {
  let dataReturn = []
  let userOrder = []
  let adminCostAllOrder = 0
  let totalAllOrder = 0
  let placeBy = []
  let paymentCollected = 0
  let numBuy = 0
  if (_listOrders && _listOrders.length > 0) {
    let orderRef = []
    _listOrders.forEach((e) => {
      if (orderRef.includes(e.ref) !== true) {
        if (e.buyerStatus !== BUYER_ORDER_CANCELED && e.status !== HOST_ORDER_CANCELED) {
          totalAllOrder += e.pPrice * e.pQuantity
          numBuy += e.pQuantity
          userOrder = [...userOrder, e.uid]
        }
        dataReturn = [
          ...dataReturn,
          {
            ref: e.ref,
            buyerStatus: e.buyerStatus,
            status: e.status,
            comment: e.comment,
            combinedPaymentRef:
              e.combinedPaymentRef && !!e.combinedPaymentRef.length ? e.combinedPaymentRef : '',
            uName: e.uName,
            uPhone: e.uPhone,
            uPhotoUrl: e.uPhotoUrl,
            uid: e.uid,
            eOrderNo: e.eOrderNo,
            eTitle: e.eTitle,
            eid: e.eid,
            paymentScreenshot: e.paymentScreenshot,
            createdAt: e.createdAt,
            ePickupTimestamp: e.ePickupTimestamp,
            ePickupAddress: e.ePickupAddress,
            allProductPerOrder: [
              {
                pid: e.pid,
                pName: e.pName,
                pPrice: e.pPrice,
                pPhotoUrls: e.pPhotoUrls,
                pQuantity: e.pQuantity,
                pSubItems: e.pSubItems,
                pPriceNoSubItems: e.pPriceNoSubItems,
              },
            ],
          },
        ]
        orderRef = [...orderRef, e.ref]
      } else {
        if (e.buyerStatus !== BUYER_ORDER_CANCELED && e.status !== HOST_ORDER_CANCELED) {
          totalAllOrder += e.pPrice * e.pQuantity
          numBuy += e.pQuantity
        }
        for (let t = 0; t < dataReturn.length; t++) {
          if (dataReturn && dataReturn[t]?.ref === e.ref) {
            dataReturn[t].allProductPerOrder.push({
              pid: e.pid,
              pName: e.pName,
              pPrice: e.pPrice,
              pPhotoUrls: e.pPhotoUrls,
              pQuantity: e.pQuantity,
              pSubItems: e.pSubItems,
            })
          }
        }
      }
    })
    const listUid = []
    dataReturn.forEach((d) => {
      if (!listUid.includes(d.uid)) {
        placeBy = [...placeBy, d.uName]
        listUid.push(d.uid)
      }
    })
    const newUserOrder = userOrder.reduce((prev, cur) => {
      prev[cur] = (prev[cur] || 0) + 1
      return prev
    }, {})
    for (let index = 0; index < dataReturn.length; index++) {
      let totalPricePerOrder = 0
      dataReturn[index].allProductPerOrder.forEach((p) => {
        totalPricePerOrder += p.pPrice * p.pQuantity
      })
      const adminCostPerOrder = _adminCost
      let discountPerOrder = 0
      if (totalAllOrder !== 0 && !Number.isNaN(totalAllOrder)) {
        discountPerOrder = (_discount * totalPricePerOrder) / totalAllOrder
      }
      let deliveryPerOrder = 0
      if (
        Object.getOwnPropertyNames(newUserOrder).length !== 0 &&
        typeof newUserOrder[dataReturn[index].uid] !== 'undefined' &&
        newUserOrder[dataReturn[index].uid] !== 0
      ) {
        deliveryPerOrder =
          _deliveryCost /
          Object.getOwnPropertyNames(newUserOrder).length /
          newUserOrder[dataReturn[index].uid]
      }
      dataReturn[index].totalPricePerOrder = totalPricePerOrder
      dataReturn[index].adminCostPerOrder = adminCostPerOrder
      dataReturn[index].discountPerOrder = discountPerOrder
      dataReturn[index].deliveryPerOrder = deliveryPerOrder
      if (
        dataReturn[index].status !== HOST_ORDER_CANCELED &&
        dataReturn[index].buyerStatus !== BUYER_ORDER_CANCELED
      ) {
        dataReturn[index].totalFee =
          totalPricePerOrder + adminCostPerOrder - discountPerOrder + deliveryPerOrder
      } else {
        dataReturn[index].totalFee = totalPricePerOrder
      }
    }
    for (let index = 0; index < dataReturn.length; index++) {
      if (
        dataReturn[index].buyerStatus >= BUYER_ORDER_PAID &&
        dataReturn[index].status >= HOST_ORDER_VERIFIED
      ) {
        paymentCollected += dataReturn[index].totalFee
      }
    }
    adminCostAllOrder =
      dataReturn.filter(
        (item) => item.buyerStatus !== BUYER_ORDER_CANCELED && item.status !== HOST_ORDER_CANCELED
      ).length * _adminCost
  }
  if (_productIdList && _productIdList.length > 0) {
    for (let index = 0; index < dataReturn.length; index++) {
      dataReturn[index].allProductPerOrder = sortArrayBaseOnAnotherArray(
        dataReturn[index].allProductPerOrder,
        [..._productIdList]
      )
    }
  }
  return {
    listOrderEventHandled: dataReturn?.sort((a, b) => a.eOrderNo - b.eOrderNo),
    placeBy,
    numBuy,
    totalAllOrder,
    adminCostAllOrder,
    paymentCollected,
  }
}
