import virtualShopApi from '_api/virtualShop'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import React, { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function Payment() {
  const dispatch = useDispatch()
  const { selectedShop: shop } = useSelector((state) => state.shop)
  const { addToast } = useToasts()
  const [transactionFees, setTransactionFees] = useState(0)
  const [transactionFeesStatus, setTransactionFeesStatus] = useState(0)
  const [cashOnDeliveryPickup, setCashOnDeliveryPickUp] = useState(0)
  const [updatingTransactionFees, setUpdatingTransactionFees] = useState(false)

  useEffect(() => {
    if (shop?.cashOnDeliveryPickup) {
      setCashOnDeliveryPickUp(shop?.cashOnDeliveryPickup)
    }

    if (shop?.transactionFees) {
      setTransactionFees(shop?.transactionFees)
    }

    if (shop?.transactionFeesStatus) {
      setTransactionFeesStatus(shop?.transactionFeesStatus)
    }
  }, [shop?.shopSlug])

  const handleAddTransactionFees = async (isCheck) => {
    try {
      setUpdatingTransactionFees(true)
      const data = {
        transactionFeesStatus: isCheck,
        transactionFees: Number(transactionFees),
      }

      if (isNaN(data.transactionFees)) {
        return
      }

      await virtualShopApi.updateShopTransactionFees({ shopId: shop.id, data })
      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          transactionFees,
          transactionFeesStatus: isCheck,
        })
      )
      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(`${e.msgResp || e.message}`, { appearance: 'error', autoDismiss: true })
    } finally {
      setUpdatingTransactionFees(false)
    }
  }

  const handleUpdateCashOnDeliveryPickUp = async (isCheck) => {
    if (isCheck && !shop?.fulfillmentStatus) {
      return addToast('Please enable fulfillment first', { appearance: 'error', autoDismiss: true })
    }

    setCashOnDeliveryPickUp(isCheck)

    try {
      const data = {
        cashOnDeliveryPickup: isCheck,
      }

      await virtualShopApi.updateShopCashOnDeliveryPickup({ shopId: shop.id, data })
      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          cashOnDeliveryPickup: isCheck,
        })
      )
      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnCheck = (e, filedName = '') => {
    const isCheck = e.target.checked ? 1 : 0
    switch (filedName) {
      case 'transactionFees':
        if (isCheck) {
          setTransactionFeesStatus(isCheck)
        } else {
          setTransactionFeesStatus(isCheck)
          handleAddTransactionFees(isCheck)
        }
        break
      case 'cashOnDeliveryPickup':
        handleUpdateCashOnDeliveryPickUp(isCheck)
        break
      default:
        break
    }
  }

  return (
    <div className='payment bg-white py-3 px-5 position-relative'>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Add transaction fees</p>
          <p>Customers will be charged the transaction fees</p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              checked={!!transactionFeesStatus}
              onChange={(e) => handleOnCheck(e, 'transactionFees')}
            />
          </div>
        </div>
        <div className='w-100'></div>
        {transactionFeesStatus ? (
          <div className='col-6'>
            <div className='w-100 d-flex align-items-center'>
              <CurrencyFormat
                value={transactionFees || 0}
                displayType={'input'}
                placeholder='Enter transaction fee'
                thousandSeparator={true}
                prefix={'$'}
                className='form-control-sm border me-3'
                onValueChange={(values) => {
                  const { value } = values
                  setTransactionFees(value)
                }}
              />

              <button
                className='btn btn-primary shadow-none rounded w-auto'
                onClick={() => handleAddTransactionFees(transactionFeesStatus)}
                disabled={updatingTransactionFees}
              >
                {updatingTransactionFees ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Cash on Delivery & Pickup</p>
          <p>Allow customer to pay upon Delivery & Pickup</p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              checked={!!cashOnDeliveryPickup}
              onChange={(e) => handleOnCheck(e, 'cashOnDeliveryPickup')}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>PayLah! / PayNow</p>
          <p>Allow customer to pay via PayLah/PayNow</p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              checked={false}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
