import useLogout from '_hooks/useLogout'
import { getUserToken } from '_utils/localData'
import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const renderedComponent = (props) => {
    const user = getUserToken()

    if (!user) {
      useLogout()

      return
    }

    return <Component {...props} />
  }

  return <Route {...rest} render={renderedComponent} />
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
}
