import React, { useState } from 'react'
import { MdModeEditOutline } from 'react-icons/md'

function PickupAddress({ pickupAddress, index, ...rest }) {
  const [isEdit, setIsEdit] = useState(false)
  const { register, errors } = rest
  return (
    <div className='delivery-options border rounded-3 p-3 mb-3'>
      <label htmlFor='pickup-address' className='w-100 mb-0'>
        <span>Pickup Address</span>
        <div className='d-flex align-items-center'>
          {isEdit ? (
            <span className='text-dark fw-semibold fs-6'>{pickupAddress}</span>
          ) : (
            <>
              <input
                type='text'
                id={`pickup-address-${index}`}
                placeholder='Enter Pickup Address'
                className='form-control-sm border w-100 flex-fill'
                defaultValue={pickupAddress}
                {...register('pickupAddress', { required: true })}
              />
            </>
          )}
          <button
            type='button'
            className='bg-transparent border-0 w-auto text-dark ms-2'
            onClick={() => setIsEdit(!isEdit)}
          >
            <MdModeEditOutline size={20} />
          </button>
        </div>
        {errors.pickupAddress && <div className='text-danger'>{errors.pickupAddress.message}</div>}
      </label>
    </div>
  )
}

export default PickupAddress
