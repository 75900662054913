import React from 'react'
import DatePicker from 'react-date-picker'

const CustomDatePicker = (props) => {
  const { value, onChange, minDate, maxDate, format = 'dd-MM-yyyy', dataTestId } = props
  return (
    <div data-testid={dataTestId}>
      <DatePicker
        onChange={onChange}
        value={value}
        className='form-control inputDate'
        calendarIcon={<i className='ico-date' />}
        clearIcon={null}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}

export default CustomDatePicker
