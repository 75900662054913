/* eslint-disable no-confusing-arrow */

const DELETE_LIST_SCHEDULED_EVENTS = 'DELETE_LIST_SCHEDULED_EVENTS'
const CLEAR_MY_EVENTS = 'CLEAR_MY_EVENTS'
const CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS'

const initialState = {
  allScheduleEvent: [],
  myEvents: [],
  myOrders: [],
  allEvents: [],
}

export const clearAllEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS,
    payload: { allEvents: [] },
  })
}

export const clearMyEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MY_EVENTS,
    payload: { myEvents: [] },
  })
}

export const clearScheduledEvent = (dispatch) => {
  dispatch({
    type: DELETE_LIST_SCHEDULED_EVENTS,
    payload: { allScheduleEvent: [] },
  })
}

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL_EVENTS:
    case CLEAR_MY_EVENTS:
    case DELETE_LIST_SCHEDULED_EVENTS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default eventReducer
