// import ChangePasswordPage from '_pages/User/ChangePassword'
// import MyShops from '_pages/User/MyShops'
// import NotificationsPage from '_pages/User/Notifications'
// import UpdateProfilePage from '_pages/User/UpdateProfile'
import { clearDelivery, clearPickup } from '_redux/modules/fulfillment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Advance from './Advance'
import Fulfillment from './Fulfillment'
import General from './General/index'
import Payment from './Payment'

import Account from './Account'
import './style.scss'

function Setting() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('')
  const { selectedShop } = useSelector((state) => state.shop)
  const { pathname } = useLocation()

  useEffect(() => {
    let tab = pathname.split('/').pop()
    const arrAccountPath = ['my-shops', 'change-password', 'update', 'profile']

    if (arrAccountPath.includes(tab)) tab = 'account'

    setActiveTab(tab)

    return () => {
      setActiveTab('')
      dispatch(clearPickup)
      dispatch(clearDelivery)
    }
  }, [pathname])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleTabClick = (tab) => {
    toggle(tab)

    if (tab === 'account') {
      history.push('/admin-console/setting/account/profile')
    } else {
      history.push(`/admin-console/${selectedShop?.shopSlug}/setting/${tab}`)
    }
  }

  return (
    <div className='setting-page pb-5 pt-4 px-3'>
      <Container fluid className='subscription-plan'>
        <div>
          <h3 className='title'>Setting</h3>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'general' ? 'active' : ''}
                onClick={() => handleTabClick('general')}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'fulfillment' ? 'active' : ''}
                onClick={() => handleTabClick('fulfillment')}
              >
                Fulfillment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'advance' ? 'active' : ''}
                onClick={() => handleTabClick('advance')}
              >
                Advance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'payment' ? 'active' : ''}
                onClick={() => handleTabClick('payment')}
              >
                Payment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'account' ? 'active' : ''}
                onClick={() => handleTabClick('account')}
              >
                Account
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='general'>
              <General />
            </TabPane>
            <TabPane tabId='fulfillment'>
              <Fulfillment />
            </TabPane>
            <TabPane tabId='advance'>
              <Advance />
            </TabPane>
            <TabPane tabId='payment'>
              <Payment />
            </TabPane>
            <TabPane tabId='account' className='bg-white pt-3'>
              <Account />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

export default Setting
