/* eslint-disable no-unused-vars */
import virtualShopApi from '_api/virtualShop'
import {
  addDelivery,
  addPickup,
  getListDelivery,
  getListPickup,
  toggleFulfillmentActive,
} from '_redux/modules/fulfillment'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { idGenerator } from '_utils/functions/generator'
import { setDataShopDetail } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import DeliveryOption from './components/Delivery/DeliveryOption'
import PickUpOption from './components/Pickup/PickUpOption'

import './style.scss'

function Fulfillment() {
  const dispatch = useDispatch()
  const { listDelivery, listPickUp } = useSelector((state) => state.fulfillment)
  const { selectedShop: shop } = useSelector((state) => state.shop)
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [listDeliveryOption, setListDeliveryOption] = useState([])
  const [listPickUpOption, setListPickUpOption] = useState([])
  const [fulfillmentStatus, setFulfillmentStatus] = useState(false)

  useEffect(() => {
    if (!isEmpty(shop)) {
      setDataShopDetail(shop)
    }
  }, [shop?.shopSlug])

  useEffect(() => {
    if (shop?.fulfillmentStatus) {
      setFulfillmentStatus(shop?.fulfillmentStatus)
    } else {
      setFulfillmentStatus(false)
    }
  }, [shop?.fulfillmentStatus])

  const handleAddDeliveryOption = () => {
    dispatch(
      addDelivery({
        id: `FAKE_ID${idGenerator()}`,
        optionName: '',
        fee: '',
        instructions: '',
      })
    )
  }

  const handleAddPickUpOption = () => {
    dispatch(
      addPickup({ id: `FAKE_ID${idGenerator()}`, optionName: '', instructions: '', fee: '' })
    )
  }

  useEffect(() => {
    let isMounted = true

    if (shop?.id) {
      const fetchData = async () => {
        setLoading(true)
        await Promise.all([dispatch(getListDelivery(shop?.id)), dispatch(getListPickup(shop?.id))])
        setLoading(false)
      }

      fetchData()
    }

    return () => {
      isMounted = false
    }
  }, [pathname, shop?.id])

  useEffect(() => {
    if (listDelivery?.length === 0) {
      dispatch(
        addDelivery({
          id: `FAKE_ID${idGenerator()}`,
          optionName: '',
          fee: '',
          instructions: '',
        })
      )
      setListDeliveryOption([
        {
          id: `FAKE_ID${idGenerator()}`,
          optionName: '',
          fee: '',
          instructions: '',
        },
      ])
    } else {
      // sort createAt asc
      setListDeliveryOption(listDelivery)
    }
  }, [listDelivery])

  useEffect(() => {
    if (listPickUp?.length === 0) {
      dispatch(
        addPickup({
          id: `FAKE_ID${idGenerator()}`,
          optionName: '',
          instructions: '',
          fee: '',
        })
      )
      setListPickUpOption([
        { id: `FAKE_ID${idGenerator()}`, optionName: '', instructions: '', fee: '' },
      ])
    } else {
      setListPickUpOption(listPickUp)
    }
  }, [listPickUp])

  const onUpdateIsTurnOnFulfillment = (e) => {
    if (e.target.checked === false) {
      Swal.fire({
        title: 'Are you sure turn off?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, turn off it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            cashOnDeliveryPickup: Number(false),
          }
          await virtualShopApi.updateShopCashOnDeliveryPickup({ shopId: shop.id, data })
          dispatch(toggleFulfillmentActive(shop?.id, false))
          setFulfillmentStatus(false)
          dispatch(
            selectShopAndSelectShopDetail({
              ...shop,
              fulfillmentStatus: false,
              cashOnDeliveryPickup: false,
            })
          )
          Swal.fire('Turn off!', '', 'success')
        }
      })
    } else {
      const hasOptionNameInDelivery = listDeliveryOption.some(
        (item) => item.optionName.trim() !== ''
      )
      const hasOptionNameInPickup = listPickUpOption.some((item) => item.optionName.trim() !== '')

      setFulfillmentStatus(true)
      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          fulfillmentStatus: true,
        })
      )
      if (hasOptionNameInDelivery || hasOptionNameInPickup) {
        dispatch(toggleFulfillmentActive(shop?.id, true))
      }
    }
  }

  return (
    <div className='fulfillment py-3 px-5 bg-white position-relative'>
      <p className='text-black mb-0 fw-bold'>Delivery & Pickup</p>
      <p className='mb-5'>
        Create delivery & Pick-up options and set how much you charge for them.
      </p>
      <div className='row w-50'>
        <div className='col-6'>Turn on/off fulfillment:</div>
        <div className='col-6'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input mt-0 check__from'
              style={{
                transform: 'translateY(4px)',
              }}
              type='checkbox'
              checked={fulfillmentStatus}
              role='switch'
              onChange={(e) => onUpdateIsTurnOnFulfillment(e)}
            />
          </div>
        </div>
      </div>
      {shop?.fulfillmentStatus ? (
        <div
          className={`row justify-content-between ${
            loading ? 'opacity-50 pointer-events-none' : ''
          }`}
        >
          <div className='col-5'>
            <p className='text-black'>Delivery Options</p>
            {listDeliveryOption?.map((item, i) => (
              <DeliveryOption key={item.id} delivery={item} index={i} shopId={shop?.id} />
            ))}
            <div
              className='box-add px-3 py-2 text-center cursor-pointer'
              onClick={handleAddDeliveryOption}
            >
              <span>+ ADD</span>
            </div>
          </div>
          <div className='col-5 position-relative'>
            <p className='text-black'>Pick-up Options</p>
            {listPickUpOption?.map((item, i) => (
              <PickUpOption key={item.id} index={i} pickup={item} shopId={shop?.id} />
            ))}
            <div
              className='box-add px-3 py-2 text-center w-100 cursor-pointer'
              onClick={handleAddPickUpOption}
            >
              <span>+ ADD</span>
            </div>
          </div>
          <div className='w-100 mb-5'></div>
        </div>
      ) : (
        <p className='badge bg-info'>Currently shop does not offer delivery/pickup services. </p>
      )}
    </div>
  )
}

export default Fulfillment
