import Dashboard from '../Dashboard'
import Delivery from '../Delivery'
import Events from '../Events'
import EventCreatedConfirm from '../Events/Confirm'
import CreateEvent from '../Events/Create'
import EventCreateItem from '../Events/CreateItem'
import ManageProducts from '../ManageProducts'
import OrderRecord from '../OrderRecord'
import ReverseOrderDetail from '../OrderRecord/Lazada/ReverseOrderDetail'
import Setting from '../Setting'
import ShopFront from '../ShopFront'
import Subscription from '../Subscription'

export const genderRoutes = (path, shopSlug) => [
  {
    path: `${path}/${shopSlug}/dashboard`,
    exact: true,
    component: Dashboard,
  },
  {
    path: `${path}/${shopSlug}/products`,
    exact: true,
    component: ManageProducts,
  },
  {
    path: `${path}/${shopSlug}/order-records`,
    exact: true,
    component: OrderRecord,
  },
  {
    path: `${path}/${shopSlug}/delivery`,
    exact: true,
    component: Delivery,
  },
  {
    path: `${path}/${shopSlug}/setting/General`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/${shopSlug}/setting/fulfillment`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/${shopSlug}/setting/advance`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/${shopSlug}/setting/payment`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/setting/account`,
    component: Setting,
    exact: true,
  },
  {
    path: `${path}/setting/account/my-shops`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/setting/account/change-password`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/setting/account/update`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/setting/account/profile`,
    exact: true,
    component: Setting,
  },
  {
    path: `${path}/${shopSlug}/subscription`,
    exact: true,
    component: Subscription,
  },
  {
    path: `${path}/${shopSlug}/shop-front`,
    exact: true,
    component: ShopFront,
  },
  {
    path: `${path}/${shopSlug}/order-records/:orderId`,
    exact: true,
    component: ReverseOrderDetail,
  },
  {
    path: `${path}/${shopSlug}/event`,
    exact: true,
    component: Events,
  },
  {
    path: `${path}/${shopSlug}/event/create`,
    exact: true,
    component: CreateEvent,
  },
  {
    path: `${path}/${shopSlug}/event/update`,
    exact: true,
    component: CreateEvent,
  },
  {
    path: `${path}/${shopSlug}/event/create-items`,
    exact: true,
    component: EventCreateItem,
  },
  {
    path: `${path}/${shopSlug}/event/update-items`,
    exact: true,
    component: EventCreateItem,
  },
  {
    path: `${path}/${shopSlug}/event/created-confirm`,
    exact: true,
    component: EventCreatedConfirm,
  },
  {
    path: `${path}/${shopSlug}/event/update-confirm`,
    exact: true,
    component: EventCreatedConfirm,
  },
]
