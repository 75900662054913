import LongText from '_components/LongText'
import noImage from '_images/noimage02.svg'
import { VIEW_GRID, VIEW_LIST } from '_utils/constant'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { QuantityProductContext } from '../index'
import SubItem from './SubItem'

function ProductItem({ item, viewDefault }) {
  const { photoUrl, name, description, subItems, price } = item
  const { quantityProduct } = useContext(QuantityProductContext)
  const [quantity, setQuantity] = useState(quantityProduct)
  const [isAddCart, setIsAddCart] = useState(false)

  const handleOnChangeDefaultQuantity = (type) => {
    if (type === 'add') {
      setQuantity(quantity + 1)
    } else if (quantity >= 1) {
      setQuantity(quantity - 1)
    }
  }

  function getStyleBasedOnView() {
    if (viewDefault === VIEW_LIST) {
      return { marginTop: '1.5rem' }
    }
    return null
  }

  const onAddToCart = () => {
    setIsAddCart(true)
    setQuantity(quantity + 1)
  }

  return (
    <div
      className={classNames('confirm__shop-product-item', viewDefault === VIEW_GRID ? 'h-100' : '')}
    >
      <div
        className={classNames(
          'confirm__shop-product-item-info',
          viewDefault === VIEW_GRID
            ? 'justify-content-center flex-column d-flex h-100'
            : 'align-items-stretch row'
        )}
      >
        <div className={classNames(viewDefault === VIEW_LIST ? 'col col-auto' : 'w-100')}>
          <div
            className={classNames(
              'rounded bg-white p-1 shadow-sm wrap-img confirm__shop-product-item-info-img ',
              viewDefault === VIEW_LIST ? 'view-list' : 'mx-auto view-grid'
            )}
          >
            <img src={photoUrl || noImage} alt='' className='rounded' />
          </div>
        </div>

        <div
          className={classNames(
            'confirm__shop-product-item-info',
            viewDefault === VIEW_LIST ? 'col-9 col-lg-8 row' : 'd-flex flex-column pt-2 mb-auto'
          )}
        >
          <div
            className={classNames(
              'confirm__shop-product-item-info-title',
              viewDefault === VIEW_LIST ? 'col-9 text-start ps-3' : 'd-flex flex-column mb-2'
            )}
            style={viewDefault === VIEW_GRID ? { gap: '5px' } : {}}
          >
            <p className='fw-bold confirm__shop-product-item-info-title-name'>{name}</p>
            <p className='confirm__shop-product-item-info-title-price mb-0'>
              <CurrencyFormat
                value={price || 0}
                className='ps-0'
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
            <LongText
              content={description}
              limit='25'
              className='cursor-pointer description text-secondary f-16'
            />
          </div>

          {viewDefault === VIEW_GRID &&
            (subItems?.length
              ? subItems?.map((subItem, index) => (
                  <SubItem
                    key={index}
                    listSubItem={subItem.list}
                    viewDefault={viewDefault}
                    isRequired={subItem.required}
                  />
                ))
              : '')}

          {viewDefault === VIEW_LIST && (
            <div className={classNames(viewDefault === VIEW_LIST ? 'col' : '')}>
              <div
                className={classNames('confirm__shop-product-item-info-quantity')}
                style={getStyleBasedOnView()}
              >
                {viewDefault === VIEW_LIST ? (
                  <div className='quanlity mx-auto'>
                    <span className='icon-mim' onClick={() => handleOnChangeDefaultQuantity('sub')}>
                      -
                    </span>
                    <span className='icon-input'>
                      <input
                        type='text'
                        step='1'
                        min='0'
                        max='100'
                        className='form-control'
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.valueAsNumber)}
                      />
                    </span>
                    <span
                      className='icon-plugs'
                      onClick={() => handleOnChangeDefaultQuantity('add')}
                    >
                      +
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>

        {viewDefault === VIEW_GRID && (
          <>
            {isAddCart ? (
              <div className='quanlity mx-auto mt-3'>
                <span className='icon-mim' onClick={() => handleOnChangeDefaultQuantity('sub')}>
                  -
                </span>
                <span className='icon-input'>
                  <input
                    type='text'
                    step='1'
                    min='0'
                    max='100'
                    className='form-control'
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.valueAsNumber)}
                  />
                </span>
                <span className='icon-plugs' onClick={() => handleOnChangeDefaultQuantity('add')}>
                  +
                </span>
              </div>
            ) : (
              <div className='card-footer w-100 mt-3'>
                <button type={'button'} className={'btn btn-primary w-100'} onClick={onAddToCart}>
                  <AiOutlineShoppingCart size={20} className={'me-1'} />
                  Add to cart
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {viewDefault === VIEW_LIST && subItems?.length
        ? subItems?.map((subItem, index) => (
            <SubItem
              key={index}
              listSubItem={subItem.list}
              viewDefault={viewDefault}
              isRequired={subItem.required}
            />
          ))
        : ''}
    </div>
  )
}

export default ProductItem
