import { CheckoutContext } from '_contexts/CheckoutContext'
import { DELIVERY_OPTION } from '_utils/constant'
import { isEmpty } from 'lodash'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Calendar from './Calendar'
import RenderInfo from './RenderInfo'

function TabDelivery() {
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const { userInfo, activeTab, shop, register } = useContext(CheckoutContext)

  const { listDelivery, selectedDelivery } = useSelector((state) => state.fulfillment)

  const isOwnerShop = userInfo?.shopLinks?.some((item) => item === shop?.shopLink)

  return (
    <div className='form-delivery-details rounded-top'>
      {!listDelivery.length ? (
        <div className='h-100 w-100 d-flex flex-column align-items-center justify-content-center mb-3'>
          <strong>Currently shop has not support delivery</strong>
          {isOwnerShop && <Link to='/admin-console/fulfillment'>Create delivery option</Link>}
        </div>
      ) : null}

      {listDelivery.length ? (
        <>
          <div className='mb-3 list-delivery'>
            <select
              className='form-select-sm border-0 w-100 cursor-pointer'
              {...register('deliveryOption')}
            >
              {listDelivery.map((delivery) => (
                <option
                  key={delivery.id}
                  value={JSON.stringify(delivery)}
                  className='cursor-pointer'
                >
                  {delivery.optionName}
                </option>
              ))}
            </select>
          </div>

          {activeTab === DELIVERY_OPTION && !isEmpty(selectedDelivery) && (
            <>
              <RenderInfo
                fee={selectedDelivery.deliveryFee}
                instructions={selectedDelivery.instructions}
                isCheckPublicHoliday={selectedDelivery.isCheckPublicHoliday}
                type={DELIVERY_OPTION}
              />
              <Calendar
                datesOfWeek={selectedDelivery.datesOfWeek}
                timeSlot={selectedDelivery.timeSlot}
                startTime={selectedDelivery.deliveryStartTime}
                endTime={selectedDelivery.deliveryEndTime}
                activeTab={activeTab}
                type={DELIVERY_OPTION}
                isOpenTimePicker={isOpenTimePicker}
                setIsOpenTimePicker={setIsOpenTimePicker}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default TabDelivery
