import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Footer({ style }) {
  const { selectedShop } = useSelector((state) => state.shop)

  const history = useHistory()

  const handleOnclick = () => {
    history.push(`/${selectedShop?.shopSlug}`)
  }

  return (
    <footer className='footer footer-admin cursor-pointer' style={style}>
      <div className='container pb-0' style={{ width: 130 }}>
        <ul className='menu btn-manage-shop' style={{ margin: '0 auto' }}>
          <li onClick={handleOnclick} className='cursor-pointer'>
            <a className='ms-3'>
              <i className='icon-host-event'></i>
              <span style={{ whiteSpace: 'nowrap' }}>Back to Shop</span>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
