import axiosClient from './axiosClient'

const productApi = {
  createShopProduct: (shopId, data) => {
    const url = `/create-shop-product?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  updateShopProduct: (productId, shopId, data) => {
    const url = `/update-shop-product?id=${productId}&shopId=${shopId}`
    return axiosClient.patch(url, data)
  },
  deleteShopProduct: (shopId, productId) => {
    const url = `/delete-shop-product?shopId=${shopId}&productId=${productId}`
    return axiosClient.delete(url)
  },
}

export default productApi
