import LazadaIcon from '_images/lazada-logo.png'
import { updateOrderStatus } from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  DELIVERY_OPTION,
  HOST_ORDER_CANCELED,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_PAID,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
  NO_FULFILLMENT,
  SELF_PICK_UP,
  TURN_ON,
} from '_utils/constant'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiFillGift } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Tooltip } from 'reactstrap'

const statusStyles = {
  [HOST_ORDER_UNPAID]: { color: '#ff6903', backgroundColor: '#f06a0f', fontSize: '1em' },
  [HOST_ORDER_VERIFIED]: { color: '#ffffff', backgroundColor: '#198754', fontSize: '0.6em' },
  [HOST_ORDER_FULFILLED]: { color: '#7c797a', backgroundColor: '#0d6efd', fontSize: '1em' },
  [HOST_ORDER_PAID]: { color: '#7c797a', backgroundColor: '#0dcaf0', fontSize: '1em' },
  default: { color: '#7c797a', backgroundColor: '#7c797a', fontSize: '1em' },
}

function OrderRecordItem({
  item,
  index,
  setInfoPersonGift,
  setIsModalGift,
  isModalGift,
  totalPriceEachOrder,
  toggleModal,
  setDataModal,
  toggleTooltip,
  tooltipOpen,
}) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [fontSize, setFontSize] = useState('1em')
  const selectRef = useRef(null)

  useEffect(() => {
    const updateFontSize = () => {
      if (selectRef.current) {
        const width = selectRef.current.offsetWidth
        if (width < 100) {
          setFontSize('0.6em')
        } else if (width < 200) {
          setFontSize('0.8em')
        } else {
          setFontSize('1em')
        }
      }
    }

    updateFontSize()
    window.addEventListener('resize', updateFontSize)
    return () => window.removeEventListener('resize', updateFontSize)
  }, [])

  const currentStyle = statusStyles[item.status] || statusStyles.default

  const toggleModalGift = (personGift) => {
    setInfoPersonGift(personGift)
    setIsModalGift(!isModalGift)
  }

  const openPaymentScreen = (e) => {
    e.preventDefault()
    setDataModal(item)
    toggleModal()
  }

  const onChangeSelect = async (e, ref) => {
    const { value } = e.target
    const statusSelected = {
      status: Number(value),
      buyerStatus: Number(value),
    }

    if (Number(value) === HOST_ORDER_FULFILLED) {
      statusSelected.buyerStatus = BUYER_ORDER_COLLECTED
    }

    try {
      await dispatch(updateOrderStatus(statusSelected, ref))
      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const formatTime = (timeString) => {
    const timeRange = timeString?.split(' - ')
    if (timeRange?.length === 2) {
      return timeRange.map((t) => moment.utc(t, 'HH:mm').local().format('HH:mm')).join(' - ')
    }
    return moment.utc(timeString, 'HH:mm').local().format('HH:mm')
  }

  const renderFormattedDate = (date, time) => {
    const formattedTime = formatTime(time)

    return (
      <div>
        {date} <br />
        <span className='fw-bold' style={{ color: '#f06a0f' }}>
          {formattedTime}
        </span>
      </div>
    )
  }

  const renderFulfillmentDate = () => {
    if (item?.fulfillmentType === SELF_PICK_UP) {
      const { date, time } = item?.pickupDetail?.pickupBookingDate || {}
      return renderFormattedDate(date, time)
    }

    if (item?.fulfillmentType === DELIVERY_OPTION) {
      const { date, time } = item?.deliveryDetail?.deliveryBookingDate || {}
      return renderFormattedDate(date, time)
    }

    return (
      <>
        <p className='mb-0'>
          {moment(item.createdAt * 1000)
            .utc()
            .format('DD-MM-YYYY')}
        </p>
        <span className='fw-bold' style={{ color: '#f06a0f' }}>
          {moment(item.createdAt * 1000)
            .utc()
            .format('HH:mm')}
        </span>
      </>
    )
  }

  const renderFees = () => {
    if (item?.transactionFees > 0) {
      return (
        <small className='text-danger fw-semibold mt-1 w-100 d-block'>
          Transaction Fee:{' '}
          {item?.transactionFees?.toLocaleString('en-SG', {
            style: 'currency',
            currency: 'SGD',
          })}
        </small>
      )
    }
    if (item?.pickupDetail?.pickupFee > 0) {
      return (
        <small className='text-danger fw-semibold mt-1 w-100 d-block'>
          Pickup Fee:{' '}
          {item?.pickupDetail?.pickupFee?.toLocaleString('en-SG', {
            style: 'currency',
            currency: 'SGD',
          })}
        </small>
      )
    }
    if (item?.deliveryDetail?.deliveryFee > 0) {
      return (
        <small className='text-danger fw-semibold mt-1 w-100 d-block'>
          Delivery Fee:{' '}
          {item?.deliveryDetail?.deliveryFee?.toLocaleString('en-SG', {
            style: 'currency',
            currency: 'SGD',
          })}
        </small>
      )
    }

    return null
  }

  return (
    <>
      <div
        id={`tooltip-${index}`}
        className={`${index % 2 !== 0 ? 'bg-light' : ''} row align-items-center p-3 row-item`}
      >
        <div className='col'>
          <span className='me-2'>{index + 1}</span>
          {item?.lazadaInfo?.order_id && (
            <img
              alt={'image'}
              width={70}
              height={40}
              src={LazadaIcon}
              style={{
                transform: 'translateY(-3px)',
              }}
            />
          )}
        </div>
        <div className='col d-flex flex-column text-break'>
          <span>{item?.deliveryDetail?.uName || item?.pickupDetail?.uName || item?.uName}</span>
          {!isEmpty(item?.deliveryDetail?.giftRecipientInfo || item?.giftRecipientInfo) && (
            <div
              className='mt-2 gift-icon cursor-pointer'
              onClick={() =>
                toggleModalGift(item?.deliveryDetail?.giftRecipientInfo || item?.giftRecipientInfo)
              }
            >
              <AiFillGift className='text-danger' size={18} />
            </div>
          )}
        </div>
        <div
          className='col text-break'
          style={{
            width: '110px',
          }}
        >
          {item?.deliveryDetail?.uPhone || item?.pickupDetail?.uPhone || item?.uPhone}
        </div>
        <div className='col-2 pe-3 text-break'>
          <ul className='list-unstyled mb-0'>
            {item?.allProductPerOrder?.map((product, _index) => (
              <Fragment key={`${product.pid}-${_index}`}>
                <li
                  className={`d-flex align-items-center flex-wrap ${
                    item?.allProductPerOrder?.length > 1 ? 'mb-1' : 'mb-0'
                  }`}
                >
                  <p className='mb-0'>
                    <span>{product.name}: </span>
                    <strong>
                      <CurrencyFormat
                        value={product.pPriceNoSubItems || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </strong>{' '}
                    <span>({product.quantity})</span>
                  </p>
                  {product?.subItems?.length > 0 ? (
                    <>
                      {product.subItems.map((subItem, subIndex) => (
                        <div key={`${subItem.name}-${subIndex}`}>
                          <span>{subItem.name}:</span>
                          {subItem.price > 0 ? (
                            <strong className='ms-1'>
                              <CurrencyFormat
                                value={subItem.price || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </strong>
                          ) : (
                            <strong className='ms-1'>$0</strong>
                          )}
                          {subIndex !== product.subItems.length - 1 ? (
                            <span style={{ marginRight: 3 }}>,</span>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </>
                  ) : null}
                </li>
              </Fragment>
            ))}
          </ul>

          {renderFees()}
        </div>
        <div className='col-2 fw-bold text-break'>
          {item?.fulfillmentType === SELF_PICK_UP && (
            <div className='d-flex flex-column fw-normal'>
              <p className='fw-bold mb-0'>Self pick-up</p>
              <span>{item?.pickupDetail?.pickupOptionName}</span>
            </div>
          )}
          {item?.fulfillmentType === DELIVERY_OPTION && (
            <div className='d-flex flex-column fw-normal'>
              <p className='fw-bold mb-0'>Delivery</p>
              <span>{item?.deliveryDetail?.deliveryOptionName}</span>
              <span>
                Address: {item?.deliveryDetail?.uAddress || item?.giftRecipientInfo?.address}
              </span>
            </div>
          )}
          {item?.fulfillmentType === NO_FULFILLMENT && (
            <div className='d-flex flex-column fw-normal'>
              <p className='fw-bold mb-0'>--</p>
            </div>
          )}
        </div>
        <div className='col text-break'>
          {moment(item?.createdAt * 1000).format('DD-MM-YYYY HH:mm:ss')}
        </div>
        <div className='col text-break'>{renderFulfillmentDate()}</div>
        <div className='col text-break text-center'>
          {item?.buyerStatus === BUYER_ORDER_UNPAID && (
            <p className='n-paid text-center mb-0' style={{ paddingRight: 0 }}>
              Not Paid
            </p>
          )}
          {item?.buyerStatus === BUYER_ORDER_PAID && (
            <div
              className='d-flex align-items-center fw-bold justify-content-center'
              style={!item?.cashOnDeliveryPickup ? { cursor: 'pointer' } : {}}
              onClick={(e) => (!item?.cashOnDeliveryPickup ? openPaymentScreen(e) : null)}
            >
              <CurrencyFormat
                value={totalPriceEachOrder(item) || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              {item?.cashOnDeliveryPickup !== TURN_ON && (
                <i className='icon-link ms-2' style={{ width: 16, height: 16 }}></i>
              )}
            </div>
          )}
          {item?.buyerStatus === BUYER_ORDER_CANCELED && (
            <p className='mb-0 text-center'>Cancelled</p>
          )}
          {item?.buyerStatus === BUYER_ORDER_COLLECTED && (
            <p className='mb-0 text-center'>
              <span
                className='d-flex align-items-center fw-bold justify-content-center'
                style={!item?.cashOnDeliveryPickup ? { cursor: 'pointer' } : {}}
                onClick={(e) => (!item?.cashOnDeliveryPickup ? openPaymentScreen(e) : null)}
              >
                <CurrencyFormat
                  value={totalPriceEachOrder(item) || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                <FaCheck color='#14b711' className='ms-1' />
              </span>
            </p>
          )}
          {item?.buyerStatus === BUYER_ORDER_REFUNDED && (
            <p className='mb-0 text-center'>Refunded</p>
          )}

          {item.cashOnDeliveryPickup && (
            <small className={'text-info fw-semibold'}>
              {item.fulfillmentType === DELIVERY_OPTION ? 'Cash on Delivery' : 'Cash on Pick-up'}
            </small>
          )}
        </div>
        <div className='col text-break'>
          <select
            ref={selectRef}
            className='form-select-sm border text-white w-100 rounded-3'
            value={item?.status}
            onChange={(e) => onChangeSelect(e, item?.ref)}
            style={{
              ...currentStyle,
              fontSize,
              height: 30,
            }}
          >
            <option className='bg-white text-default' value={HOST_ORDER_CANCELED}>
              Cancelled
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_UNPAID}>
              Unpaid
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_PAID}>
              Paid
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_VERIFIED}>
              Payment Verified
            </option>
            <option className='bg-white text-default' value={HOST_ORDER_FULFILLED}>
              Fulfilled
            </option>
          </select>
        </div>
      </div>
      <Tooltip
        isOpen={tooltipOpen === item.ref}
        target={`tooltip-${index}`}
        toggle={() => toggleTooltip(item.ref)}
        className='tooltip-custom'
      >
        Order Ref: {item.ref}
      </Tooltip>
    </>
  )
}

export default OrderRecordItem
