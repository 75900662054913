import classNames from 'classnames'
import React from 'react'
import { BsGrid, BsList } from 'react-icons/bs'
import { VIEW_GRID, VIEW_LIST } from '_utils/constant'

function ViewDefault({ viewDefault, handleSetViewDefault }) {
  return (
    <div className='w-100 d-flex align-items-center mb-3'>
      <button
        className={classNames(
          'btn me-2 w-auto rounded shadow-none',
          viewDefault === VIEW_LIST ? 'btn-secondary' : 'btn-outline-secondary'
        )}
        onClick={() => handleSetViewDefault(VIEW_LIST)}
      >
        <BsList className='me-2' size={16} />
        List
      </button>

      <button
        className={classNames(
          'btn mt-0 w-auto rounded shadow-none',
          viewDefault === VIEW_GRID ? 'btn-secondary' : 'btn-outline-secondary'
        )}
        onClick={() => handleSetViewDefault(VIEW_GRID)}
      >
        <BsGrid className='me-2' size={16} />
        Tile
      </button>
    </div>
  )
}

export default ViewDefault
