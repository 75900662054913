import React from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { FiCheckCircle } from 'react-icons/fi'
import PasswordChecklist from 'react-password-checklist'

function MyPasswordCheckList({ password, confirmPassword, setIsValidChecklist, className }) {
  return (
    <div className={`checklist f-14 rounded p-3 bg-light ${className}`}>
      <p className='mb-0'>Passwords valid:</p>
      <PasswordChecklist
        rules={['minLength', 'number', 'letter', 'notEmpty', 'match']}
        minLength={8}
        iconSize={12}
        value={password}
        valueAgain={confirmPassword}
        validColor='green'
        iconComponents={{
          InvalidIcon: <BiInfoCircle size={14} className='me-2' />,
          ValidIcon: <FiCheckCircle size={14} className='me-2' style={{ color: 'green' }} />,
        }}
        onChange={(isValid) => setIsValidChecklist(isValid)}
      />
    </div>
  )
}

export default MyPasswordCheckList
