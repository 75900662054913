import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller } from 'react-hook-form'

function DeliveryFee({ checkedDeliveryFee, handleAddDeliveryFee, deliveryFee, errors, control }) {
  return (
    <div className='row align-items-center mb-3'>
      <div className='col-5'>
        <span className='d-inline-block'>Add Delivery Fee</span>
      </div>
      <div className='col-7'>
        <div className='form-check form-switch'>
          <input
            className='form-check-input mt-0 check__from'
            type='checkbox'
            checked={checkedDeliveryFee}
            role='switch'
            onChange={(e) => handleAddDeliveryFee(e)}
          />
        </div>
      </div>
      {checkedDeliveryFee && (
        <>
          <div className='w-100'></div>
          <div className='col-4 mt-2'>
            <Controller
              name='deliveryFee'
              defaultValue={deliveryFee || 0}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CurrencyFormat
                  value={value || 0}
                  displayType={'input'}
                  thousandSeparator={true}
                  prefix='$'
                  className='form-control-sm border w-100'
                  onValueChange={(values) => {
                    onChange(values.value)
                  }}
                />
              )}
            />

            <small className='errorMessage'>{errors?.deliveryFee?.message}</small>
          </div>
        </>
      )}
    </div>
  )
}

export default DeliveryFee
