/* eslint-disable multiline-ternary */
import authApi from '_api/auth'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { fetchUserInfo, getNotifications } from '_redux/modules/user'
import { FACEBOOK, GOOGLE, UNKNOWN } from '_utils/constant'
import {
  getCartCheckout,
  getCreateShopData,
  getSocialToken,
  setSocialToken,
  setUserInfo,
  setUserToken,
} from '_utils/localData'
import { gapi } from 'gapi-script'
import { isEmpty, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

const SocialButton = ({ social = FACEBOOK, isLogin = true, setIsShowFormConnect }) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_APP_ID,
        plugin_name: 'chat',
      })
    })
  }, [])

  const getCustomSizeImageUrl = (imageUrl, width, height) => {
    const url = new URL(imageUrl)
    url.searchParams.set('sz', `${width}`)
    url.searchParams.set('sz', `${height}`)
    return url.toString()
  }

  const handleLogin = async (token) => {
    try {
      setLoading(true)
      const _res = await authApi.login3rdParty({ type: social, accessToken: token })
      setUserToken(_res.msgResp.token)

      await Promise.all([
        dispatch(fetchUserInfo()),
        dispatch(fetchListInvoiceSubscription()),
        dispatch(getNotifications),
      ])

      if (getCartCheckout()) {
        history.push('/virtual-shop/checkout')
      } else if (getCreateShopData()) {
        history.push('/virtual-shop/confirm-shop', {
          from: location.pathname,
        })
      } else {
        history.push('/user/update')
      }
    } catch ({ msgResp }) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleRegister = async (userInfo, token) => {
    if (isEmpty(userInfo)) {
      return
    }

    try {
      userInfo.social = social
      setUserInfo(userInfo)
      setSocialToken(token)

      if (!isUndefined(userInfo) && !isEmpty(userInfo)) {
        const postData = {
          accessToken: getSocialToken(),
          email: userInfo?.email,
          name: userInfo?.name,
          phone: userInfo?.phone,
          photoUrl: userInfo?.photoUrl,
        }
        const socialType = userInfo?.social
        if (socialType === GOOGLE) {
          postData.linkedGoogleAccount = userInfo?.id
        } else if (socialType === FACEBOOK) {
          postData.linkedFBAccount = userInfo?.id
        }

        setIsShowFormConnect(true)
      }
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  const response = async (resp) => {
    let userInfo = null
    const token = resp?.accessToken
    if (social === FACEBOOK) {
      if (resp.status !== UNKNOWN) {
        userInfo = {
          name: resp.name,
          email: resp.email,
          photoUrl: resp.picture.data.url,
          imageUrl: resp.picture.data.url,
          id: resp.userID,
        }
      } else {
        return
      }
    } else if (social === GOOGLE) {
      const { name, email, imageUrl, googleId: id } = resp.profileObj
      userInfo = {
        name,
        email,
        photoUrl: getCustomSizeImageUrl(imageUrl, 107, 107),
        imageUrl: getCustomSizeImageUrl(imageUrl, 107, 107),
        id,
      }
    }

    if (isLogin) {
      await handleLogin(token)
    } else {
      await handleRegister(userInfo, token)
    }
  }

  return (
    <>
      {social === FACEBOOK ? (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields='name,email,picture.width(200).height(200)' //
          callback={response}
          cssClass={isLogin ? 'btn btn--gray btn--bold' : 'btn btn--graylight btn--bold'}
          icon={<i className='icon-fb'></i>}
          textButton='Facebook'
          isMobile={false}
        />
      ) : (
        <GoogleLogin
          icon={false}
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          onSuccess={response}
          render={(renderProps) => (
            <button className='btn btn--gray btn--bold' onClick={renderProps.onClick}>
              {loading ? (
                'Logging...'
              ) : (
                <>
                  <i className='icon-google'></i>Google
                </>
              )}
            </button>
          )}
        />
      )}
    </>
  )
}

export default SocialButton

SocialButton.propTypes = {
  setIsAuthError: PropTypes.func,
  setIsShowFormConnect: PropTypes.func,
  social: PropTypes.string,
  isLogin: PropTypes.bool,
}
