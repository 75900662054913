import Pagination from '_components/Pagination'
import { scrollToTop } from '_utils/function'
import React from 'react'
import { CiSearch } from 'react-icons/ci'
import EventFilterStatus from './EventFilterStatus'
import EventItem from './EventItem'
import EventView from './EventView'
import ModalBroadCast from './ModalBroadCast'

export default function EventList({
  view,
  handleViewChange,
  currentEvents,
  currentPage,
  setCurrentPage,
  listEventByShopFiltered,
  openBroadcastModal,
  setOpenBroadcastModal,
  message,
  onChangeMessage,
  onCreateNotification,
  search,
  setSearch,
  toggleBroadcastModal,
  PageSize,
  currentPackagePro,
}) {
  const overlayStyle = !currentPackagePro
    ? {
        pointerEvents: 'none',
        filter: 'blur(2px)',
      }
    : {}

  return (
    <div className='event-list' style={overlayStyle}>
      <div className='d-flex align-items-center justify-content-end gap-3'>
        <EventView view={view} handleViewChange={handleViewChange} />
        <EventFilterStatus />
      </div>
      <div
        className='event-search position-relative p-0 mb-3'
        style={{
          width: '100%',
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <input
          type='text'
          className='form-control'
          placeholder='Search event name'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ paddingRight: '40px' }}
        />
        <CiSearch
          size={20}
          style={{
            position: 'absolute',
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </div>
      <div
        className={`event-list custom-scrollbar d-flex gap-3 ${
          view === 'grid' ? 'flex-wrap' : 'flex-column'
        }`}
      >
        {currentEvents?.length > 0 ? (
          currentEvents.map((event) => (
            <EventItem
              key={event.id}
              event={event}
              view={view}
              toggleBroadcastModal={toggleBroadcastModal}
            />
          ))
        ) : (
          <div className='text-center w-100 text-secondary mt-5'>
            <h5>No event found</h5>
          </div>
        )}
      </div>

      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={listEventByShopFiltered.length}
        pageSize={PageSize}
        onPageChange={(page) => {
          setCurrentPage(page)
          scrollToTop()
        }}
      />

      {openBroadcastModal && (
        <ModalBroadCast
          modal={openBroadcastModal !== null}
          toggle={() => setOpenBroadcastModal(null)}
          message={message}
          onChangeMessage={onChangeMessage}
          onCreateNotification={onCreateNotification}
        />
      )}
    </div>
  )
}
