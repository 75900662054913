import { OrderRecordContext } from '_contexts/OrderRecordContext'
import moment from 'moment'
import React, { useContext } from 'react'

function RenderSelectingOrderDate() {
  const { filter } = useContext(OrderRecordContext)
  return (
    <>
      <p className='mb-0' style={{ fontSize: '1.2em' }}>
        {filter.orderDate[0].startDate !== null && (
          <>
            <span className='me-1'>Selecting order from</span>
            <strong className='me-1'>
              {moment(filter.orderDate[0]?.startDate).format('DD/MM/YYYY')}
            </strong>
            <span className='me-1'>to</span>
            <strong>{moment(filter.orderDate[0]?.endDate).format('DD/MM/YYYY')}</strong>
          </>
        )}
      </p>
      <p className='mb-0 mx-auto w-auto' style={{ fontSize: '1.2em' }}>
        {filter.fulfillmentDate[0].startDate !== null && (
          <>
            <span className='me-1'>Selecting fulfillment from</span>
            <strong className='me-1'>
              {moment(filter.fulfillmentDate[0]?.startDate).format('DD/MM/YYYY')}
            </strong>
            <span className='me-1'>to</span>
            <strong>{moment(filter.fulfillmentDate[0]?.endDate).format('DD/MM/YYYY')}</strong>
          </>
        )}
      </p>
    </>
  )
}

export default RenderSelectingOrderDate
