import { LIMIT_PER_ORDER_DEFAULT, MINIMUM_ORDER_PER_PRODUCT } from '_utils/constant'
import { Modal } from 'reactstrap'

import InputProduct from './InputProduct'

const ProductSettingModal = (props) => {
  const { isOpen, toggle, register, productIndex, errors } = props

  const handleToggle = () => {
    if (errors && errors?.length > 0 && Object.entries(errors?.products).length !== 0) {
      return
    }
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered className='product-setting'>
      <div className='container'>
        <p className='c-txt-org text-center fw-bold text-uppercase'>Item Options</p>
        <div className='c-form'>
          <div className='form-group'>
            <div className='row ml-2 mr-2'>
              {/* <label className='col-5 align-self-center'>Max Quantity</label>
              <div className='col-7 mb-2'>
                <InputProduct
                  type='number'
                  className={`form-control-sm border w-100 ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.maxQuantity &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.maxQuantity`)}
                  defaultValue={AVAILABLE_STOCK_DEFAULT}
                  error={errors?.products?.[productIndex]?.maxQuantity?.message}
                />
              </div> */}
              <label className='col-5 align-self-center'>Limit per order</label>
              <div className='col-7 mb-2'>
                <InputProduct
                  type='number'
                  className={`form-control-sm border w-100 ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.limitPerOrder &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.limitPerOrder`)}
                  defaultValue={LIMIT_PER_ORDER_DEFAULT}
                  error={errors?.products?.[productIndex]?.limitPerOrder?.message}
                />
              </div>
              <label className='col-5 align-self-center'>Minimum order quantity</label>
              <div className='col-7 mb-2'>
                <InputProduct
                  type='number'
                  className={`form-control-sm border w-100 ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.minOrderQty &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.minOrderQty`)}
                  defaultValue={MINIMUM_ORDER_PER_PRODUCT}
                  error={errors?.products?.[productIndex]?.minOrderQty?.message}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 m-auto px-3'>
            <button
              className='btn btn--org btn--org02 w-100 shadow-none f-14'
              onClick={handleToggle}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProductSettingModal
