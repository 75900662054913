import virtualShopApi from '_api/virtualShop'
import {
  selectShopAndSelectShopDetail,
  selectThemeColor,
  updateShopInMyShops,
} from '_redux/modules/shop'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import './style.scss'

function ColorPicker() {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const { addToast } = useToasts()
  const [colorsTheme, setColorsTheme] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const customsColorTheme = selectedShop?.shopColorTheme?.customsColorTheme
  const defaultColorTheme = selectedShop?.shopColorTheme?.defaultColorTheme

  useEffect(() => {
    if (customsColorTheme) {
      const convertColor = {
        headerTextColor: customsColorTheme.headerTextColor,
        backgroundColor: customsColorTheme.backgroundColor,
        generalTextColor: customsColorTheme.generalTextColor,
        priceColor: customsColorTheme.priceColor,
        titleItemColor: customsColorTheme.titleItemColor,
      }
      const convertObjectToArray = Object.keys(convertColor).map((key, index) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Biến đổi tên thuộc tính thành chuỗi đọc được
        value: customsColorTheme[key],
        index,
      }))
      setColorsTheme(convertObjectToArray)
    } else {
      const mockDefaultColor = {
        headerTextColor: '#F06A0F',
        backgroundColor: '#f2f2f2',
        generalTextColor: '#15cdca',
        priceColor: '#ff6903',
        titleItemColor: '#51aaaa',
      }

      const convertColor = Object.keys(mockDefaultColor).map((key, index) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Biến đở tên thuộc tính không chứa chữ thức
        value: mockDefaultColor[key],

        index,
      }))

      setColorsTheme(convertColor)
    }
  }, [customsColorTheme !== undefined])

  useEffect(() => {
    const timeoutDispatchColor = setTimeout(() => {
      dispatch(selectThemeColor(colorsTheme))
      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          shopColorTheme: {
            ...selectedShop?.shopColorTheme,
            customsColorTheme: {
              headerTextColor: colorsTheme[0].value,
              backgroundColor: colorsTheme[1].value,
              generalTextColor: colorsTheme[2].value,
              priceColor: colorsTheme[3].value,
              titleItemColor: colorsTheme[4].value,
            },
          },
        })
      )
      dispatch(
        updateShopInMyShops({
          ...selectedShop,
          shopColorTheme: {
            ...selectedShop?.shopColorTheme,
            customsColorTheme: {
              headerTextColor: colorsTheme[0].value,
              backgroundColor: colorsTheme[1].value,
              generalTextColor: colorsTheme[2].value,
              priceColor: colorsTheme[3].value,
              titleItemColor: colorsTheme[4].value,
            },
          },
        })
      )
    }, 700)

    return () => {
      clearTimeout(timeoutDispatchColor)
    }
  }, [JSON.stringify(colorsTheme)])

  const handleResetColor = () => {
    const convertColor = {
      headerTextColor: defaultColorTheme.headerTextColor,
      backgroundColor: defaultColorTheme.backgroundColor,
      generalTextColor: defaultColorTheme.generalTextColor,
      priceColor: defaultColorTheme.priceColor,
      titleItemColor: defaultColorTheme.titleItemColor,
    }
    const convertObjectToArray = Object.keys(convertColor).map((key, index) => ({
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Biến đổi tên thuộc tính thành chuỗi đọc được
      value: defaultColorTheme[key],
      index,
    }))
    setColorsTheme(convertObjectToArray)
  }

  const handleChooseColor = (hexColor, index) => {
    const findIndex = colorsTheme.findIndex((item) => item.index === index)
    const updateColorsTheme = (prev, _findIndex, _index, _hexColor) => {
      const newColorsTheme = [...prev]
      newColorsTheme[_findIndex].value = prev[_index].value
      newColorsTheme[_index].value = _hexColor
      return newColorsTheme
    }

    if (findIndex !== -1) {
      setColorsTheme((prev) => updateColorsTheme(prev, findIndex, index, hexColor))
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const postData = {
        ...selectedShop,
        customsColorTheme: {
          headerTextColor: colorsTheme[0].value,
          backgroundColor: colorsTheme[1].value,
          generalTextColor: colorsTheme[2].value,
          priceColor: colorsTheme[3].value,
          titleItemColor: colorsTheme[4].value,
        },
      }

      await virtualShopApi.updateShop(postData, selectedShop?.id)

      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          shopColorTheme: {
            customsColorTheme: {
              headerTextColor: colorsTheme[0].value,
              backgroundColor: colorsTheme[1].value,
              generalTextColor: colorsTheme[2].value,
              priceColor: colorsTheme[3].value,
              titleItemColor: colorsTheme[4].value,
            },
            defaultColorTheme: selectedShop?.shopColorTheme?.defaultColorTheme,
          },
        })
      )

      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(`${error.msgResp}`, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className='color-picker rounded p-3 row'
      style={{ backgroundColor: 'rgb(248 250 252)', gap: '1rem 0', maxWidth: 650 }}
    >
      {!isEmpty(selectedShop)
        ? colorsTheme.map((colorTheme, index) => (
            <div className='col-6 d-flex align-items-center' key={index}>
              <div className='row w-100 justify-content-between'>
                <div className={`col-9 ${index % 2 !== 0 ? 'ps-5' : ''}`}>
                  <span className='f-14 text-danger'>{colorTheme.label}</span>
                </div>
                <div className='col-3'>
                  <input
                    className={'color-item rounded m-0'}
                    id={colorTheme.value}
                    value={colorTheme.value}
                    type='color'
                    onChange={(e) => handleChooseColor(e.target.value, index)}
                    style={{
                      backgroundColor: colorTheme.value,
                      border: '1px solid',
                      outline: 'none',
                      height: 37,
                    }}
                  />
                </div>
              </div>
            </div>
          ))
        : null}
      <div className='d-flex align-items-center justify-content-end col-12'>
        <button
          type='button'
          className='btn bg-transparent border-0 rounded text-secondary shadow-none w-auto'
          onClick={handleResetColor}
        >
          <span className='text-decoration-underline'>Reset to default</span>
        </button>
        <button
          type='button'
          className='btn btn-primary rounded shadow-none w-auto ms-2'
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size='sm' /> : 'Save'}
        </button>
      </div>
    </div>
  )
}

export default ColorPicker
