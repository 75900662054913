/* eslint-disable arrow-body-style */
import { setOpenSubItem } from '_redux/modules/product'
import { generateIdWithLength } from '_utils/functions/generator'
import { isNull, isUndefined } from 'lodash'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiOutlinePlus } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'

function SubItem({
  subItems,
  product,
  register,
  append,
  insert,
  remove,
  getValues,
  setValue,
  watch,
}) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const onCopySubItem = (subItemId) => {
    if (subItems.length >= 2) {
      addToast(`Maximum number of sub-items: ${2}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    try {
      const index = subItems.findIndex((item) => item.id === subItemId)

      const subItemData = getValues(`subItems.${index}`)
      const newSubItem = {
        ...subItemData,
        id: generateIdWithLength(20), // Tạo ID mới cho subItem sao chép
        list: subItemData.list.map((option) => ({
          ...option,
          id: generateIdWithLength(20), // Tạo ID mới cho mỗi option
        })),
      }
      insert(index + 1, newSubItem)
      // dispatch(copySubItem(product.id, subItemId))
    } catch (error) {
      addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onAddSubItem = () => {
    if (subItems.length >= 2) {
      addToast(`Maximum number of sub-items: ${2}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    try {
      append({
        list: [
          {
            price: 0,
            name: '',
            id: generateIdWithLength(20),
          },
        ],
        required: 1, // 1: required, 0: optional
        type: '',
        id: generateIdWithLength(20),
      })
      dispatch(setOpenSubItem(true, product.id, product.index))
    } catch (error) {
      addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onAddOption = (subItemIndex) => {
    const newOption = {
      price: 0,
      name: '',
      id: generateIdWithLength(20),
    }

    const currentList = getValues(`subItems.${subItemIndex}.list`)

    setValue(`subItems.${subItemIndex}.list`, [...currentList, newOption])
  }

  const onDeleteOption = (subItemIndex, optionIndex) => {
    const listOptions = watch(`subItems.${subItemIndex}.list`)

    if (listOptions.length <= 1) return

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const currentList = getValues(`subItems.${subItemIndex}.list`)

          const newList = currentList.filter((_, index) => index !== optionIndex)

          setValue(`subItems.${subItemIndex}.list`, newList)
        }
      })
      .catch((error) => {
        Swal.fire('Error!', error.message || error?.msgResp, 'error')
      })
  }

  if (isUndefined(subItems) || isNull(subItems)) return []

  return (
    <>
      {subItems?.map((subItem, subIndex) => (
        <div className='sub-item c-form is-active' key={subItem?.id || generateIdWithLength(20)}>
          <div className='sub-item-div'>
            <div className='row mb-3'>
              <div className='col col-2 align-self-center col-index fw-semibold px-0'>
                Sub item {subIndex + 1}:
              </div>
              <div className='col col-10'>
                <input
                  type='text'
                  defaultValue={subItem?.type}
                  placeholder='Enter sub item name'
                  className='form-control form-control-line bg-light p-1 rounded-top-3 f-14 mx-auto'
                  {...register(`subItems.${subIndex}.type`)}
                />
              </div>
            </div>
            {subItem?.list?.map((item, optionIndex) => (
              <div className='row sub-item-option align-items-center' key={item?.id || item.name}>
                <span className='stt col col-2'>Option Name:</span>
                <input
                  type='text'
                  className='form-control form-control-line bg-light p-1 rounded-top-3 col col-4'
                  placeholder='Enter option name'
                  defaultValue={item?.name}
                  {...register(`subItems.${subIndex}.list.${optionIndex}.name`)}
                />

                <span className='stt col col-2 text-end ps-2 pe-3'>Price ($):</span>

                <CurrencyFormat
                  value={item?.price || 0}
                  displayType='input'
                  thousandSeparator={true}
                  className='form-control form-control-line p-1 bg-light rounded-top-3 col col-3'
                  onValueChange={(values) => {
                    const { value } = values
                    setValue(`subItems.${subIndex}.list.${optionIndex}.price`, value)
                  }}
                />
                <div className='col col-1'>
                  <div className='d-flex align-items-center justify-content-end w-100'>
                    <AiOutlinePlus
                      className='btn-add-option cursor-pointer d-flex align-items-center justify-content-center me-2 border-0 rounded-circle bg-transparent shadow-none'
                      onClick={() => {
                        onAddOption(subIndex)
                      }}
                      size={20}
                    />
                    <a
                      className='btn-remove cursor-pointer'
                      onClick={() => onDeleteOption(subIndex, optionIndex)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='sub-item-div sub-item-div-last border-top-0 mt-3'>
            <div className='row'>
              <div className='col-6 ps-0'></div>
              <div className='col-6'>
                <div className='btn-action-01 d-flex w-100 align-items-center justify-content-end'>
                  <a className='btn-add-01 cursor-pointer' onClick={onAddSubItem} />
                  <a
                    className='btn-copy cursor-pointer me-0'
                    onClick={() => onCopySubItem(subItem.id)}
                  />
                  <a className='btn-remove me-0 cursor-pointer' onClick={() => remove(subIndex)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default SubItem
