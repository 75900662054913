/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import lazadaApi from '_api/lazada'
import uploadApi from '_api/upload'
import LongText from '_components/LongText'
import { ProductItemLazadaProvider } from '_contexts/ProductItemLazadaContext'
import {
  clearProductLazadaSelected,
  deleteProductLazada,
  fetchListVariationByProductId,
  handleAddVariation,
  pushToLazada,
  selectAndToggleProductLazadaModal,
  setOpenVariationsForProduct,
  updateListProductLazada,
  updateNewListVariantWithActionRemove,
  updateSingleProductLazada,
  updateVariationProduct,
} from '_redux/modules/product'
import { TURN_ON } from '_utils/constant'
import { delay, getSelectedVariantIds, isLazadaImgURL } from '_utils/function'
import { convertBlobUrlToFile, convertURLImageToFile } from '_utils/functions/converter'
import { formatPrice } from '_utils/functions/formatter'
import { generateIdWithLength, generateVariations } from '_utils/functions/generator'
import { sortedJsonStringify } from '_utils/functions/sorter'
import { isUndefined } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useFieldArray, useForm } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import ModalEditProductLazada from '../ModalEditProductLazada'
import ProductImage from './Image'
import Purchase from './Purchase'
import Variations from './Variations'

function ProductLazadaItem({
  product,
  tableHeaders,
  isPushingLaz,
  setIsPushingLaz,
  isRemovingLaz,
  setIsRemovingLaz,
  isUpdatingVariationLaz,
  setIsUpdatingVariationLaz,
}) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { selectedShop } = useSelector((state) => state.shop)
  const { listProductLazada } = useSelector((state) => state.product)

  const listProductLazadaLocalStorage = JSON.parse(localStorage.getItem('listProductLazada')) || []

  const productFromLocalStorage = listProductLazadaLocalStorage.find(
    (item) => item.id === product.id
  )

  const [loadingProduct, setLoadingProduct] = useState('')
  const [listVariationIdNeedRemove, setListVariationIdNeedRemove] = useState([])
  const [listVariationMatchingProduct, setListVariationMatchingProduct] = useState([])
  const [listVariantSelected, setListVariantSelected] = useState([])
  const inputFileRefs = useRef([])

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting, errors },
    getValues,
  } = useForm({
    defaultValues: product,
  })
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'variations',
  })

  const watchProduct = watch()
  const watchListVariationProduct = watch('listVariationProduct')
  const watchVariations = watch('variations')
  const controlVariations = fields
    .map((field, index) => ({
      ...field,
      ...watchVariations[index],
    }))
    .map((variant) => {
      const { id, ...restVariant } = variant

      const updatedList = variant.list.map((item) => {
        const { id: _id, ...restItem } = item

        restItem.price = Number(restItem.price)

        return restItem
      })

      return { ...restVariant, list: updatedList }
    })

  useEffect(() => {
    let isMounted = true
    if (
      isMounted &&
      product.listVariationProduct !== undefined &&
      product.listVariationProduct.length
    ) {
      const initialVariation =
        controlVariations !== undefined && controlVariations.length
          ? generateVariations(controlVariations)
          : []

      const mappedControlVariations = controlVariations?.map((item) => ({
        ...item,
        list: item.list.map((option) => ({
          ...option,
          codeProd: generateIdWithLength(20),
        })),
      }))

      const variationsWithCodeProd = initialVariation?.map((variant) => {
        const saleProps = variant.saleProp

        // Find codeProd for each saleProp
        const codeProdList = Object.keys(saleProps).map((key) => {
          const matchedOption = mappedControlVariations
            ?.find((ctrl) => ctrl.type === key)
            ?.list.find((option) => option.name === saleProps[key])

          return matchedOption ? matchedOption.codeProd : ''
        })

        // Combine all codeProds into a single string or use any other logic to determine the final codeProd
        const finalCodeProd = codeProdList.join('-') // Example: combining them with a hyphen

        return {
          ...variant,
          codeProd: finalCodeProd,
        }
      })

      const salePropToCodeProdMap = new Map()

      variationsWithCodeProd.forEach((variant) => {
        const salePropKey = sortedJsonStringify(variant.saleProp)
        salePropToCodeProdMap.set(salePropKey, variant.codeProd)
      })

      const mappedList = product.listVariationProduct.map((item) => {
        const salePropKey = sortedJsonStringify(item.saleProp)

        const codeProd = salePropToCodeProdMap.get(salePropKey) || item.codeProd
        return {
          ...item,
          codeProd,
        }
      })
      // Create an index map for variationsWithCodeProd
      const indexMap = new Map()
      variationsWithCodeProd.forEach((variant, index) => {
        const salePropKey = sortedJsonStringify(variant.saleProp)
        indexMap.set(salePropKey, index)
      })

      const sortedMappedList = [...mappedList].sort((a, b) => {
        const salePropKeyA = sortedJsonStringify(a.saleProp)
        const salePropKeyB = sortedJsonStringify(b.saleProp)
        const indexA = indexMap.get(salePropKeyA) || 0
        const indexB = indexMap.get(salePropKeyB) || 0
        return indexA - indexB
      })

      setValue('variations', mappedControlVariations)
      setValue('listVariationProduct', sortedMappedList)
      setListVariationMatchingProduct(sortedMappedList)
    }

    return () => {
      isMounted = false
    }
  }, [JSON.stringify(product.listVariationProduct)])

  useEffect(() => {
    let isMounted = true
    if (isMounted && listVariantSelected.length > 0 && listVariationMatchingProduct.length > 0) {
      const selectedVariantIds = getSelectedVariantIds(
        listVariationMatchingProduct,
        listVariantSelected
      )
      setListVariationIdNeedRemove(selectedVariantIds.filter((item) => item !== undefined) || [])
    }

    return () => {
      isMounted = false
    }
  }, [listVariantSelected.length, listVariationMatchingProduct.length])

  useEffect(() => {
    if (!watchProduct.categoryId && !product?.isOpenModalEdit) {
      setValue('name', product.name)
    }
  }, [watchProduct.categoryId, product?.isOpenModalEdit])

  const renderPrice = () => {
    const priceRange =
      product.variations.length > 0 && watchListVariationProduct
        ? [...watchListVariationProduct]
            .map((variant) => variant.price)
            .sort((a, b) => a - b)
            .reduce(
              (range, price, index) => {
                if (index === 0) {
                  range.min = price
                  range.max = price
                } else {
                  if (price < range.min) {
                    range.min = price
                  }
                  if (price > range.max) {
                    range.max = price
                  }
                }

                return range
              },
              { min: null, max: null }
            )
        : {
            min: watchProduct.price,
            max: watchProduct.price,
          }

    if (!priceRange || (priceRange.min === null && priceRange.max === null)) {
      return (
        <div
          className='text-content cursor-pointer'
          onClick={() => {
            if (!product?.variations?.length) {
              dispatch(selectAndToggleProductLazadaModal(watchProduct, 'price'))
            } else {
              addToast('You can only update price when the product no list variation', {
                appearance: 'error',
                autoDismiss: true,
              })
            }
          }}
        >
          <CurrencyFormat
            value={watchProduct.price || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix='$'
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      )
    }

    if (priceRange.min === priceRange.max) {
      return (
        <div
          className='text-content cursor-pointer'
          onClick={() => {
            if (!product?.variations?.length) {
              dispatch(selectAndToggleProductLazadaModal(watchProduct, 'price'))
            } else {
              addToast('You can only update price when the product no list variation', {
                appearance: 'error',
                autoDismiss: true,
              })
            }
          }}
        >
          {!watchListVariationProduct || !product?.variations?.length ? (
            <CurrencyFormat
              value={watchProduct.price || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix='$'
              decimalScale={2}
              fixedDecimalScale={true}
            />
          ) : null}

          {watchListVariationProduct && product?.variations?.length ? (
            <>
              <CurrencyFormat
                value={priceRange.min || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix='$'
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </>
          ) : null}
        </div>
      )
    }

    return (
      <div
        className='text-content cursor-pointer'
        onClick={() => {
          if (!product?.variations?.length) {
            dispatch(selectAndToggleProductLazadaModal(watchProduct, 'price'))
          } else {
            addToast('You can only update price when the product no list variation', {
              appearance: 'error',
              autoDismiss: true,
            })
          }
        }}
      >
        <CurrencyFormat
          value={priceRange.min || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix='$'
          decimalScale={2}
          fixedDecimalScale={true}
        />
        {' - '}
        <CurrencyFormat
          value={priceRange.max || 0}
          displayType={'text'}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={true}
          prefix='$'
        />
      </div>
    )
  }

  const handleResetInputFile = (index) => {
    if (inputFileRefs.current[index]) {
      inputFileRefs.current[index].value = ''
      inputFileRefs.current[index].type = 'text'
      inputFileRefs.current[index].type = 'file'
    }
  }

  const onGenerateVariations = () => {
    if (
      product.variations.length > 0 &&
      (controlVariations === undefined || !controlVariations?.length)
    ) {
      addToast('Please add at least 1 variant', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    if (
      controlVariations !== undefined &&
      controlVariations.length &&
      product?.listVariationProduct !== undefined &&
      product?.listVariationProduct.length
    ) {
      let initialVariation = []

      if (controlVariations.length === 1) {
        initialVariation = [...generateVariations([...controlVariations])]
      } else {
        const listVariations = [...generateVariations([...controlVariations])]
        // const variationsWithCodeProd = listVariations?.map((variant) => {
        //   const saleProps = variant.saleProp

        //   // Find codeProd for each saleProp
        //   const codeProdList = Object.keys(saleProps).map((key) => {
        //     const matchedOption = controlVariations
        //       ?.find((ctrl) => ctrl.type === key)
        //       ?.list.find((option) => option.name === saleProps[key])

        //     return matchedOption ? matchedOption.codeProd : ''
        //   })

        //   // Combine all codeProds into a single string or use any other logic to determine the final codeProd
        //   const finalCodeProd = codeProdList.join('-') // Example: combining them with a hyphen

        //   return {
        //     ...variant,
        //     codeProd: finalCodeProd,
        //   }
        // })
        initialVariation = [...listVariations]
      }

      if (listVariationMatchingProduct.length) {
        if (listVariationMatchingProduct?.length === initialVariation?.length) {
          const mappedList = initialVariation.map((currentVariant) => {
            const matchListVariation = listVariationMatchingProduct.find((variant) =>
              currentVariant.codeProd.includes(variant.codeProd)
            )

            const returnItem = matchListVariation || {
              ...currentVariant,
              active: true,
              width: 1,
              height: 1,
              length: 1,
              weight: 1,
            }

            return {
              ...returnItem,
              saleProp: currentVariant.saleProp,
            }
          })
          setValue('listVariationProduct', mappedList)
          setListVariationMatchingProduct(mappedList)
        } else if (
          listVariationMatchingProduct?.length !== initialVariation?.length &&
          listVariantSelected.length === 0
        ) {
          const matched = new Set()
          const updatedList = initialVariation.map((variant, index) => {
            const matchingVariant = listVariationMatchingProduct.find(
              (currentVariant) =>
                variant.codeProd.includes(currentVariant.codeProd) &&
                !matched.has(currentVariant.codeProd)
            )

            if (matchingVariant) {
              matched.add(matchingVariant.codeProd)
              return {
                ...matchingVariant,
                saleProp: variant.saleProp,
                photoUrl: matchingVariant.photoUrl,
                price: matchingVariant.price,
                quantity: matchingVariant.quantity,
              }
            }

            const valuesVariant = Object.values(variant.saleProp)
            if (valuesVariant.length > 0 && valuesVariant.every((value) => value === '')) {
              handleResetInputFile(index)
              return {
                ...variant,
                active: true,
                width: 1,
                height: 1,
                length: 1,
                weight: 1,
                photoUrl: null,
                price: 0,
              }
            }

            return {
              ...variant,
              active: watchListVariationProduct[index]?.active || true,
              width: watchListVariationProduct[index]?.width || 1,
              height: watchListVariationProduct[index]?.height || 1,
              length: watchListVariationProduct[index]?.length || 1,
              weight: watchListVariationProduct[index]?.weight || 1,
              photoUrl: matchingVariant?.id ? watchListVariationProduct[index]?.photoUrl : null,
              price: watchListVariationProduct[index]?.price || 0,
            }
          })

          setValue('listVariationProduct', updatedList)
        } else if (
          listVariationMatchingProduct?.length !== initialVariation?.length &&
          listVariantSelected.length > 0
        ) {
          const matched = new Set()

          const updatedList = initialVariation.map((item, index) => {
            const matchProd = listVariationMatchingProduct.find(
              (currentVariant) =>
                item.codeProd.includes(currentVariant.codeProd) &&
                !matched.has(currentVariant.codeProd)
            )

            if (matchProd) {
              matched.add(matchProd.codeProd)
              return {
                ...matchProd,
                saleProp: item.saleProp,
                // photoUrl: watchListVariationProduct[index]?.photoUrl,
                photoUrl: matchProd.photoUrl,
              }
            }

            const valuesVariant = Object.values(item.saleProp)

            if (valuesVariant.length > 0 && valuesVariant.every((value) => value === '')) {
              handleResetInputFile(index)
              return {
                ...item,
                active: true,
                width: 1,
                height: 1,
                length: 1,
                weight: 1,
                photoUrl: null,
                price: 0,
              }
            }

            return {
              ...item,
              active: watchListVariationProduct[index]?.active || true,
              width: watchListVariationProduct[index]?.width || 1,
              height: watchListVariationProduct[index]?.height || 1,
              length: watchListVariationProduct[index]?.length || 1,
              weight: watchListVariationProduct[index]?.weight || 1,
              photoUrl: watchListVariationProduct[index]?.photoUrl || null,
              price: watchListVariationProduct[index]?.price || 0,
            }
          })

          setValue('listVariationProduct', updatedList)
        } else {
          const updatedList = initialVariation
            .map((variant) => {
              const matchingVariant = listVariationMatchingProduct.find(
                (currentVariant) => currentVariant.codeProd === variant.codeProd
              )

              if (matchingVariant?.id) {
                return matchingVariant
              }

              return {
                ...variant,
                active: true,
              }
            })
            .map((item, index) => ({
              ...item,
              saleProp: initialVariation[index].saleProp,
            }))

          setValue('listVariationProduct', updatedList)
          setListVariationMatchingProduct(updatedList)
        }
      } else {
        setValue(
          'listVariationProduct',
          initialVariation.map((item) => ({
            ...item,
            active: true,
            weight: 1,
            length: 1,
            height: 1,
            width: 1,
          }))
        )
      }
    } else {
      const initialVariation = [...generateVariations([...controlVariations])]
      setValue(
        'listVariationProduct',
        initialVariation.map((item, index) => {
          const variation = watchListVariationProduct?.[index] || {}
          return {
            ...item,
            active: variation.active ?? true,
            weight: variation.weight ?? 1,
            length: variation.length ?? 1,
            height: variation.height ?? 1,
            width: variation.width ?? 1,
            photoUrl: variation.photoUrl ?? null,
            price: variation.price ?? 0,
            quantity: variation.quantity ?? 0,
          }
        })
      )
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (watchProduct?.variations?.length > 0 && controlVariations !== undefined) {
        // const hasCodeProd = controlVariations.some((item) =>
        //   item.list.some((option) => option.codeProd)
        // )
        onGenerateVariations()
      }
    }

    return () => {
      isMounted = false
    }
  }, [JSON.stringify(controlVariations)])

  const onEditName = () => {
    dispatch(selectAndToggleProductLazadaModal(watchProduct, 'name'))
  }

  const onEditQuantity = () => {
    if (!product?.variations?.length) {
      dispatch(selectAndToggleProductLazadaModal(watchProduct, 'availableOfStock'))
    } else {
      addToast('You just can update inventory in list variants', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onEditDescription = () => {
    dispatch(selectAndToggleProductLazadaModal(watchProduct, 'description'))
  }

  const getInventory = () => {
    if (watchProduct?.listVariationProduct?.length) {
      const totalInventory = watchProduct?.listVariationProduct?.reduce(
        (sum, p) => sum + Number(p.quantity || 0),
        0
      )

      return totalInventory
    }

    if (!product?.variations?.length) {
      return watchProduct?.availableOfStock || 0
    }

    return 0
  }

  const onToggleVariation = (e, prod) => {
    const { checked } = e.target

    if (prod.variations.length) {
      onGenerateVariations()
    }

    dispatch(setOpenVariationsForProduct(checked, product.id))
  }

  const onDeleteProduct = (pId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsRemovingLaz(true)
          await dispatch(deleteProductLazada(selectedShop, pId))
          setIsRemovingLaz(false)
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
      .catch((error) => {
        Swal.fire('Error!', error.message, 'error')
        setIsRemovingLaz(false)
      })
  }

  const onAddVariant = () => {
    if (controlVariations !== undefined && controlVariations.length === 2) {
      return addToast('You can only create a maximum of 2 products', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    // if (isGeneratedVariations) {
    //   return addToast('You have to clear variation when updating option.', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   })
    // }

    const NEW_VARIANT = {
      type: '',
      id: generateIdWithLength(20),
      list: [
        {
          price: 0,
          id: generateIdWithLength(20),
          codeProd: generateIdWithLength(20),
          name: '',
        },
      ],
    }

    dispatch(setOpenVariationsForProduct(true, product.id))

    append(NEW_VARIANT)
  }

  const onRemoveVariant = (variantIndex) => {
    const variantToRemove = controlVariations[variantIndex]

    const isVariantInListVariationProduct = product?.listVariationProduct?.some((variant) =>
      variantToRemove.list.some((item) =>
        Object.values(variant.saleProp).some((value) => value === item.name)
      )
    )

    if (isVariantInListVariationProduct) {
      return addToast('You can not remove a variant that exists in the Lazada.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        remove(variantIndex)
      }
    })
  }

  const onCopyVariant = (variantIndex) => {
    if (controlVariations.length === 2) {
      return addToast('You can only create a maximum of 2 products', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    const newVariant = {
      ...controlVariations[variantIndex],
      list: controlVariations[variantIndex].list.map((item) => ({
        ...item,
        id: generateIdWithLength(20),
        codeProd: generateIdWithLength(20),
        name: '',
      })),
      id: generateIdWithLength(20),
      type: '',
    }

    insert(variantIndex + 1, newVariant)
  }

  const onAddOption = (variantIndex) => {
    const newOption = {
      price: 0,
      id: generateIdWithLength(20),
      name: '',
      codeProd: generateIdWithLength(20),
    }

    const currentList = getValues(`variations.${variantIndex}.list`)

    const updatedList = [...currentList, newOption]

    setValue(`variations.${variantIndex}.list`, updatedList)
  }

  const onRemoveOption = (variantIndex, optionIndex) => {
    const currentListOption = getValues(`variations.${variantIndex}.list`)
    const optionSelected = { ...currentListOption[optionIndex] }

    if (currentListOption.length === 1 && !product?.isPendingToPush) {
      addToast('This option cannot remove', {
        appearance: 'error',
        autoDismiss: true,
      })

      return
    }

    Swal.fire({
      title: 'Are you sure remove?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it',
    }).then((result) => {
      if (result.isConfirmed) {
        if (optionSelected) {
          setListVariantSelected([...listVariantSelected, optionSelected])
        }

        if (currentListOption.length === 1) {
          setValue(`variations.${variantIndex}.list`, [
            { price: 0, name: '', id: generateIdWithLength(20) },
          ])
          return
        }

        // currentListOption[optionIndex] = { ...optionSelected, name: '' }
        // setValue(`variations.${variantIndex}.list`, currentListOption)

        currentListOption[optionIndex].codeProd = null

        currentListOption.splice(optionIndex, 1)

        setValue(`variations.${variantIndex}.list`, currentListOption)
      }
    })
  }

  const determineActions = (postData, _productFromLocalStorage) => {
    const actions = {
      add: [],
      remove: [],
      update: [],
    }

    const postVariations = postData.variations
    const storedVariations = _productFromLocalStorage?.variations || []
    const postListVariationProduct = postData.listVariationProduct
    const storedListVariationProduct = _productFromLocalStorage?.listVariationProduct || []

    if (listVariantSelected.length > 0) {
      actions.remove.push(...postVariations)
    }

    if (postListVariationProduct.length === storedListVariationProduct.length) {
      const modifiedElement = postListVariationProduct.find((postElement) => {
        const originalElement = storedListVariationProduct.find(
          (origElement) => origElement.id === postElement.id
        )

        if (originalElement) {
          const priceChanged = originalElement.price !== postElement.price
          const quantityChanged = originalElement.quantity !== postElement.quantity
          const weightChanged = originalElement.weight !== postElement.weight
          const widthChanged = originalElement.width !== postElement.width
          const heightChanged = originalElement.height !== postElement.height
          const lengthChanged = originalElement.length !== postElement.length

          return (
            (priceChanged || quantityChanged) &&
            !(weightChanged || widthChanged || heightChanged || lengthChanged)
          )
        }

        return false
      })

      if (modifiedElement) {
        actions.update.push(modifiedElement)
      }
    }

    // Tìm các phần tử cần thêm và cập nhật
    if (postVariations.length === 1) {
      postVariations.forEach((postVariant, index) => {
        const storedVariant = storedVariations[index]
        if (!storedVariant) {
          actions.add.push(postVariant)
        } else if (JSON.stringify(postVariant) !== JSON.stringify(storedVariant)) {
          actions.update.push(postVariant)
        }
      })
    } else {
      // eslint-disable-next-line no-lonely-if
      if (JSON.stringify(storedVariations) !== JSON.stringify(postVariations)) {
        actions.update.push(...postVariations)
      }
    }

    return actions
  }

  const preparePostData = () => ({
    productId: product.id,
    shopId: selectedShop?.id,
    variations: controlVariations,
    listVariationProduct: watchListVariationProduct?.map((variant) => ({
      ...variant,
      quantity: variant.quantity || 0,
      price: formatPrice(variant.price),
      weight: Number(variant.weight) || 0,
      height: Number(variant.height) || 0,
      width: Number(variant.width) || 0,
      length: Number(variant.length) || 0,
      photoUrl: variant.photoUrl,
      active: Number(variant.active),
    })),
  })

  const convertImgToLazada = async (fileImage) => {
    try {
      const { msgResp } = await uploadApi.uploadPhotoLazada(selectedShop?.id, fileImage)
      return msgResp.url || null
    } catch (error) {
      addToast(error?.msgResp?.message || error?.msgResp || error.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handlePushToLazada = async (data) => {
    if (watchListVariationProduct && watchListVariationProduct.length === 1) {
      addToast('Must be at least 2 variants', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    try {
      setIsPushingLaz(true)
      const mappedVariations = watchListVariationProduct?.map((item) => {
        const { codeProd, ...restItem } = item
        return {
          ...restItem,
          price: formatPrice(item.price),
          quantity: Number(item.quantity),
          height: parseFloat(item.height) || 1,
          width: parseFloat(item.width) || 1,
          length: parseFloat(item.length) || 1,
          weight: parseFloat(item.weight) || 1,
        }
      })

      const postData = {
        shopId: selectedShop?.id,
        name: data?.name,
        description: data?.description,
        categoryId: data?.categoryId,
        quantity: Number(data?.availableOfStock) || 0,
        price: data.price,
        active: isUndefined(data?.active) ? TURN_ON : Number(data?.active),
        photoUrl: data?.photoUrl || product.photoUrls,
        maxQuantity: Number(data?.maxQuantity),
        minOrderQty: Number(data?.minOrderQty),
        defaultQuantity: Number(data?.defaultQuantity),
        limitPerOrder: Number(data?.limitPerOrder),
        weight: parseFloat(data?.weight) || 1,
        width: parseFloat(data?.width) || 1,
        height: parseFloat(data?.height) || 1,
        length: parseFloat(data?.length) || 1,
        variations: controlVariations?.length
          ? controlVariations?.map((item) => {
              const { id, required, ...restItem } = item

              restItem.list = restItem.list.map((listItem) => {
                const { id: _id, codeProd, ...restListItem } = listItem

                restListItem.price = Number(restListItem.price)

                return restListItem
              })

              return restItem
            })
          : undefined,
        existsProductId: product.id,
        listVariationProduct: mappedVariations || undefined,
      }

      if (Boolean(controlVariations.length) && !watchListVariationProduct) {
        addToast('Please generate variations', { appearance: 'error', autoDismiss: true })
        return
      }

      const listImageInvalid = postData?.listVariationProduct?.filter(
        (variant) => !isLazadaImgURL(variant.photoUrl)
      )

      const imageConversionPromises =
        listImageInvalid?.map(async (variant, index) => {
          await delay(index * 300)
          try {
            const fileImage = await convertBlobUrlToFile(variant.photoUrl)
            variant.photoUrl = await convertImgToLazada(fileImage)
            setValue(`listVariationProduct.${index}.photoUrl`, variant.photoUrl)
          } catch (error) {
            console.error(`Error converting image at index ${index}:`, error)
          }
        }) || []

      await Promise.allSettled(imageConversionPromises)

      if (!isLazadaImgURL(postData.photoUrl)) {
        const fileImage = await convertURLImageToFile(postData.photoUrl)

        // Validate image size
        const image = new Image()
        image.src = URL.createObjectURL(fileImage)
        await new Promise((resolve, reject) => {
          image.onload = () => {
            if (image.width < 330 || image.height < 330) {
              reject(new Error('Image size must be at least 330x330'))
            } else {
              resolve()
            }
          }
          image.onerror = () => {
            reject(new Error('Failed to load image'))
          }
        })

        const { msgResp } = await uploadApi.uploadPhotoLazada(selectedShop?.id, fileImage)

        postData.photoUrl = msgResp?.url || undefined
        setValue('photoUrl', msgResp?.url || undefined)
      }

      const res = await dispatch(pushToLazada(postData))
      dispatch(clearProductLazadaSelected())

      if (postData?.variations?.length > 0) {
        dispatch(fetchListVariationByProductId(res.id))
      }

      addToast('Push to Lazada success', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.message || error?.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsPushingLaz(false)
    }
  }

  const handleUpdateSingleProduct = async (data) => {
    let convertedData = {
      ...data,
      quantity: Number(data.availableOfStock),
      price: Number(data.price),
    }

    if (data.hasOwnProperty('photoUrls')) {
      const { photoUrls, ...restData } = data
      convertedData = {
        ...restData,
        photoUrl: photoUrls,
      }
    }

    let postData = { ...convertedData }

    if (data?.variations?.length > 0) {
      const { price, quantity, ...restData } = convertedData
      postData = { ...restData }
    }

    const { availableOfStock, ...restData } = postData

    try {
      await dispatch(updateSingleProductLazada(restData, selectedShop?.id))
      addToast('Updated successfully', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message || e.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleUpdateVariationAction = async (postData, variantIndex) => {
    const postDataListVariation = [...postData.listVariationProduct]
    const storedListVariationProduct = productFromLocalStorage?.listVariationProduct?.length
      ? [...productFromLocalStorage.listVariationProduct]
      : []

    const originalStoredListVariationProduct = JSON.parse(
      JSON.stringify(storedListVariationProduct)
    )

    const postDataOrder = {}
    postDataListVariation.forEach((item, index) => {
      postDataOrder[item.id] = index
    })
    storedListVariationProduct.sort((a, b) => postDataOrder[a.id] - postDataOrder[b.id])

    if (postDataListVariation.length === storedListVariationProduct.length) {
      let modifiedElements = postDataListVariation.filter((postElement) => {
        const originalElement = storedListVariationProduct.find(
          (origElement) => origElement.id === postElement.id
        )

        if (originalElement) {
          const priceChanged = originalElement.price !== postElement.price
          const quantityChanged = originalElement.quantity !== postElement.quantity
          const weightChanged = originalElement.weight !== postElement.weight
          const widthChanged = originalElement.width !== postElement.width
          const heightChanged = originalElement.height !== postElement.height
          const lengthChanged = originalElement.length !== postElement.length
          const photoUrlChanged = originalElement.photoUrl !== postElement.photoUrl

          return (
            (priceChanged || quantityChanged) &&
            !(weightChanged || widthChanged || heightChanged || lengthChanged || photoUrlChanged)
          )
        }

        return false
      })

      if (modifiedElements?.length && modifiedElements?.length === 1) {
        if (typeof variantIndex === 'number') {
          const variantSelected = postDataListVariation[variantIndex]

          await lazadaApi.updatePriceQuantity({
            shopId: selectedShop?.id,
            variationProductId: variantSelected.id,
            price: Number(variantSelected.price),
            quantity: Number(variantSelected.quantity),
          })
        } else {
          const lastElement = modifiedElements[modifiedElements.length - 1]

          await lazadaApi.updatePriceQuantity({
            shopId: selectedShop?.id,
            variationProductId: lastElement?.id,
            price: Number(lastElement?.price),
            quantity: Number(lastElement?.quantity),
          })

          modifiedElements = modifiedElements.map((item) => {
            if (item.id !== lastElement.id) {
              const originalElement = originalStoredListVariationProduct.find(
                (origElement) => origElement.id === item.id
              )
              return {
                ...(originalElement || item),
                codeProd: item.codeProd,
              }
            }
            return item
          })
        }

        const updatedListVariationProduct = postDataListVariation.map((itemVar) => {
          const findModifiedElement = modifiedElements.find(
            (modifiedElement) => modifiedElement.id === itemVar.id
          )

          if (findModifiedElement) {
            return findModifiedElement
          }

          return itemVar
        })

        const newList = listProductLazada.map((item) => {
          if (item.id === product.id) {
            return {
              ...item,
              listVariationProduct: updatedListVariationProduct,
            }
          }
          return item
        })

        setListVariationMatchingProduct(updatedListVariationProduct)
        setValue('listVariationProduct', updatedListVariationProduct)

        dispatch(updateListProductLazada(newList))
        localStorage.setItem('listProductLazada', JSON.stringify(newList))
      } else {
        await dispatch(updateVariationProduct(postData))
        setListVariationMatchingProduct(postDataListVariation)
        localStorage.setItem('listProductLazada', JSON.stringify(listProductLazada))
      }
    } else {
      await dispatch(updateVariationProduct(postData))
      setListVariationMatchingProduct(postDataListVariation)
      localStorage.setItem('listProductLazada', JSON.stringify(listProductLazada))
    }
  }

  const handleRemoveVariant = async (currentList) => {
    if (listVariationIdNeedRemove.length > 0) {
      try {
        setIsRemovingLaz(true)

        const convertedCurrentList = currentList.map((item) => ({
          ...item,
          list: item.list.map((option) => {
            const { codeProd, ...restOption } = option
            return restOption
          }),
        }))

        await lazadaApi.removeVariationProduct(
          selectedShop?.id,
          listVariationIdNeedRemove,
          convertedCurrentList
        )

        setListVariationIdNeedRemove([])
        setListVariantSelected([])
        setIsRemovingLaz(false)

        if (product?.variations?.length) {
          dispatch(updateNewListVariantWithActionRemove(product, watchListVariationProduct))
        }
      } catch (error) {
        addToast(error.message || error.msgResp, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  const handleImageUpload = async (listVariationProduct) => {
    const THREE_HUNDRED_MILLISECOND = 300

    const listVariantNotImgLaz = listVariationProduct
      .map((variant, index) => ({ ...variant, originalIndex: index }))
      .filter((variant) => variant.photoUrl !== undefined && !isLazadaImgURL(variant.photoUrl))

    if (listVariantNotImgLaz.length > 0) {
      const results = await Promise.allSettled(
        listVariantNotImgLaz.map(async (variant) => {
          await delay(variant.originalIndex * THREE_HUNDRED_MILLISECOND)
          // eslint-disable-next-line no-useless-catch
          try {
            const fileImage = await convertBlobUrlToFile(variant.photoUrl)
            const lazadaPhotoUrl = await convertImgToLazada(fileImage)
            listVariationProduct[variant.originalIndex].photoUrl = lazadaPhotoUrl
            setValue(`listVariationProduct.${variant.originalIndex}.photoUrl`, lazadaPhotoUrl)
          } catch (error) {
            throw error
          }
        })
      )

      results.forEach((result) => {
        if (result.status === 'rejected') {
          addToast('Error upload image', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
    }
  }

  const handleUpdateVariationProduct = async (variantIndex) => {
    if (watchListVariationProduct && watchListVariationProduct.length === 1) {
      addToast('Must be at least 2 variants', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    try {
      setIsUpdatingVariationLaz(true)
      const postData = preparePostData()

      if (postData?.listVariationProduct?.length) {
        await handleImageUpload(postData.listVariationProduct)
      }

      const actions = determineActions(postData, productFromLocalStorage)

      // Gọi API remove trước nếu có hành động remove
      if (actions.remove.length > 0) {
        let typeActionArr

        if (controlVariations.length === 1) {
          typeActionArr = actions.remove
        } else {
          typeActionArr = actions.update
        }

        await handleRemoveVariant(typeActionArr)

        actions.remove = []
      }

      // Sau đó gọi API update nếu có hành động update
      if (actions.update.length > 0) {
        const convertedPostData = {
          ...postData,
          listVariationProduct: postData.listVariationProduct.map((item) => {
            const { codeProd, ...restItem } = item
            return restItem
          }),
          variations: postData.variations.map((item) => ({
            ...item,
            list: item.list.map((option) => {
              const { codeProd, ...restOption } = option
              return restOption
            }),
          })),
        }

        if (typeof variantIndex === 'number') {
          await handleUpdateVariationAction(convertedPostData, variantIndex)
        } else {
          await handleUpdateVariationAction(convertedPostData)
        }
        actions.update = []
      }

      // Cuối cùng gọi API add nếu có hành động add
      if (actions.add.length > 0) {
        const convertData = {
          ...postData,
          variations: postData.variations.map((item) => ({
            ...item,
            list: item.list.map((option) => {
              const { codeProd, ...restOption } = option
              return restOption
            }),
          })),
        }
        await dispatch(handleAddVariation(convertData, product))
        actions.add = []
      }

      const mappedListProd = listProductLazada.map((item) => ({
        ...item,
        variations: controlVariations,
        listVariationProduct: watchListVariationProduct,
      }))

      localStorage.setItem('listProductLazada', JSON.stringify(mappedListProd))

      // setReRender((prev) => !prev)
      dispatch(fetchListVariationByProductId(product.id))

      addToast('Update success', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message || e.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsUpdatingVariationLaz(false)
    }
  }

  return (
    <ProductItemLazadaProvider
      value={{
        watchListVariationProduct,
        handleSubmit,
        setValue,
        register,
        errors,
        watchProduct,
        onGenerateVariations,
        onCopyVariant,
        onRemoveVariant,
        onAddOption,
        onRemoveOption,
        handleUpdateVariationProduct,
        isUpdatingVariationLaz,
        controlVariations,
        onAddVariant,
        product,
        inputFileRefs,
      }}
    >
      <>
        <tr>
          <td scope='row' className='col-image'>
            <ProductImage
              product={product}
              setValue={setValue}
              loadingProduct={loadingProduct}
              setLoadingProduct={setLoadingProduct}
            />
          </td>

          <td className='col-name'>
            <div className='cursor-pointer text-wrap' onClick={onEditName}>
              <div className='text-content'>{watchProduct.name || 'Add name'}</div>
            </div>
          </td>
          <td className='col-price'>{renderPrice()}</td>
          <td className='col-inventory' style={{ width: 100 }}>
            <div className='cursor-pointer text-wrap' onClick={onEditQuantity}>
              <div className='text-content'>
                <CurrencyFormat
                  value={getInventory()}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </div>
            </div>
          </td>
          <td>
            <Purchase product={watchProduct} watchAvailableStock={watch('availableOfStock')} />
          </td>
          <td className='col-description'>
            <div className='cursor-pointer' style={{ overflowWrap: 'anywhere' }}>
              {!watchProduct.description ? (
                <div className='text-content' onClick={onEditDescription}>
                  Add description
                </div>
              ) : (
                <LongText
                  content={watchProduct.description}
                  limit={116}
                  className='text-content'
                  handleEdit={onEditDescription}
                />
              )}
            </div>
          </td>
          <td className='col-active'>
            <label className='switch'>
              <input
                type='checkbox'
                defaultChecked={watchProduct.active}
                disabled={product.variations.length > 0}
                {...register('active')}
              />
              <span className={`slider round ${product.variations.length > 0 ? 'disabled' : ''}`} />
            </label>
          </td>
          <td className='col-setting' style={{ width: 110 }}>
            <div
              className='d-flex flex-column'
              style={{
                transform:
                  product.isPendingToPush || product.variations.length > 0
                    ? 'translateY(10px)'
                    : 'translateY(0)',
              }}
            >
              <button
                className='bg-transparent border-0 mb-1'
                onClick={() => onDeleteProduct(watchProduct?.id)}
              >
                <FaTrashAlt size={27} className={'btn-settings'} />
              </button>
              {(product.isPendingToPush || product.variations.length > 0) && (
                <p className='mb-0 cursor-pointer' onClick={onAddVariant}>
                  <small className='d-block btn-settings'>Add variation</small>
                </p>
              )}
            </div>
          </td>
        </tr>
        <tr className='p-0'>
          <td colSpan={tableHeaders?.length} className='border-top-0 pt-0'>
            <div className='d-flex align-items-center w-100'>
              {product?.isPendingToPush && (
                <span className='text-danger'>This product is pending for pushing to Lazada</span>
              )}
              {product?.item_id ? (
                <button
                  className={`btn btn-primary rounded-pill shadow-none w-auto text-white ms-auto ${
                    product.isPendingToPush || product.variations.length > 0 ? 'mb-4' : 'mb-0'
                  }`}
                  style={{ marginRight: '1rem' }}
                  onClick={handleSubmit(handleUpdateSingleProduct)}
                  disabled={isSubmitting || isRemovingLaz || isPushingLaz || loadingProduct}
                >
                  {isSubmitting ? 'Updating...' : 'Update'}
                </button>
              ) : (
                <button
                  className={`btn btn-primary rounded-pill shadow-none w-auto text-white ms-auto ${
                    product.isPendingToPush || product.variations.length > 0 ? 'mb-4' : 'mb-0'
                  }`}
                  style={{ marginRight: '1rem' }}
                  onClick={handleSubmit(handlePushToLazada)}
                  disabled={isSubmitting || isRemovingLaz || isPushingLaz}
                >
                  {isSubmitting ? 'Pushing...' : 'Push'}
                </button>
              )}
              {product.variations.length > 0 && (
                <div className={'wrap-toggle-sub-items'}>
                  <label
                    htmlFor={`toggle-${product.id}`}
                    className={'toggle-sub-items position-relative mx-auto'}
                  >
                    {product?.isOpenVariations ? (
                      <MdKeyboardArrowUp size={30} />
                    ) : (
                      <MdKeyboardArrowDown size={30} />
                    )}

                    <input
                      id={`toggle-${product.id}`}
                      type='checkbox'
                      onChange={(e) => onToggleVariation(e, product)}
                    />
                  </label>
                  <small className='mt-2'>
                    {product?.isOpenVariations ? 'Hide Variations' : 'Show Variations'}
                  </small>
                </div>
              )}
            </div>
          </td>
        </tr>

        {product.isOpenVariations && (
          <tr>
            <td className='bg-gray' colSpan={tableHeaders?.length}>
              {controlVariations && <Variations />}
            </td>
          </tr>
        )}

        {product?.isOpenModalEdit && <ModalEditProductLazada isOpen={product?.isOpenModalEdit} />}
      </>
    </ProductItemLazadaProvider>
  )
}

export default ProductLazadaItem
