import { isEmpty } from 'lodash'
import moment from 'moment'
import {
  HOST_ORDER_FULFILLED,
  OPTION_CURRENCY_SG,
  ORDER_CANCELED_STATUS,
  ORDER_CONFIRMED_STATUS,
  ORDER_DELIVERED_STATUS,
  ORDER_FAILED_DELIVERY_STATUS,
  ORDER_PACKED_STATUS,
  ORDER_PENDING_STATUS,
  ORDER_READY_TO_SHIP_PENDING_STATUS,
  ORDER_READY_TO_SHIP_STATUS,
  ORDER_SHIPPED_STATUS,
  ORDER_UNPAID_STATUS,
} from '../constant'
import { getTimeZoneHour } from '../function'

export const getStatusColor = (status) => {
  const colorMapping = {
    [ORDER_CANCELED_STATUS]: '#7c797a',
    [ORDER_UNPAID_STATUS]: '#ff735c',
    [ORDER_PENDING_STATUS]: '#ff735c',
    [ORDER_READY_TO_SHIP_STATUS]: '#27ae60',
    [ORDER_PACKED_STATUS]: '#2980b9',
    [HOST_ORDER_FULFILLED]: '#16a085',
  }
  return colorMapping[status] || '#7c797a'
}

export const mappingStatus = (status) => {
  const statusMapping = {
    [ORDER_UNPAID_STATUS]: 'Unpaid',
    [ORDER_PENDING_STATUS]: 'Pending',
    [ORDER_PACKED_STATUS]: 'Packaged',
    [ORDER_READY_TO_SHIP_PENDING_STATUS]: 'Ready to ship pending',
    [ORDER_READY_TO_SHIP_STATUS]: 'Ready to ship',
    [ORDER_SHIPPED_STATUS]: 'Shipped',
    [ORDER_FAILED_DELIVERY_STATUS]: 'Failed delivery',
    [ORDER_DELIVERED_STATUS]: 'Delivered',
    [ORDER_CONFIRMED_STATUS]: 'Confirmed',
    [ORDER_CANCELED_STATUS]: 'Canceled',
  }
  return statusMapping[status] || 'Unknown'
}

export const formatPrice = (price) => {
  if (typeof price === 'string') {
    return parseFloat(price?.replace(',', ''))
  }

  return price || 1000
}

export const formatNumberToCurrency = (
  number,
  countryDollar = 'en-US',
  options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
) => {
  if (!number && !countryDollar && isEmpty(options)) throw new Error('Missing params')

  const currency = new Intl.NumberFormat(countryDollar, options)
  return currency.format(number).replace('$', '').trim()
}

export const formatNumberToCurrencyHasDot = (inputText) => {
  const hasDot = inputText.includes('.')

  const sanitizedInput = inputText.replace(/[^\d.]/g, '')

  // Separate integerPart and decimalPart
  const [integerPart, decimalPart] = sanitizedInput.split('.')

  /**
   * Flow typing input:
   * input: 1000
   * format: 1,000
   * input: 1,000.
   * format: 1,000.
   * input: 1,000.15
   * format: 1,000.15
   */
  let formattedIntegerPart = ''

  if (integerPart && !hasDot) {
    formattedIntegerPart = formatNumberToCurrency(integerPart, 'en-SG', OPTION_CURRENCY_SG)
  }

  if (integerPart && hasDot) {
    formattedIntegerPart = `${formatNumberToCurrency(integerPart, 'en-SG', OPTION_CURRENCY_SG)}.`
  }

  const formattedDecimalPart = decimalPart ? `${decimalPart}` : ''

  const formattedInput = `${formattedIntegerPart}${formattedDecimalPart}`

  return formattedInput
}

export const formatError = (code) => {
  switch (code) {
    case 1001:
      return 'Account not exist'
    case 1003:
      return 'Account not exist'
    case 1005:
      return 'Account not exist'
    case 1007:
      return 'Account not found'
    case 1009:
      return 'Request in progress'
    case 1023:
      return 'Email is taken'
    default:
      return 'Some thing went wrong'
  }
}

export const formatDay = (timestamp) => {
  const d = new Date(timestamp * 1000)
  const date = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  return `${date}-${month}-${year}`
}

export const formatDate = ({ date, format = 'DD-MM-YYYY' }) => {
  if (!date) return
  return moment(date, 'DD-MM-YYYY').format(format)
}

export const formatGMT = () => {
  const timeZoneHour = getTimeZoneHour()
  return `GMT-${timeZoneHour}:00`
}

export const formatPlannedDate = (date) => moment(date).format('DD-MM-YYYY HH:mm')
