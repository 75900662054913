import LongText from '_components/LongText'
import MyGallery from '_components/MyGallery'
import { deleteMyShop } from '_redux/modules/shop'
import { removeSelectedShopLocalStorage } from '_utils/localData'
import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'

function MyShopItem({ item, loading }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedShop, myShops } = useSelector((state) => state.shop)

  const onDelete = async (shop) => {
    if (myShops.length === 1) {
      return addToast('You must have at least one shop', { appearance: 'error', autoDismiss: true })
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(deleteMyShop(shop.id))

          if (selectedShop?.id === shop.id) {
            removeSelectedShopLocalStorage()
          }

          Swal.fire('Deleted!', 'Your shop has been deleted.', 'success')
        }
      })
      .catch((error) => {
        Swal.fire('Error!', error.message, 'error')
      })
  }

  const styleExpired = {
    filter: 'grayscale(100%)',
    pointerEvents: 'none',
  }

  return (
    <>
      <div className='my-shops__item rounded-2 shadow-sm mb-3 overflow-hidden'>
        <Link
          to={`/${item.shopSlug}`}
          className='text-secondary'
          style={!item.active ? styleExpired : {}}
        >
          {item.logo.length === 0 && (
            <div
              style={{
                height: '100%',
                backgroundColor: 'rgb(83, 105, 128)',
              }}
            ></div>
          )}
          {item.logo.length > 0 && <MyGallery image={item.logo[0]} />}
        </Link>

        <div className='my-shops__item__info p-3'>
          <h3 className='my-shops__item__info__title text-black d-flex align-items-center justify-content-between mb-0'>
            <span>{item.shopName}</span>
            <button
              className='btn bg-transparent text-secondary w-auto shadow-none btn-action-shop'
              onClick={() => onDelete(item)}
              disabled={loading}
            >
              <FaTrash size={20} />
            </button>
          </h3>
          <div className='my-shops__item__info__description mb-0 text-wrap'>
            <LongText content={item.headline} limit={251} />
          </div>
        </div>
      </div>
    </>
  )
}

export default MyShopItem
