import { CheckoutContext } from '_contexts/CheckoutContext'
import { TURN_ON } from '_utils/constant'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'
import SelectPaymentMethod from './SelectPaymentMethod'

const DISCOUNT = 0

export default function FeesInfo({ subTotal, deliveryFee, pickupFee, transactionFees }) {
  const { shop } = useContext(CheckoutContext)
  const { orderDetailsByRef } = useSelector((state) => state.order)

  const totalPrice = subTotal + transactionFees + pickupFee + deliveryFee

  return (
    <div className='fees-info'>
      <SelectPaymentMethod />
      <div className='mb-1 d-flex align-items-center justify-content-between'>
        Sub-Total:
        <div>
          <CurrencyFormat
            value={subTotal || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />{' '}
          SGD
        </div>
      </div>
      {DISCOUNT > 0 && <p className='mb-1'>Discount: 0 </p>}
      <div className='mb-1 d-flex align-items-center justify-content-between'>
        {deliveryFee && deliveryFee > 0 ? (
          <>
            Delivery Fee:
            <div>
              <CurrencyFormat
                value={deliveryFee || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              SGD
            </div>
          </>
        ) : null}
      </div>
      <div className='mb-1 d-flex align-items-center justify-content-between'>
        {pickupFee && pickupFee > 0 ? (
          <>
            Pickup Fee:
            <div>
              <CurrencyFormat
                value={pickupFee || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              SGD
            </div>
          </>
        ) : null}
      </div>
      {orderDetailsByRef?.transactionFees && (
        <>
          <div className='text-danger mb-1 d-flex w-100 items-center justify-content-between'>
            This order will add transaction fee:
            <div>
              <CurrencyFormat
                className='text-end'
                value={orderDetailsByRef?.transactionFees || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              SGD
            </div>
          </div>
          <hr className='bg-black' />
        </>
      )}

      {shop?.transactionFeesStatus === TURN_ON && isEmpty(orderDetailsByRef) && (
        <>
          <div className='text-danger mb-1 d-flex w-100 items-center justify-content-between'>
            This order will add transaction fee:
            <div>
              <CurrencyFormat
                className='text-end'
                value={shop?.transactionFees || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              SGD
            </div>
          </div>
          <hr className='bg-black' />
        </>
      )}
      <div
        className='fw-bold d-flex align-items-center justify-content-between'
        style={{ color: '#15cdca' }}
      >
        Total:
        <div>
          <CurrencyFormat
            value={totalPrice || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />{' '}
          SGD
        </div>
      </div>
    </div>
  )
}
