export const GET_LIST_DELIVERY_BY_HOST = 'GET_LIST_DELIVERY_BY_HOST'
export const GET_LIST_DELIVERY_BY_HOST_SUCCESS = 'GET_LIST_DELIVERY_BY_HOST_SUCCESS'
export const GET_LIST_DELIVERY_BY_HOST_FAILURE = 'GET_LIST_DELIVERY_BY_HOST_FAILURE'
export const UPDATE_STATUS_DELIVERY = 'UPDATE_STATUS_DELIVERY'
export const UPDATE_STATUS_DELIVERY_SUCCESS = 'UPDATE_STATUS_DELIVERY_SUCCESS'
export const UPDATE_STATUS_DELIVERY_FAILURE = 'UPDATE_STATUS_DELIVERY_FAILURE'
export const GET_LIST_PICK_UP = 'GET_LIST_PICK_UP'
export const GET_LIST_PICK_UP_SUCCESS = 'GET_LIST_PICK_UP_SUCCESS'
export const GET_LIST_PICK_UP_FAILURE = 'GET_LIST_PICK_UP_FAILURE'
export const TOGGLE_FULFILLMENT_ACTIVE = 'TOGGLE_FULFILLMENT_ACTIVE'
export const TOGGLE_FULFILLMENT_ACTIVE_SUCCESS = 'TOGGLE_FULFILLMENT_ACTIVE_SUCCESS'
export const TOGGLE_FULFILLMENT_ACTIVE_FAILURE = 'TOGGLE_FULFILLMENT_ACTIVE_FAILURE'
export const ADD_PICK_UP = 'ADD_PICK_UP'
export const DELETE_PICK_UP = 'DELETE_PICK_UP'
export const UPDATE_PICK_UP = 'UPDATE_PICK_UP'
export const ADD_DELIVERY = 'ADD_DELIVERY'
export const DELETE_DELIVERY = 'DELETE_DELIVERY'
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY'
export const CLEAR_PICK_UP = 'CLEAR_PICK_UP'
export const CLEAR_DELIVERY = 'CLEAR_DELIVERY'
export const GET_LIST_DELIVERY = 'GET_LIST_DELIVERY'
export const GET_LIST_DELIVERY_SUCCESS = 'GET_LIST_DELIVERY_SUCCESS'
export const GET_LIST_DELIVERY_FAILURE = 'GET_LIST_DELIVERY_FAILURE'
export const SELECT_DELIVERY = 'SELECT_DELIVERY'
export const SELECT_FULFILLMENT_TYPE = 'SELECT_FULFILLMENT_TYPE'
export const SELECT_PICK_UP = 'SELECT_PICK_UP'
export const UPDATE_NEW_LIST_DELIVERY_WITH_SORT = 'UPDATE_NEW_LIST_DELIVERY_WITH_SORT'
export const CLEAR_SELECT_PICK_UP = 'CLEAR_SELECT_PICK_UP'
export const CLEAR_SELECT_DELIVERY = 'CLEAR_SELECT_DELIVERY'
export const CLEAR_LIST_DELIVERY_BY_HOST = 'CLEAR_LIST_DELIVERY_BY_HOST'
export const GET_LIST_PICKUP_BY_HOST = 'GET_LIST_PICKUP_BY_HOST'
export const GET_LIST_PICKUP_BY_HOST_SUCCESS = 'GET_LIST_PICKUP_BY_HOST_SUCCESS'
export const GET_LIST_PICKUP_BY_HOST_FAILURE = 'GET_LIST_PICKUP_BY_HOST_FAILURE'
export const CLEAR_LIST_PICKUP_BY_HOST = 'CLEAR_LIST_PICKUP_BY_HOST'
