import { VIEW_LIST } from '_utils/constant'
import classNames from 'classnames'
import React from 'react'

function SubItem(props) {
  const { listSubItem, isRequired, viewDefault } = props
  const handleOnchange = () => {}

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div
      className={classNames(
        'confirm__shop-product-item-info-select',
        viewDefault === VIEW_LIST ? 'ms-auto' : 'mx-auto w-100'
      )}
    >
      <div className='c-form c-form-select-list confirm__shop-product-item-info-select-item '>
        <div className='form-item'>
          <select
            className={`form-control rounded me-0 ${
              viewDefault === VIEW_LIST ? 'w-75 ms-auto px-0' : 'w-100'
            }`}
            onChange={(e) => handleOnchange(e)}
          >
            {isRequired ? '' : <option value={{ name: '', price: '' }}>Option</option>}
            {listSubItem?.map((item, i) => (
              <option key={i} value={JSON.stringify({ name: item.name, price: item.price })}>
                {capitalizeFirstLetter(item.name)} (+{item.price})
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

export default SubItem
