import { OrderRecordContext } from '_contexts/OrderRecordContext'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

function Search() {
  const { searchString, onChangeSearch } = useContext(OrderRecordContext)

  return (
    <div className='c-form c-form--search'>
      <div className='form-group' style={{ marginTop: 0 }}>
        <i className='icon-search'></i>
        <input
          className='form-control'
          placeholder='Search phone number & ref'
          type='text'
          value={searchString}
          onChange={(e) => onChangeSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

Search.propTypes = {
  onChangeSearch: PropTypes.func,
  searchString: PropTypes.string,
}

export default Search
