/* eslint-disable no-prototype-builtins */
/* eslint-disable no-confusing-arrow */
import NotFoundData from '_images/not-found-data.jpg'
// import LoadingOverlay from '_components/LoadingOverlay'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { updateOrderStatus } from '_redux/modules/order'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ModalInfoGiftRecipient from '../Common/ModalInfoGiftRecipient'
import ModalPayment from '../Common/ModalPayment'
import { headers } from '../headerTable'
import OrderRecordItem from './OrderRecordItem'

function ListOrderRecord() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [dataModal, setDataModal] = useState({})
  const [isModalGift, setIsModalGift] = useState(false)
  const [infoPersonGift, setInfoPersonGift] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(null)

  const { setSorting, isLoading, sortListOrders } = useContext(OrderRecordContext)

  const toggleTooltip = (itemRef) => setTooltipOpen(tooltipOpen === itemRef ? null : itemRef)

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const totalPriceEachOrder = (item) => {
    let deliveryFee = 0
    let pickUpFee = 0
    const transactionFees = item?.transactionFees || 0

    if (item.cashOnDeliveryPickup === 1) deliveryFee = 0
    if (item.hasOwnProperty('deliveryDetail')) deliveryFee = item.deliveryDetail?.deliveryFee
    if (item.hasOwnProperty('pickupDetail')) pickUpFee = item.pickupDetail?.pickupFee

    const subTotal = item.totalFee

    const calculateDeliveryFee = isNaN(deliveryFee) ? 0 : deliveryFee
    const calculatePickUpFee = isNaN(pickUpFee) ? 0 : pickUpFee

    return subTotal + calculateDeliveryFee + calculatePickUpFee + transactionFees
  }

  const handleInvalidPayment = async (ref, _status) => {
    const r = confirm('Are you sure this is invalid payment?')
    if (r === true) {
      try {
        const postStatus = {
          buyerStatus: _status,
          status: _status,
        }
        await dispatch(updateOrderStatus(postStatus, ref))

        addToast('Update successfully', { appearance: 'success', autoDismiss: true })
      } catch (error) {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        toggleModal()
      }
    }
  }

  if (isLoading) {
    return <div className='text-center text-info fw-bold f-16 my-5'>Loading...</div>
  }

  if (!isLoading && sortListOrders?.length === 0) {
    return (
      <div className='text-center'>
        <img src={NotFoundData} style={{ width: 200, height: 200 }} alt='' />
        <h6 className='text-secondary'>No Orders Found</h6>
      </div>
    )
  }

  return (
    <div className='list-order-record'>
      {!isEmpty(dataModal) && (
        <>
          <ModalPayment
            toggleModal={toggleModal}
            isModalOpen={isModalOpen}
            order={dataModal}
            price={totalPriceEachOrder(dataModal)}
            handleInvalidPayment={handleInvalidPayment}
          />
        </>
      )}

      {!isEmpty(infoPersonGift) && (
        <ModalInfoGiftRecipient
          isModalGift={isModalGift}
          setIsModalGift={setIsModalGift}
          infoPersonGift={infoPersonGift}
        />
      )}

      {!isLoading && (
        <TableHeaderWithSort
          headers={headers}
          defaultSortingField='shopOrderNo'
          defaultSortingOrder='asc'
          onSorting={(field, order, type) => setSorting({ field, order, type })}
          className='row p-3 bg-light table-header fw-bold'
          style={{ fontSize: '0.9vw' }}
        />
      )}

      {!isLoading &&
        sortListOrders.map((item, index) => (
          <Fragment key={`${item.id}-${index}`}>
            <OrderRecordItem
              item={item}
              index={index}
              setInfoPersonGift={setInfoPersonGift}
              setIsModalGift={setIsModalGift}
              isModalGift={isModalGift}
              totalPriceEachOrder={totalPriceEachOrder}
              toggleModal={toggleModal}
              setDataModal={setDataModal}
              tooltipOpen={tooltipOpen}
              toggleTooltip={toggleTooltip}
            />
          </Fragment>
        ))}
    </div>
  )
}

ListOrderRecord.propTypes = {
  listOrders: PropTypes.array,
  setSorting: PropTypes.func,
  setTimeRender: PropTypes.func,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
}

export default ListOrderRecord
