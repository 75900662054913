import React, { useEffect, useState } from 'react'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'

function Purchase({ product, watchAvailableStock }) {
  const [popoverOpen, setPopoverOpen] = useState(false)

  useEffect(() => {
    let timer
    if (popoverOpen) {
      timer = setTimeout(() => {
        setPopoverOpen(false)
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [popoverOpen])

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen)
  }

  const getAvailableStock = () => {
    if (!product.variations.length) {
      return watchAvailableStock - product.purchaseCount
    }

    return (
      product.listVariationProduct?.reduce((acc, variation) => {
        const variationStock = variation.quantity - variation.purchaseCount
        return acc + variationStock
      }, 0) || 0
    )
  }

  return (
    <div className='purchase-count cursor-pointer rounded text-center'>
      <span
        className='d-inline-block w-100 h-100'
        id={`purchase-count-${product.pid}`}
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
      >
        {product.purchaseCount}
      </span>
      <Popover
        placement='top'
        isOpen={popoverOpen}
        target={`purchase-count-${product.pid}`}
        toggle={togglePopover}
      >
        <PopoverHeader className='f-16'>Quantity Sold</PopoverHeader>
        <PopoverBody className='f-14'>
          You have sold <strong className='text-primary'>{product.purchaseCount}</strong> of this
          product. <br />
          Available stock:
          <strong className='text-primary'>{getAvailableStock()}</strong>
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default Purchase
