import React from 'react'
import { CiBoxList, CiGrid41 } from 'react-icons/ci'

export default function EventView({ view, handleViewChange }) {
  return (
    <div className='event-view d-flex align-items-center justify-content-end my-3 gap-1'>
      <button
        type='button'
        className={`border btn-view ${view === 'grid' ? 'active' : ''}`}
        onClick={() => handleViewChange('grid')}
      >
        <CiGrid41 />
      </button>
      <button
        type='button'
        className={`border btn-view ${view === 'list' ? 'active' : ''}`}
        onClick={() => handleViewChange('list')}
      >
        <CiBoxList />
      </button>
    </div>
  )
}
