import { getEventInShop } from '_redux/modules/shop'
import { flexibleSearch } from '_utils/function'
import { getCreateEventData, removeCreateEventData } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import CreateEvent from './Create'
import EventList from './components/EventList'
import './style.scss'

const PageSize = 10
const styleTitleOverlay = { marginTop: 100, color: '#5b5b5b', fontWeight: 500, fontSize: 24 }

export default function Events() {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const { addToast } = useToasts()
  const { selectedShop, eventsByShop, eventsByShopFiltered } = useSelector((state) => state.shop)
  const { currentPackage } = useSelector((state) => state.subscription)

  const [view, setView] = useState('grid')
  const [loading, setLoading] = useState(true)

  const [openBroadcastModal, setOpenBroadcastModal] = useState(false)
  const [message, setMessage] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')

  const listEventByShop = !isEmpty(eventsByShop) ? eventsByShop[selectedShop?.id] || [] : []
  const currentPackagePro = currentPackage !== (undefined || null) && currentPackage.price > 0

  useEffect(() => {
    const eventCreateData = getCreateEventData()

    if (eventCreateData) {
      removeCreateEventData()
    }
  }, [])

  useEffect(() => {
    let isMounted = true

    const eventView = localStorage.getItem('EVENT_VIEW')
    if (eventView) {
      setView(eventView)
    }

    const fetchData = async () => {
      await dispatch(getEventInShop(selectedShop?.id))
      if (isMounted) {
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [selectedShop?.id])

  const handleViewChange = (newView) => {
    localStorage.setItem('EVENT_VIEW', newView)
    setView(newView)
  }

  const listEventByShopFiltered = useMemo(() => {
    if (!search) return eventsByShopFiltered

    return eventsByShopFiltered.filter((event) => flexibleSearch(event.title, search))
  }, [search, JSON.stringify(eventsByShopFiltered)])

  let currentEvents = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return listEventByShopFiltered.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listEventByShopFiltered)])

  const isExistInCurrentEvents = listEventByShopFiltered?.some((event) =>
    currentEvents.some((currentEvent) => event.id === currentEvent.id)
  )

  if (search.length >= 1 && !isExistInCurrentEvents) {
    currentEvents = listEventByShopFiltered

    if (currentEvents.length >= PageSize && listEventByShopFiltered?.length >= PageSize) {
      setCurrentPage(1)
    }
  }

  const toggleBroadcastModal = () => setOpenBroadcastModal(!openBroadcastModal)

  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onCreateNotification = () => {
    setMessage('')
    setOpenBroadcastModal(null)

    addToast('Notification created successfully', {
      appearance: 'success',
      autoDismiss: true,
    })
  }

  return (
    <div className='page-events px-3 f-16 h-100 position-relative'>
      <p className='text-uppercase text-orange fw-bold f-24'>Events</p>

      {currentPackagePro ? (
        <div className='alert alert-warning' role='alert'>
          <p className='mb-0'>
            <b>Note:</b> Features below will not apply for event
          </p>
          <ul className='ps-3 mb-0'>
            <li>Gift recipient</li>
            <li>Privacy mode</li>
            <li>Cash on Delivery/Pickup</li>
            <li>Add transaction fees</li>
          </ul>
        </div>
      ) : null}

      <div
        className={`d-flex align-items-center justify-content-between ${
          currentPackagePro ? '' : 'opacity-50 pointer-events-none'
        }`}
      >
        <Link to={`${path}/create`} className='text-orange text-decoration-underline'>
          Create New Events
        </Link>
      </div>

      {loading && currentPackagePro && <div className='loader' style={{ marginTop: 50 }}></div>}

      {!loading && currentPackagePro && !listEventByShop.length ? (
        <div className='text-secondary h-100 d-flex flex-column align-items-center'>
          <p style={styleTitleOverlay}>There is currently no events organized</p>
          <small>
            Events are for the purpose of group-buy in Co-bee which should provide savings for
            members in Co-bee.
          </small>
        </div>
      ) : null}

      {!loading && !currentPackagePro ? (
        <div className='position-absolute top-0 start-0 bottom-0 end-0' style={{ zIndex: 50 }}>
          <div className='position-absolute bg-white w-100 h-100' style={{ zIndex: 0.9 }}></div>
          <div className='position-absolute text-center w-100 h-100' style={{ zIndex: 30 }}>
            <p style={{ ...styleTitleOverlay, color: 'red' }}>You are using free plan</p>
            <small>
              Upgrade to premium plan to unlock more features and get more benefits for your shop.
              <Link
                to={`/admin-console/${selectedShop?.shopSlug}/subscription`}
                className='alert-link text-orange text-decoration-underline ms-1'
              >
                Subscribe now
              </Link>
            </small>
          </div>
        </div>
      ) : null}

      {!loading && (
        <EventList
          view={view}
          handleViewChange={handleViewChange}
          currentEvents={currentEvents}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          listEventByShopFiltered={listEventByShopFiltered}
          openBroadcastModal={openBroadcastModal}
          setOpenBroadcastModal={setOpenBroadcastModal}
          message={message}
          onChangeMessage={onChangeMessage}
          onCreateNotification={onCreateNotification}
          search={search}
          setSearch={setSearch}
          toggleBroadcastModal={toggleBroadcastModal}
          PageSize={PageSize}
          currentPackagePro={currentPackagePro}
        />
      )}

      <Switch>
        <Route exact path={`${path}/create`}>
          <CreateEvent />
        </Route>
      </Switch>
    </div>
  )
}
