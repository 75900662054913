/* eslint-disable function-paren-newline */
import LoadingOverlay from '_components/LoadingOverlay'
import Pagination from '_components/Pagination'

import MyLogo from '_components/MyLogo'
import { clearShopAndShopDetail, getAllShopsOpening, setIdShop } from '_redux/modules/shop'
import { NO_DATA_SHOP_DETAIL } from '_utils/constant'
import { flexibleSearch, scrollToTop } from '_utils/function'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import SearchShop from './components/SearchShop'
import ShopList from './components/ShopList'
import './style.scss'

// 10 shops per page
const PageSize = 10

function ShopPage() {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [loadingAllShopsOpening, setLoadingAllShopsOpening] = useState(false)
  const [loadingDebounce, setLoadingDebounce] = useState(false)

  const { allShops } = useSelector((state) => state.shop)

  const fetchShopOpening = async () => {
    try {
      setLoadingAllShopsOpening(true)
      await dispatch(getAllShopsOpening())
    } catch (error) {
      setLoadingAllShopsOpening(false)
    }
  }

  useEffect(() => {
    let isMounted = true

    scrollToTop()
    dispatch(setIdShop(NO_DATA_SHOP_DETAIL))
    fetchShopOpening().then(() => {
      if (isMounted) {
        setLoadingAllShopsOpening(false)
      }
    })
    dispatch(clearShopAndShopDetail())

    return () => {
      isMounted = false
    }
  }, [])

  const shopNewCreate = useMemo(
    () => allShops?.sort((a, b) => b.createdAt - a.createdAt),
    [JSON.stringify(allShops)]
  )

  const [currentPage, setCurrentPage] = useState(1)

  const listShopFiltered = useMemo(
    () => shopNewCreate.filter((shop) => flexibleSearch(shop.shopName, search)),
    [search, JSON.stringify(shopNewCreate)]
  )

  let currentShops = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return listShopFiltered.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listShopFiltered)])

  const isExistInCurrentShops = listShopFiltered?.some((shop) =>
    currentShops.some((currentShop) => shop.id === currentShop.id)
  )

  if (search.length > 1 && !isExistInCurrentShops) {
    currentShops = listShopFiltered
    if (currentShops.length > PageSize && listShopFiltered?.length > PageSize) {
      setCurrentPage(1)
    }
  }

  return (
    <div className='container get-shop-opening px-3 pt-3' style={{ marginTop: 0 }}>
      <MyLogo />
      <Row className='align-items-center' style={{ margin: '30px auto 50px auto' }}>
        <Col sm='12'>
          <SearchShop
            setSearch={setSearch}
            setLoadingDebounce={setLoadingDebounce}
            styles={{ marginTop: 0 }}
          />
        </Col>
      </Row>

      {!loadingAllShopsOpening && !allShops?.length ? (
        <div className='text-center'>
          <h6>Not Found Shops Are Opening</h6>
        </div>
      ) : (
        <Row className='shop-list'>
          {loadingAllShopsOpening || loadingDebounce ? (
            <LoadingOverlay
              style={{
                marginTop: 50,
                height: '100%',
                marginBottom: 200,
              }}
            />
          ) : (
            <ShopList listShop={currentShops} search={search} />
          )}
        </Row>
      )}

      <Row>
        <Col sm='12'>
          <Pagination
            className='pagination-bar'
            currentPage={currentPage}
            totalCount={listShopFiltered.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ShopPage
