import LogoIcon from '_images/logo-co-hoot.png'
import React from 'react'
import './style.scss'

function MyLogo() {
  return (
    <div className='logo'>
      <img src={LogoIcon} alt='logo' />
      {/* <small>Support Local Business & Community</small> */}
    </div>
  )
}

export default MyLogo
