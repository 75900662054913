import axiosClient from './axiosClient'

const orderEventApi = {
  getListOrdersByEventId: (eventId) => {
    const url = `/list-shop-event-orders-by-event?eid=${eventId}`
    return axiosClient.get(url)
  },
  updateOrderEventStatus: (ref, data) => {
    const url = `/update-shop-event-order-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  listInvoicesCombinedPayment: (combinedPaymentRef) => {
    const url = `/list-shop-event-invoice-combined-payment-ref?combinedPaymentRef=${combinedPaymentRef}`
    return axiosClient.get(url)
  },
  updateShopEventInvoicesPlaceStatus: (data) => {
    const url = '/update-shop-event-invoices-place-status'
    return axiosClient.patch(url, data)
  }
}
export default orderEventApi
