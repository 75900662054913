import React from 'react'

function PickupName({ optionName, register, errors, index }) {
  return (
    <div className='mb-3 row'>
      <div className='col-12 d-flex flex-column'>
        <span>Option Name</span>
        <input
          id={`pickup-name-${index}`}
          type='text'
          defaultValue={optionName}
          {...register('optionPickupName')}
          className='form-control-sm w-100 border rounded'
        />
        <small className='errorMessage'>{errors?.optionPickupName?.message}</small>
      </div>
    </div>
  )
}

export default PickupName
