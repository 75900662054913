import classNames from 'classnames'
import React from 'react'

import cancelImage from '_images/cancel.png'

function RenderListImage({ listImageShop, handleOnRemoveImg, onUserImageUploaded, noImage }) {
  const isMobile = window.innerWidth < 768
  const colClass = isMobile
    ? 'col-4 col-sm-4 col-md-4 col-lg-4'
    : 'col-3 col-sm-3 col-md-3 col-lg-3'

  const handleUploadImage = (event) => {
    onUserImageUploaded(event)
  }

  const handleCancelImage = (index) => {
    handleOnRemoveImg(index)
  }

  const renderNoImage = () => (
    <div className='h-100 upload-image c-form d-flex p-3 align-items-center justify-content-center flex-column rounded-0 shadow-none w-100'>
      <img src={noImage} alt='' />
      <div className='upload-image-txt' style={{ display: 'contents' }}>
        <span>{listImageShop.length > 0 ? 'Upload more shop image' : 'Upload shop image'}</span>
      </div>
      <div className='u-file-upload__item'>
        <div className='u-file-upload__wrap'>
          <a className='c-upload preview-images-zone'>
            <input
              type='file'
              accept='image/*'
              className='inputFile'
              onChange={(e) => handleUploadImage(e)}
              readOnly
              multiple
            />
          </a>
        </div>
      </div>
    </div>
  )

  const renderImageItem = (item, index) => (
    <div key={item.url} className={classNames('col mb-2', colClass)}>
      <div className='upload-image h-100 w-100'>
        <img src={item.url} alt='' className='main-image w-100' />
        <img src={cancelImage} className='cancel-btn' onClick={() => handleCancelImage(index)} />
      </div>
    </div>
  )

  return (
    <>
      {listImageShop.length === 0 ? (
        <div className={`col ${listImageShop.length === 0 ? 'col-12' : colClass}`}>
          {renderNoImage()}
        </div>
      ) : (
        <>
          {listImageShop.map((item, index) => renderImageItem(item, index))}
          <div className={`col mb-2 ${colClass}`}>{renderNoImage()}</div>
        </>
      )}
    </>
  )
}

export default RenderListImage
