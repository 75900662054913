import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ModalBroadCast({
  modal,
  toggle,
  message,
  onChangeMessage,
  onCreateNotification,
}) {
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader className='modal-header-created' style={{ margin: '0 auto' }}>
        <span className='c-txt-gra'>Message to Broadcast?</span>
      </ModalHeader>
      <ModalBody>
        <div className='c-form'>
          <div className='form-group'>
            <input
              type='text'
              value={message}
              className='form-control form-control--radius'
              onChange={onChangeMessage}
            ></input>
          </div>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
        <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
          <button className='btn btn--solid btn--solid04' onClick={toggle}>
            CANCEL
          </button>
        </div>
        <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
          <button className='btn btn--org btn--org02' onClick={onCreateNotification}>
            OK
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
