/* eslint-disable no-useless-escape */
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { getDataShopDetail } from '_utils/localData'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody } from 'reactstrap'

function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

const ExternalLinkModal = (props) => {
  const { modal, toggle, value, setValue } = props
  const shop = getDataShopDetail()
  const { addToast } = useToasts()
  const [linkError, setLinkError] = useState(false)
  const externalLinkRef = useRef('')
  const nameExternalLinkRef = useRef('')
  const dispatch = useDispatch()

  const handleAddLinkExternal = () => {
    if (nameExternalLinkRef.current.value !== '' && externalLinkRef.current.value !== '') {
      const externalLink = {
        name: nameExternalLinkRef.current.value,
        value: externalLinkRef.current.value,
      }
      value.push(externalLink)
      setValue(value)

      dispatch(selectShopAndSelectShopDetail({ ...shop, externalLink: value }))
      toggle(!modal)
    } else {
      addToast('Invalid name and link url', { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnChange = (e) => {
    if (validURL(e.target.value)) {
      setLinkError(false)
    } else if (e.target.value === '') {
      setLinkError(false)
    } else {
      setLinkError(true)
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered className='add-external-link-modal'>
      <ModalBody>
        <div className='container p-0'>
          <h5 className='text-center'>Add external Link</h5>
          <div className='form-group row'>
            <p className='c-txt-org p-0 mb-1'>Field Name:</p>
            <input className='form-control-sm border' ref={nameExternalLinkRef} />
          </div>
          <div className='form-group row'>
            <p className='c-txt-org p-0 mb-1'>External Link:</p>
            <input
              ref={externalLinkRef}
              className='form-control-sm border'
              onChange={(e) => handleOnChange(e)}
            />
            <small className='errorMessage'>{linkError ? 'Invalid external link' : ''}</small>
          </div>
          <div className='row mt-3'>
            <div className='col-12 m-auto'>
              <button
                className='btn btn-submit mx-0 w-100'
                onClick={handleAddLinkExternal}
                disabled={linkError}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ExternalLinkModal
