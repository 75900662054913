import { CheckoutContext } from '_contexts/CheckoutContext'
import { SELF_PICK_UP } from '_utils/constant'
import { isEmpty } from 'lodash'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Calendar from './Calendar'
import RenderInfo from './RenderInfo'

function TabPickup() {
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const { activeTab, register, errors, user, shop } = useContext(CheckoutContext)
  const { listPickUp, selectedPickUp } = useSelector((state) => state.fulfillment)

  const isOwnerShop = user?.shopLinks?.some((item) => item === shop?.shopLink)

  return (
    <div className='pickup-location'>
      {!listPickUp.length ? (
        <div className='h-100 w-100 d-flex flex-column align-items-center justify-content-center'>
          <strong>Currently shop has not pick-up</strong>
          {isOwnerShop && <Link to='/admin-console/fulfillment'>Create pickup option</Link>}
        </div>
      ) : null}

      {listPickUp.length ? (
        <>
          <select
            name='pickup'
            id='pickup'
            className='form-select-sm border-0 w-100 cursor-pointer mb-3'
            {...register('pickupOption')}
          >
            {listPickUp.map((pickup) => (
              <option key={pickup.id} value={JSON.stringify(pickup)}>
                {pickup.optionName}
              </option>
            ))}
          </select>
          {errors.pickupOption && (
            <small className='text-danger text-left'>{errors.pickupOption.message}</small>
          )}

          {activeTab === SELF_PICK_UP && !isEmpty(selectedPickUp) && (
            <>
              <RenderInfo
                fee={selectedPickUp.pickupFee}
                instructions={selectedPickUp.instructions}
                isCheckPublicHoliday={selectedPickUp.isCheckPublicHoliday}
                type={SELF_PICK_UP}
              />
              <Calendar
                datesOfWeek={selectedPickUp.datesOfWeek}
                timeSlot={selectedPickUp.timeSlot}
                startTime={selectedPickUp?.pickupStartTime}
                endTime={selectedPickUp?.pickupEndTime}
                activeTab={activeTab}
                type={SELF_PICK_UP}
                isOpenTimePicker={isOpenTimePicker}
                setIsOpenTimePicker={setIsOpenTimePicker}
              />
            </>
          )}

          {selectedPickUp?.pickupAddress && (
            <>
              <span className='fw-semibold'>Pickup Address:</span>
              <p className='fw-semibold text-dark'>{selectedPickUp?.pickupAddress}</p>
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default TabPickup
