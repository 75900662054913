import React from 'react'

function DeliveryInstruction({
  checkedInstruction,
  handleChangeInstruction,
  instructions,
  register,
  errors,
}) {
  return (
    <div className='row align-items-center mb-3'>
      <div className='col-5'>
        <span className='d-inline-block'>Add Instruction</span>
      </div>
      <div className='col-7'>
        <div className='form-check form-switch'>
          <input
            className='form-check-input mt-0 check__from'
            type='checkbox'
            checked={checkedInstruction}
            role='switch'
            onChange={(e) => handleChangeInstruction(e)}
          />
        </div>
      </div>
      {checkedInstruction && (
        <>
          <div className='w-100'></div>
          <div className='col-12 mt-2'>
            <textarea
              type='text'
              className='form-control-sm border w-100'
              placeholder='Please enter your instruction'
              defaultValue={instructions}
              {...register('instruction')}
            />
            <small className='errorMessage'>{errors?.instruction?.message}</small>
          </div>
        </>
      )}
    </div>
  )
}

export default DeliveryInstruction
