import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'

import reportApi from '_api/report'
import userApi from '_api/user'
import MyPasswordCheckList from '_components/MyPasswordCheckList'

const ChangePassword = () => {
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)

  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidCheckList, setIsValidCheckList] = useState(false)

  const clearState = () => {
    setErrorMessage('')
  }

  const onSubmit = () => {
    if (isValidCheckList) {
      setIsLoading(true)
      userApi
        .changePassword({ newPassword: password, oldPassword: 'aaaa' }) // oldPassword used to cheat API. However, the server will ignore this variable
        .then(({ msgCode }) => {
          if (msgCode % 100 === 0) {
            addToast('Saved Successfully', { appearance: 'success', autoDismiss: true })
            clearState()
          }
          setIsLoading(false)
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `${msgCode} - ${msgResp}` })
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
          setIsLoading(false)
        })
    }
  }

  return (
    <React.Fragment>
      <div className='c-txt-14 text-center text-underline'>Update Password</div>
      {errorMessage && (
        <small className='errorMessage' style={{ color: '#ff9900' }}>
          {errorMessage}
        </small>
      )}
      <div className='c-form c-form--email'>
        <div className='form-group'>
          <div className='c-txt-org'>Change Password</div>
          <input
            type='password'
            className='form-control'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <div className='c-txt-gray'>Confirm Password</div>
          <input
            type='password'
            className='form-control'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {/* <Password pinLength={4} onPinChanged={onPinChangedSePIN} pin={secondPin} /> */}
        </div>

        <MyPasswordCheckList
          password={password}
          confirmPassword={confirmPassword}
          setIsValidChecklist={setIsValidCheckList}
          className={'mt-3'}
        />
      </div>
      <div className='row row--bot align-self-end mt-3'>
        <div className='col-6'>
          <button className='btn btn--solid btn--solid03 w-100' onClick={() => {}}>
            CANCEL
          </button>
        </div>
        <div className='col-6'>
          <button
            className='btn btn--main w-100'
            onClick={onSubmit}
            disabled={isLoading || isValidCheckList === false}
          >
            {isLoading ? <Spinner color='light' size='sm' /> : 'SAVE'}
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
