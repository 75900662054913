// hãy giúp tôi tạo ra một component dành cho văn bản, mô tả:
// khi người dùng nhấp vào văn bản đó thì sẽ copy văn bản đó
// và bắn ra toast thông báo "copied"
// component này sẽ hiển thị icon copy thừ viện "react-icons"
import { MdContentCopy } from 'react-icons/md'
import { useToasts } from 'react-toast-notifications'

const CopyClipboardText = ({ text }) => {
  const { addToast } = useToasts()

  const onCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      addToast('Copied', { appearance: 'success', autoDismiss: true })
    })

    return true
  }

  return (
    <>
      <span className='d-none'>Copied</span>
      <button
        type='button'
        className='bg-transparent border-0 d-flex align-items-center justify-content-between'
        onClick={onCopy}
      >
        <span className='me-1 fw-bold'>{text}</span>
        <MdContentCopy />
      </button>
    </>
  )
}

export default CopyClipboardText
