/* eslint-disable max-len */
// import virtualShopApi from '_api/virtualShop'
import virtualShopApi from '_api/virtualShop'
import LoadingOverlay from '_components/LoadingOverlay'
import { updateNewListProduct } from '_redux/modules/product'
import {
  clearShopAndShopDetail,
  pushToMyShops,
  selectShopAndSelectShopDetail,
} from '_redux/modules/shop'
import { VIEW_GRID, VIEW_LIST } from '_utils/constant'
import {
  getCreateShopData,
  getUserToken,
  getViewDefault,
  removeCategoryName,
  removeSelectedShopLocalStorage,
  setCreateShopData,
  setSelectedShopLocalStorage,
  setViewDefault as setViewDefaultLocal,
} from '_utils/localData'
import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Button, Spinner } from 'reactstrap'
import Banner from './components/Banner'
import SingleListProduct from './components/SingleListProduct'
import ViewDefault from './components/ViewDefault'
import './style.scss'

export const QuantityProductContext = createContext()

function ConfirmShop() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()
  const dataCreateShop = getCreateShopData()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const userToken = getUserToken()
  const [viewDefault, setViewDefault] = useState(getViewDefault() || VIEW_GRID)
  const [quantityProduct, setQuantityProduct] = useState(0)
  const listImages = dataCreateShop?.logo?.map((item) => ({ original: item })) || []
  const [loading, setLoading] = useState(false)

  const handleCreateShop = async () => {
    try {
      if (!userToken) {
        history.push('/virtual-shop/sign-up', { from: '/virtual-shop/confirm-shop' })
        return
      }

      setIsSubmitting(true)
      dispatch(clearShopAndShopDetail())
      removeSelectedShopLocalStorage()
      const products = dataCreateShop?.products.map((item) => {
        const convertDataOfSubItem = item?.items.map((listItem) => ({
          ...listItem,
          price: parseFloat(listItem.price.replace(/[^\d.]/g, '')),
          subItems: listItem.subItems?.map((sub) => ({
            list: sub.list.map((option) => ({
              ...option,
              price: parseFloat(option.price.replace(/[^\d.]/g, '')),
            })),
            type: sub.type,
            required: sub.required ? 1 : 0,
          })),
        }))

        return {
          ...item,
          items: convertDataOfSubItem,
        }
      })

      const postData = {
        ...dataCreateShop,
        products,
        productView: viewDefault === VIEW_GRID ? VIEW_GRID : VIEW_LIST,
      }

      const { msgResp } = await virtualShopApi.createVirtualShop(postData)

      const convertedShop = { ...msgResp, products: msgResp.products[0].items, viewDefault }
      dispatch(pushToMyShops(convertedShop))
      setSelectedShopLocalStorage(convertedShop)
      dispatch(updateNewListProduct(convertedShop.products))
      dispatch(selectShopAndSelectShopDetail(convertedShop))
      setIsSubmitting(false)
      removeCategoryName()

      setCreateShopData({
        ...dataCreateShop,
        isShopCompleted: true,
      })
      history.push(`/${dataCreateShop.shopSlug}`)
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    if (!dataCreateShop?.products?.length) {
      history.push('/virtual-shop/add-items')
    }

    const previousURL = history.location.state?.from

    if (
      dataCreateShop &&
      previousURL &&
      (previousURL.includes('/sign-up') || previousURL.includes('/sign-in'))
    ) {
      setLoading(true)
      const fetchData = async () => {
        await handleCreateShop()
      }
      fetchData()
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [JSON.stringify(dataCreateShop)])

  const handleSetViewDefault = (value) => {
    setViewDefault(value)
    setViewDefaultLocal(value)
  }

  const onBack = () => history.push('/virtual-shop/add-items')

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
      }}
    >
      <div
        className={`container bg-white confirm__shop ${
          window.innerWidth > 576 ? 'w-75 mx-auto' : ''
        }`}
        style={{ marginTop: 0 }}
      >
        <button
          onClick={onBack}
          className='btn-back shadow rounded-circle d-flex align-items-center justify-content-center border-primary bg-primary'
          style={{
            width: '40px',
            height: '40px',
          }}
        >
          <BiArrowBack className='text-white' />
        </button>
        <Banner dataCreateShop={dataCreateShop} listImages={listImages} />

        <ViewDefault viewDefault={viewDefault} handleSetViewDefault={handleSetViewDefault} />

        <QuantityProductContext.Provider
          value={{ quantityProduct, setQuantityProduct, viewDefault }}
        >
          <SingleListProduct products={dataCreateShop?.products} viewDefault={viewDefault} />
        </QuantityProductContext.Provider>

        <Button
          onClick={handleCreateShop}
          className='btn-primary shadow-none f-16 text-uppercase d-flex align-items-center btn-publish justify-content-center w-100 mt-3 fw-semibold'
          style={{ height: '50px' }}
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner color='info' size='sm' /> : 'Publish'}
        </Button>
      </div>
    </div>
  )
}

ConfirmShop.propTypes = {
  shopDetail: PropTypes.object,
}

export default ConfirmShop
