import React from 'react'
import { IoStorefrontOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import MyShopItem from './MyShopItem'

function ListShops({ myShops }) {
  return (
    <>
      <div className='d-flex align-items-center justify-content-end my-3'>
        <Link to={'/virtual-shop/create'} className='text-decoration-underline f-14'>
          Create Shop
          <IoStorefrontOutline className='ms-1' size={20} />
        </Link>
      </div>
      <div className='my-shops__list'>
        {myShops.length ? (
          myShops.map((item, index) => <MyShopItem key={index} item={item} />)
        ) : (
          <div className='text-center mt-5'>
            <p className='mb-0'>You have not created any shop yet.</p>
          </div>
        )}
      </div>
    </>
  )
}

export default ListShops
