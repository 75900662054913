/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import HeaderAlert from '_components/HeaderAlert'
import TextComponent from '_components/TextComponent'
import useLogout from '_hooks/useLogout'
import { readNotification } from '_redux/modules/notification'
import { setCurrentPackage } from '_redux/modules/subscription'
import { getNotifications } from '_redux/modules/user'
import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import { getUserInfo, getUserToken } from '_utils/localData'
import { isEmpty } from 'lodash'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { AiFillShop, AiOutlineHistory } from 'react-icons/ai'
import { BiLogOut, BiSolidPurchaseTag } from 'react-icons/bi'
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { MdAccountCircle } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import './style.scss'

const Header = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [alertType, setAlertType] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const user = getUserInfo()
  const userToken = getUserToken()

  const { selectedShop } = useSelector((state) => state.shop)
  const { listNotification } = useSelector((state) => state.notification)

  const today = moment()
  const { currentPackage } = useSelector((state) => state.subscription)
  const isOpenAlert = JSON.parse(localStorage.getItem('isOpenAlert'))

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('isOpenAlert', 'true')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  // test for 2/10/2023
  // const test = 1696230613
  useEffect(() => {
    if (currentPackage) {
      const daysRemaining = moment(moment.unix(currentPackage.expireTimestamp)).diff(today, 'days')
      const subscriptionIsExpired = currentPackage.expireTimestamp
        ? today.isAfter(moment.unix(currentPackage.expireTimestamp))
        : false

      // notify before 7 days
      if (daysRemaining > 0 && daysRemaining <= 7) {
        setAlertType('approaching-expiration')
        if (isOpenAlert) {
          setOpenAlert(true)
        }
      } else if (subscriptionIsExpired && !currentPackage?.closeAlert) {
        setAlertType('expired')
        if (isOpenAlert) {
          setOpenAlert(true)
        }
      } else {
        setAlertType('')
        setOpenAlert(false)
      }
    }

    return () => {
      setOpenAlert(false)
    }
  }, [!isEmpty(currentPackage)])

  useEffect(() => {
    if (!isEmpty(selectedShop)) {
      dispatch(getNotifications)
    }
  }, [JSON.stringify(selectedShop)])

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const handleOnClick = (link) => {
    if (link !== '') {
      window.open(link)
    } else {
      addToast('This Link is not exist', { appearance: 'error', autoDismiss: true })
    }
  }

  const handleReadNotification = async () => {
    if (listNotification.some((item) => item.new)) {
      await dispatch(readNotification())
    }
  }

  const onCloseAlert = () => {
    dispatch(
      setCurrentPackage({
        ...currentPackage,
        closeAlert: true,
      })
    )
    setOpenAlert(false)
    localStorage.setItem('isOpenAlert', 'false')
  }

  return (
    <div className='header align-self-start header-co-bee-desktop'>
      <div className='container-fluid header-co-bee-desktop-box' style={{ height: '56px' }}>
        <Link to={selectedShop?.shopSlug ? `/${selectedShop?.shopSlug}` : '/virtual-shop/list'}>
          <div className='main-logo text-left pb-1'>
            <h4
              className='ttl-grp'
              style={{
                display: selectedShop ? 'block' : 'none',
              }}
            >
              {selectedShop?.shopName}
            </h4>
            <TextComponent tag={selectedShop ? 'small' : 'h6'} className='ttl-company text-white'>
              {' '}
              Powered by Co-Hoot
            </TextComponent>
          </div>
        </Link>

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret
            className='bg-transparent border-0 shadow-none'
            style={{ background: 'transparent' }}
          >
            <div className='position-relative'>
              <div className='avatar-top__img'>
                <img
                  id='userAvatar'
                  src={user?.photoUrl || process.env.REACT_APP_AVATAR_URL + user?.name}
                  alt=''
                />
              </div>
              {listNotification.some((item) => item.new) && (
                <span
                  className='position-absolute start-100 bg-danger border border-light rounded-circle'
                  style={{
                    width: '15px',
                    height: '15px',
                    top: 3,
                    transform: 'translate(-80%, -50%)',
                  }}
                >
                  <span className='visually-hidden'>New alerts</span>
                </span>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-avatar' style={{ fontSize: 16, left: 'inherit' }}>
            <DropdownItem header className='px-3 py-2'>
              Hello, {user?.name}
            </DropdownItem>
            <Link
              to='/admin-console/setting/account/profile'
              onClick={handleReadNotification}
              className='text-secondary w-100 h-100 d-inline-block'
            >
              <DropdownItem className='px-3 py-2'>
                <MdAccountCircle className='me-2' />
                <span>Account</span>
              </DropdownItem>
            </Link>
            <DropdownItem divider className='my-0' />
            <Link to='/virtual-shop/list'>
              <DropdownItem className='px-3 py-2'>
                <AiFillShop className='me-2' />
                <span>Co-Hoot shops</span>
              </DropdownItem>
            </Link>
            <Link to='/virtual-shop/my-purchase'>
              <DropdownItem className='px-3 py-2'>
                <BiSolidPurchaseTag className='me-2' />
                <span>My Purchase</span>
              </DropdownItem>
            </Link>
            <Link to='/virtual-shop/payment-history'>
              <DropdownItem className='px-3 py-2'>
                <AiOutlineHistory className='me-2' />
                <span>Payment History</span>
              </DropdownItem>
            </Link>
            <DropdownItem divider className='my-0' />
            <div onClick={useLogout}>
              <DropdownItem className='px-3 py-2'>
                <BiLogOut className='me-2' />
                <span>Log out</span>
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className='nav-menu'>
        <div className='container' style={{ margin: 0 }}>
          <div className='social-media d-flex align-items-center'>
            <a
              className='cursor-pointer'
              onClick={() =>
                handleOnClick(
                  `https://wa.me/${SINGAPORE_CODE_NO_ADD}${selectedShop?.linkInstagram}`
                )
              }
            >
              <BsWhatsapp size={24} color='#A6DFDE' />
            </a>
            <a
              className='cursor-pointer'
              onClick={() => handleOnClick(selectedShop?.linkInstagram)}
            >
              <BsInstagram size={24} color='#A6DFDE' />
            </a>
            <a className='cursor-pointer' onClick={() => handleOnClick(selectedShop?.linkFacebook)}>
              <BsFacebook size={24} color='#A6DFDE' />
            </a>
          </div>
        </div>
      </div>
      {openAlert ? (
        <HeaderAlert
          tag='div'
          className='alert alert-danger d-flex align-items-center justify-content-between rounded-0 mb-0'
          style={{ zIndex: 10 }}
          open={userToken && openAlert}
          onClose={onCloseAlert}
        >
          {alertType === 'expired' ? (
            <p className='mb-0 f-14 mx-auto'>
              Your subscription has expired. Please renew it to continue using our services.
              <Link to='/admin-console/subscription' className='text-decoration-underline mx-1'>
                Click here
              </Link>
              to renew.
            </p>
          ) : alertType === 'approaching-expiration' ? (
            <p className='mb-0 f-14 mx-auto'>
              Your subscription expires on{' '}
              <b>{moment.unix(currentPackage?.expireTimestamp).format('DD/MM/YYYY')}</b>. Renew now
              to continue
            </p>
          ) : null}
        </HeaderAlert>
      ) : null}
    </div>
  )
}

export default withRouter(Header)
