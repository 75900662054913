/* eslint-disable function-paren-newline */
import PropTypes from 'prop-types'
import ShopItem from './ShopItem'

function ShopList({ listShop, search }) {
  if (search.length === 0) {
    return (
      <div className='shop-list'>
        {listShop.map((shop) => (
          <div className='mb-3' key={shop.id}>
            <ShopItem shop={shop} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='shop-list'>
      {listShop === undefined || listShop.length === 0 ? (
        <div className='text-center my-5'>
          <p className='fw-bold fs-3'>No Shop Found</p>
        </div>
      ) : (
        listShop.map((shop) => (
          <div className='mb-3' key={shop.id}>
            <ShopItem shop={shop} />
          </div>
        ))
      )}
    </div>
  )
}

ShopList.propTypes = {
  listShop: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
}

export default ShopList
