import React from 'react'
import { FaWhatsapp } from 'react-icons/fa6'
// import { SINGAPORE_CODE_NO_ADD } from '../utils/constant'
// import { getDataShopDetail } from '../utils/localData'

function ButtonWhatsApp({ style, className }) {
  // const shopDetail = getDataShopDetail()
  return (
    <div style={style} className={className}>
      <a
        // href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${shopDetail?.whatsApp || '123456789'}`}
        href={'https://chat.whatsapp.com/GHzGkuAuIlJChgIiK8g11r'}
        className='text-white'
        target='_blank'
        rel='noreferrer'
      >
        <FaWhatsapp style={{ transform: 'translateY(-3px)' }} size={22} />
      </a>
    </div>
  )
}

export default ButtonWhatsApp
