import axiosClient from './axiosClient'

const serviceApi = {
  updatePaymentScheduleEvent: (id, data) => {
    const url = `/update-payment-schedule-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  updatePaymentSubdomain: (id, data) => {
    const url = `/update-payment-domain-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  updatePaymentPremium: (id, data) => {
    const url = `/update-payment-premium-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  purchasePaymentScheduleEvent: (userId, data) => {
    const url = `/create-schedule-feature?userId=${userId}`
    return axiosClient.post(url, data)
  },
  purchasePaymentPremium: (userId, data) => {
    const url = `/create-premium-feature?userId=${userId}`
    return axiosClient.post(url, data)
  },
}

export default serviceApi
