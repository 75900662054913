import { useWindowSize } from '_hooks/useWindowSize'
import React from 'react'
import { AiFillShop } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import { clearCart, clearOrderDetailsByRef } from '../redux/modules/order'
import { removeCartCheckout, removeCartOrderDetailsByRef } from '../utils/localData'

function ButtonAdminConsole({ style }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { width } = useWindowSize()
  const { addToast } = useToasts()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)
  const { myShops } = useSelector((state) => state.shop)

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    removeCartOrderDetailsByRef()
    removeCartCheckout()
    dispatch(clearCart())
  }

  const onDirect = () => {
    if (width <= 768) {
      return addToast('You cannot login to admin console on mobile device.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    const myFirstShop = myShops.length > 0 ? myShops[0] : null

    if (cart?.orderList?.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          onClearCart()
          setTimeout(() => {
            history.push(`/admin-console/${myFirstShop?.shopSlug}/setting/general`)
          }, 500)
        }
      })
      return
    }

    if (orderDetailsByRef?.ref) {
      onClearCart()
    }

    localStorage.setItem('isAdminConsole', true)
    return history.push(`/admin-console/${myFirstShop?.shopSlug}/setting/general`)
  }

  return (
    <button
      className='btn btn-primary float-button w-auto rounded btn-admin-console'
      style={style}
      onClick={onDirect}
    >
      <AiFillShop size={18} className='me-1' />
      <span>Admin Console</span>
    </button>
  )
}

export default ButtonAdminConsole
