import { getNotifications } from '_redux/modules/user'
import { getUserToken } from '_utils/localData'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FeedBack from './components/FeedBack'
import NotificationList from './components/NotificationList'

const Notifications = () => {
  const dispatch = useDispatch()
  const { notifications } = useSelector((state) => state.user)
  const userToken = getUserToken()

  useEffect(() => {
    if (userToken) {
      dispatch(getNotifications)
    }
  }, [userToken])

  return (
    <div className='notify-container'>
      <NotificationList notifications={notifications} />
      <div className='row row--bot align-self-end'>
        <FeedBack />
      </div>
    </div>
  )
}

export default Notifications
