// EVENT FEATURE
export const EVENTS_BY_SHOP = 'EVENTS_BY_SHOP'
export const PAYMENT_STATUS_CANCEL_BY_USER = -2
export const PAYMENT_STATUS_CANCEL_BY_COBEE = -1
export const PAYMENT_STATUS_UNPAID = 1
export const PAYMENT_STATUS_PAID = 2
export const PAYMENT_STATUS_VERIFY = 3
export const CREATE_EVENT_DATA = 'CREATE_EVENT_DATA'
export const DOMAIN = window.location.hostname
export const GROUP_IS_HOST = 1
export const PAYMENT_SERVICE_SCHEDULE_PRICE = 30
export const STATUS_NOT_USE = 0
export const STATUS_PENDING = 1
export const STATUS_PAID = 2
export const DEFAULT_PRODUCT_EVENT = {
  pid: '',
  name: '',
  description: null,
  price: 0,
  maxQuantity: 1000,
  defaultQuantity: 0,
  limitPerOrder: 1000,
  subItems: [],
  photoUrls: [],
  minOrderQty: 1,
}
export const LIST_TIME = [
  { text: '1AM', value: 1, id: 1 },
  { text: '2AM', value: 2, id: 2 },
  { text: '3AM', value: 3, id: 3 },
  { text: '4AM', value: 4, id: 4 },
  { text: '5AM', value: 5, id: 5 },
  { text: '6AM', value: 6, id: 6 },
  { text: '7AM', value: 7, id: 7 },
  { text: '8AM', value: 8, id: 8 },
  { text: '9AM', value: 9, id: 9 },
  { text: '10AM', value: 10, id: 10 },
  { text: '11AM', value: 11, id: 11 },
  { text: '12PM', value: 12, id: 12 },
  { text: '1PM', value: 13, id: 13 },
  { text: '2PM', value: 14, id: 14 },
  { text: '3PM', value: 15, id: 15 },
  { text: '4PM', value: 16, id: 16 },
  { text: '5PM', value: 17, id: 17 },
  { text: '6PM', value: 18, id: 18 },
  { text: '7PM', value: 19, id: 19 },
  { text: '8PM', value: 20, id: 20 },
  { text: '9PM', value: 21, id: 21 },
  { text: '10PM', value: 22, id: 22 },
  { text: '11PM', value: 23, id: 23 },
  { text: '12AM', value: 24, id: 24 },
]
export const DEFAULT_OPTION = {
  name: '',
  price: 0,
}
export const DEFAULT_SUB_ITEM = {
  type: '',
  required: 0,
  list: [DEFAULT_OPTION],
}

// LAZADA FEATURE
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_FAILED = -3
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_CANCEL_BY_USER = -2
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_CANCEL_BY_ADMIN = -1
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID = 1
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_PAID = 2
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_VERIFY = 3

export const LAZADA_FEATURE_PAYMENT_STATUS_APPROVED = 2
export const LAZADA_FEATURE_PAYMENT_STATUS_PENDING = 1
export const LAZADA_FEATURE_PAYMENT_STATUS_CANCEL = 0
export const LAZADA_FEATURE_PAYMENT_STATUS_REFUND = -1
export const LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED = -2

// SUBSCRIPTION STATUS
export const SUBSCRIPTION_STATUS_PENDING = 1
export const SUBSCRIPTION_STATUS_APPROVED = 2
export const SUBSCRIPTION_STATUS_CANCEL = -3
export const SUBSCRIPTION_STATUS_REFUND = -1
export const SUBSCRIPTION_STATUS_REFUNDED = -2
export const SUBSCRIPTION_STATUS_EXPIRED = 0
export const SUBSCRIPTION_STATUS_FAIL = -4
export const SUBSCRIPTION_STATUS_UNPAID = -5

// SUBSCRIPTION PAYMENT STATUS
export const SUBSCRIPTION_PAYMENT_STATUS_FAILED = -3
export const SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_HOST = -2
export const SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN = -1
export const SUBSCRIPTION_PAYMENT_STATUS_UNPAID = 1
export const SUBSCRIPTION_PAYMENT_STATUS_PAID = 2
export const SUBSCRIPTION_PAYMENT_STATUS_VERIFY = 3

export const LAZADA_PACKAGE = 'LAZADA_PACKAGE'
export const CURRENT_LAZADA_PACKAGE = 'CURRENT_LAZADA_PACKAGE'

// PACKAGE
export const PACKAGE_LAZADA = 2
export const PACKAGE_SCHEDULE = 3
export const PACKAGE_SUBDOMAIN = 4
export const PACKAGE_BASIC = 1
export const PACKAGE_SUBSCRIPTION = 1

export const PACKAGE_SUBSCRIPTION_BASIC = 1
export const PACKAGE_SUBSCRIPTION_PROFESSIONAL = 2
export const PACKAGE_SUBSCRIPTION_FOUNDER = 3
export const CURRENT_PACKAGE = 'CURRENT_PACKAGE'
export const LIST_PACKAGE_SUBSCRIPTION_TYPE = [
  {
    name: 'Basic',
    type: 1, // or bas1
    features: [
      'Immediate shopfront in 5 minutes',
      'Full Co Hoot admin features',
      'Max of 5 products sales',
    ],
  },
  {
    name: 'Professional',
    type: 2,
    features: [
      'Full Co Hoot admin features',
      'Unlimited products in shop',
      'Co Hoot Support',
      'Personal Customization & Enhancement (additional charge may apply)',
    ],
  },
  {
    name: 'Founder',
    type: 3,
    features: [
      'All you need to grow your business with a big discount because you are a founder to Co-hoot!',
    ],
  },
]
export const LIST_PACKAGE_TYPE = [
  {
    name: 'Subscription',
    type: 1,
    forProject: 'co-kii',
  },
  {
    name: 'Lazada',
    type: 2,
    forProject: 'co-kii',
  },
  {
    name: 'Schedule',
    type: 3,
    forProject: 'co-bee',
  },
  {
    name: 'SubDomain',
    type: 4,
    forProject: 'co-bee',
  },
  {
    name: 'Co-bee Desktop',
    type: 5,
    forProject: 'co-bee',
  },
]

// SUBSCRIPTION
export const SHOP_IS_NOT_PAID_FOR_SUBSCRIPTION = -2
export const MONTHLY_SUBSCRIPTION = 1
export const ANNUAL_SUBSCRIPTION = 12
export const SUBSCRIPTION_REFUNDING = -1
export const SUBSCRIPTION_REFUNDED = -2

// ORDER
export const ORDER_CO_KII = 1
export const ORDER_LAZADA = 2
export const ORDER_UNPAID_STATUS = 0
export const ORDER_PENDING_STATUS = 1
export const ORDER_PACKED_STATUS = 2
export const ORDER_READY_TO_SHIP_PENDING_STATUS = 3
export const ORDER_READY_TO_SHIP_STATUS = 4
export const ORDER_SHIPPED_STATUS = 5
export const ORDER_DELIVERED_STATUS = 6
export const ORDER_FAILED_DELIVERY_STATUS = 7
export const ORDER_CONFIRMED_STATUS = 7
export const ORDER_CANCELED_STATUS = -1

export const BUYER_ORDER_REFUNDED = -2
export const BUYER_ORDER_CANCELED = -1
export const BUYER_ORDER_UNPAID = 1
export const BUYER_ORDER_PAID = 2
export const BUYER_ORDER_COLLECTED = 3

export const HOST_ORDER_CANCELED = -1
export const HOST_ORDER_UNPAID = 1
export const HOST_ORDER_PAID = 2
export const HOST_ORDER_VERIFIED = 3
export const HOST_ORDER_EVENT_VERIFIED = 2
export const HOST_ORDER_EVENT_COLLECTED = 3
export const HOST_ORDER_FULFILLED = 4

// PIN
export const PIN_MIN_VALUE = 0
export const PIN_MAX_VALUE = 9

export const CART_ORDER_DETAILS_BY_REF = 'CART_ORDER_DETAILS_BY_REF'
export const HITPAY_PAYMENT_ONLY_ONCE = 'HITPAY_PAYMENT_ONLY_ONCE'
export const HITPAY_PAYMENT_RECURRING_BILLING = 'HITPAY_PAYMENT_RECURRING_BILL'
export const VIEW_LIST = 1
export const VIEW_GRID = 2
export const VISIBLE = 1
export const UN_VISIBLE = 0
export const USER_TOKEN = 'USER_TOKEN'
export const USER_INFO = 'USER_INFO'
export const FACEBOOK = 'facebook'
export const GOOGLE = 'google'
export const UNKNOWN = 'unknown'
export const SOCIAL_TOKEN = 'SOCIAL_TOKEN'
export const DEVICE_USER = 'DEVICE_USER'
export const PHONE = 'PHONE'
export const DESKTOP = 'DESKTOP'
export const USER_AVATAR_DEFAULT =
  'https://firebasestorage.googleapis.com/v0/b/co-bee-stg.appspot.com/o/images%2Fsample.jpeg?alt=media&token=07160743-72a8-4856-ae7c-e2dcbe82f5be'

export const DATA_FIREBASE = 'DATA_FIREBASE'
export const DEBUG_MODE = 'DEBUG_MODE'
export const DEBUG_SCOPE = 'DEBUG_SCOPE'

export const LIST_ORDER = 'LIST_ORDER'

export const POSTAL_CODE_MAX_VALUE = 6

export const EMAIL_IS_TAKEN_CODE = 10013
export const SUCCESS = 'success'
export const ERROR = 'error'

export const DELIVERY_CANCEL_STATUS = -1
export const DELIVERY_UNPAID_STATUS = 0
export const DELIVERY_PENDING_STATUS = 1
export const DELIVERY_DELIVERED_STATUS = 2

export const SUBITEM_REQUIRED = 1

export const DONT_EXIST_IN_ARRAY = -1

export const AVAILABLE_STOCK_DEFAULT = 1000
export const LIMIT_PER_ORDER_DEFAULT = 1000
export const MINIMUM_ORDER_PER_PRODUCT = 1
export const END_DAY = '23:59'
export const DEFAULT_COST = 0
export const DEFAULT_QUANTITY = 0
export const FIRST_INDEX_PRODUCT_CSV = 3
export const FIRST_INDEX_SUBITEM_CSV = 20

export const PAYNOW = 1
export const NOT_PAYNOW = 0
export const NOT_PAYABLE = 0

export const SHOP_INFO = 'SHOP_INFO'
export const SHOP_IS_CANCELED = 0
export const SHOP_IS_VERIFY = 2
export const SHOP_IS_PENDING = 1
export const SHOP_IS_TRAIL = 3

export const SINGAPORE_CODE = '+65'
export const SINGAPORE_CODE_NO_ADD = '65'

export const DATA_ORDER_SHOP = 'DATA_ORDER_SHOP'
export const CREATE_SHOP_DATA = 'CREATE_SHOP_DATA'
export const CATEGORY_NAME = 'CATEGORY_NAME'
export const CART_USER = 'CART_USER'
export const SHOP_DETAIL = 'SHOP_DETAIL'
export const TYPE_SHOP_WITH_SINGLE_LIST = 1
export const TYPE_SHOP_WITH_DIFFERENT_CATEGORY = 2
export const CREATE_SHOP_WITHOUT_LOGIN = 'CREATE_SHOP_WITHOUT_LOGIN'

export const DEFAULT_OPTION_SUB_ITEM = {
  name: '',
  price: 0,
}

export const DEFAULT_PRODUCT_DIGISTALL = {
  name: '',
  description: null,
  price: 0,
  maxQuantity: AVAILABLE_STOCK_DEFAULT,
  defaultQuantity: DEFAULT_QUANTITY,
  limitPerOrder: LIMIT_PER_ORDER_DEFAULT,
  subItems: [],
  photoUrl: '',
  minOrderQty: MINIMUM_ORDER_PER_PRODUCT,
}

export const EVENT_STATUS_OPEN = 1
export const EVENT_COLECTED = 3

export const SHORT_DATE = 'DD-MM-YYYY'
export const DATE_FORMAT = 'DD-MM-YYYY HH:mm'

export const EVENT_CLOSE = 0

export const GMT_0 = 'GMT-00:00'

export const FEEDBACK = 'feedback'

export const LIST_TIME_2 = [
  { text: '1AM', valueString: '01:00', id: 1, valueNumber: 1 },
  { text: '2AM', valueString: '02:00', id: 2, valueNumber: 2 },
  { text: '3AM', valueString: '03:00', id: 3, valueNumber: 3 },
  { text: '4AM', valueString: '04:00', id: 4, valueNumber: 4 },
  { text: '5AM', valueString: '05:00', id: 5, valueNumber: 5 },
  { text: '6AM', valueString: '06:00', id: 6, valueNumber: 6 },
  { text: '7AM', valueString: '07:00', id: 7, valueNumber: 7 },
  { text: '8AM', valueString: '08:00', id: 8, valueNumber: 8 },
  { text: '9AM', valueString: '09:00', id: 9, valueNumber: 9 },
  { text: '10AM', valueString: '10:00', id: 10, valueNumber: 10 },
  { text: '11AM', valueString: '11:00', id: 11, valueNumber: 11 },
  { text: '12PM', valueString: '12:00', id: 12, valueNumber: 12 },
  { text: '1PM', valueString: '13:00', id: 13, valueNumber: 13 },
  { text: '2PM', valueString: '14:00', id: 14, valueNumber: 14 },
  { text: '3PM', valueString: '15:00', id: 15, valueNumber: 15 },
  { text: '4PM', valueString: '16:00', id: 16, valueNumber: 16 },
  { text: '5PM', valueString: '17:00', id: 17, valueNumber: 17 },
  { text: '6PM', valueString: '18:00', id: 18, valueNumber: 18 },
  { text: '7PM', valueString: '19:00', id: 19, valueNumber: 19 },
  { text: '8PM', valueString: '20:00', id: 20, valueNumber: 20 },
  { text: '9PM', valueString: '21:00', id: 21, valueNumber: 21 },
  { text: '10PM', valueString: '22:00', id: 22, valueNumber: 22 },
  { text: '11PM', valueString: '23:00', id: 23, valueNumber: 23 },
  { text: '12AM', valueString: '00:00', id: 24, valueNumber: 24 },
]

export const TOKEN_EXPIRED = 99999

export const UNAUTHORIZED = 'Unauthorized'

export const LIMIT_TEXT = 64

export const MAXIMUM_NUMBER_OF_EVENT_IMAGES = 5
export const SHIFT_UP = 'UP'
export const SHIFT_DOWN = 'DOWN'

export const SIZE_IMAGE = 256
export const NUMBER_ENLARGE_DEFAULT = 3

export const ALLOW_SCHEDULE_EVENT = 1

export const DEFAULT_DELIVERY_OPTION = {
  name: '',
  price: 0,
}
export const NO_DELIVERY = 1
export const DELIVERY_BUT_HAVE_NOT_BOOKED = 2
export const DELIVERY_AND_HAVE_BOOKED = 3
export const DELIVERY_CLOSED = 0
export const DELIVERY_NOT_CLOSE = 1
export const DELIVERY_BUYER_REFUND = -2
export const DELIVERY_BUYER_CANCELLED = -1
export const DELIVERY_BUYER_UNPAID = 1
export const DELIVERY_BUYER_PAID = 2

export const DELIVERY_HOST_CANCELLED = -1
export const DELIVERY_HOST_UNPAID = 1
export const DELIVERY_HOST_PAID = 2

export const DELIVERY_OPENED = 1

export const ORDERS_PAGE = 1
export const ORDER_DELIVERY_PAGE = 2

// hardCode, will fix later
export const PAYMENT_SERVICE_PHONE = 'XXXXXXX'
export const PAYMENT_SERVICE_EXPIRY_DATE = '21001231'
export const PAYMENT_SERVICE_SCHEDULE_REF = 'SC11111'
export const PAYMENT_SERVICE_SUBDOMAIN_REF = 'SU11111'

export const SHOP = 'SHOP'
export const DELIVERY_OPTION = 1
export const SELF_PICK_UP = 2
export const CHECKED = 1
export const UNCHECKED = 0
export const NO_DATA_SHOP_DETAIL = '0'

export const SHOP_PAYMENT_STATUS_PAID = 2
export const IS_PAYMENT_SUBSCRIPTION = 'IS_PAYMENT_SUBSCRIPTION'
export const ORDER_REF = 'ORDER_REF'
export const TIME_SLOT = [
  { title: '30 mins', isCheck: false, value: 30 },
  { title: '60 mins', isCheck: false, value: 60 },
  { title: '2 hours', isCheck: false, value: 120 },
  { title: '4 hours', isCheck: false, value: 240 },
  { title: '6 hours', isCheck: false, value: 360 },
  { title: 'per day', isCheck: false, value: 1440 },
]

export const DATES_OF_WEEK = [
  { title: 'Sun', isClose: false, value: 1, date: '21-11-2022' },
  { title: 'Mon', isClose: false, value: 2, date: '21-11-2022' },
  { title: 'Tue', isClose: false, value: 3, date: '21-11-2022' },
  { title: 'Wed', isClose: false, value: 4, date: '21-11-2022' },
  { title: 'Thu', isClose: false, value: 5, date: '21-11-2022' },
  { title: 'Fri', isClose: false, value: 6, date: '21-11-2022' },
  { title: 'Sat', isClose: false, value: 7, date: '21-11-2022' },
]

export const USER_INFO_SELF_PICK_UP = 'USER_INFO_SELF_PICK_UP'
export const SYNC_LIST_ORDERS = 'SYNC_LIST_ORDERS'

export const VIEW_DEFAULT = 'VIEW_DEFAULT'
export const COLOR_SCHEME = 'COLOR_SCHEME'
export const FREE_TRIAL = 0
export const EXPIRE_DATE = 0
export const PENDING = 1
export const PAYMENT_COP = 2
export const PAYMENT_COD = 0
export const TRANSFER_BANKING = 1
export const PAYMENT_HITPAY = 2
export const NO_FULFILLMENT = 0
export const LIST_SHOP = 1
export const LIST_SHOP_DELETED = 2

export const PLATFORM_CO_KII = 1
export const PLATFORM_LAZADA = 2
export const PLATFORM_WIX = 3
export const TURN_ON = 1
export const TURN_OFF = 0

export const OPTION_CURRENCY_SG = {
  style: 'currency',
  currency: 'SGD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
}

export const URL_SCHEME = 'https://'
export const STG_DOMAIN = 'stg.co-kii.com'
export const PROD_DOMAIN = 'co-hoot.com'
