import classNames from 'classnames'
import React from 'react'

function OpeningDays({
  datesOfWeek,
  handleClickDate,
  checkedPublicHoliday,
  handleCheckPublicHoliday,
}) {
  return (
    <div className='row opening-hours'>
      <div className='col-12 d-flex align-items-center mb-1'>
        <span>Opening Days</span>
        <span className='date-open ms-auto position-relative'></span>
        <span className='date-close position-relative'></span>
      </div>
      <div className='col-12 d-flex align-items-stretch'>
        {datesOfWeek.map((date, i) => (
          <label
            htmlFor={`date-delivery-${i}`}
            key={i}
            className={classNames(
              'date-of-week cursor-pointer mb-0 flex-fill d-flex align-items-center justify-content-center',
              i !== 0 && 'border-left-0'
            )}
            title={date.name}
            style={
              date.isClose || date.isHoliday
                ? { backgroundColor: 'gray' }
                : { backgroundColor: 'lightblue' }
            }
          >
            <input
              type='checkbox'
              id={`date-delivery-${i}`}
              checked={date.isClose || date.isHoliday}
              onChange={(e) => handleClickDate(e, i)}
              hidden
            />
            <span>{date.title[0]}</span>
          </label>
        ))}
      </div>
      <div className='col-12 mt-2'>
        <label htmlFor={'holiday'} className='float-start d-flex align-items-center cursor-pointer'>
          <input
            type='checkbox'
            id={'holiday'}
            checked={checkedPublicHoliday}
            className='me-1'
            onChange={(e) => handleCheckPublicHoliday(e)}
          />
          <span>We are close during Public Holiday</span>
        </label>
      </div>
    </div>
  )
}

export default OpeningDays
