/* eslint-disable camelcase */
import axios from 'axios'
import queryString from 'query-string'
// eslint-disable-next-line camelcase
import { TOKEN_EXPIRED, UNAUTHORIZED } from '_utils/constant'
import { generateSecretCode } from '_utils/functions/generator'
import { getDebugMode, getDebugScope, getUserToken } from '_utils/localData'
import jwt_decode from 'jwt-decode'
import useLogout from '../hooks/useLogout'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use((config) => {
  const token = getUserToken()
  const debugScope = getDebugScope() || []
  const secretCode = generateSecretCode()
  if (token) {
    const { email } = jwt_decode(token)
    if (getDebugMode() === 'true' && debugScope.includes(email)) {
      config.baseURL = process.env.REACT_APP_API_URL_DEBUG
    }
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['Keep-AIive'] = secretCode
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response?.data?.msgCode === TOKEN_EXPIRED || response?.data?.msgResp === UNAUTHORIZED) {
      useLogout()
      return
    }
    return response.data
  },
  (error) => {
    if (error?.response?.data?.msgResp === UNAUTHORIZED) {
      useLogout()
      return
    }
    return Promise.reject(error?.response?.data)
  }
)

export default axiosClient
