/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import { fetchListOrderLazada, getListOrdersByHost } from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_REFUNDED,
  HOST_ORDER_CANCELED,
  NO_FULFILLMENT,
  ORDER_CO_KII,
  ORDER_LAZADA,
} from '_utils/constant'
import { normalizeName } from '_utils/function'
import { convertStatusNumberToText } from '_utils/functions/converter'
import { handleDataOrderDigistall } from '_utils/functions/handler'
import moment from 'moment'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useQuery from '../hooks/useQuery'
import { selectTabOrderPlatform } from '../redux/modules/order'
import { REVERSE_ORDER_FOR_SELLER_BUTTON } from '../utils/LazadaConstants'
import { PLATFORM_CO_KII } from '../utils/constant'
import { exportToCsv } from '../utils/function'
import { handleDataOrderEvent } from '../utils/functions/handler'

const contextDefaultValues = {
  searchString: '',
  sorting: {},
  setSorting: () => {},
}

export const OrderRecordContext = createContext(contextDefaultValues)

export const OrderRecordProvider = ({ children }) => {
  const dispatch = useDispatch()
  const eidParam = useQuery().get('eid')

  const [filter, setFilter] = useState({
    orderDate: [
      {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
        key: 'selection',
      },
    ],
    orderStatus: [],
    fulfillment: [],
    fulfillmentDate: [
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ],
  })
  const [searchString, setSearchString] = useState('')
  const [sorting, setSorting] = useState({ field: 'shopOrderNo', order: 'asc', type: 'number' })
  const [isLoading, setIsLoading] = useState(false)
  const [selectFilterOrder, setSelectFilterOrder] = useState('order')
  const [activeTabReverseOrder, setActiveTabReverseOrder] = useState(
    REVERSE_ORDER_FOR_SELLER_BUTTON
  )

  const { selectedShop, eventsByShop } = useSelector((state) => state.shop)
  const {
    listOrdersByHost,
    listDateIsPicked,
    tabOrderPlatform: tabOrder,
  } = useSelector((state) => state.order)
  const { eventAndOrderById } = useSelector((state) => state.orderEvent)
  const listOrderEvent = eventAndOrderById.find((event) => event.id === eidParam)?.orders || []

  const eventsByShopList = eventsByShop[selectedShop?.id] || []
  const eventDetail = eventsByShopList.find((event) => event.id === eidParam)

  const [tabOrderPlatform, setTabOrderPlatform] = useState(!eidParam ? tabOrder : null)
  const [tabOrderEvent, setTabOrderEvent] = useState(null)

  const { listOrder = [] } = handleDataOrderDigistall(listOrdersByHost)
  const { listOrderEventHandled = [] } = handleDataOrderEvent(
    listOrderEvent,
    eventDetail?.adminCost || 0,
    eventDetail?.discount || 0,
    eventDetail?.deliveryCost || 0
  )

  const query = useQuery()

  useEffect(() => {
    let isMounted = true

    const loadData = async () => {
      setIsLoading(true)
      const rangeDate = {
        startDate: moment(filter.orderDate[0].startDate).unix(),
        endDate: moment(filter.orderDate[0].endDate).unix(),
      }

      await dispatch(getListOrdersByHost(selectedShop?.id, rangeDate))

      if (isMounted) {
        setIsLoading(false)
      }
    }

    if (selectedShop?.id && tabOrderPlatform === ORDER_CO_KII) {
      loadData()
    }

    const loadDataLazada = async () => {
      setIsLoading(true)
      try {
        await dispatch(fetchListOrderLazada(selectedShop?.id, filter))
      } catch (error) {
        throw new Error(error.msgResp || error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (selectedShop?.id && tabOrderPlatform === ORDER_LAZADA) {
      loadDataLazada()
    }

    return () => {
      isMounted = false
    }
  }, [
    tabOrderPlatform,
    selectedShop?.id,
    moment(filter.orderDate[0].startDate).unix(),
    moment(filter.orderDate[0].endDate).unix(),
  ])

  useEffect(() => {
    const startDate = query.get('startDate')
    const endDate = query.get('endDate')

    if (startDate && endDate) {
      setFilter({
        ...filter,
        orderDate: [
          {
            startDate: moment(startDate, 'DD-MM-YYYY').startOf('day').toDate(),
            endDate: moment(endDate, 'DD-MM-YYYY').endOf('day').toDate(),
            key: 'selection',
          },
        ],
      })
    }
  }, [query.get('startDate'), query.get('endDate')])

  const onChangeSearch = (search) => setSearchString(search)

  const clearFilter = () => {
    setSearchString('')
    setFilter({
      orderDate: [
        {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
          key: 'selection',
        },
      ],
      orderStatus: [],
      fulfillment: [],
      fulfillmentDate: [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ],
    })

    if (query.get('startDate') && query.get('endDate')) {
      query.delete('startDate')
      query.delete('endDate')
      window.history.replaceState({}, '', `${window.location.pathname}`)
    }
  }

  const onSelectTabOrderPlatform = (tabSelected) => {
    dispatch(selectTabOrderPlatform(tabSelected))
    setTabOrderPlatform(tabSelected)
  }

  const onSelectTabEvent = (tabSelected) => setTabOrderEvent(tabSelected)

  const handleExport = (orderList, tab) => {
    const generalInfo = [
      ['Shop Name', selectedShop?.shopName],
      ['Shop Link', selectedShop?.shopLink],
      ['Headline', selectedShop?.headline],
      ['Shop Owner', selectedShop?.createdUserName],
      ['Contact', selectedShop?.createdUserPhone],
      [''],
      [''],
    ]

    const ordersActive = orderList.filter(
      (order) =>
        order.buyerStatus !== BUYER_ORDER_CANCELED &&
        order.buyerStatus !== BUYER_ORDER_REFUNDED &&
        order.status !== HOST_ORDER_CANCELED
    )

    let rowsOfProduct
    if (tab === PLATFORM_CO_KII) {
      rowsOfProduct = ordersActive.map((product) => {
        const nameProductsOfBuyer = product?.allProductPerOrder?.map((item) => {
          let pNameAndSub = ''
          if (Array.isArray(item?.subItems)) {
            if (item?.subItems.length) {
              pNameAndSub += ' ('
              for (let i = 0; i < item?.subItems.length; i++) {
                const subItem = item?.subItems[i]
                pNameAndSub += `${subItem.name}: +$${subItem.price}`
                if (i < item?.subItems.length - 1) {
                  pNameAndSub += ', '
                }
              }
              pNameAndSub += ')'
            }
          }

          return `${item.name}${item.quantity ? `(qty:${item.quantity})` : null}${
            pNameAndSub ? ` ${pNameAndSub}` : ''
          }`
        })

        const {
          // shopOrderNo,
          ref,
          uName,
          uPhone,
          allProductPerOrder,
          discountPerOrder,
          totalFee,
          transactionFees,
          deliveryDetail,
          pickupDetail,
          buyerStatus,
          status,
        } = product

        const roundedTotalFee = Number(totalFee.toFixed(2))

        return [
          // shopOrderNo,
          ref,
          uName,
          uPhone,
          `${allProductPerOrder?.length}`,
          nameProductsOfBuyer,
          discountPerOrder,
          `$${roundedTotalFee}`,
          transactionFees || 0,
          pickupDetail?.pickupFee || 0,
          deliveryDetail?.deliveryFee || 0,
          convertStatusNumberToText(buyerStatus),
          convertStatusNumberToText(status),
        ]
      })
    } else {
      rowsOfProduct = ordersActive.map((product) => {
        const nameProductsOfBuyer = product?.allProductPerOrder?.map((item) => {
          let pNameAndSub = ''
          if (Array.isArray(item?.pSubItems)) {
            if (item?.pSubItems.length) {
              pNameAndSub += ' ('
              for (let i = 0; i < item?.pSubItems.length; i++) {
                const subItem = item?.pSubItems[i]
                pNameAndSub += `${subItem.name}: +$${subItem.price}`
                if (i < item?.pSubItems.length - 1) {
                  pNameAndSub += ', '
                }
              }
              pNameAndSub += ')'
            }
          }

          return `${item.pName}${item.pQuantity ? `(qty:${item.pQuantity})` : null}${
            pNameAndSub ? ` ${pNameAndSub}` : ''
          }`
        })

        const {
          // shopOrderNo,
          ref,
          uName,
          uPhone,
          allProductPerOrder,
          discountPerOrder,
          totalFee,
          transactionFees,
          deliveryDetail,
          pickupDetail,
          buyerStatus,
          status,
        } = product

        const roundedTotalFee = Number(totalFee.toFixed(2))

        return [
          // shopOrderNo,
          ref,
          uName,
          uPhone,
          `${allProductPerOrder?.length}`,
          nameProductsOfBuyer,
          discountPerOrder,
          `$${roundedTotalFee}`,
          transactionFees || 0,
          pickupDetail?.pickupFee || 0,
          deliveryDetail?.deliveryFee || 0,
          convertStatusNumberToText(buyerStatus),
          convertStatusNumberToText(status),
        ]
      })
    }

    const rows = [
      ...generalInfo,
      [
        // 'Order No',
        'Ref',
        'Name',
        'Phone',
        'Quantity Product',
        'Product',
        'Discount',
        'Total Amount',
        'Transaction Fee',
        'Pickup Fee',
        'Delivery Fee',
        'Payment',
        'Status',
      ],
      ...rowsOfProduct,
    ]

    const fileName = normalizeName(
      `${selectedShop?.shopName} shop exportOrders by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const onChangeFulfillment = (fulfillment) => setFilter({ ...filter, fulfillment })

  const onSelectRangeFulfillDate = (fulfillmentDate) => setFilter({ ...filter, fulfillmentDate })

  const onSelectRangeOrderDate = (orderDate) => setFilter({ ...filter, orderDate })

  const onChangeOrderStatus = (orderStatus) => setFilter({ ...filter, orderStatus })

  const listOrdersFilter = useMemo(() => {
    const fulfillStartDate = filter.fulfillmentDate[0].startDate
    const fulfillEndDate = filter.fulfillmentDate[0].endDate

    const listValueFulfillment = filter.fulfillment.map((item) => item.value)
    const listValueOrderStatus = filter.orderStatus.map((item) => item.value)

    const filteredList = listOrder.filter((order) => {
      const isFulfillmentMatched = listValueFulfillment.some(
        (fulfillmentItem) =>
          fulfillmentItem === order?.deliveryDetail?.deliveryOptionId ||
          fulfillmentItem === order?.pickupDetail?.pickupOptionId ||
          fulfillmentItem === order.fulfillmentType
      )

      const isOrderInRange = (timestamp) =>
        moment.unix(timestamp).isBetween(moment(fulfillStartDate), moment(fulfillEndDate))

      const isOrderPickupInRange = isOrderInRange(order?.pickupDetail?.pickupBookingTimestamp)
      const isOrderDeliveryInRange = isOrderInRange(order?.deliveryDetail?.deliveryTimestamp)
      const isOrderNoFulfillmentInRange =
        order.fulfillmentType === NO_FULFILLMENT && isOrderInRange(order?.createdAt)

      const isFulfillmentInRangeDate =
        isOrderPickupInRange || isOrderDeliveryInRange || isOrderNoFulfillmentInRange

      // filter by order's statuses selected
      if (listValueOrderStatus.length) {
        if (listValueFulfillment.length) {
          if (fulfillStartDate !== null && fulfillEndDate !== null) {
            return (
              listValueOrderStatus.includes(order.status) &&
              isFulfillmentMatched &&
              isFulfillmentInRangeDate
            )
          }

          return listValueOrderStatus.includes(order.status) && isFulfillmentMatched
        }

        if (fulfillStartDate !== null && fulfillEndDate !== null) {
          return (
            (listValueOrderStatus.includes(order.status) && isFulfillmentInRangeDate) ||
            isFulfillmentMatched
          )
        }

        return listValueOrderStatus.includes(order.status)
      }

      // filter by fulfillment date in range [1,...,n] selected
      if (fulfillStartDate !== null && fulfillEndDate !== null) {
        if (listValueFulfillment.length) {
          return isFulfillmentInRangeDate && isFulfillmentMatched
        }

        return isFulfillmentInRangeDate
      }

      return !listValueFulfillment.length ? order : isFulfillmentMatched
    })

    return filteredList
  }, [JSON.stringify(listOrder), JSON.stringify(filter), JSON.stringify(listDateIsPicked)])

  const listOrdersEventFiltered = useMemo(() => {
    const fulfillStartDate = filter.fulfillmentDate[0].startDate
    const fulfillEndDate = filter.fulfillmentDate[0].endDate

    const listValueFulfillment = filter.fulfillment.map((item) => item.value)
    const listValueOrderStatus = filter.orderStatus.map((item) => item.value)

    const filteredList = listOrderEventHandled.filter((order) => {
      const isFulfillmentMatched = listValueFulfillment.some(
        (fulfillmentItem) =>
          fulfillmentItem === order?.deliveryDetail?.deliveryOptionId ||
          fulfillmentItem === order?.pickupDetail?.pickupOptionId ||
          fulfillmentItem === order.fulfillmentType
      )

      const isOrderInRange = (timestamp) =>
        moment.unix(timestamp).isBetween(moment(fulfillStartDate), moment(fulfillEndDate))

      const isOrderPickupInRange = isOrderInRange(order?.ePickupTimestamp)
      const isOrderCreatedAtInRange = isOrderInRange(order?.createdAt)

      const isFulfillmentInRangeDate = isOrderPickupInRange || isOrderCreatedAtInRange

      // filter by order's statuses selected
      if (listValueOrderStatus.length) {
        if (listValueFulfillment.length) {
          if (fulfillStartDate !== null && fulfillEndDate !== null) {
            return (
              listValueOrderStatus.includes(order.status) &&
              isFulfillmentMatched &&
              isFulfillmentInRangeDate
            )
          }

          return listValueOrderStatus.includes(order.status) && isFulfillmentMatched
        }

        if (fulfillStartDate !== null && fulfillEndDate !== null) {
          return (
            (listValueOrderStatus.includes(order.status) && isFulfillmentInRangeDate) ||
            isFulfillmentMatched
          )
        }

        return listValueOrderStatus.includes(order.status)
      }

      // filter by fulfillment date in range [1,...,n] selected
      if (fulfillStartDate !== null && fulfillEndDate !== null) {
        if (listValueFulfillment.length) {
          return isFulfillmentInRangeDate && isFulfillmentMatched
        }

        return isFulfillmentInRangeDate
      }

      return !listValueFulfillment.length ? order : isFulfillmentMatched
    })

    return filteredList
  }, [
    JSON.stringify(listOrderEventHandled),
    JSON.stringify(filter),
    JSON.stringify(listDateIsPicked),
  ])

  const sortListOrders = useMemo(() => {
    if (listDateIsPicked?.length > 0) {
      const newOrderList = listDateIsPicked.flatMap((pickedDate) => {
        const pickedDateFormatted = moment.unix(pickedDate.createdAt).format('DD-MM-YYYY')

        return listOrdersFilter.filter((item) => {
          const itemDateFormatted = moment.unix(item.createdAt).format('DD-MM-YYYY')
          return itemDateFormatted === pickedDateFormatted
        })
      })
      return newOrderList.flat()
    }

    if (listOrdersFilter) {
      let listOrderSort = [...listOrdersFilter]
      if (searchString) {
        listOrderSort = listOrderSort.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString()?.toLowerCase()?.includes(searchString.toLowerCase())
          )
        )
      }
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * (a[sorting.field]?.localeCompare(b[sorting.field]) || 0)
          )
        } else {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * ((a[sorting.field] || 0) - (b[sorting.field] || 0))
          )
        }
      }
      return listOrderSort
    }
  }, [searchString, sorting, JSON.stringify(listOrdersFilter), JSON.stringify(listDateIsPicked)])

  const sharedValues = {
    tabOrderEvent,
    onSelectTabEvent,
    filter,
    setFilter,
    searchString,
    sorting,
    setSorting,
    onChangeSearch,
    clearFilter,
    tabOrderPlatform,
    onSelectTabOrderPlatform,
    handleExport,
    onChangeFulfillment,
    onSelectRangeFulfillDate,
    onSelectRangeOrderDate,
    onChangeOrderStatus,
    sortListOrders,
    isLoading,
    setSelectFilterOrder,
    selectFilterOrder,
    setActiveTabReverseOrder,
    activeTabReverseOrder,
    listOrdersEventFiltered,
  }

  return <OrderRecordContext.Provider value={sharedValues}>{children}</OrderRecordContext.Provider>
}
