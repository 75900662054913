import { selectTab } from '_redux/modules/product'
import { PLATFORM_CO_KII, PLATFORM_LAZADA, SUBSCRIPTION_STATUS_APPROVED } from '_utils/constant'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap'

// { selectedShop } as props
function TabsECommerce() {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const { tabSelected } = useSelector((state) => state.product)
  const { lazadaPackage } = useSelector((state) => state.lazada)

  const isLazadaValid = lazadaPackage?.shopId === selectedShop?.id

  return (
    <ButtonGroup className='my-3'>
      <Button
        outline
        onClick={() => dispatch(selectTab(PLATFORM_CO_KII))}
        active={tabSelected === PLATFORM_CO_KII}
        className='button-platform'
      >
        Co-Hoot
      </Button>
      {isLazadaValid ? (
        <Button
          outline
          className='mt-0 button-platform'
          onClick={() => dispatch(selectTab(PLATFORM_LAZADA))}
          active={tabSelected === PLATFORM_LAZADA}
          disabled={lazadaPackage?.status !== SUBSCRIPTION_STATUS_APPROVED}
        >
          Lazada
        </Button>
      ) : null}
    </ButtonGroup>
  )
}

export default TabsECommerce
