/* eslint-disable no-restricted-properties */
/* eslint-disable no-else-return */
/* eslint-disable function-paren-newline */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-extra-semi */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-cycle */

import {
  AVAILABLE_STOCK_DEFAULT,
  BUYER_ORDER_CANCELED,
  DEFAULT_COST,
  DEFAULT_QUANTITY,
  END_DAY,
  FIRST_INDEX_PRODUCT_CSV,
  FIRST_INDEX_SUBITEM_CSV,
  LIMIT_PER_ORDER_DEFAULT,
  LIMIT_TEXT,
} from '_utils/constant'
import moment from 'moment'
import { MINIMUM_ORDER_PER_PRODUCT } from './constant'

export const truncateTitle = (title, maxWidth) => {
  const tempDiv = document.createElement('div')
  tempDiv.style.position = 'absolute'
  tempDiv.style.visibility = 'hidden'
  tempDiv.style.whiteSpace = 'nowrap'

  tempDiv.innerText = title
  document.body.appendChild(tempDiv)

  const titleWidth = tempDiv.offsetWidth
  document.body.removeChild(tempDiv)

  if (titleWidth > maxWidth) {
    return `${title.substring(0, Math.floor(title.length * (maxWidth / titleWidth)))}...`
  }

  return title
}

export const sanitizeStringForUrl = (str) =>
  str
    ?.toLowerCase()
    ?.split(' ')
    ?.join('-')
    ?.replace(/^-+|-+$/g, '')
    ?.replace(/-+/g, '-')

export function hexToRgb(hex) {
  let r = 0
  let g = 0
  let b = 0
  // 3 ký tự hex
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length === 7) {
    // 6 ký tự hex
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }
  return [r, g, b]
}

// Hàm tính khoảng cách Euclidean giữa hai màu
export function colorDistance(color1, color2) {
  const [r1, g1, b1] = hexToRgb(color1)
  const [r2, g2, b2] = hexToRgb(color2)
  return Math.sqrt(Math.pow(r2 - r1, 2) + Math.pow(g2 - g1, 2) + Math.pow(b2 - b1, 2))
}

// Hàm tạo mã màu ngẫu nhiên không mang màu tương tự trong "listColor", với giới hạn lặp lại
export function generateRandomColor(excludeColors) {
  let color = ''
  let isSimilar
  let attempts = 0 // Đếm số lần thử
  const maxAttempts = 100 // Giới hạn số lần thử để tránh vòng lặp vô hạn

  do {
    color = `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')
      .toUpperCase()}`
    isSimilar = excludeColors.some((excludeColor) => colorDistance(color, excludeColor) < 100) // Ngưỡng khoảng cách
    attempts += 1

    if (attempts > maxAttempts) {
      break // Thoát khỏi vòng lặp nếu vượt quá giới hạn số lần thử
    }
  } while (isSimilar)

  return color
}

export function flexibleSearch(fullString, searchTerm) {
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const regex = new RegExp(escapedSearchTerm.replace(/\s+/g, '.*'), 'i')
  return regex.test(fullString)
}

export function isBlobURL(url) {
  return url.startsWith('blob:')
}

export function maskString(str, num) {
  if (str.length <= num) {
    return str
  }

  const lastNChars = str.slice(-num)
  const restOfString = str.slice(0, -num)

  const maskedPart = restOfString.replace(/./g, '*')

  return maskedPart + lastNChars
}

export function getSelectedVariantIds(listOriginal, _listVariantSelected) {
  const variantCodes = [...new Set(_listVariantSelected.map((variant) => variant.codeProd))]

  const selectedIds = listOriginal
    ?.filter((item) => variantCodes.some((code) => item.codeProd?.includes(code)))
    ?.map((item) => item.id)

  return selectedIds
}

export const removeQueryParamsFromUrl = () => {
  const url = new URL(window.location.href)
  url.search = ''
  window.history.pushState({}, document.title, url.toString())
}

export const capitalizeWords = (str) => {
  const words = str.split(' ')

  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
    return word
  })

  return capitalizedWords.join(' ')
}

export const scrollToTop = () => window.scrollTo(0, 0)

export const removeValuesFromArray = (valuesArray, value) => {
  const valueIndex = valuesArray.findIndex((entry) => entry === value)
  if (valueIndex === -1) {
    return
  }
  valuesArray.splice(valueIndex, 1)
}

export const arrayToString = (array) => array.toString().replaceAll(',', '')

export const getDaysLeft = (_date1) => {
  const date1 = _date1.split(' ')[0].replaceAll('-', ' ').split(' ')
  const date2 = moment().format('DD MM YYYY').split(' ')
  date1.map((i) => parseInt(i, 10))
  date2.map((i) => parseInt(i, 10))
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = new Date(...date1.reverse())
  const secondDate = new Date(...date2.reverse())

  return Math.round(Math.abs((firstDate - secondDate) / oneDay))
}

export const dec2alphabet = (number) => {
  const ALPHABET = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]
  if (number < 0) return null
  if (number === 0) return 'A'
  let alphaNum = ''
  let q = number
  while (q > 0) {
    const r = (q - 1) % 26
    q = parseInt((q - r) / 26, 10)
    alphaNum += ALPHABET[r]
  }

  return alphaNum
}

export const getSubItemInFile = (sheet) => {
  let row = FIRST_INDEX_SUBITEM_CSV
  let subItems = []
  while (typeof x === 'undefined') {
    const indexCell = sheet[`A${row}`]

    if (!indexCell) break

    const subItemIndex = indexCell.v
    const subItemType = sheet[`B${row}`] ? sheet[`B${row}`].v : ''
    const subItemRequired = sheet[`C${row}`] ? sheet[`C${row}`].v : 0
    const subItemList = sheet[`D${row}`] ? sheet[`D${row}`].v.split('\n') : []

    const listTemp = subItemList
      .filter((subItem) => !!subItem)
      .map((subItem) => {
        const temps = subItem.split(':')
        return {
          name: temps[0],
          price: temps[1] !== '' ? parseFloat(temps[1].replace('$', '').trim()) : DEFAULT_COST,
        }
      })

    subItems = [
      ...subItems,
      {
        idx: subItemIndex,
        type: subItemType,
        required: subItemRequired,
        list: listTemp.length ? listTemp : [{ name: '', price: DEFAULT_COST }],
      },
    ]

    row += 1
  }

  return subItems
}

export const validateDate = (date) => moment(date, 'DD-MM-YYYY HH:mm').isAfter(moment())

export const readFileToCreateEvent = (sheet) => {
  // Read data from csv
  const title = sheet.B2.v
  const description = sheet.B3 ? sheet.B3.v : ''
  const pickupAddress = sheet.B4 ? sheet.B4.v : ''
  const closingDate = sheet.B5.w
  const pickupDate = sheet.B6.w
  const payLater = sheet.B7.v
  const adminCost = sheet.B8 ? sheet.B8.v : DEFAULT_COST
  const photoUrl = sheet.B9 ? sheet.B9.v : ''

  const event = {
    title,
    description,
    pickupAddress,
    paynow: payLater !== 'TRUE',
    payable: payLater !== 'TRUE',
    adminCost,
    deliveryCost: DEFAULT_COST,
    photoUrl: photoUrl ? [photoUrl] : [],
    closingTime: `${moment(closingDate).format('DD-MM-YYYY')} ${END_DAY}`,
    pickupTime: `${moment(pickupDate).format('DD-MM-YYYY')} ${END_DAY}`,
  }

  // Get subitems in file csv
  const itemList = []
  let colIdx = FIRST_INDEX_PRODUCT_CSV
  const SUBITEMS = getSubItemInFile(sheet)

  while (typeof x === 'undefined') {
    const item = {}
    const col = dec2alphabet(colIdx)
    const nameCell = sheet[`${col}11`]
    const priceCell = sheet[`${col}12`]

    if (!nameCell || !priceCell) break

    const avaStkCell = sheet[`${col}13`]
    const imgUrlCell = sheet[`${col}14`]
    const limitPerOrderCell = sheet[`${col}15`]
    const subItemsCell = sheet[`${col}16`]

    const subItemsIndex = subItemsCell ? subItemsCell.v.split(',') : []

    const productPhotoUrl = imgUrlCell ? imgUrlCell.v : ''
    item.idx = colIdx - FIRST_INDEX_PRODUCT_CSV
    item.name = nameCell.v || ''
    item.price = priceCell.v || DEFAULT_COST
    item.maxQuantity = avaStkCell ? avaStkCell.v : AVAILABLE_STOCK_DEFAULT
    item.defaultQuantity = DEFAULT_QUANTITY
    item.limitPerOrder = limitPerOrderCell ? limitPerOrderCell.v : LIMIT_PER_ORDER_DEFAULT
    item.photoUrl = productPhotoUrl ? [productPhotoUrl] : []
    item.minOrderQty = MINIMUM_ORDER_PER_PRODUCT
    item.subItems = subItemsIndex.map((idx) => {
      const subItemTemp = { ...SUBITEMS.find((subItem) => subItem.idx.toString() === idx) }

      delete subItemTemp.idx

      if (subItemTemp?.list?.length) {
        subItemTemp.list = [
          ...subItemTemp.list.map((subitem) => {
            const opt = { ...subitem }
            return { ...opt }
          }),
        ]
      }

      return { ...subItemTemp }
    })

    itemList.push(item)
    colIdx += 1
  }

  return { ...event, products: itemList }
}

export const getDateRemainFromNow = (date, getDaysRemain = false) => {
  if (!date) return
  if (!validateDate(date)) return '0 day'
  if (getDaysRemain) {
    const timestampRemain = moment(date, 'DD-MM-YYYY HH:mm').diff(moment())
    const timestamp24Hour = 1000 * 60 * 60 * 24
    if (timestampRemain < 0) {
      return '0 day'
    }
    if (timestampRemain < timestamp24Hour) {
      return 'A day'
    }
    return moment(date, 'DD-MM-YYYY HH:mm').fromNow(true)
  } else {
    return moment(date, 'DD-MM-YYYY HH:mm').fromNow(true)
  }
}

export const getListPlacedBy = (listName) => {
  if (!listName.length) return ''
  return [...new Set(listName)].join(', ')
}

export const exportToCsv = (filename, rows) => {
  const processRow = (row) => {
    let finalVal = ''
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j]?.toString()
      if (row[j] instanceof Date) {
        innerValue = row[j]?.toLocaleString()
      }
      let result = innerValue?.replace(/"/g, '""')
      if (result?.search(/(["|,|\n])/g) >= 0) result = `"${result}"`
      if (j > 0) finalVal += ';\t'
      finalVal += result
    }
    return `${finalVal}\n`
  }

  let csvFile = '\ufeff' // Thêm ký tự Unicode BOM vào đầu file
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i], i)
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const normalizeName = (name) => name.replace(/\s+/g, '-').toLowerCase()

export const isDisableEvent = (eStatus, buyerStatus, ePickupTime, ePickupDuration) => {
  if (!eStatus || !buyerStatus || !ePickupTime) return true
  const timeStart = Number(ePickupTime.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + ePickupDuration / 3600
  let newEPickupTime
  if (timeEnd <= 24) {
    newEPickupTime = `${ePickupTime.split(' ')[0]} ${timeEnd}:00`
  } else {
    newEPickupTime = ePickupTime
  }
  return (
    eStatus === BUYER_ORDER_CANCELED ||
    buyerStatus === BUYER_ORDER_CANCELED ||
    moment().isAfter(moment(newEPickupTime, 'DD-MM-YYYY HH:mm'))
  )
}

export const filterOrdersByUserId = (listOrder, uid) => {
  if (!listOrder || !listOrder.length) return []
  return listOrder.filter((o) => o.uid === uid)
}

export const cartesian = (...args) => {
  const t = []
  for (let i = 0; i < args.length; i++) {
    const element = args[i]
    for (let j = 0; j < element.length; j++) {
      const element1 = element[j]
      t.push(element1)
    }
  }
  const r = []
  const max = t.length - 1

  function helper(arr, i) {
    for (let j = 0, l = t[i].length; j < l; j++) {
      const a = arr.slice(0) // clone arr
      if (t[i][j] !== '') {
        a.push(t[i][j])
      }
      if (i === max) r.push(a)
      else helper(a, i + 1)
    }
  }

  helper([], 0)
  return r
}

export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

export const sum = (array) => {
  const holder = {}
  array.forEach((d) => {
    if (holder.hasOwnProperty(d.pName)) {
      holder[d.pName] += d.pQuantity
    } else {
      holder[d.pName] = d.pQuantity
    }
  })

  const array2 = []

  for (const prop in holder) {
    if ({}.hasOwnProperty.call(holder, prop)) {
      array2.push({ pNameAndPid: prop, pQuantity: holder[prop] })
    }
  }

  return array2
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )
  const result = JSON.parse(jsonPayload)
  result.id = result.uid
  delete result.uid
  return result
}

export const shortDescription = ({ description, maxWord = LIMIT_TEXT }) => {
  if (!description) return ''

  const descWords = description.split(' ').length

  if (descWords <= maxWord) return description

  const shortDesc = description.split(' ').slice(0, maxWord).join(' ')

  return `${shortDesc}...`
}

// read levenshtein distance or edit distance for more information
export const editDistance = (s1 = '', s2 = '') => {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j
      } else if (j > 0) {
        let newValue = costs[j - 1]
        if (s1[i - 1] !== s2[j - 1]) {
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
        }
        costs[j - 1] = lastValue
        lastValue = newValue
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue
    }
  }
  return costs[s2.length]
}

export const getTimeZoneHour = () => {
  const date = new Date()
  const timeZoneOffsetInMinutes = date.getTimezoneOffset()
  return -timeZoneOffsetInMinutes / 60 // Chuyển đổi sang giờ và đảo dấu
}

// input is String
export const numDayFromNow = (day) => {
  const given = moment(day?.split(' ')[0], 'DD-MM-YYYY')
  const current = moment().startOf('day')
  return moment.duration(given.diff(current)).asDays()
}

export function removeAccents(str) {
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ]
  for (let i = 0; i < AccentsMap.length; i++) {
    const re = new RegExp(`[${AccentsMap[i].substr(1)}]`, 'g')
    const char = AccentsMap[i][0]
    str = str.replace(re, char)
  }
  return str
}

export const getCoordinates = async (lstAddress) => {
  const newAddress = []

  if (lstAddress.length > 0) {
    for (let i = 0; i < lstAddress.length; i++) {
      const { index, value } = lstAddress[i]
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_GOOGLE_KEY}`
      )
      const data = await res.json()
      if (data && data.results.length > 0) {
        const latitude = data.results[0].geometry.location.lat
        const longitude = data.results[0].geometry.location.lng

        newAddress.push({ index, lat: latitude, lng: longitude })
      }
    }
  }

  return newAddress
}

export const findNearestDate = (arrayOfDates, findDate) => {
  let nearestDate
  let momentsDate
  try {
    momentsDate = arrayOfDates
      .map((date) => {
        date = moment(date, 'DD-MM-YYYY')
        const diff = date.diff(moment(findDate, 'DD-MM-YYYY'), 'days')
        if (diff >= 0) {
          if (nearestDate) {
            if (date.diff(moment(nearestDate), 'days') < 0) {
              nearestDate = date
            }
          } else {
            nearestDate = date
          }
        }
        return date
      })
      .filter((isValid) => isValid)
    if (!nearestDate) {
      nearestDate = moment.max(momentsDate)
    }
    return nearestDate._i
  } catch (error) {
    return false
  }
}

export const findNearestDateOfEventClose = (arrayOfDates, findDate, listEvent) => {
  const getDateNearest = () => {
    let nearestDate
    let momentsDate
    try {
      momentsDate = arrayOfDates
        .map((date) => {
          date = moment(date, 'DD-MM-YYYY')
          const diff = date.diff(moment(findDate, 'DD-MM-YYYY'), 'days')
          if (diff >= 0) {
            if (nearestDate) {
              if (date.diff(moment(nearestDate), 'days') < 0) {
                nearestDate = date
              }
            } else {
              nearestDate = date
            }
          }
          return date
        })
        .filter((isValid) => isValid)
      if (!nearestDate) {
        nearestDate = moment.max(momentsDate)
      }
      return nearestDate._i
    } catch (error) {
      return false
    }
  }
  const listEventSameDateNearest = listEvent?.filter(
    (item) =>
      moment(item?.closingTime, 'DD-MM-YYYY').format('DD-MM-YYYY') ===
      moment(getDateNearest(), 'DD-MM-YYYY').format('DD-MM-YYYY')
  )
  const eventsCloseInFuture = listEvent?.filter((item) => {
    const convertDateNearest = moment(getDateNearest(), 'DD-MM-YYYY').format('YYYY-MM-DD')
    const convertDateClosing = moment(item.closingTime, 'DD-MM-YYYY').format('YYYY-MM-DD')
    return moment(convertDateClosing).isAfter(convertDateNearest)
  })
  return listEventSameDateNearest.concat(eventsCloseInFuture)
}

export const findNearestDateOfEventCollection = (arrayOfDates, findDate, listEvent) => {
  const getDateNearest = () => {
    let nearestDate
    let momentsDate
    try {
      momentsDate = arrayOfDates
        .map((date) => {
          date = moment(date, 'DD-MM-YYYY')
          const diff = date.diff(moment(findDate, 'DD-MM-YYYY'), 'days')
          if (diff >= 0) {
            if (nearestDate) {
              if (date.diff(moment(nearestDate), 'days') < 0) {
                nearestDate = date
              }
            } else {
              nearestDate = date
            }
          }
          return date
        })
        .filter((isValid) => isValid)
      if (!nearestDate) {
        nearestDate = moment.max(momentsDate)
      }
      return nearestDate._i
    } catch (error) {
      return false
    }
  }
  const listEventSameDateNearest = listEvent?.filter(
    (item) =>
      moment(item?.pickupTime, 'DD-MM-YYYY').format('DD-MM-YYYY') ===
      moment(getDateNearest(), 'DD-MM-YYYY').format('DD-MM-YYYY')
  )
  const eventsCollectionInFuture = listEvent?.filter((item) => {
    const convertDateNearest = moment(getDateNearest(), 'DD-MM-YYYY').format('YYYY-MM-DD')
    const convertDateCollection = moment(item.pickupTime, 'DD-MM-YYYY').format('YYYY-MM-DD')
    return moment(convertDateCollection).isAfter(convertDateNearest)
  })
  return listEventSameDateNearest.concat(eventsCollectionInFuture)
}

export const getFirstStringAndUppercase = (string) => {
  const str = string.split(' ')
  let s = ''
  for (let i = 0; i < str.length; i++) {
    if (i === 2) break
    s += str[i].substring(0, 1).toUpperCase()
  }

  return s
}

export const getDatesOfCurrentWeek = () => {
  const datesOfWeek2 = []
  const currentDate = moment().startOf('week')
  for (let i = 0; i < 7; i++) {
    datesOfWeek2.push({
      date: currentDate.format('YYYY-MM-DD'),
      title: currentDate.format('ddd'),
      isClose: false,
    })
    currentDate.add(1, 'days')
  }
  return datesOfWeek2
}

export const isDataObject = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      return true
    }
  }
  return false
}

export const isLazadaImgURL = (url) => !!url?.includes('sg-test-11.slatic.net')

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time))
