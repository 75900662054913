export const CLEAR_CART = 'CLEAR_CART'
export const ORDER_SET_DATE_AND_TIME = 'ORDER_SET_DATE_AND_TIME'
export const UPDATE_CART = 'UPDATE_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const GET_CART = 'GET_CART'
export const GET_LIST_ORDERS_SHOP_BY_HOST_REQUEST = 'GET_LIST_ORDERS_SHOP_BY_HOST_REQUEST'
export const GET_LIST_ORDERS_SHOP_BY_HOST_SUCCESS = 'GET_LIST_ORDERS_SHOP_BY_HOST_SUCCESS'
export const GET_LIST_ORDERS_SHOP_BY_HOST_FAILURE = 'GET_LIST_ORDERS_SHOP_BY_HOST_FAILURE'
export const GET_LIST_ORDER_FULFILLMENT = 'GET_LIST_ORDER_FULFILLMENT'
export const SYNC_LIST_ORDERS = 'SYNC_LIST_ORDERS'
export const CLEAR_LIST_ORDERS = 'CLEAR_LIST_ORDERS'
export const GET_LIST_ORDERS = 'GET_LIST_ORDERS'
export const UPDATE_STATUS_ORDER = 'UPDATE_STATUS_ORDER'
export const GET_LIST_ORDER_LOGIN = 'GET_LIST_ORDER_LOGIN'
export const GET_LIST_ORDER_NO_LOGIN = 'GET_LIST_ORDER_NO_LOGIN'
export const UPDATE_NEW_LIST_PURCHASE = 'UPDATE_NEW_LIST_PURCHASE'
export const UPDATE_STATUS_ORDER_NO_LOGIN = 'UPDATE_STATUS_ORDER_NO_LOGIN'
export const GET_ORDER_DETAILS_BY_REF_NO_LOGIN = 'GET_ORDER_DETAILS_BY_REF_NO_LOGIN'
export const DELETE_ORDER_BY_BUYER_NO_LOGIN = 'DELETE_ORDER_BY_BUYER_NO_LOGIN'
export const CLEAR_LIST_PURCHASE = 'CLEAR_LIST_PURCHASE'
export const CLEAR_ORDER_DETAILS_BY_REF = 'CLEAR_ORDER_DETAILS_BY_REF'
export const SET_LIST_DATE_IS_PICKED = 'SET_LIST_DATE_IS_PICKED'
export const FETCH_LIST_ORDER_LAZADA = 'FETCH_LIST_ORDER_LAZADA'
export const TOGGLE_SHOW_ORDER_PRODUCT = 'TOGGLE_SHOW_ORDER_PRODUCT'
export const UPDATE_STATUS_ORDER_LAZADA = 'UPDATE_STATUS_ORDER_LAZADA'
export const SELECT_ALL_ORDER_ITEMS = 'SELECT_ALL_ORDER_ITEMS'
export const SELECT_CHECKBOX_ORDER_ITEM = 'SELECT_CHECKBOX_ORDER_ITEM'
export const UPDATE_LIST_ORDER_LAZADA = 'UPDATE_LIST_ORDER_LAZADA'
export const UPDATE_LIST_ORDER_ITEMS_AFTER_REPACKAGED = 'UPDATE_LIST_ORDER_ITEMS_AFTER_REPACKAGED'
export const GET_LIST_PURCHASE = 'GET_LIST_PURCHASE'
export const CLEAR_LIST_MY_PURCHASE = 'CLEAR_LIST_MY_PURCHASE'
export const GET_REVERSE_ORDER_FOR_SELLER = 'GET_REVERSE_ORDER_FOR_SELLER'
export const CLEAR_LIST_ORDERS_BY_HOST = 'CLEAR_LIST_ORDERS_BY_HOST'
export const UPDATE_STATUS_DELIVERY_FAILED = 'UPDATE_STATUS_DELIVERY_FAILED'
export const UPDATE_STATUS_DELIVERY_CONFIRMED = 'UPDATE_STATUS_DELIVERY_CONFIRMED'
export const GET_REVERSE_ORDER_HISTORY = 'GET_REVERSE_ORDER_HISTORY'
export const GET_REVERSE_ORDER_DETAIL = 'GET_REVERSE_ORDER_DETAIL'
export const GET_REVERSE_ORDER_REASON = 'GET_REVERSE_ORDER_REASON'
export const SELECT_TAB_ORDER_PLATFORM = 'SELECT_TAB_ORDER_PLATFORM'
export const DELETE_ORDER_BY_BUYER_LOGIN = 'DELETE_ORDER_BY_BUYER_LOGIN'
