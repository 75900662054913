import { VIEW_GRID } from '_utils/constant'
import classNames from 'classnames'
import React from 'react'
import ProductItem from './ProductItem'

function SingleListProduct(props) {
  const { products, viewDefault } = props

  return (
    <div className='confirm__shop-product-list row flex-row'>
      {products?.length > 0 ? (
        products[0]?.items?.map((item, i) => (
          <div
            className={classNames('col mb-3', viewDefault === VIEW_GRID ? 'col-6' : 'col-12')}
            key={i}
          >
            <ProductItem item={item} viewDefault={viewDefault} />
          </div>
        ))
      ) : (
        <div className='col' key={'0'}>
          <p>Something went wrongs</p>
        </div>
      )}
    </div>
  )
}

export default SingleListProduct
