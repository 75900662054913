/* eslint-disable no-extra-semi */
/* eslint-disable function-paren-newline */
import orderApi from '_api/order'
import LoadingOverlay from '_components/LoadingOverlay'
import {
  clearOrderDetailsByRef,
  getListMyPurchaseLogin,
  getListMyPurchaseNoLogin,
  onFilterMyPurchase,
  updateOrderCanceledByRef,
} from '_redux/modules/order'
import { clearShopAndShopDetail } from '_redux/modules/shop'
import { BUYER_ORDER_CANCELED } from '_utils/constant'
import { flexibleSearch, scrollToTop } from '_utils/function'
import { getUserToken, removeCartOrderDetailsByRef } from '_utils/localData'
import React, { useEffect, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiArrowBack } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import FilterMyPurchase from './components/FilterMyPurchase'
import ListMyPurchase from './components/ListMyPurchase'
import './style.scss'

function MyPurchase() {
  const dispatch = useDispatch()
  const userToken = getUserToken()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [refOrder, setRefOrder] = useState('')
  const [search, setSearch] = useState('')

  const { listPurchase, originalListPurchase } = useSelector((state) => state.order)

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if (userToken) {
      const fetchData = async () => {
        try {
          setLoading(true)
          await dispatch(getListMyPurchaseLogin())
          dispatch(clearOrderDetailsByRef())
          dispatch(clearShopAndShopDetail())
          removeCartOrderDetailsByRef()
        } catch (e) {
          addToast(e.message || e.msgResp, {
            appearance: 'error',
            autoDismiss: true,
          })
        } finally {
          setLoading(false)
        }
      }

      fetchData().then(() => {})
    }
  }, [userToken])

  const handleChangeInput = (e) => {
    const { value } = e.target

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (emailRegex.test(value)) {
      setEmail(value)
    } else {
      setRefOrder(value)
    }

    setSearch(value)
  }

  const listPurchaseFiltered = useMemo(() => {
    if (search.length === 0) {
      return listPurchase
    }

    return listPurchase?.filter((purchase) =>
      Object.values(purchase).some(
        (value) => value !== null && flexibleSearch(value.toString(), search)
      )
    )
  }, [search, JSON.stringify(listPurchase)])

  const submitGetListPurchaseNoLogin = async (e) => {
    e.preventDefault()

    if (!email) {
      return addToast('Please enter your email', { appearance: 'error', autoDismiss: true })
    }

    try {
      setLoading(true)
      await dispatch(getListMyPurchaseNoLogin(email))

      setEmail('')
      setRefOrder('')
      setSearch('')
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  const cancelPurchaseWithLogin = async (purchaseItem) => {
    Swal.fire({
      title: 'Are you sure to cancel?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (!purchaseItem.uid) {
            return addToast(
              'This order is not logged in or is not yours, so it cannot be canceled',
              {
                appearance: 'error',
                autoDismiss: true,
              }
            )
          }

          const { ref } = purchaseItem

          await orderApi.updateStatusOrderByBuyer({
            ref,
            status: BUYER_ORDER_CANCELED,
          })

          dispatch(updateOrderCanceledByRef(ref))
          Swal.fire('Canceled!', 'Your file has been canceled.', 'success')
        } catch (error) {
          Swal.fire('Error!', error.message || error.msgResp, 'error')
        }
      }
    })
  }

  const cancelPurchaseNoLogin = async (purchaseItem) => {
    Swal.fire({
      title: 'Are you sure to cancel?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { ref } = purchaseItem

          await orderApi.updateStatusOrderByBuyer({
            ref,
            status: BUYER_ORDER_CANCELED,
          })

          dispatch(updateOrderCanceledByRef(ref))
          Swal.fire('Canceled!', 'Your file has been canceled.', 'success')
        } catch (error) {
          Swal.fire('Error!', error.message || error.msgResp, 'error')
        }
      }
    })
  }

  const onCancelPurchase = async (purchaseItem) => {
    if (userToken) {
      await cancelPurchaseWithLogin(purchaseItem)
    } else {
      await cancelPurchaseNoLogin(purchaseItem)
    }
  }

  const onFilter = (e) => {
    const statusSelected = Number(e.target.value)

    dispatch(onFilterMyPurchase(statusSelected))
  }

  return (
    <div className='my-purchase container mt-3 px-0'>
      <div className='content p-3 rounded'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Link to='/virtual-shop/list' className='w-100 mb-1 d-flex align-items-center'>
            <BiArrowBack />
            <span className='ms-1'>Back</span>
          </Link>
        </div>
        <h5 className='text-center fw-bold mb-3' style={{ color: '#17a2b8' }}>
          List Your Purchase
        </h5>

        <form onSubmit={submitGetListPurchaseNoLogin}>
          <div className={'row mb-3'}>
            <div className='col-10'>
              <input
                type='email'
                className={'form-control-sm border w-100'}
                placeholder={'Enter your email to get list purchase'}
                value={email || refOrder || search}
                onChange={(e) => handleChangeInput(e)}
              />
            </div>
            <div className='col-2'>
              <button type={'submit'} className={'shadow-none rounded h-100 btn-search'}>
                <AiOutlineSearch size={22} />
              </button>
            </div>
          </div>
        </form>

        {loading ? (
          <LoadingOverlay
            style={{
              height: 300,
              marginTop: 100,
            }}
          />
        ) : null}

        {!loading && originalListPurchase?.length ? <FilterMyPurchase onFilter={onFilter} /> : null}

        {!loading && originalListPurchase.length ? (
          <ListMyPurchase
            listPurchaseFiltered={listPurchaseFiltered}
            cancelPurchase={onCancelPurchase}
          />
        ) : null}

        {!loading && originalListPurchase?.length === 0 ? (
          <div className='text-center mt-5'>
            <p className='mb-5'>You have not purchased any items yet.</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default MyPurchase
