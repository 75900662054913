import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import cancelImage from '_images/cancel.png'
import noImage from '_images/noimage02.svg'
import { DEFAULT_PRODUCT_EVENT } from '_utils/constant'
import { getCreateEventData, setCreateEventData } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import * as Yup from 'yup'
import ProductList from '../components/ProductList'
import './style.scss'

const validationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      pid: Yup.string(),
      name: Yup.string().required('Product name is required'),
      description: Yup.string().nullable().notRequired(),
      price: Yup.number()
        .typeError('Product price is required')
        .required('Product price is required'),
      maxQuantity: Yup.number()
        .typeError('Available stock is required')
        .required('Available stock is required'),
      defaultQuantity: Yup.number()
        .typeError('Default quantity is required')
        .default(0)
        .required('Default quantity is required'),
      limitPerOrder: Yup.number()
        .typeError('Limit per order is required')
        .required('Limit per order is required'),
      minOrderQty: Yup.number()
        .typeError('Minimum order quantity must be greater than 0')
        .min(1, 'Minimum order quantity must be greater than 0')
        .required('Minimum order quantity must not be blank.'),
      photoUrls: Yup.array().notRequired().nullable(),
      subItems: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('Sub item name is required'),
          required: Yup.boolean().default(false),
          list: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Option name is required'),
              price: Yup.number()
                .typeError('Option price is required')
                .required('Option price is required'),
            })
          ),
        })
      ),
    })
  ),
})

const EventCreateItem = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const createEventData = getCreateEventData()
  const { selectedShop } = useSelector((state) => state.shop)

  const idParam = new URLSearchParams(window.location.search).get('id')

  useEffect(() => {
    if (selectedShop?.id && !createEventData && !idParam) {
      history.push(`/admin-console/${selectedShop?.shopSlug}/event/create`)
    }
  }, [selectedShop?.id, idParam, JSON.stringify(createEventData)])

  const [listImageEvent, setListImageEvent] = useState(
    createEventData?.photoUrls.map((item) => ({ url: item, file: '' } || []))
  )

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    defaultValues: createEventData || { products: [DEFAULT_PRODUCT_EVENT] },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  })

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImageEvent.length > 5) {
      addToast('Maximum images for event is 5!', { appearance: 'warning', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 5) {
        listImageEvent.push({ url: URL.createObjectURL(files[i]), file: files[i] })
      }
    }
    setListImageEvent([...listImageEvent])
  }

  const handleOnRemoveImg = (index) => {
    listImageEvent.splice(index, 1)
    setListImageEvent([...listImageEvent])
  }

  const onSubmit = async (_data) => {
    const list = listImageEvent
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.file) {
        const { msgResp } = await uploadApi.uploadPhoto([element.file])
        element.url = msgResp.url
      }
    }
    _data.photoUrls = list.map((item) => item.url)
    if (_data?.products?.length === 0) {
      addToast('You must have at least 1 product!!!', { appearance: 'error', autoDismiss: true })
      return
    }
    _data.products = _data.products?.map((product) => ({
      ...product,
      minOrderQty: product?.minOrderQty ? product?.minOrderQty : 1,
      subItems: product.subItems.map(({ id, ...subItem }) => subItem),
    }))

    setCreateEventData({
      ..._data,
      shopId: selectedShop?.id,
    })

    if (idParam) {
      history.push(`/admin-console/${selectedShop?.shopSlug}/event/update-confirm?id=${idParam}`)
    } else {
      history.push(`/admin-console/${selectedShop?.shopSlug}/event/created-confirm`)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='create-items-page'>
      <div className='row-top'>
        <h4 className='text-center c-ttl-blue mt-4'>Banner</h4>
        <div className='d-flex justify-content-end w-100'>
          <span className='add-more-image'>Remain: ({5 - listImageEvent.length} photos left)</span>
        </div>

        <div className='c-form upload-image-square mt-3 mb-3'>
          <div className='upload-image'>
            <img src={noImage} alt='' />
            <div className='upload-image-txt'>
              <span>Upload Image</span>
            </div>
            <div className='u-file-upload__item'>
              <div className='u-file-upload__wrap'>
                <a className='c-upload preview-images-zone'>
                  <input
                    type='file'
                    accept='image/*'
                    className='inputFile'
                    onChange={onUserImageUploaded}
                    readOnly
                    multiple
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <h4 className='text-center c-ttl-blue mt-4'>Items for Sales</h4>

        <div className='list-image'>
          {listImageEvent.map((item, index) => (
            <div key={index} className='upload-image'>
              <img src={item.url} alt='' className='main-image' />
              <img
                src={cancelImage}
                className='cancel-btn'
                onClick={() => handleOnRemoveImg(index)}
              />
            </div>
          ))}
        </div>

        <ProductList {...{ control, register, getValues, setValue, errors, watch }} />
      </div>

      <div className='row-bot mt-4'>
        <div className='row'>
          <div className='col-6'>
            <button
              className='btn btn--solid btn--solid03'
              onClick={() => {
                setCreateEventData(getValues())
                if (createEventData?.id || idParam) {
                  history.push(
                    `/admin-console/${selectedShop?.shopSlug}/event/update?id=${
                      createEventData?.id || idParam
                    }`
                  )
                } else {
                  history.push(`/admin-console/${selectedShop?.shopSlug}/event/create`)
                }
              }}
            >
              PREVIOUS
            </button>
          </div>
          <div className='col-6'>
            <button
              className='btn btn--main'
              type='submit'
              disabled={isSubmitting}
              onClick={() => {
                if (errors?.products?.length > 0) {
                  return addToast('Please fill out all required fields', {
                    appearance: 'error',
                    autoDismiss: true,
                  })
                }
              }}
            >
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'NEXT'}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EventCreateItem
