import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller } from 'react-hook-form'

function PickupFee({ checkedPickupFee, handleAddPickupFee, pickupFee, errors, control }) {
  return (
    <div className='mb-3 row align-items-center'>
      <div className='col-5'>
        <span>Pick-up Fee($)</span>
      </div>
      <div className='col-7'>
        <div className='form-check form-switch'>
          <input
            className='form-check-input mt-0 check__from cursor-pointer'
            type='checkbox'
            checked={checkedPickupFee}
            role='switch'
            onChange={(e) => handleAddPickupFee(e)}
          />
        </div>
      </div>
      {checkedPickupFee && (
        <>
          <div className='w-100'></div>
          <div className='col-4 mt-2'>
            <Controller
              name='pickupFee'
              control={control}
              defaultValue={pickupFee || 0}
              render={({ field: { onChange, value } }) => (
                <CurrencyFormat
                  value={value || 0}
                  displayType={'input'}
                  thousandSeparator={true}
                  prefix='$'
                  className='form-control-sm border w-100'
                  onValueChange={(values) => {
                    onChange(values.value)
                  }}
                />
              )}
            />
            <small className='errorMessage'>{errors?.pickupFee?.message}</small>
          </div>
        </>
      )}
    </div>
  )
}

export default PickupFee
