import { LIMIT_TEXT } from '_utils/constant'
import { shortDescription } from '_utils/function'
import { convertToCurrentGMT } from '_utils/functions/converter'
import { generateIdWithLength } from '_utils/functions/generator'
import { getUserInfo } from '_utils/localData'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HeaderEventInfo from '../components/HeaderEventInfo'

export default function EventItem({ event, view, toggleBroadcastModal }) {
  const history = useHistory()
  // const setOpenModalJoinGroup = useContext(ListAllEventContext)?.setOpenModalJoinGroup
  // const setGroup = useContext(ListAllEventContext)?.setGroup
  // const { user } = useSelector((state) => state.user)
  const userInfo = getUserInfo()
  const { myShops, selectedShop } = useSelector((state) => state.shop)

  const [isShowMore, setIsShowMore] = useState(false)
  const [shareCode, setShareCode] = useState('')

  const listImages = event?.photoUrls?.map((item) => ({ original: item })) || []

  const shopName = myShops.find((shop) => shop.id === event?.shopId)?.shopName

  useEffect(() => {
    const getShareCode = async () => {
      // const { msgCode, msgResp } = await eventApi.getShareEventCode(event.id)
      // if (msgCode % 100 === 0) {
      //   setShareCode(msgResp.shareCode)
      // }

      setShareCode(generateIdWithLength(8))
    }

    if (event?.shareCode) {
      setShareCode(event.shareCode)
    } else {
      getShareCode()
    }
  }, [event])

  const showMore = () => {
    setIsShowMore(true)
  }

  const directToEventDetail = async (selectedEvent) =>
    window.open(
      `https://co-bee.com/event/detail-shared?eid=${selectedEvent.id}&shareCode=${shareCode}`,
      '_blank'
    )

  const directToEditEvent = (selectedEvent) => {
    history.push(`/admin-console/${selectedShop?.shopSlug}/event/update?id=${selectedEvent.id}`)
  }

  const directToCheckOrders = (selectedEvent) => {
    history.push(`/admin-console/${selectedShop?.shopSlug}/order-records?eid=${selectedEvent.id}`)
  }

  return (
    <>
      <div
        className='group-list__item event-item flex-column p-0'
        style={{
          width: view === 'grid' ? 'calc(50% - 10px)' : '50%',
          maxWidth: view === 'grid' ? 'calc(50% - 10px)' : '50%',
          margin: view === 'grid' ? '' : '0 auto 1rem auto',
        }}
      >
        <div className='p-3 w-100'>
          <HeaderEventInfo
            createdUserPhotoUrl={event?.createdUserPhotoUrl}
            createdUserName={event?.createdUserName || userInfo?.name}
            eClosingTime={convertToCurrentGMT(event?.closingTime)}
            eTotalOrderCount={event?.totalOrderCount}
            shopName={shopName}
          />
          <div className='group-list__des'>
            {listImages?.length > 0 && (
              <ReactImageGallery
                items={listImages}
                lazyLoad
                autoPlay
                showPlayButton={false}
                showNav={false}
                showBullets={true}
                showThumbnails={false}
                onClick={() => directToEventDetail(event)}
                additionalClass={
                  view === 'grid' ? 'image-gallery img--grid' : 'image-gallery img--list'
                }
              />
            )}

            <h4>
              <button
                className={'bg-transparent border-0 text-blue'}
                onClick={() => directToEventDetail(event)}
              >
                {event?.title}
              </button>
            </h4>
            <p className='paragraph-pre-wrap mb-0'>
              <button
                className={'bg-transparent border-0 text-start'}
                onClick={() => directToEventDetail(event)}
              >
                {isShowMore
                  ? event?.description
                  : shortDescription({ description: event?.description })}
              </button>
              {!isShowMore &&
              event?.description &&
              event?.description.split(' ').length > LIMIT_TEXT ? (
                <span className='read-more' onClick={showMore}>
                  More
                </span>
              ) : null}
            </p>
          </div>
        </div>

        <div className='group-list__footer d-flex align-items-center w-100 rounded'>
          <button
            className='btn bg-transparent shadow-none rounded-0'
            onClick={() => window.open('https://co-bee.com/', '_blank')}
          >
            View Event
          </button>
          <button
            className='btn bg-transparent shadow-none rounded-0'
            onClick={() => directToEditEvent(event)}
          >
            Edit Event
          </button>
          <button
            className='btn bg-transparent shadow-none rounded-0'
            onClick={toggleBroadcastModal}
          >
            Broadcast
          </button>
          <button
            className='btn bg-transparent shadow-none rounded-0'
            onClick={() => directToCheckOrders(event)}
          >
            Check Orders
          </button>
        </div>
      </div>
    </>
  )
}

EventItem.propTypes = {
  event: PropTypes.shape({
    createdUserId: PropTypes.string,
    groupId: PropTypes.string,
  }),
}
