import { DELIVERY_OPTION } from '_utils/constant'
import React from 'react'
import CurrencyFormat from 'react-currency-format'

function RenderInfo({ fee, instructions, isCheckPublicHoliday, type }) {
  return (
    <div
      className='rounded-3 p-3 render-info mb-3'
      style={{
        backgroundColor: '#edfbfb',
        color: '#679e9c',
      }}
    >
      <p className='mb-0 fw-semibold'>
        {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Charge:
      </p>
      <p>
        {fee ? (
          <CurrencyFormat
            value={fee || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ) : (
          'Free'
        )}
      </p>

      {instructions?.length > 0 && (
        <>
          <p className='mb-0 fw-semibold'>
            {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Instructions:
          </p>
          <p>{instructions}</p>
        </>
      )}

      {isCheckPublicHoliday && (
        <p className='fw-semibold'>
          No {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} during Public Holiday
        </p>
      )}
    </div>
  )
}

export default RenderInfo
