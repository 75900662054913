import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { ORDER_CO_KII, ORDER_LAZADA } from '_utils/constant'

import React, { useContext } from 'react'
import ListOrderRecord from '../CoHoot/ListOrderRecord'
import ListOrderEventRecord from '../Event/ListOrderEventRecord'
import ListOrderLazada from '../Lazada/ListOrderLazada'

function RenderListOrder() {
  const { tabOrderPlatform, tabOrderEvent } = useContext(OrderRecordContext)

  if (tabOrderEvent) {
    return <ListOrderEventRecord />
  }

  if (tabOrderPlatform === ORDER_LAZADA) {
    return <ListOrderLazada />
  }

  if (tabOrderPlatform === ORDER_CO_KII) {
    return <ListOrderRecord />
  }

  return null
}

export default RenderListOrder
