import { filterEventStatus } from '_redux/modules/shop'
import { TURN_OFF, TURN_ON } from '_utils/constant'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function EventFilterStatus() {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  return (
    <div className='event-filter-status rounded'>
      <select
        className='form-select-sm border'
        onChange={(e) => dispatch(filterEventStatus(e.target.value, selectedShop?.id))}
      >
        <option value='all'>All</option>
        <option value={TURN_ON}>Active</option>
        <option value={TURN_OFF}>Inactive</option>
      </select>
    </div>
  )
}
