import CollapseProvider from '_contexts/CollapseMenuContext'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { getCreateShopData } from '../utils/localData'
import MainDigistallDesktop from './Main'
import Header from './components/Header'

function DigistallDesktop() {
  const history = useHistory()
  const { addToast } = useToasts()
  const dataCreateShop = getCreateShopData()

  useEffect(() => {
    const isOwnerShop = true

    if (!isOwnerShop && !dataCreateShop) {
      history.push('/virtual-shop/list')
      addToast('You are not owner this shop', { appearance: 'error', autoDismiss: true })
    }
  }, [])

  return (
    <CollapseProvider>
      <Header />
      <section className='section-content section-content--top mains-screen pb-0' id='checkHeight'>
        <MainDigistallDesktop />
      </section>
    </CollapseProvider>
  )
}

export default DigistallDesktop
