import LongText from '_components/LongText'
import MySlider from '_components/MySlider'
import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import moment from 'moment'
import PropTypes from 'prop-types'
import { BiLinkAlt } from 'react-icons/bi'
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const LinkIcons = ({ shop }) => {
  const disableWhatsApp = !shop?.whatsApp || shop?.whatsApp?.length === 0
  const disableFacebook = !shop?.linkFacebook || shop?.linkFacebook?.length === 0
  const disableInstagram = !shop?.linkInstagram || shop?.linkInstagram?.length === 0
  const disableColor = {
    filter: 'grayscale(100%)',
    pointerEvents: 'none',
    opacity: 0.5,
  }

  return (
    <>
      <a
        href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${shop?.whatsApp}`}
        target='blank'
        className='whats-app'
        style={disableWhatsApp ? disableColor : {}}
      >
        <BsWhatsapp color='#25d366' />
      </a>

      <a
        href={shop?.linkInstagram}
        target='blank'
        className='instagram'
        style={disableInstagram ? disableColor : {}}
      >
        {/* This line used to hover gradient color */}
        <svg width='0' height='0'>
          <radialGradient id='instagram-gradient' r='150%' cx='30%' cy='107%'>
            <stop stopColor='#fdf497' offset='0' />
            <stop stopColor='#fdf497' offset='0.05' />
            <stop stopColor='#fd5949' offset='0.45' />
            <stop stopColor='#d6249f' offset='0.6' />
            <stop stopColor='#285AEB' offset='0.9' />
          </radialGradient>
        </svg>
        <BsInstagram
          style={
            shop.linkInstagram?.length === 0
              ? { filter: 'grayscale(100%)' }
              : {
                  stroke: 'url(#instagram-gradient)',
                  fill: 'url(#instagram-gradient)',
                }
          }
        />
      </a>

      <a
        href={shop?.linkFacebook}
        target='blank'
        className='facebook'
        style={disableFacebook ? disableColor : {}}
      >
        <BsFacebook color='#1778f2' />
      </a>

      <a href={shop?.shopLink} target='blank' className='domain-link'>
        <BiLinkAlt color='#007bff' />
      </a>
    </>
  )
}

function ShopItem({ shop }) {
  const hoursCreated = moment().diff(moment(shop.createdAt * 1000), 'hours')
  const { shopSlug } = shop

  return (
    <div className='shop-item bg-white shadow-sm overflow-hidden'>
      <Link to={`/${shopSlug}`} className='me-0'>
        <div className='wrap-banner'>
          <MySlider listImage={shop.logo} />
        </div>
      </Link>

      <div className='shop-content p-3'>
        <div className='shop-name d-flex align-items-center mb-2'>
          <Link to={`/${shopSlug}`}>{shop.shopName}</Link>
          {hoursCreated < 24 ? <span className='badge bg-danger'>New</span> : null}
        </div>
        <div className='shop-description'>
          <LongText content={shop.headline} limit={100} />
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='link-icons'>
            <LinkIcons shop={shop} />
          </div>
          <div className='author d-flex align-items-start flex-column'>
            <small>Created by: {shop.createdUserName}</small>
            <small>Date: {moment(shop.createdAt * 1000).format('DD/MM/YYYY')}</small>
          </div>
        </div>
      </div>
    </div>
  )
}

ShopItem.propTypes = {
  shop: PropTypes.object.isRequired,
}

export default ShopItem
