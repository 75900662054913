import PropTypes from 'prop-types'
import React from 'react'

function Badge({ className = '', type = 'info', style = {}, children }) {
  return (
    <span
      className={`badge badge-${type} bg-${type} ${className}`}
      style={style}
    >
      {children}
    </span>
  )
}

Badge.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Badge
