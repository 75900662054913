import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export default function OrderComment({
  openOrderComment,
  toggleOpenModalComment,
  selectedOrder,
  ...args
}) {
  return (
    <Modal isOpen={!!openOrderComment} toggle={toggleOpenModalComment} {...args}>
      <ModalHeader toggle={toggleOpenModalComment}>{selectedOrder?.uName}</ModalHeader>
      <ModalBody>{selectedOrder?.comment}</ModalBody>
    </Modal>
  )
}
