import { isEmpty } from 'lodash'
import React, { Fragment } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useLocation } from 'react-router-dom'

function RenderListSingle({ item }) {
  const { name, priceNoSubItems, quantity, subItems } = item

  const calculateTotalPrice = () => {
    const totalSubItemsPrice = subItems.length
      ? subItems.reduce((acc, curr) => acc + curr.price, 0)
      : 0

    const totalPrice = (priceNoSubItems + totalSubItemsPrice) * quantity

    return totalPrice || 0
  }

  return (
    <>
      {quantity > 0 && (
        <div className='row'>
          <div className='col-6 col-md-8 product-name'>
            <ol className='toc-list f-14' role='list'>
              <li>
                <span>
                  {name}
                  {item?.priceNoSubItems > 0 ? (
                    <>
                      :{' '}
                      <CurrencyFormat
                        className='text-secondary fw-bold'
                        value={item?.priceNoSubItems || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </>
                  ) : null}
                </span>
                {subItems.length > 0 ? (
                  <ol>
                    {subItems.map((subItem, index) => (
                      <li key={index}>
                        <span className='product-item'>
                          {subItem.name}{' '}
                          {subItem.price > 0 ? (
                            <>
                              :{' '}
                              <CurrencyFormat
                                className='text-secondary fw-bold'
                                value={subItem.price || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </>
                          ) : null}
                        </span>
                      </li>
                    ))}
                  </ol>
                ) : null}
              </li>
            </ol>
          </div>
          <div className='col-3 col-md-2 product-quantity'>QTY: {quantity}</div>
          <div className='col-3 col-md-2 product-total-price text-end text-break'>
            {priceNoSubItems > 0 ? (
              <div>
                <CurrencyFormat
                  value={calculateTotalPrice()}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix='$'
                />{' '}
                SGD
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}

function Bill({ cart, totalPrice, subTotal, orderDetailsByRef }) {
  const { pathname } = useLocation()

  const orderRefParams = pathname.split('/')[pathname.split('/').length - 1]

  return (
    <div className='bg-white p-3 rounded bill-info'>
      <div className='bill'>
        {cart?.map((item, i) => (
          <Fragment key={i}>
            <RenderListSingle item={item} cart={cart} orderRef={orderRefParams} />
          </Fragment>
        ))}
        <hr />

        <div className='total-price text-black f-14'>
          <div className='row text-secondary'>
            <div className='col-12 text-end'>
              <div className='row mb-1'>
                <div className='col-6 text-start'>Sub-Total:</div>
                <div className='col-6'>
                  {subTotal === 0 ? (
                    'Free'
                  ) : (
                    <>
                      <CurrencyFormat
                        value={subTotal || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix='$'
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />{' '}
                      SGD
                    </>
                  )}
                </div>
              </div>
              {!isEmpty(orderDetailsByRef?.orderDelivery) &&
                orderDetailsByRef?.orderDelivery?.deliveryFee > 0 && (
                  <div className='row mb-1'>
                    <div className='col-6 text-start'>Delivery Fee:</div>
                    <div className='col-6'>
                      <>
                        <CurrencyFormat
                          value={orderDetailsByRef?.orderDelivery?.deliveryFee || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix='$'
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />{' '}
                        SGD
                      </>
                    </div>
                  </div>
                )}
              {!isEmpty(orderDetailsByRef?.orderPickup) &&
                orderDetailsByRef?.orderPickup?.pickupFee > 0 && (
                  <div className='row mb-1'>
                    <div className='col-6 text-start'>Pick-up Fee:</div>
                    <div className='col-6'>
                      <>
                        <CurrencyFormat
                          value={orderDetailsByRef?.orderPickup?.pickupFee || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix='$'
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />{' '}
                        SGD
                      </>
                    </div>
                  </div>
                )}
              <div className='row text-danger'>
                {orderDetailsByRef?.transactionFees && (
                  <>
                    <div className='col-6 text-start'>This order will add transaction fee:</div>
                    <div className='col-6'>
                      <CurrencyFormat
                        value={orderDetailsByRef?.transactionFees || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix='$'
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />{' '}
                      SGD
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className='row fw-bold' style={{ color: '#15cdca' }}>
            <div className='col-6'>Total:</div>
            <div className='col-6 text-end'>
              <CurrencyFormat
                value={totalPrice || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix='$'
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              SGD
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bill
