import React, { useContext } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ProductItemContext } from './ProductItem'

function ModalSetting({ isModal, toggleModal, product }) {
  const { register, errors, handleSubmit, setValue } = useContext(ProductItemContext)

  const onSubmit = () => {
    toggleModal()
  }

  return (
    <Modal toggle={toggleModal} isOpen={isModal} className='modal-setting'>
      <ModalHeader toggle={toggleModal}>Setting - {product.name}</ModalHeader>
      <ModalBody style={{ fontSize: 16 }}>
        <Row className='mb-3'>
          <Col sm='4'>Limit per order</Col>
          <Col sm='8'>
            <CurrencyFormat
              value={product.limitPerOrder || 0}
              displayType='input'
              thousandSeparator={true}
              className='form-control-sm border rounded w-100'
              onValueChange={(values) => setValue('limitPerOrder', values.value)}
              {...register('limitPerOrder')}
            />
            {errors?.limitPerOrder && (
              <small className='text-danger'>{errors?.limitPerOrder?.message}</small>
            )}
          </Col>
        </Row>

        <Row className='mb-0'>
          <Col sm='4'>Minimum per order</Col>
          <Col sm='8'>
            <CurrencyFormat
              value={product.minOrderQty || 0}
              displayType='input'
              thousandSeparator={true}
              className='form-control-sm border rounded w-100'
              onValueChange={(values) => setValue('minOrderQty', values.value)}
              {...register('minOrderQty')}
            />

            {errors?.minOrderQty && (
              <small className='text-danger'>{errors?.minOrderQty?.message}</small>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='border-top-0 py-0'>
        <button className='btn btn-submit mx-0' onClick={handleSubmit(onSubmit)}>
          OK
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalSetting
