import { CollapseContext } from '_contexts/CollapseMenuContext'
import ShopDetail from '_pages/VirtualShop/ShopDetail'
import React, { useContext } from 'react'
import Footer from '../components/Footer'

function ShopFront() {
  const { isCollapse } = useContext(CollapseContext)

  return (
    <div className='managed-shop-front'>
      <>
        <ShopDetail />
        <Footer
          style={{
            width: !isCollapse ? '82.33vw' : '90.6vw',
            position: 'fixed',
            left: 'inherit',
          }}
        />
      </>
    </div>
  )
}

export default ShopFront
