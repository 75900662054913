import InputField from '_components/InputField'
import React from 'react'

const EventTitle = (props) => {
  const { register, error } = props
  return (
    <div className='form-group' data-testid='event-title'>
      <label>
        Title<span className='sys'>*</span>
      </label>
      <div>
        <div className='form-group'>
          <InputField
            className={`form-control textarea text-left ${error ? 'is-invalid' : ''}`}
            name={register.name}
            type='text'
            register={register}
          />
          {error && <small className='errorMessage'>{error}</small>}
        </div>
      </div>
    </div>
  )
}

export default EventTitle
