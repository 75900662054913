import ProtectedRoute from '_components/ProtectedRoute'
import { FirebaseContext } from '_contexts/FirebaseContext'
import useScript from '_hooks/useScript'
import ForgotPasswordPage from '_pages/ForgotPassword'
import Policy from '_pages/Policy'
import ResetPasswordPage from '_pages/ResetPassword'
// import SignInPage from '_pages/SignIn'
// import SignUpPage from '_pages/SignUp'
import WithErrorBoundary from '_components/WithErrorBoundary'
import { store } from '_redux/store'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import DigistallDesktop from '../digistall-desktop-pages'
import { firebase } from '../firebase/config'
import LandingPage from '../landing-page'
import MainPage from './Main'

const RedirectGoogleSites = () => {
  useEffect(() => {
    window.location.href = 'https://sites.google.com/co-bee.com/home'
  }, [])

  return null
}

export default function App() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') useScript()

  return (
    <Provider store={store}>
      <ToastProvider>
        <FirebaseContext.Provider value={{ firebase }}>
          <Router history={history}>
            <Switch>
              <Route path='/' exact component={LandingPage} />
              <Route>
                <div id='digistall'>
                  <Switch>
                    {/* <Route path='/sign-in' exact={true} component={SignInPage} />
                    <Route path='/sign-up' exact={true} component={SignUpPage} /> */}
                    <Route path='/reset-password' exact={true} component={ForgotPasswordPage} />
                    <Route path='/reset-password-with-code' component={ResetPasswordPage} />
                    <Route path='/info' exact={true} component={RedirectGoogleSites} />
                    <Route path='/policy' exact={true} component={Policy} />
                    <ProtectedRoute
                      path='/admin-console'
                      component={WithErrorBoundary(DigistallDesktop)}
                    />
                    <Route path='/' component={WithErrorBoundary(MainPage)} />
                  </Switch>
                </div>
              </Route>
            </Switch>
          </Router>
        </FirebaseContext.Provider>
      </ToastProvider>
    </Provider>
  )
}
