import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import React from 'react'
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { IoLocationSharp } from 'react-icons/io5'

function ShopSocialIcon({ shopDetail, handleOnClick }) {
  const shopColorTheme = shopDetail?.shopColorTheme
  const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme

  return (
    <div
      className='bg-white social-media d-flex flex-column justify-content-center align-items-center'
      style={{
        gap: '1rem',
      }}
    >
      <div className='home'>
        <a
          rel='noreferrer'
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            shopDetail?.shopAddress
          )}`}
          target={'_blank'}
          style={{ color: colorTheme?.generalTextColor }}
        >
          <IoLocationSharp style={{ fontSize: 24, transform: 'translateX(0px)' }} />
        </a>
      </div>
      {shopDetail?.whatsApp ? (
        <div className='whatsapp'>
          <a
            className={'cursor-pointer'}
            style={{ color: colorTheme?.generalTextColor }}
            onClick={() =>
              handleOnClick(`https://wa.me/${SINGAPORE_CODE_NO_ADD}${shopDetail?.whatsApp}`)
            }
          >
            <BsWhatsapp style={{ fontSize: 21 }} />
          </a>
        </div>
      ) : null}
      {shopDetail?.linkInstagram ? (
        <div className='instagram'>
          <a
            className={'cursor-pointer'}
            style={{ color: colorTheme?.generalTextColor }}
            onClick={() => handleOnClick(shopDetail?.linkInstagram)}
          >
            <BsInstagram style={{ fontSize: 21 }} />
          </a>
        </div>
      ) : null}

      {shopDetail?.linkFacebook ? (
        <div className='facebook'>
          <a
            className={'cursor-pointer'}
            style={{ color: colorTheme?.generalTextColor }}
            onClick={() => handleOnClick(shopDetail?.linkFacebook)}
          >
            <BsFacebook style={{ fontSize: 21 }} />
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default ShopSocialIcon
