/* eslint-disable react/no-deprecated */

import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'react-date-picker/dist/DatePicker.css'
import ReactDOM from 'react-dom'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import 'sweetalert2/src/sweetalert2.scss'

// must import style below bootstrap
import '_styles/styles.scss'
import './index.css'
import App from './pages/index'

ReactDOM.render(<App />, document.getElementById('root'))
