import React from 'react'
import './style.scss'

function LoadingPopup() {
  return (
    <div className='popup' id='popup-loading' data-backdrop='static'>
      <div className='popup-dialog popup-sm'>
        <div className='popup-content bg-white shadow rounded'>
          <div className='popup-body text-center'>
            <div className='loading-spinner mb-2'></div>
            <div>Processing</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPopup
