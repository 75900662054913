/* eslint-disable no-prototype-builtins */
import orderEventApi from '_api/orderEvent'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import {
  fetchOrderEventById,
  updateOrderCombinedPayment,
  updateOrderEventStatus,
} from '_redux/modules/orderEvent'
import { HOST_ORDER_CANCELED } from '_utils/constant'
import { handleDataOrderEvent } from '_utils/functions/handler'
import { isEmpty } from 'lodash'
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { headers } from '../headerTable'
import ModalEventPaymentScreen from './ModalEventPaymentScreen'
import OrderComment from './OrderComment'
import OrderEventRecordItem from './OrderEventRecordItem'
import './style.scss'

export default function ListOrderEventRecord() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { setSorting, listOrdersEventFiltered } = useContext(OrderRecordContext)
  const { listCombinedPayments, eventAndOrderById } = useSelector((state) => state.orderEvent)

  const [dataModalPaymentScreen, setDataModalPaymentScreen] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(null)
  const [isOpenModalPaymentScreen, setIsOpenModalPaymentScreen] = useState(false)
  const [openOrderComment, setOpenOrderComment] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)

  const eidParam = new URLSearchParams(window.location.search).get('eid')

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      setIsLoading(true)

      await dispatch(fetchOrderEventById(eidParam))
      if (isMounted) {
        setIsLoading(false)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [eidParam])

  const toggleTooltip = (itemRef) => setTooltipOpen(tooltipOpen === itemRef ? null : itemRef)

  const toggleModalPaymentScreen = () => setIsOpenModalPaymentScreen(!isOpenModalPaymentScreen)

  const toggleOpenModalComment = () => setOpenOrderComment(!openOrderComment)

  const handleUpdateStatusCombinedPayment = async (combinedPaymentRef, postStatus) => {
    try {
      dispatch(
        updateOrderCombinedPayment(combinedPaymentRef, postStatus.status, postStatus.buyerStatus)
      )

      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
      // await orderEventApi.updateShopEventInvoicesPlaceStatus({ ...postStatus, combinedPaymentRef })
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUpdateStatusPayment = async (ref, postStatus, eid) => {
    try {
      dispatch(updateOrderEventStatus(eid, ref, postStatus.status, postStatus.buyerStatus))
      await orderEventApi.updateOrderEventStatus(ref, postStatus)
      addToast('Update successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleInvalidPayment = async ({ ref, status, combinedPaymentRef, eid }) => {
    const r = confirm('Are you sure this is invalid payment?')
    if (r === true) {
      try {
        const postStatus = {
          status,
          buyerStatus: status,
        }

        if (!combinedPaymentRef) {
          await handleUpdateStatusPayment(ref, postStatus, eid)
        } else {
          await handleUpdateStatusCombinedPayment(combinedPaymentRef, postStatus)
        }

        addToast('Update successfully', { appearance: 'success', autoDismiss: true })
      } catch (error) {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        toggleModalPaymentScreen()
      }
    }
  }

  const openPaymentScreen = (e, order) => {
    e.preventDefault()

    const { combinedPaymentRef = '' } = order
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef && eventAndOrderById.length && listCombinedPayments.length) {
      const exitCombinedPayment = listCombinedPayments.findIndex(
        (cpItem) => cpItem.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment
          // exclude current order
          .forEach((element) => {
            const { eid, ref: elementRef } = element
            const { orders, adminCost, discount, deliveryCost, productIdList } =
              eventAndOrderById.find((eoItem) => eoItem.id === eid) || {}
            const { listOrderEventHandled } = handleDataOrderEvent(
              orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList
            )
            const otherOrder = listOrderEventHandled.find((ooItem) => ooItem.ref === elementRef)
            listCombinedOrder = [...listCombinedOrder, otherOrder]
          })
        listCombinedDelivery = deliveryCombinedPayment ? [...deliveryCombinedPayment] : []
      }
    }

    setDataModalPaymentScreen({ ...order, listCombinedOrder, listCombinedDelivery })
    toggleModalPaymentScreen()
  }

  const onChangeStatusSelect = async (e, ref, _combinedPaymentRef, eid) => {
    const { value } = e.target
    const statusSelected = {
      status: Number(value),
      buyerStatus: Number(value),
    }

    if (Number(value) === HOST_ORDER_CANCELED && _combinedPaymentRef) {
      await handleUpdateStatusCombinedPayment(_combinedPaymentRef, statusSelected)
      return
    }

    await handleUpdateStatusPayment(ref, statusSelected, eid)
  }

  const onOpenComment = (order) => {
    setSelectedOrder(order)
    toggleOpenModalComment()
  }

  const sortListOrders = useMemo(
    () => listOrdersEventFiltered,
    [JSON.stringify(listOrdersEventFiltered)]
  )

  if (isLoading) {
    return <div className='text-center text-info fw-bold f-16 my-5'>Loading...</div>
  }

  if (!isLoading && !sortListOrders.length) {
    return <div className='text-center text-secondary fw-bold f-16 my-5'>No order found</div>
  }

  return (
    <div className='event-list-order'>
      {!isEmpty(dataModalPaymentScreen) && (
        <>
          <ModalEventPaymentScreen
            data={dataModalPaymentScreen}
            modalPaymentScreen={isOpenModalPaymentScreen}
            togglePaymentScreen={toggleModalPaymentScreen}
            handleInvalidPayment={handleInvalidPayment}
          />
        </>
      )}
      {!isLoading && (
        <TableHeaderWithSort
          headers={headers}
          defaultSortingField='shopOrderNo'
          defaultSortingOrder='asc'
          onSorting={(field, order, type) => setSorting({ field, order, type })}
          className='row p-3 bg-light table-header fw-bold cursor-pointer'
          style={{ fontSize: '0.9vw', color: '#079d9a' }}
        />
      )}
      {!isLoading &&
        sortListOrders.map((item, index) => (
          <Fragment key={`${item.id}-${index}`}>
            <OrderEventRecordItem
              item={item}
              index={index}
              tooltipOpen={tooltipOpen}
              toggleTooltip={toggleTooltip}
              toggleModalPaymentScreen={toggleModalPaymentScreen}
              setDataModalPaymentScreen={setDataModalPaymentScreen}
              setSelectedOrder={setSelectedOrder}
              toggleOpenModalComment={toggleOpenModalComment}
              openPaymentScreen={openPaymentScreen}
              onChangeStatusSelect={onChangeStatusSelect}
              onOpenComment={onOpenComment}
            />
          </Fragment>
        ))}

      <OrderComment
        openOrderComment={openOrderComment}
        toggleOpenModalComment={toggleOpenModalComment}
        selectedOrder={selectedOrder}
      />
    </div>
  )
}
