/* eslint-disable no-confusing-arrow */
import LongText from '_components/LongText'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ProductItemContext } from '.'

function ProductDescription({ productDescription, productName }) {
  const [isEditDescription, setIsEditDescription] = useState(false)

  const { isSavedProduct } = useSelector((state) => state.product)
  const { handleSubmit, errors, isSubmitting, register, handleSave } =
    useContext(ProductItemContext)

  useEffect(() => {
    if (isSavedProduct) setIsEditDescription(false)
  }, [isSavedProduct])

  const toggle = () => setIsEditDescription(!isEditDescription)

  const onSubmit = (data) => {
    handleSave(data)
    toggle()
  }

  return (
    <div className='cursor-pointer' style={{ overflowWrap: 'anywhere' }}>
      {!isEditDescription && !productDescription && (
        <>
          <div className='text-content' onClick={toggle}>
            Add description
          </div>
          {errors.description && (
            <small className='text-danger'>{errors.description.message}</small>
          )}
        </>
      )}

      <Modal isOpen={isEditDescription} toggle={toggle} className='modal-edit-product'>
        <ModalHeader toggle={toggle}>{productName}</ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <label htmlFor='product-price'>Enter description:</label>
            <textarea
              rows={10}
              cols={30}
              defaultValue={productDescription}
              style={{ fontSize: 14 }}
              className={`${isEditDescription ? 'mb-2' : 'mb-0'} form-control-sm border w-100`}
              {...register('description')}
            />
            {errors.description && (
              <small className='text-danger'>{errors.description.message}</small>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-submit' type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </ModalFooter>
        </form>
      </Modal>

      <LongText
        content={productDescription}
        limit={80}
        className='text-content'
        handleEdit={toggle}
      />
    </div>
  )
}

export default ProductDescription
