import { getCoordinates } from '_utils/function'
import React, { useEffect, useState } from 'react'
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'

const { MarkerWithLabel } = require('react-google-maps/lib/components/addons/MarkerWithLabel')

const Map = ({
  googleMapURL,
  loadingElement,
  containerElement,
  mapElement,
  lstAddress,
  listDateIsPicked,
}) => {
  const [positions, setPositions] = useState([])
  const [center, setCenter] = useState({})
  const [zoom, setZoom] = useState(13)
  const [positionsPicked, setPositionsPicked] = useState([])

  const convertListPicked = listDateIsPicked?.map((item, index) => ({
    index,
    value: item?.address,
  }))

  useEffect(() => {
    let mounted = true

    const fetchAddress = async () => {
      const listCoordinatesAddress = await getCoordinates(lstAddress)
      if (mounted) {
        setPositions(listCoordinatesAddress)
      }
    }

    fetchAddress()

    return () => {
      mounted = false
    }
  }, [lstAddress, listDateIsPicked])

  useEffect(() => {
    let mounted = true

    const fetchAddress = async () => {
      const listCoordinatesPicked = await getCoordinates(convertListPicked)
      setPositionsPicked(listCoordinatesPicked)
    }

    if (mounted) fetchAddress()

    return () => {
      mounted = false
    }
  }, [listDateIsPicked])

  useEffect(() => {
    // Dựa vào listDateIsPicked để set lại center và zoom
    // Nếu listDateIsPicked.length === 0 thì set center và zoom về default
    // Nếu listDateIsPicked.length === 1 thì set center và zoom về địa chỉ đó
    // Nếu listDateIsPicked.length > 1 thì set center và zoom về tập hợp các địa chỉ đó
    if (positions.length > 0) {
      if (listDateIsPicked?.length === 0) {
        setCenter({ lat: positions[0]?.lat, lng: positions[0]?.lng })
        setZoom(13)
      } else if (listDateIsPicked?.length === 1) {
        if (positionsPicked.length > 0) {
          setCenter({
            lat: positionsPicked[0]?.lat,
            lng: positionsPicked[0]?.lng,
          })
          setZoom(15)
        }
      } else if (listDateIsPicked?.length > 1) {
        if (positionsPicked.length > 0) {
          const lat = positionsPicked.reduce((a, b) => a + b.lat, 0) / positionsPicked.length
          const lng = positionsPicked.reduce((a, b) => a + b.lng, 0) / positionsPicked.length

          const latStdDev = Math.sqrt(
            positionsPicked.map((p) => p.lat).reduce((a, b) => a + (b - lat) ** 2, 0) /
              positionsPicked.length
          )
          const lngStdDev = Math.sqrt(
            positionsPicked.map((p) => p.lng).reduce((a, b) => a + (b - lat) ** 2, 0) /
              positionsPicked.length
          )

          setCenter({ lat, lng })

          // Adjust zoom based on standard deviation of lat and lng.
          // This is a simple linear scaling for the example
          // and may need to be adjusted for your specific use case.
          const maxStdDev = Math.max(latStdDev, lngStdDev)
          setZoom(Math.round(maxStdDev / 10))
        }
      }
    }
  }, [listDateIsPicked?.length, positionsPicked, positions])

  if (positions?.length === 0) return null

  return (
    <GoogleMap
      zoom={zoom}
      center={{ lat: center.lat || 0, lng: center.lng || 0 }}
      googleMapURL={googleMapURL}
      loadingElement={loadingElement}
      containerElement={containerElement}
      mapElement={mapElement}
    >
      {positions &&
        positions.map((p) => (
          <MarkerWithLabel
            key={`map-${p.index}`}
            position={new window.google.maps.LatLng(p)}
            labelAnchor={new window.google.maps.Point(0, 0)}
            labelStyle={{
              backgroundColor: '#15cdca',
              fontSize: '12px',
              padding: '3px',
              color: '#fff',
            }}
          >
            <div>{p.index + 1}</div>
          </MarkerWithLabel>
        ))}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(Map))
