import { SELF_PICK_UP } from '_utils/constant'
import moment from 'moment'

const IntervalTime = ({ interval, onChange, setIsOpen }) => {
  const today = moment()
  const intervalDate = moment(interval.split(',')[0], 'DD-MM-YYYY')
  const intervalRangeTime = interval.split(',')[1]
  const intervalEndTime = moment(
    `${intervalDate.format('DD-MM-YYYY')} ${intervalRangeTime.split('-')[1].trim()}`,
    'DD-MM-YYYY HH:mm'
  )

  const isDisabled = today.isAfter(intervalEndTime)

  return (
    <p
      className={`time-option mb-0 py-2 ${isDisabled ? 'opacity-50 mb-1 cursor-not-allow' : ''}`}
      onClick={() => {
        if (isDisabled) return
        onChange(interval)
        setIsOpen(false)
      }}
    >
      {interval.split(',')[1]}
    </p>
  )
}

const CustomTimePicker = ({ selectedTime, onChange, timeIntervals, isOpen, setIsOpen, type }) => (
  <div
    id={type === SELF_PICK_UP ? 'pickup-time' : 'delivery-time'}
    name={type === SELF_PICK_UP ? 'pickup' : 'delivery'}
    className='border cursor-pointer w-100 outline-none text-center custom-select-time position-relative mb-0'
    style={{
      padding: '0.4rem 0.75rem',
      color: '#495057',
    }}
    onClick={() => setIsOpen(!isOpen)}
  >
    <div key={selectedTime || Math.random().toString().slice(2)}>{selectedTime}</div>
    {isOpen && (
      <div className={'wrap-options py-3'}>
        {timeIntervals?.map((interval, index) => (
          <IntervalTime key={index} interval={interval} onChange={onChange} setIsOpen={setIsOpen} />
        ))}
      </div>
    )}
  </div>
)

export default CustomTimePicker
