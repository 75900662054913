import { FaCheckCircle, FaFacebook, FaLink } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import { FacebookShare, WhatsappShare } from 'react-share-kit'

import { Modal, ModalBody } from 'reactstrap'

export function ModalCongratulationCreateShop({ isOpenModal, toggle, shopDetail, urlPath }) {
  // const handleInviteFacebook = () => {
  //   // Implement invite Facebook friends logic here
  //   alert('Invite Facebook friends')
  // }

  // const handleShareWhatsApp = () => {
  //   // Implement share on WhatsApp logic here
  //   alert('Share on WhatsApp')
  // }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shopDetail?.shopLink)
    alert('Link copied to clipboard!')
  }

  const shareUrl = shopDetail?.shopLink || urlPath

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      // style={{
      //   transform: 'translate(-1.5%, 70%)',
      // }}
      className='modal-congratulation-create-shop'
    >
      <ModalBody>
        <div className='generated-domain-box h-auto'>
          <div className='generated-domain-check-icon'>
            <FaCheckCircle />
          </div>
          <div className='generated-domain-name pb-0'>
            <span className='txt-congratulation'>
              Congratulation! Your virtual shop is launched.
            </span>
            <p>URL link:</p>
            <a href={shopDetail?.shopLink} className={'txt-link f-16'}>
              {shopDetail?.shopLink}
            </a>
          </div>
          <ul className='list-group generated-domain-share mt-3 mb-0'>
            <li className='list-group-item d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center column-gap-2'>
                <FaFacebook className='facebook' />
                <span>Invite Facebook friends</span>
              </div>
              {/* <button onClick={handleInviteFacebook} className='facebook'></button> */}

              <FacebookShare url={shareUrl} quote={'TO2K is testing share function'} />
            </li>
            <li className='list-group-item py-3 d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center column-gap-2'>
                <IoLogoWhatsapp className='whatsapp' />
                <span>Share on WhatsApp</span>
              </div>
              {/* <button onClick={handleShareWhatsApp} className='whatsapp'>
                Share
              </button> */}

              <WhatsappShare
                url={shareUrl}
                title={'The new shop is coming'}
                // separator=':: '
              />
            </li>
            <li className='list-group-item d-flex align-items-center email'>
              <div className='box link me-2'>
                <FaLink className='link' />
              </div>
              <div>
                <span className='mb-0'>Send a link via email or text</span>
                <input
                  type='text'
                  readOnly
                  className='border rounded form-control-sm w-100'
                  value={shopDetail?.shopLink || urlPath}
                />
              </div>
              <button className='border ms-auto' type='button' onClick={handleCopyLink}>
                Copy
              </button>
            </li>
          </ul>

          <button
            className='btn btn-success rounded w-auto'
            style={{
              margin: '0 auto',
              transform: 'translateY(35px)',
            }}
            onClick={toggle}
          >
            Let&apos;s Roll
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}
