import {
  LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
  LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
} from '_utils/constant'
import React from 'react'
import { useSelector } from 'react-redux'

const RenderButtonLazada = ({ onUpgradeLazada }) => {
  const { selectedShop } = useSelector((state) => state.shop)
  const { lazadaPackage } = useSelector((state) => state.lazada)

  const packageApproved = lazadaPackage?.status === LAZADA_FEATURE_PAYMENT_STATUS_APPROVED
  const packagePending = lazadaPackage?.status === LAZADA_FEATURE_PAYMENT_STATUS_PENDING

  const isLazadaInvalid = lazadaPackage?.shopId !== selectedShop?.id
  if (packageApproved || (packagePending && isLazadaInvalid)) return null

  if (packagePending) {
    return (
      <button
        className='btn btn-secondary rounded w-auto text-white shadow-none mt-0 ms-3'
        disabled
      >
        Pending approval
      </button>
    )
  }

  return (
    <button
      className='btn btn-primary w-auto text-white shadow-none mt-0 ms-3 rounded'
      onClick={onUpgradeLazada}
    >
      Upgrade Lazada Feature
    </button>
  )
}

export default RenderButtonLazada
