/* eslint-disable function-paren-newline */
import deliveryApi from '_api/delivery'
import { useHover } from '_hooks/useHover'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  DELIVERY_CANCEL_STATUS,
  DELIVERY_DELIVERED_STATUS,
  DELIVERY_PENDING_STATUS,
  DELIVERY_UNPAID_STATUS,
} from '_utils/constant'
import { convertToCurrentGMT } from '_utils/functions/converter'
import moment from 'moment'
import React from 'react'
import { AiFillGift } from 'react-icons/ai'
import { useToasts } from 'react-toast-notifications'

function getFulfillmentClass(status) {
  switch (status) {
    case DELIVERY_CANCEL_STATUS:
      return 'bg-secondary'
    case DELIVERY_UNPAID_STATUS:
      return 'bg-warning'
    case DELIVERY_PENDING_STATUS:
      return 'bg-info'
    default:
      return 'bg-success'
  }
}

function FulfillmentItem({
  fulfillment,
  fulfillmentIndex,
  swapUpFulfillment,
  swapDownFulfillment,
  maxLengthFulfillment,
  updateStatusFulfillment,
}) {
  const { addToast } = useToasts()
  const [hoverArrowUp, isUpHovered] = useHover()
  const [hoverArrowDown, isDownHovered] = useHover()

  const updateFulfillmentStatusAPI = async (_ref, _data, typeFulfillment) => {
    if (typeFulfillment === 'delivery') {
      try {
        updateStatusFulfillment(_ref, _data.status, _data.bookerStatus)
        const { msgResp } = await deliveryApi.updateDeliveryStatus(_ref, {
          status: _data.status,
          bookerStatus: _data.bookerStatus,
        })
        addToast(msgResp, { appearance: 'success', autoDismiss: true })
      } catch (error) {
        const { msgResp } = error
        addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
      }
    } else {
      try {
        updateStatusFulfillment(_ref, _data.status, _data.bookerStatus)
        const { msgResp } = await deliveryApi.updatePickupStatus(_ref, {
          status: _data.status,
          bookerStatus: _data.bookerStatus,
        })
        addToast(msgResp, { appearance: 'success', autoDismiss: true })
      } catch (error) {
        const { msgResp } = error
        addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const onChangeSelect = async (e, _fulfillment) => {
    try {
      const { ref, bookerStatus } = _fulfillment

      const statusSelected = Number(e.target.value)

      const statusMap = {
        [BUYER_ORDER_UNPAID]: {
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
        },
        [BUYER_ORDER_PAID]: {
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
        },
        [BUYER_ORDER_CANCELED]: {
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
        },
      }

      const mappedBookerStatus = statusMap[bookerStatus]

      const data = {
        status: statusSelected,
        bookerStatus: mappedBookerStatus ? mappedBookerStatus[statusSelected] : bookerStatus,
      }

      const typeFulfillment = _fulfillment.pickupBookingTimestamp ? 'pickup' : 'delivery'

      await updateFulfillmentStatusAPI(ref, data, typeFulfillment)
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleSwapUp = (_delivery) => {
    swapUpFulfillment(_delivery)
  }

  const renderTimeFulfillment = () => {
    if (fulfillment?.pickupBookingTime) {
      const convertTimeToArray = fulfillment?.pickupBookingTime?.split('-')
      const newTimeCurrentGMT = convertTimeToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(
            `${fulfillment?.pickupBookingDate?.date} ${time.trim()}`
          )
          return moment(currentTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })
        ?.join(' - ')

      return newTimeCurrentGMT
    }

    const convertTimeToArray = fulfillment?.deliveryBookingTime?.split('-')
    const newTimeCurrentGMT = convertTimeToArray
      ?.map((time) => {
        const currentTime = convertToCurrentGMT(
          `${fulfillment?.deliveryBookingDate?.date} ${time.trim()}`
        )
        return moment(currentTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
      })
      ?.join(' - ')

    return newTimeCurrentGMT
  }

  return (
    <div
      className={`${fulfillmentIndex % 2 !== 0 ? 'bg-light' : ''} row align-items-center p-3 f-12`}
    >
      <div className='col text-center'>
        <p className='mb-0 fw-bold' style={{ color: '#079d9a' }}>
          {fulfillmentIndex + 1}
        </p>
        <div className='d-flex flex-column align-items-center'>
          {fulfillmentIndex !== 0 && (
            <div
              className='arrow-up cursor-pointer'
              ref={hoverArrowUp}
              onClick={() => handleSwapUp(fulfillment)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill={isUpHovered ? '#B5B1B3' : '#D2CFD0'}
              >
                <path d='M12 3l12 18h-24z' />
              </svg>
            </div>
          )}
          {fulfillmentIndex !== maxLengthFulfillment && (
            <div
              className='arrow-down cursor-pointer'
              ref={hoverArrowDown}
              onClick={() => swapDownFulfillment(fulfillment)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill={isDownHovered ? '#B5B1B3' : '#D2CFD0'}
              >
                <path d='M12 21l-12-18h24z' />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className='col'>
        <div className='d-flex flex-column'>
          {fulfillment?.giftRecipientInfo ? (
            <>
              <span>{fulfillment.giftRecipientInfo?.name}</span>
              <span>{fulfillment.giftRecipientInfo?.phone}</span>
              <AiFillGift color='red' size={18} className='mt-2' />
            </>
          ) : (
            <>
              <span>{fulfillment.uName}</span>
              <span>{fulfillment.uPhone}</span>
            </>
          )}
        </div>
      </div>
      <div className='col'>
        {fulfillment.listOrder.map((item) => (
          <p key={item.id} className='mb-0'>
            {item.pName} ({item.pQuantity})
          </p>
        ))}
      </div>
      <div className='col'>
        <div className='d-flex flex-column'>
          <span className='fw-bold mb-2'>
            {fulfillment?.pickupBookingTimestamp ? 'Pick-up' : 'Delivery'}
          </span>
          <span className='mb-2'>
            {fulfillment?.pickupOptionName || fulfillment.deliveryOptionName}
          </span>
          <span>
            {fulfillment?.giftRecipientInfo
              ? fulfillment.giftRecipientInfo?.address
              : fulfillment?.pickupAddress || fulfillment?.uAddress}
          </span>
        </div>
      </div>
      <div className='col'>
        <div>
          Fulfill date: <br />
          <span className='fw-bold text-primary'>
            {fulfillment?.pickupBookingDate?.date || fulfillment?.deliveryBookingDate?.date}
          </span>
        </div>
        <div className='mt-1'>
          Time fulfillment: <br />{' '}
          <span className='fw-bold text-primary'>{renderTimeFulfillment()}</span>
        </div>
      </div>
      <div className='col'>
        <select
          className={`${getFulfillmentClass(
            fulfillment.status
          )} form-select-sm border w-100 rounded-3 text-white`}
          value={fulfillment.status}
          onChange={(e) => onChangeSelect(e, fulfillment)}
        >
          <option value={DELIVERY_CANCEL_STATUS}>Cancelled</option>
          <option value={DELIVERY_UNPAID_STATUS}>Unpaid</option>
          <option value={DELIVERY_PENDING_STATUS}>Pending</option>
          <option value={DELIVERY_DELIVERED_STATUS}>Delivered</option>
        </select>
      </div>
    </div>
  )
}
export default FulfillmentItem
