import LoadingOverlay from '_components/LoadingOverlay'
import useQuery from '_hooks/useQuery'
import {
  clearListDeliveryByHost,
  clearListPickupByHost,
  getListDeliveryByHost,
  getListPickupByHost,
} from '_redux/modules/fulfillment'
import {
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CANCEL_STATUS,
  DELIVERY_DELIVERED_STATUS,
  DELIVERY_PENDING_STATUS,
  DELIVERY_UNPAID_STATUS,
} from '_utils/constant'
import { exportToCsv, normalizeName } from '_utils/function'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ModalFulfillmentDate from './components/ModalFulfillmentDate'
import Search from './components/Search'

import ListFulfillment from './components/ListFulfillment'
import './style.scss'

function Delivery() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const query = useQuery()

  const { selectedShop } = useSelector((state) => state.shop)
  const { listDeliveryByHost, listPickupByHost } = useSelector((state) => state.fulfillment)

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [allListFulfillment, setAllListFulfillment] = useState([])
  const [checkedIncludePickup, setCheckedIncludePickup] = useState(false)
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: moment().startOf('date').toDate(),
      endDate: moment().add(30, 'days').endOf('date').toDate(),
      key: 'selection',
    },
  ])

  useEffect(() => {
    if (!isEmpty(selectedShop)) {
      const postData = {
        startDate: moment(rangeDate[0].startDate).unix(),
        endDate: moment(rangeDate[0].endDate).unix(),
        shopId: selectedShop?.id,
      }

      const fetchData = async () => {
        await dispatch(getListDeliveryByHost(postData))
        setLoading(false)
      }

      fetchData()
    }

    return () => {
      setLoading(true)
      dispatch(clearListDeliveryByHost())
    }
  }, [
    selectedShop?.shopSlug,
    moment(rangeDate[0].startDate).unix(),
    moment(rangeDate[0].endDate).unix(),
  ])

  useEffect(() => {
    if (checkedIncludePickup) {
      const fetchData = async () => {
        try {
          setLoading(true)
          const postData = {
            startDate: moment(rangeDate[0].startDate).unix(),
            endDate: moment(rangeDate[0].endDate).unix(),
            shopId: selectedShop?.id,
          }
          await dispatch(getListPickupByHost(postData))
        } catch (error) {
          addToast(error.message || error.msgResp, {
            appearance: 'error',
            autoDismiss: true,
          })
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      dispatch(clearListPickupByHost())
    }
  }, [checkedIncludePickup])

  useEffect(() => {
    const newListFulfillment = [...listDeliveryByHost, ...listPickupByHost].sort(
      (a, b) => b.createdAt - a.createdAt
    )
    setAllListFulfillment(newListFulfillment)
  }, [listDeliveryByHost.length, listPickupByHost.length])

  useEffect(() => {
    if (query.get('date')) {
      const date = query.get('date')
      setRangeDate([
        {
          startDate: moment(date, 'DD-MM-YYYY').startOf('day').toDate(),
          endDate: moment(date, 'DD-MM-YYYY').endOf('day').toDate(),
          key: 'selection',
        },
      ])
    }
  }, [query.get('date')])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  const getRangeFulfillmentDate = (dates) => {
    setRangeDate(dates)
  }

  const handleClear = () => {
    setRangeDate([
      {
        startDate: moment().startOf('date').toDate(),
        endDate: moment().add(30, 'days').endOf('date').toDate(),
        key: 'selection',
      },
    ])
    setSearch('')
  }

  const handleExport = (data) => {
    if (data.length === 0) {
      addToast('No delivery to export', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    const rowsOfData = new Array(data.length) || []
    for (let index = 0; index < data.length; index++) {
      const delivery = data[index]

      let statusString = ''
      let bookerStatusString = ''

      const {
        status,
        bookerStatus,
        uName,
        uPhone,
        uEmail,
        orderRefs,
        deliveryFee,
        deliveryOptionName,
        uAddress,
        giftRecipientInfo,
      } = delivery

      switch (status) {
        case DELIVERY_CANCEL_STATUS:
          statusString = 'Cancelled'
          break
        case DELIVERY_UNPAID_STATUS:
          statusString = 'Unpaid'
          break
        case DELIVERY_PENDING_STATUS:
          statusString = 'Pending'
          break
        case DELIVERY_DELIVERED_STATUS:
          statusString = 'Delivered'
          break
        default:
          break
      }

      switch (bookerStatus) {
        case DELIVERY_BUYER_CANCELLED:
          bookerStatusString = 'Cancelled'
          break
        case DELIVERY_BUYER_REFUND:
          bookerStatusString = 'Refund'
          break
        case DELIVERY_BUYER_UNPAID:
          bookerStatusString = 'Unpaid'
          break
        case DELIVERY_BUYER_PAID:
          bookerStatusString = 'Paid'
          break
        default:
          break
      }

      const deliveryFeeValue = deliveryFee ? `$${deliveryFee}` : '$0'

      rowsOfData[index] = [
        index,
        orderRefs,
        deliveryOptionName,
        deliveryFeeValue,
        giftRecipientInfo ? giftRecipientInfo?.address : delivery?.pickupAddress || uAddress,
        giftRecipientInfo ? giftRecipientInfo?.name : uName,
        giftRecipientInfo ? giftRecipientInfo?.phone : uPhone,
        giftRecipientInfo ? giftRecipientInfo?.email : uEmail,
        statusString,
        bookerStatusString,
      ]
    }
    const rows = [
      [
        'Fulfillment no',
        'Ref',
        'Zone name',
        'Zone price',
        'Address',
        'Buyer name',
        'Buyer phone',
        'Buyer email',
        'Status',
        'Booker status',
      ],
      ...rowsOfData,
    ]

    const fileName = normalizeName(
      `${selectedShop?.shopName} shop export list fulfillment by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const currentOrdersFulfillment = useMemo(() => {
    const PAGE_SIZE = 5
    let filteredList = []

    if (search.length > 0) {
      filteredList = allListFulfillment.filter((item) =>
        Object.values(item)?.some((value) =>
          String(value)?.toLowerCase()?.includes(search.toLowerCase())
        )
      )
    } else {
      filteredList = allListFulfillment.filter((item) => item.shopId === selectedShop?.id)
    }

    const firstPageIndex = (currentPage - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    return filteredList.slice(firstPageIndex, lastPageIndex)
  }, [
    JSON.stringify(allListFulfillment),
    search,
    selectedShop?.id,
    currentPage,
    checkedIncludePickup,
  ])

  const updateStatusFulfillment = (ref, status, bookerStatus) => {
    setAllListFulfillment((prevList) => {
      const newListFulfillment = [...prevList]
      const findIndex = newListFulfillment.findIndex((item) => item.ref === ref)
      if (findIndex !== -1) {
        newListFulfillment[findIndex].status = status
        newListFulfillment[findIndex].bookerStatus = bookerStatus
      }
      return newListFulfillment
    })
  }

  const swapUpFulfillment = (delivery) => {
    const newListFulfillment = [...currentOrdersFulfillment]
    const index = newListFulfillment.findIndex((item) => item.id === delivery.id)
    if (index > 0) {
      setAllListFulfillment((prev) => {
        const newAllListFulfillment = [...prev]
        const indexAll = newAllListFulfillment.findIndex((item) => item.id === delivery.id)
        newAllListFulfillment.splice(indexAll, 1)
        newAllListFulfillment.splice(indexAll - 1, 0, delivery)
        return newAllListFulfillment
      })
    }
  }

  const swapDownFulfillment = (delivery) => {
    const newListFulfillment = [...currentOrdersFulfillment]
    const index = newListFulfillment.findIndex((item) => item.id === delivery.id)
    if (index < newListFulfillment.length - 1) {
      setAllListFulfillment((prev) => {
        const newAllListFulfillment = [...prev]
        const indexAll = newAllListFulfillment.findIndex((item) => item.id === delivery.id)
        newAllListFulfillment.splice(indexAll, 1)
        newAllListFulfillment.splice(indexAll + 1, 0, delivery)
        return newAllListFulfillment
      })
    }
  }

  return (
    <div className='delivery p-3'>
      <h3 className='mb-4 title'>Delivery & Fulfillment</h3>
      <div className='d-flex align-items-center'>
        <Search searchString={search} handleSearch={handleSearch} />
        <button
          className='btn btn-fulfillment-date w-auto shadow-none bg-transparent rounded-0 border'
          onClick={toggleModal}
        >
          Fulfillment Date
        </button>
        <button
          className='btn btn-clear bg-transparent border rounded-0 shadow-none w-auto mt-0 ms-auto'
          onClick={handleClear}
        >
          Clear filter
        </button>
      </div>
      <div className='d-flex align-items-center mt-3'>
        <p className='mb-0'>
          <span className='me-1'>Selecting date from</span>
          <strong className='me-1'>{moment(rangeDate[0]?.startDate).format('DD/MM/YYYY')}</strong>
          <span className='me-1'>to</span>
          <strong>{moment(rangeDate[0]?.endDate).format('DD/MM/YYYY')}</strong>
        </p>
        <button
          onClick={() => handleExport(currentOrdersFulfillment)}
          className='text-uppercase bg-transparent btn shadow-none rounded btn-export border-0 ms-auto w-auto'
        >
          Export
        </button>
      </div>
      <div className='mb-3 d-flex align-items-center'>
        <input
          type='checkbox'
          id='includePickup'
          className='holiday-checkbox'
          onChange={(e) => setCheckedIncludePickup(e.target.checked)}
        />
        <label htmlFor='includePickup' className='cursor-pointer mb-0'>
          Include Pickup
        </label>
        {checkedIncludePickup && loading && (
          <span className='ms-1 text-info f-12 mt-auto'>Getting list pick-up order...</span>
        )}
      </div>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <ListFulfillment
          currentOrdersFulfillment={currentOrdersFulfillment}
          swapUpFulfillment={swapUpFulfillment}
          swapDownFulfillment={swapDownFulfillment}
          searchString={search}
          rangeDate={rangeDate}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          updateStatusFulfillment={updateStatusFulfillment}
          search={search}
          allListFulfillment={allListFulfillment}
        />
      )}

      {isModalOpen && (
        <ModalFulfillmentDate
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          getRangeFulfillmentDate={getRangeFulfillmentDate}
          fulfillmentDate={rangeDate}
        />
      )}
    </div>
  )
}

export default Delivery
