import MyGallery from '_components/MyGallery'
import { HOST_ORDER_UNPAID } from '_utils/constant'
import PropTypes from 'prop-types'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

function ModalPayment({ toggleModal, isModalOpen, order, price, handleInvalidPayment }) {
  const handlePaymentInValid = (data) => {
    handleInvalidPayment(data.ref, HOST_ORDER_UNPAID)
    toggleModal()
  }
  return (
    <Modal toggle={toggleModal} isOpen={isModalOpen} className='modal-payment py-0'>
      <ModalBody
        style={{
          fontSize: 16,
        }}
      >
        <div className='text-center font-semibold shadow-none border-0'>
          <p className='text-payment'>
            Payment made for{' '}
            <CurrencyFormat
              value={price || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </p>
          {order?.paymentScreenshot ? (
            <div className='wrap-img'>
              <MyGallery image={order?.paymentScreenshot} className='mx-auto h-100 w-100' />
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter className='border-top-0 border-0'>
        <Button color='primary' className='rounded-pill btn-submit' onClick={toggleModal}>
          OK
        </Button>
        <Button onClick={() => handlePaymentInValid(order)} className='btn-cancel m-0'>
          Click here if the payment invalid
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalPayment.propTypes = {
  toggleModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  order: PropTypes.object,
  price: PropTypes.number,
}

export default ModalPayment
