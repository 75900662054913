export const ORDER_UNPAID_STATUS = 0
export const ORDER_PENDING_STATUS = 1
export const ORDER_PACKED_STATUS = 2
export const ORDER_READY_TO_SHIP_PENDING_STATUS = 3
export const ORDER_READY_TO_SHIP_STATUS = 4
export const ORDER_SHIPPED_STATUS = 5
export const ORDER_LOST_BY_3PL_STATUS = 6
export const ORDER_DAMAGED_BY_3PL_STATUS = 7
export const ORDER_RETURNED_STATUS = 8
export const ORDER_DELIVERED_STATUS = 9
export const ORDER_CONFIRMED_STATUS = 10
export const ORDER_FAILED_DELIVERY_STATUS = 11
export const ORDER_CANCELED_STATUS = -1
export const ORDER_CANCEL_INIT_STATUS = -2

export const REVERSE_ORDER_FOR_SELLER_BUTTON = 0
export const REVERSE_ORDER_HISTORY_LIST_BUTTON = 1
export const REVERSE_ORDER_REASON_LIST_BUTTON = 2

export const REVERSE_ORDER_STATUS_PENDING = 0
export const REVERSE_ORDER_STATUS_PROCESSING = 1
export const REVERSE_ORDER_STATUS_COMPLETED = 2
export const REVERSE_ORDER_STATUS_UNKNOWN = 3
export const REVERSE_ORDER_STATUS_CANCELLED = 4
