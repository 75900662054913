import {
  MONTHLY_SUBSCRIPTION,
  PACKAGE_SUBSCRIPTION_BASIC,
  SUBSCRIPTION_PAYMENT_STATUS_PAID,
  SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
} from '_utils/constant'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

const ButtonVariation = ({ variant, packageItem, onSelectPackage }) => {
  if (!variant.available) return null

  const { isSubmittedPayment, subscriptionPackageSelected } = useSelector(
    (state) => state.subscription
  )
  const location = useLocation()
  const { addToast } = useToasts()
  const { currentPackage } = useSelector((state) => state.subscription)
  const isFreeVariant = variant.price === 0

  const validSelectedPackage = variant.id === currentPackage?.variationId
  const isDisabled = (isFreeVariant || validSelectedPackage) && location.pathname !== '/'
  const isPackageBasic = currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC

  const isExistTicketSubscription = [
    SUBSCRIPTION_PAYMENT_STATUS_PAID,
    SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
  ].includes(currentPackage?.paymentStatus)

  const onSignUpSubscription = () => {
    if (!isPackageBasic && isExistTicketSubscription) {
      return addToast('You have registered the previous package.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    onSelectPackage({
      ...packageItem,
      variationId: variant.id,
      variantPrice: variant.price,
      discountPrice: variant.discountPrice,
    })
  }

  return (
    <button
      disabled={isDisabled || isSubmittedPayment}
      onClick={onSignUpSubscription}
      className={`btn rounded shadow-none d-flex flex-column mb-3 f-16 ${
        isDisabled ? 'btn-outline-midnight-blue disabled' : 'btn-midnight-blue'
      }`}
      style={{
        height: 54,
      }}
    >
      {subscriptionPackageSelected?.variationId === variant.id && isSubmittedPayment ? (
        'Processing payment...'
      ) : (
        <>
          {location.pathname === '/' && isFreeVariant && 'Sign-up for free'}

          {location.pathname !== '/' ? (
            <>{isFreeVariant || validSelectedPackage ? 'You are subscribed to this plan' : null}</>
          ) : null}

          {!isFreeVariant && !validSelectedPackage ? (
            <>
              <span>
                {variant.expireValue > MONTHLY_SUBSCRIPTION ? 'Pay Annually' : 'Pay Monthly'}
              </span>
              <div className='package-price'>
                SGD{' '}
                {variant.discountPrice ? (
                  <>
                    <span className='fw-bold text-warning'>${variant.discountPrice}</span>
                    <sup>
                      <strike className='text-secondary'>${variant.price}</strike>
                    </sup>
                  </>
                ) : (
                  <span>${variant.price}</span>
                )}{' '}
                / {variant.expireValue > MONTHLY_SUBSCRIPTION ? 'Year' : 'Month'}
              </div>
            </>
          ) : null}
        </>
      )}
    </button>
  )
}

export default ButtonVariation
