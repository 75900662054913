/* eslint-disable no-confusing-arrow */
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  DELIVERY_OPTION,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_VERIFIED,
  NO_FULFILLMENT,
  SELF_PICK_UP,
  VISIBLE,
} from '_utils/constant'
import { maskString } from '_utils/function'
import { getUserInfo, setCartOrderDetailsByRef } from '_utils/localData'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { AiFillGift } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'

const grayStyle = {
  filter: 'grayscale(100%)',
}

const statusProps = {
  '-1': 'bg-secondary',
  1: 'bg-warning',
  2: 'bg-success',
  3: 'bg-success',
  4: 'bg-primary',
}

const fulfillmentTypeMap = {
  [DELIVERY_OPTION]: 'Delivery',
  [SELF_PICK_UP]: 'Self pickup',
  [NO_FULFILLMENT]: 'No fulfillment',
}

function PurchaseItem({ item, cancelPurchase }) {
  const history = useHistory()
  const userInfo = getUserInfo()

  const directToPayment = (_item) => {
    setCartOrderDetailsByRef({ ref: _item.ref, email: item.uEmail })

    history.push(`/virtual-shop/payment-code/${_item.ref}`, { from: 'my-purchase' })
  }

  return (
    <>
      {(item.buyerStatus === BUYER_ORDER_UNPAID || item.visible === VISIBLE) && (
        <div
          className='mb-3 p-3 rounded shadow-sm bg-white'
          style={item.status === BUYER_ORDER_CANCELED ? grayStyle : {}}
        >
          <div className='row align-items-start mb-3'>
            <div className='col-6 cursor-pointer'>
              <button
                className='shop-name bg-transparent border-0 p-0 fw-bold w-100 text-start'
                onClick={() => directToPayment(item)}
                style={{ overflowWrap: 'anywhere' }}
              >
                {item.shopName.slice(0, 100)} - Order Number:{' '}
                {maskString(item.ref, 6).replace(/\*/g, '')}
              </button>
            </div>
            <div className='col-6'>
              {item.cashOnDeliveryPickup && (
                <span className={'badge float-end bg-info ms-2'}>
                  {item.fulfilmentType === DELIVERY_OPTION ? 'COD' : 'COP'}
                </span>
              )}

              <span className={classNames('badge float-end ms-2', statusProps[`${item?.status}`])}>
                {item?.status === BUYER_ORDER_CANCELED && 'Canceled'}
                {item?.status === BUYER_ORDER_UNPAID && 'Pending'}
                {item?.status === BUYER_ORDER_PAID && 'Paid'}
                {item?.status === HOST_ORDER_VERIFIED && 'Verified'}
                {item?.status === HOST_ORDER_FULFILLED && 'Fulfilled'}
              </span>
              <span className={'badge float-end bg-info'}>
                {fulfillmentTypeMap[item.fulfilmentType]}
              </span>
              {item?.deliveryDetail?.giftRecipientInfo && (
                <AiFillGift className='text-danger float-end me-2' />
              )}
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='date-order' style={{ fontWeight: 500 }}>
              Created: {moment(item.createdAt * 1000).format('DD/MM/YYYY, h:mm:ss a')}
            </div>
            <div className='payment d-flex align-items-center'>
              {userInfo?.id && item?.status === BUYER_ORDER_UNPAID && (
                <button
                  className={classNames(
                    'btn bg-transparent border-0 rounded shadow-none text-secondary fw-bold'
                  )}
                  style={{ fontSize: 12 }}
                  onClick={() => cancelPurchase(item)}
                >
                  Cancel
                </button>
              )}

              {item?.status === BUYER_ORDER_UNPAID && (
                <button
                  className='btn btn-confirm rounded mt-0 ms-2 shadow-none text-white'
                  onClick={() => directToPayment(item)}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PurchaseItem
