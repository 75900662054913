import { isNumber } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ProductItemContext } from '.'

function ProductInventory({ availableOfStock, product }) {
  const [isEditInventory, setIsEditInventory] = useState(false)
  const productHaveVariants = product?.variations?.length > 0

  const { isSavedProduct } = useSelector((state) => state.product)
  const { handleSubmit, register, setValue, errors, isSubmitting, handleSave } =
    useContext(ProductItemContext)

  useEffect(() => {
    if (isSavedProduct) setIsEditInventory(false)
  }, [isSavedProduct])

  const toggleModalEditProduct = () => {
    if (productHaveVariants) return
    setIsEditInventory(!isEditInventory)
  }

  const onSubmit = (data) => {
    handleSave(data)
    toggleModalEditProduct()
  }

  return (
    <div className='cursor-pointer'>
      {(!isEditInventory && !isNumber(product?.availableOfStock)) || !availableOfStock ? (
        <div className='text-content text-end' onClick={toggleModalEditProduct}>
          0
        </div>
      ) : (
        <div className='text-content text-end' onClick={toggleModalEditProduct}>
          {!productHaveVariants ? (
            <CurrencyFormat
              value={availableOfStock || 0}
              displayType={'text'}
              thousandSeparator={true}
            />
          ) : (
            'You just can edit inventory in variants'
          )}
        </div>
      )}

      <Modal
        isOpen={isEditInventory}
        toggle={toggleModalEditProduct}
        className='modal-edit-product'
      >
        <ModalHeader toggle={toggleModalEditProduct}>Available Of Stock</ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <label htmlFor='product-availableOfStock' className='mb-0'>
              Enter available of stock:
            </label>

            <CurrencyFormat
              id='product-availableOfStock'
              value={availableOfStock || 0}
              displayType={'input'}
              thousandSeparator={true}
              className='form-control-sm border w-100 mb-2'
              onValueChange={(values) => {
                const { value } = values
                setValue('availableOfStock', value)
              }}
              {...register('availableOfStock')}
            />

            {errors.availableOfStock && (
              <small className='text-danger'>{errors.availableOfStock.message}</small>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-submit' type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

export default ProductInventory
