import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

function SearchShop({ setSearch, styles, setLoadingDebounce }) {
  const printValue = useCallback(
    debounce((value) => {
      setSearch(value)
      setLoadingDebounce(false)
    }, 500),
    []
  )

  const handleChange = ({ target }) => {
    printValue(target.value)
    setLoadingDebounce(true)
  }

  return (
    <div className='c-form c-form--search' style={styles}>
      <div className='form-group' style={styles}>
        <i className='icon-search'></i>
        <input
          className='form-control'
          placeholder='Search Shop Name'
          type='text'
          onChange={handleChange}
          data-cy='search-shop'
        />
      </div>
    </div>
  )
}

SearchShop.propTypes = {
  setSearch: PropTypes.func.isRequired,
  styles: PropTypes.object,
}

export default SearchShop
