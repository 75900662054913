import React from 'react'

function Search({ searchString, handleSearch }) {
  return (
    <div className='c-form c-form--search me-3 mt-0'>
      <div className='form-group' style={{ marginTop: 0 }}>
        <i className='icon-search'></i>
        <input
          className='form-control'
          placeholder='Search'
          type='text'
          value={searchString}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

export default Search
