import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { PLATFORM_CO_KII } from '_utils/constant'
import React, { useContext } from 'react'
import Fulfillment from '../CoHoot/Fulfillment'
import FulfillmentDate from '../CoHoot/FulfillmentDate'
import OrderDate from '../CoHoot/OrderDate'
import OrderStatus from '../CoHoot/OrderStatus'

function SelectFilter() {
  const { tabOrderPlatform } = useContext(OrderRecordContext)
  return (
    <div className='select-filter d-flex align-items-center ms-3 w-75'>
      <OrderDate />
      <OrderStatus />
      {tabOrderPlatform === PLATFORM_CO_KII && <Fulfillment />}
      <FulfillmentDate />
    </div>
  )
}

export default SelectFilter
