import { selectPaymentMethod } from '_redux/modules/package'
import { HITPAY_PAYMENT_ONLY_ONCE, SUBSCRIPTION_STATUS_APPROVED } from '_utils/constant'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { FormHitpay } from './components/FormHitpay'
import { SelectPaymentMethod } from './components/SelectPaymentMethod'
import './style.scss'

function ModalGeneralPayment({
  isOpenModal,
  toggleModal,
  nextStep,
  setNextStep,
  noCheckSubscription = false,
  isOpenRecurring = false,
}) {
  const dispatch = useDispatch()
  const [titleModal, setTitleModal] = useState('')
  const { selectedPaymentMethod } = useSelector((state) => state.package)
  const { currentPackage: currentSubscription } = useSelector((state) => state.subscription)

  useEffect(() => {
    if (currentSubscription?.price === 0 && !noCheckSubscription) {
      setTitleModal('Upgrade Lazada')
    } else {
      if (nextStep === 1) {
        setTitleModal('Select Payment Method')
        dispatch(selectPaymentMethod(HITPAY_PAYMENT_ONLY_ONCE))
      }

      if (nextStep === 2) {
        setTitleModal(
          selectedPaymentMethod === HITPAY_PAYMENT_ONLY_ONCE
            ? 'Payment Only Once'
            : 'Payment Recurring'
        )
      }
    }
  }, [nextStep])

  useEffect(() => {
    if (isOpenModal) {
      setNextStep(1)
    }
  }, [])

  const checkCurrentSubscriptionInvalid = (_currentSubscription) => {
    if (_currentSubscription?.price === 0) {
      return true
    }

    return _currentSubscription?.status !== SUBSCRIPTION_STATUS_APPROVED
  }

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggleModal}
      className={'modal-subscription-payment-request f-16'}
    >
      <ModalHeader toggle={toggleModal}>{titleModal}</ModalHeader>
      <ModalBody>
        {checkCurrentSubscriptionInvalid(currentSubscription) && !noCheckSubscription ? (
          <div>
            You are currently using the basic subscription package. To upgrade to Lazada, you will
            need to upgrade your package.
          </div>
        ) : (
          <>
            {nextStep === 1 && <SelectPaymentMethod isOpenRecurring={isOpenRecurring} />}

            {nextStep === 2 && <FormHitpay toggleModal={toggleModal} />}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {checkCurrentSubscriptionInvalid(currentSubscription) && !noCheckSubscription ? null : (
          <>
            {nextStep === 1 && (
              <button type={'button'} className={'btn btn-primary'} onClick={() => setNextStep(2)}>
                Next
              </button>
            )}

            {nextStep === 2 && (
              <button
                type={'button'}
                className={'btn btn-outline-secondary text-secondary w-100 text-center shadow-sm'}
                onClick={() => setNextStep(1)}
              >
                Back
              </button>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ModalGeneralPayment
