import packagesApi from '_api/packages'
import uploadApi from '_api/upload'
import { PACKAGE_LAZADA, PACKAGE_SUBSCRIPTION, PAYMENT_HITPAY } from '_utils/constant'

const FETCH_LIST_PACKAGE_SUBSCRIPTION = 'FETCH_LIST_PACKAGE_SUBSCRIPTION'
const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD'
const SET_SUBMITTED_PAYMENT = 'SET_IS_SUBMITTED_PAYMENT'
const UPLOAD_PAYMENT_SCREENSHOT = 'UPLOAD_PAYMENT_SCREENSHOT'
const SELECT_PACKAGE = 'SELECT_PACKAGE'
const UNSELECT_PACKAGE = 'UNSELECT_PACKAGE'
const SUBMIT_PAYMENT_REQUEST = 'SUBMIT_PAYMENT_REQUEST'
const FETCH_LIST_PACKAGE_LAZADA = 'FETCH_LIST_PACKAGE_LAZADA'

const initialState = {
  listPackageConfig: [],
  listPackageByType: [],
  selectedPaymentMethod: PAYMENT_HITPAY,
  isSubmittedPaymentRequest: false,
  paymentScreenshotUrl: '',
  selectedPackage: {},
  listPackageSubscription: [],
  listPackageLazada: [],
}

export const setIsSubmittedPaymentRequest = (value) => (dispatch) => {
  dispatch({
    type: SET_SUBMITTED_PAYMENT,
    payload: { isSubmittedPaymentRequest: value },
  })
}

export const submitPaymentRequestSuccess = () => (dispatch) => {
  dispatch({
    type: SUBMIT_PAYMENT_REQUEST,
    payload: {
      isSubmittedPaymentRequest: true,
      paymentFileUrl: '',
    },
  })
}

export const unselectPackage = () => (dispatch) => {
  dispatch({
    type: UNSELECT_PACKAGE,
    payload: { selectedPackage: {} },
  })
}

export const selectPackage = (pack) => (dispatch) => {
  dispatch({
    type: SELECT_PACKAGE,
    payload: { selectedPackage: pack },
  })
}

export const userUploadPaymentScreenshot = (event) => async (dispatch) => {
  const { files } = event.target
  if (files) {
    const img = files[0]

    if (img) {
      dispatch({
        type: UPLOAD_PAYMENT_SCREENSHOT,
        payload: { paymentScreenshotUrl: URL.createObjectURL(img) },
      })

      try {
        const { msgResp } = await uploadApi.uploadPhoto(files)
        dispatch({
          type: UPLOAD_PAYMENT_SCREENSHOT,
          payload: { paymentScreenshotUrl: msgResp.url },
        })
      } catch (e) {
        throw new Error(e.message || e.msgResp)
      }
    }
  }
}

export const selectPaymentMethod = (method) => (dispatch) => {
  dispatch({
    type: SELECT_PAYMENT_METHOD,
    payload: {
      selectedPaymentMethod: method,
    },
  })
}

export const fetchListPackageByType = (type) => async (dispatch) => {
  try {
    const { msgResp } = await packagesApi.getListPackagesByType(type)

    if (type === PACKAGE_SUBSCRIPTION) {
      dispatch({
        type: FETCH_LIST_PACKAGE_SUBSCRIPTION,
        payload: { listPackageSubscription: msgResp },
      })
    } else if (type === PACKAGE_LAZADA) {
      dispatch({
        type: FETCH_LIST_PACKAGE_LAZADA,
        payload: { listPackageLazada: msgResp.sort((a, b) => b.createdDate - a.createdDate) },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_PACKAGE_LAZADA:
    case SUBMIT_PAYMENT_REQUEST:
    case UNSELECT_PACKAGE:
    case SELECT_PACKAGE:
    case UPLOAD_PAYMENT_SCREENSHOT:
    case SET_SUBMITTED_PAYMENT:
    case SELECT_PAYMENT_METHOD:
    case FETCH_LIST_PACKAGE_SUBSCRIPTION:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default packageReducer
