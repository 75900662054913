import React from 'react'

function DeliveryName({ optionName, register, errors }) {
  return (
    <div className='mb-3 row'>
      <div className='col-12 d-flex flex-column'>
        <span>Option Name</span>
        <input
          type='text'
          className='form-control-sm border'
          defaultValue={optionName}
          placeholder='Delivery'
          {...register('optionDeliveryName')}
        />
        <small className='errorMessage' style={{ fontSize: '14px' }}>
          {errors?.optionDeliveryName?.message}
        </small>
      </div>
    </div>
  )
}

export default DeliveryName
