import React from 'react'
import CategoryItem from './CategoryItem'

function CategoryList({ listProduct, handleAddCategory, handleRemoveCategory, setListProduct }) {
  return (
    <div className='list-category mb-5'>
      {listProduct?.map((category, index) => (
        <CategoryItem
          key={index}
          index={index}
          categoryName={category.category}
          handleRemoveCategory={handleRemoveCategory}
          setListProduct={setListProduct}
        />
      ))}
      <p className='text-center cursor-pointer' onClick={handleAddCategory}>
        Add category
      </p>
    </div>
  )
}

export default CategoryList
