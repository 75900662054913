import reportApi from '_api/report'
import userApi from '_api/user'
import { FEEDBACK } from '_utils/constant'
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

const FeedBack = () => {
  const { addToast } = useToasts()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(null)

  const toggle = () => setIsOpenModal(!isOpenModal)

  const onChangeFeedback = (e) => {
    const {
      target: { value },
    } = e
    if (value && error) setError(null)
    setMessage(value)
  }

  const onSubmitFeedback = () => {
    if (!message.trim()) {
      setError('Content cannot be blank')
    } else {
      userApi
        .createFeedBack({ type: FEEDBACK, message })
        .then(({ msgCode }) => {
          if (msgCode % 100 === 0) {
            addToast('Thank you!', { appearance: 'success', autoDismiss: true })
            setMessage('')
            toggle()
          }
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `${msgCode} - ${msgResp}` })
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
        })
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      setError(null)
    }
  }, [isOpenModal])

  return (
    <React.Fragment>
      <div className='col-12'>
        <p className='text-center c-txt-9 mb-2'>
          <strong>We appreciate your feedback!</strong>
        </p>
        <p
          style={{ fontSize: '12px', textAlign: 'center', marginBottom: '1rem', marginTop: '10px' }}
        >
          Or drop us an email to: <a href='mailto:friends@co-hoot.com'>friends@co-hoot.com</a>
        </p>
      </div>
      <div className='col-12'>
        <button onClick={toggle} className='btn btn--main w-100'>
          Feedback
        </button>
      </div>

      <Modal isOpen={isOpenModal} toggle={toggle}>
        <h2 className='text-center c-ttl'>
          <strong id='groupMemberName'>Feedback</strong>
        </h2>
        <ModalBody>
          <textarea value={message} onChange={onChangeFeedback} className='form-control' />
          <small style={{ color: '#ff9900' }}>{error}</small>
        </ModalBody>
        <ModalFooter>
          <div className='flex-fill'>
            <button onClick={toggle} className='btn btn--solid btn--solid04'>
              CANCEL
            </button>
          </div>
          <div className='flex-fill'>
            <button onClick={onSubmitFeedback} className='btn btn--org btn--org02'>
              OK
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default FeedBack
