/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'

import { yupResolver } from '@hookform/resolvers/yup'
import reportApi from '_api/report'
import userApi from '_api/user'
import { getUserInfo, setUserInfo } from '_utils/localData'
import { isNull } from 'lodash'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phone: yup.string().required('Phone is required'),
})

const InfoItem = ({ data, name, placeholder, error, type = 'text', register }) => (
  <div className='form-group'>
    <input
      defaultValue={data[name]}
      placeholder={placeholder}
      className='form-control'
      type={type}
      {...register(name)}
    />
    {error && <p className='text-danger'>{error.message}</p>}
  </div>
)

const UpdateProfile = () => {
  const { addToast } = useToasts()
  const currentUser = getUserInfo()
  const [newCurrentUser, setNewCurrentUser] = useState({})
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handleSave = async (data) => {
    const { firstName, lastName, phone, postalCode } = data
    const name = `${firstName} ${lastName}`
    const newProfile = { name, phone, postalCode }

    try {
      const res = await userApi.updateUser(newProfile)
      if (res.msgCode % 100 === 0) {
        addToast('Saved Successfully', { appearance: 'success', autoDismiss: true })
        const { msgResp } = await userApi.getUser()
        setUserInfo(msgResp)
      }
    } catch (error) {
      reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })

      if (error.msgCode % 100 === 99) {
        addToast('Something went wrong', { appearance: 'error', autoDismiss: true })
      } else {
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const { name } = currentUser || {}

  useEffect(() => {
    if (name) {
      const firstName = name.split(' ').slice(0, 1).join(' ')
      const lastName = name.split(' ').slice(1).join(' ')
      const nCurrentUser = {
        ...currentUser,
        firstName,
        lastName,
        postalCode: currentUser.postalCode || '',
      }

      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('phone', currentUser?.phone)
      setValue('postalCode', currentUser.postalCode)

      if (!isNull(nCurrentUser)) {
        setNewCurrentUser(nCurrentUser)
      }
    }
  }, [name])

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      {currentUser && (
        <>
          <div className='c-txt-14 text-center'>Profile Update ({currentUser?.email})</div>
          <InfoItem
            register={register}
            data={newCurrentUser}
            name='firstName'
            placeholder='First Name'
            error={errors.firstName}
          />
          <InfoItem
            register={register}
            data={newCurrentUser}
            name='lastName'
            placeholder='Last Name'
            error={errors.lastName}
          />
          <InfoItem
            register={register}
            data={currentUser}
            name='phone'
            placeholder='Mobile Number'
            error={errors.phone}
            type='text'
          />
          <InfoItem
            register={register}
            data={currentUser}
            name='postalCode'
            placeholder='Postal Code'
            error={errors.postalCode}
          />
          <div className='row row--bot align-self-end mt-3'>
            <div className='col-6'>
              <button
                className='btn btn--solid btn--solid03 w-100'
                // onClick={() => {
                //   setCurrentUser(getUserInfo())
                // }}
              >
                CANCEL
              </button>
            </div>
            <div className='col-6'>
              <button className='btn btn--main w-100' type='submit' disabled={isSubmitting}>
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'SAVE'}
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

export default UpdateProfile
