import LazadaLogo from '_images/lazada-logo.png'
import {
  exportProductToLazada,
  selectAndToggleProductLazadaModal,
  selectTab,
} from '_redux/modules/product'
import { PLATFORM_LAZADA } from '_utils/constant'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { ButtonGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'

function ModalShare({ isOpenModalShare, toggleOpenModalShare, ...args }) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [rSelected, setRSelected] = useState(PLATFORM_LAZADA)
  const { productLazadaSelected } = useSelector((state) => state.product)

  const renderTextPlatform = () => 'Lazada'

  const onExportProductToLazada = (product) => {
    if (product?.photoUrls === null || product?.photoUrls === undefined) {
      toggleOpenModalShare()
      return addToast('Please upload product image', { appearance: 'error', autoDismiss: true })
    }

    try {
      dispatch(selectAndToggleProductLazadaModal(product, 'name'))
      dispatch(exportProductToLazada(product))
      dispatch(selectTab(PLATFORM_LAZADA))
    } catch (error) {
      return addToast(error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      toggleOpenModalShare()
    }
  }

  return (
    <Modal
      isOpen={isOpenModalShare}
      toggle={toggleOpenModalShare}
      className='modal-share-product'
      {...args}
    >
      <ModalHeader toggle={toggleOpenModalShare}>Share to platform</ModalHeader>
      <ModalBody>
        <p>Chosen: {renderTextPlatform()}</p>
        <ButtonGroup className='column-gap-2'>
          <button
            className={`icon-platform ${rSelected === PLATFORM_LAZADA ? 'active' : ''}`}
            onClick={() => setRSelected(PLATFORM_LAZADA)}
          >
            <img src={LazadaLogo} alt='lazada-logo' />
          </button>
          {/* <button
            className={`icon-platform ${rSelected === PLATFORM_WIX ? 'active' : ''}`}
            onClick={() => setRSelected(PLATFORM_WIX)}
          >
            <img src={WixLogo} alt='wix-logo' />
          </button> */}
        </ButtonGroup>

        <button
          className='btn btn-submit shadow-none w-auto rounded-pill mt-3 ms-auto'
          onClick={() => onExportProductToLazada(productLazadaSelected)}
        >
          Ok
        </button>
      </ModalBody>
    </Modal>
  )
}

export default ModalShare
