import React, { useEffect, useState } from 'react'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'

function Purchase({ purchaseCount, pid }) {
  const [popoverOpen, setPopoverOpen] = useState(false)

  useEffect(() => {
    if (popoverOpen) {
      setTimeout(() => {
        setPopoverOpen(false)
      }, 3000)
    }
  }, [popoverOpen])

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen)
  }

  return (
    <div className='purchase-count cursor-pointer rounded text-center'>
      <span
        className='d-inline-block w-100 h-100 text-end'
        id={`purchase-count-${pid}`}
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
      >
        {String(purchaseCount)} {/* Cast purchaseCount to a string */}
      </span>
      <Popover
        placement='top'
        isOpen={popoverOpen}
        target={`purchase-count-${pid}`}
        toggle={togglePopover}
      >
        <PopoverHeader className='f-16'>Quantity Sold</PopoverHeader>
        <PopoverBody className='f-14'>
          You have sold <strong className='text-primary'>{String(purchaseCount)}</strong> of this
          product.
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default Purchase
