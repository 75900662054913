import { CheckoutContext } from '_contexts/CheckoutContext'
import { getCartCheckout } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { AiFillGift } from 'react-icons/ai'
import { useSelector } from 'react-redux'

function FormGiftRecipient() {
  const { buyer, register, errors, setValue } = useContext(CheckoutContext)
  const cart = getCartCheckout()
  const { orderDetailsByRef } = useSelector((state) => state.order)

  useEffect(() => {
    if (!isEmpty(cart?.buyer?.giftInfo)) {
      setValue('giftInfo.name', cart.buyer.giftInfo.name)
      setValue('giftInfo.email', cart.buyer.giftInfo.email)
      setValue('giftInfo.phone', cart.buyer.giftInfo.phone)
      setValue('giftInfo.address', cart.buyer.giftInfo.address)
    }
  }, [JSON.stringify(cart)])

  useEffect(() => {
    if (!isEmpty(orderDetailsByRef?.giftRecipientInfo)) {
      setValue('giftInfo.name', orderDetailsByRef?.giftRecipientInfo.name)
      setValue('giftInfo.email', orderDetailsByRef?.giftRecipientInfo.email)
      setValue('giftInfo.phone', orderDetailsByRef?.giftRecipientInfo.phone)
      setValue('giftInfo.address', orderDetailsByRef?.giftRecipientInfo.address)
    }
  }, [JSON.stringify(orderDetailsByRef)])

  return (
    <div
      className='rounded mb-3 bg-white p-3 position-relative'
      style={{
        border: '1px solid lightseagreen',
      }}
    >
      <div className='ribbon-2'>
        <div className='glow'>&nbsp;</div>
        Special
      </div>
      <p className='fw-semibold text-uppercase'>
        Gift Information Form{' '}
        <AiFillGift
          color={'#cc3333'}
          size={24}
          style={{
            transform: 'translateY(-5px)',
          }}
        />
      </p>
      <div className='form-delivery-details'>
        <div className='mb-3'>
          <input
            type='text'
            name='name'
            className='form-control form-control-sm border-0 rounded-pill shadow-sm'
            placeholder='Full Name'
            defaultValue={buyer?.giftInfo?.name}
            {...register('giftInfo.name')}
          />
          {errors?.giftInfo?.name?.message && (
            <small className='text-danger'>{errors?.giftInfo?.name?.message}</small>
          )}
        </div>
        <div className='mb-3'>
          <input
            type='email'
            name='email'
            className='form-control form-control-sm border-0 rounded-pill shadow-sm'
            placeholder='E-mail'
            defaultValue={buyer?.giftInfo?.email}
            {...register('giftInfo.email')}
            style={{
              display: !isEmpty(buyer?.isGift) ? 'none' : 'block',
            }}
          />
          {errors?.giftInfo?.email?.message && (
            <small className='text-danger'>{errors?.giftInfo?.email?.message}</small>
          )}
        </div>
        <div className='mb-3'>
          <input
            type='text'
            name='mobileNumber'
            className='form-control form-control-sm border-0 rounded-pill shadow-sm'
            placeholder='Mobile Number'
            defaultValue={buyer?.giftInfo?.phone}
            {...register('giftInfo.phone')}
          />
          {errors?.giftInfo?.phone?.message && (
            <small className='text-danger'>{errors?.giftInfo?.phone?.message}</small>
          )}
        </div>
        <div className=''>
          <input
            type='text'
            name='address'
            className='form-control form-control-sm border-0 rounded-pill shadow-sm'
            placeholder='Address'
            defaultValue={buyer?.giftInfo?.address}
            {...register('giftInfo.address')}
          />

          {errors?.giftInfo?.address?.message && (
            <small className='text-danger'>{errors?.giftInfo?.address?.message}</small>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormGiftRecipient
