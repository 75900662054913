import React from 'react'
import PurchaseItem from './PurchaseItem'

function ListMyPurchase({ loading, listPurchaseFiltered, cancelPurchase }) {
  if (!loading && listPurchaseFiltered?.length === 0) {
    return <h6 className='text-center my-5 text-uppercase fw-bold'>Have No Purchase</h6>
  }

  if (!loading && listPurchaseFiltered?.length > 0) {
    return listPurchaseFiltered?.map((item, index) => (
      <PurchaseItem key={index} item={item} cancelPurchase={cancelPurchase} />
    ))
  }
}

export default ListMyPurchase
